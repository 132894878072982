.cta-section-09 {
    padding: 55px 0 60px;
    z-index: 1;
    background: #2B59FF;
    position: relative;
    overflow: hidden;
    &__bg-shape{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 40%;
        z-index: -2;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: 768px) {
        padding: 75px 0 80px;
    }

    @media (min-width: 992px) {
        padding: 90px 0 100px;
    }
    &__content{
        margin-bottom: 20px;
        @media (min-width:768px) {
            margin-bottom: 40px;
        }
    }

    &__button-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-right: -5px;
        @media (min-width:445px) {
            margin-right: -24px;
        }
        a {
            display: block;
            max-width: 142px;
            min-width: 142px;
            margin-top: 10px;
            margin-right: 5px;
            @media (min-width:420px) {
                margin-right: 5px;
                margin-bottom: 0px;
                
            }
            @media (min-width:445px) {
                margin-right: 24px;
                margin-bottom: 0px;
                
            }
            @media (min-width:480px) {
                max-width: 150px;
                min-width: 150px;
            }
            @media (min-width:768px) {
                max-width: unset;
                min-width: unset;
            }
            img{
                @media (max-width:768px) {
                    width: 100%;
                }
            }
        }
    }
}