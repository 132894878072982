.counter-section-08 {
    background: #FFFFFF;
    padding: 60px 0;
    @media (min-width:992px) {
        padding: 0;
        padding-bottom: 62px;
        background-color: inherit;
    }

    &__wrapper {
        .widget-counter {
            @media (min-width: 768px) {
                flex-direction: column;
            }
        }

        @media (min-width:992px) {
            margin-top: -117px;
            z-index: 11;
            position: relative;
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
            border-radius: 10px;
            padding: 60px 0;

            .col-xl-3 {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    height: 60px;
                    width: 1px;
                    background-color: rgb(43, 89, 255, 0.1);
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}