.error-section{
    padding: 60px 0;
    background: rgb(55, 124, 253,0.02);
    @media (min-width:768px) {
        padding: 80px 0;
    }
    @media (min-width:992px) {
        padding: 100px 0;
    }
    @media (min-width:1200px) {
        padding: 130px 0;
    }
    &__content{
        .title{
            padding-bottom: 16px;
            @media (min-width:1200px) {
                padding-bottom: 30px;
            }
        }
        .btn{
            margin-top: 30px;
            @media (min-width:576px) {
                margin-top: 40px;
                min-width: 192px;
            }
        }
    }
}