.text-bule{
    color: #2B59FF !important;
}
.text-yellow{
    color: #FFC83E !important;
}
.text-green{
    color: #00BB98 !important;
}
.text-red{
    color: #FD4C5C !important;
}
.text-violet{
    color: #781BFF !important;
}
.text-dodger-blue{
    color: #001C80;
}
.bg-dodger-blue{
    background-color: #001C80;
}

.group-green{
    border-color: #00BB98 !important;
    color: #00BB98 !important;
}
.group-violet{
    border-color: #781BFF !important;
    color: #781BFF !important;
}
.group-red{
    border-color: #FD4C5C !important;
    color: #FD4C5C !important;
}
.group-yellow{
    border-color: #FFC83E !important;
    color: #FFC83E !important;
}

.text-gray{
    color: rgb(0, 11, 51,0.5) !important;
}
.text-grren{
    color: #00BB98 !important; 
}