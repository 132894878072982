.feature-section-02 {
    padding-top: 25px;
   padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 0;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding: 100px 0;
    }
    @media (min-width:1200px) {
        padding: 60px 0 125px;
    }
    .widget-feature-02{
        p{
            font-size: 16px;
            line-height: 26px;
            color: rgb(0, 11, 51,0.8); 
        }
    }
}