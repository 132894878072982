.service-section-03 {
    padding: 60px 0;

    @media (min-width:768px) {
        padding: 80px 0;
    }

    @media (min-width:768px) {
        padding: 130px 0;
    }

    &__sidebar {
        padding: 30px 20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        border-radius: 10px;

        @media (min-width:420px) {
            padding: 40px;
        }

        .title {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 34px;
        }

        &--list {
            li {
                list-style: none;
                padding: 18px 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                a {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 1.444444444444444;
                    color: #000B33;
                    @media (min-width:420px) {
                        font-size: 18px;
                    }
                }

                &.active {
                    a {
                        color: #377CFD;
                    }
                }
            }
        }
    }

    &__wrapper {
        &--content {
            .titel {
                margin-bottom: 24px;
            }
        }

        &--job-list {
            margin: 0;
            padding: 0;
            margin-top: 30px;
            @media (min-width:576px) {
                margin-top: 60px;
            }
            li {
                margin-bottom: 24px;
                list-style: none;
                &:last-child{
                    margin-bottom: 0;
                }
                a {
                    background: #FFFFFF;
                    border: 0.25px solid rgb(0, 11, 51, 0.3);
                    border-radius: 10px;
                    padding: 30px;
                    display: block;
                    transition: 0.3s;
                    @media (min-width:576px) {
                        padding: 40px;
                    }
                    &:hover {
                        border: 0.25px solid transparent;
                        box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
                        .list-content {
                            &--icon {
                                svg {
                                    fill: #FD4C5C;
                                }
                            }
                        }
                    }

                    .list-content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &--texts {
                            h6 {
                                line-height: 1.25;
                                font-weight: 600;
                                font-size: 18px;
                                padding-bottom: 15px;

                                @media (min-width:420px) {
                                    font-size: 20px;
                                }
                                @media (min-width:576px) {
                                    font-size: 24px;
                                }
                            }

                            span {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 1.3;
                                color: rgb(0, 11, 51, 0.5);
                                @media (min-width:420px) {
                                    font-size: 18px;
                                }
                                @media (min-width:576px) {
                                    font-size: 20px;
                                }
                            }
                        }

                        &--icon {
                            svg {
                                fill: #000B33;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
        }
    }
}