.content-section-10{
    padding: 55px 0 60px;
    background: rgb(0, 28, 128,0.02);
    overflow: hidden;
    @media (min-width:768px) {
        padding-top: 75px;
        padding-bottom: 80px;
    }
    @media (min-width:992px) {
        padding: 95px 0 100px;
    }
    @media (min-width:992px) {
        padding: 125px 0 130px;
    }
    &__content{
      margin-bottom: 35px;
    @media (min-width:576px) {
     margin-bottom: 57px;
    }
    @media (min-width:992px) {
     margin-bottom: 97px;
    }
}
}

.screenshot-slider-wrapper-10 {
    position: relative;
    .phone-bg-img {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 11;
      // display: none;
      // min-height: 700px !important;
      // max-height: 700px !important;
      // @media (min-width: 768px) {
      //   max-height: 680px !important;
      //   min-height: 680px !important;
      // }
      width: 267px;
      transform: translate(-50%, -50%);
      @media (min-width: 690px) {
          width: 256px;
          transform: translate(-50%, -50%);
        display: block;
      }
      > img {
        width: 100%;
      }
    }
  
  
    .screenshot-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      overflow: hidden;
      // min-height: 400px !important;
      // max-height: 680px !important;
      @media (min-width: 768px) {
      //   max-height: 680px !important;
      //   min-height: 680px !important;
      }
      
      @media (min-width: 690px) {
        width: 230px;
      }
      @media (min-width: 850px) {
          // max-height: 680px !important;
          // min-height: 680px !important;
      }
  
      > img {
        max-width: 100%;
        border: 1px solid #efeaf7;
        filter: drop-shadow(0px 18px 26px rgba(65, 32, 103, 0.06));
        border-radius: 10px;
      }
  
      .screenshot-slider {
        .slick-list {
          margin: 0 -23.5px;
        }
  
        .slick-slide {
          opacity: 0.5;
          transition: 0.4s;
        }
  
        .slick-center {
          opacity: 1;
          border-radius: 10px;
          @media (min-width: 850px) {
              transform: scale(1.1);
              // max-height: 700px !important;
              // min-height: 700px;
          }
          @media (min-width: 768px) {
          //   max-height: 680px !important;
          //   min-height: 680px;
          }
          img {
            border-radius: 10px;
          }
        }
      }
    }
  }