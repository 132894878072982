.content-section-19{
    background: rgb(43, 89, 255,0.02);
    padding: 65px 0 62px;
    position: relative;
    &__dots{
        position: absolute;
        right: 0;
        bottom: 8%;
        width: 5%;
    }
    @media (min-width:768px) {
        padding: 80px 0;
        
    }
    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 160px;
    }
    @media (min-width:1200px) {
        padding-top: 130px;
        padding-bottom: 187px;
    }
    &__image-group{
        margin-bottom: 60px;
        @media (min-width:992px) {
            margin-bottom: 0px;
            
        }
        position: relative;
        img{
        filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
        border-radius: 20px;
        }
        &--card{
            position: absolute;
            bottom: -13%;
            right: 0;
            width: 54.5%;
            img{
            filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
            border-radius: 20px;
            }
        }
    }
    &__content{
        &--list{
            display: grid;
            grid-template-columns: repeat(2, minmax(0px, 200px));
            @media (min-width:375px) {
                grid-template-columns: repeat(2, minmax(175px, 200px));
            }
            @media (min-width:575px) {
                grid-template-columns: repeat(2, minmax(220px, 200px));
            }
            padding: 0px;
            margin: 0;
            margin-top: 30px;
            overflow: hidden;
            list-style: none;
            margin-bottom: -15px;
            li{
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                svg{
                    margin-right: 10px;
                }
            }
        }
    }
}