.portfoliogrid-section-02{
    padding: 36px 0;
    background: rgb(55, 124, 253,0.02);
    @media (min-width:768px) {
        padding: 56px 0;
    }
    @media (min-width:992px) {
        padding: 106px 0;
    }
    &__tab{
        text-align: center;
        margin-bottom: 30px;
        @media (min-width:992px) {
            margin-bottom: 60px;
        }
        .btn{
            margin: 0 10px;
            margin-bottom: 10px;
            list-style: none;
            padding: 8px 15px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #FD4C5C;
            border: 2px solid #FD4C5C;
            background: transparent;
            border-radius: 3px;
            transition: .3s;
            &:last-child{
                margin-right: 0;
            }
            &:first-child{
                margin-left: 0;
            }
            @media (min-width:992px) {
                margin-bottom: 0px;
                padding: 13px 30px;
                margin: 0 20px;

            }
            &.active{
            background: #FD4C5C;
            color: white;
            }
        }
    }
    .portfolio-masonry{
        &--single{
            position: relative;
            display: block;
            img{
                border-radius: 10px;
            }
            .icon{
                position: absolute;
                top: 30px;
                right: 30px;
                visibility: hidden;
            }
            .content{
                visibility: hidden;
                position: absolute;
                bottom: 30px;
                left: 30px;
                h6{
                font-weight: 600;
                font-size: 18px;
                line-height: 1.3;
                padding-bottom: 5px;
                color: white !important;
                @media (min-width:420px) {
                    fon: 20px;
                }
                }
                span{
                    font-size: 16px;
                    line-height: 26px;
                    color: white !important;
                }
            }
            &::before{
                position: absolute;
                content: "";
                background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
                height: 100%;
                width: 0%;
                transition: .5s;
                opacity: 0;
                visibility: hidden;
                border-radius: 10px;
            }
            &:hover{
                &::before{
                    opacity: 0.7;
                    visibility: visible;
                    width: 100%;
                }
                .icon{
                    visibility: visible;
                }
                .content{
                    visibility: visible;
                }
            }
        }
    }
}