.reset-section-01 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    min-height: 100vh;
    @media (min-width:768px) {
        padding: 80px 0;
    }
    @media (min-width:992px) {
        padding: 100px 0;
    }
    &__logo {
        margin-bottom: 50px;

        @media (min-width:768px) {
            margin-bottom: 100px;
        }
    }
}

.reset-form-01 {
    @media (min-width:420px) {
        background: #FFFFFF;
        box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        border-radius: 10px;
        padding: 50px 25px;
        
    }
    @media (min-width:576px) {
        padding: 60px 45px;

    }
    @media (min-width:992px) {
        padding: 80px 70px;

    }
    @media (min-width:1400px) {
        padding: 100px 110px;

    }
    &__title{
        margin-bottom: 30px; 
        @media (min-width:768px) {
            margin-bottom: 40px; 
        }
        @media (min-width:992px) {
            margin-bottom: 60px; 
        }
    }
    .from {
        &__input-group {
            margin-bottom: 20px;
            &--label{
                font-weight: 600;
                font-size: 18px;
                line-height: 1.3;
                margin-bottom: 15px;
                display: block;
                @media (min-width:992px) {
                    font-size: 20px;
                }
            }
            &--input{
                height: 50px;
                width: 100%;
                padding: 0 30px;
                border: 0.25px solid rgb(43, 89, 255,0.3);
                border-radius: 10px;
                &:focus-visible{
                    outline: none;
                }
                &::placeholder{
                    color: rgb(0, 11, 51,0.5);
                }
                @media (min-width:480px) {
                    height: 60px;
                }
            }

        }
        &__button-group{
            &--one{
                margin-top: 10px;
                .btn{
                   width: 100%;
                }
            }
            &--two{
                margin-top: 30px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                @media (min-width:768px) {
                    flex-direction: row;
                }
                .account-btn{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 1.3;
                    transition: .3s;
                    &:hover{
                        text-decoration: underline;
                    }
                    @media (min-width:768px) {
                        font-size: 20px;
                    }
                }
                .createanaccout-btn{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 1.3;
                    text-decoration-line: underline;
                    color: #FD4C5C;
                    margin-top: 10px;
                    @media (min-width:768px) {
                        font-size: 20px;
                        margin-top: 0px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

}