.about-section-06 {
    padding: 55px 0 60px;
    position: relative;
    z-index: 1;
    &__shape{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 6%;
      }
    @media (min-width:768px) {
        padding: 80px 0;
     }
 
     @media (min-width:992px) {
         padding:100px 0;
     }
     @media (min-width:1200px) {
         padding:130px 0;
     }
    &__content{
        .btn{
            min-width: 150px;
            margin-top: 30px;
            @media (min-width:576px) {
                margin-top: 40px;
            }
        }
    }
    &__card-group{
        .widget-card{
            padding: 40px 30px;
            text-align: start;
            background: #FFFFFF;
            border-radius: 10px;
            transition: .3s;
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
            @media (min-width:768px) {
                box-shadow: none;
                &:hover{
                    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
                }
            }
            .widget-title{
                font-weight: bold;
                font-size: 18px;
                line-height: 1.3;
                margin-bottom: 10px;
                @media (min-width:576px) {
                    font-size: 20px;
                }
            }
            .widget-text{
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: rgb(0, 11, 51,0.8);
            }
        }
    }
}