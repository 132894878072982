.hero-section-06 {
    padding-top: 150px;
    padding-bottom: 80px;
   background: #377CFD;
   @media (min-width:768px) {
    padding-top: 115px;
    padding-bottom: 70px;
   }
   @media (min-width:992px) {
    padding-top: 216px;
    padding-bottom: 197px;
   }
    &__content {
        .title {
            margin-bottom: 15px;
        }

        &--btn-group {
            margin-top: 40px;
            display: inline-flex;
            flex-direction: column;

            .btn {
                min-width: 180px;
                margin-bottom: 25px;
                height: 60px;
            }

            .btn-2 {
                font-weight: 600;
                font-size: 16px;
                color: #FFFFFF;
                display: inline-flex;
                align-items: center;

                img {
                    margin-right: 10px;
                }
            }
        }
    }

    &__image-group {
        position: relative;
        margin-bottom: 60px;
        @media (min-width:992px) {
        margin-bottom: 0;
        }
        @media (min-width:1200px) {
            margin-right: -66%;
        }
        img {
            filter: drop-shadow(-5px 5px 50px rgba(108, 113, 114, 0.1));
            border-radius: 2%;
        }
        &--card{
            position: absolute;
            bottom: -5.6%;
            left: -5.7%;
            width: 22.7%;
            img{
                filter: drop-shadow(0px 140.083px 344.604px rgba(121, 149, 199, 0.21));
            }
        }
        &--circel{
            position: absolute;
            top: -25%;
            left: -6%;
            width: 94%;
            z-index: -1;
            @media (nin-width:576px) {
                left: -15%;
            }
        }
    }
}