.pricing-section-07 {
  background: rgb(55, 124, 253,0.02);
  padding: 56px 0;

  @media (min-width:768px) {
    padding: 76px 0;
  }

  @media (min-width:992px) {
    padding: 125px 0;

  }

  &__title {
    .subtitle {
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 30px;
    }

    margin-bottom: 30px;

    @media (min-width:768px) {
      margin-bottom: 67px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;

    .btn-toggle__wrapper {
      display: flex;
      justify-content: center;
      margin: 0 20px;
    }
      &--text{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      }
  }
  .col-lg-4{
    &:first-child{
      .card-pricing--06{
        &__list{
          li{
            &:last-child{
              display: none;
            }
          }
        }
      }
    }
    &:last-child{
      .card-pricing--06{
        &__list{
          li{
            &:last-child{
              display: none;
            }
          }
        }
      }
    }
  }
  .pricing-sider{
    .slick-slide{
      margin: 0 12px;
    }
  }
}