.team-section-02{
    padding: 55px 0 60px;
    background: rgb(43, 89, 255,0.02);
    @media (min-width:768px) {
        padding: 75px 0 80px;
    }
    @media (min-width:992px) {
        padding: 100px 0;
    }
    @media (min-width:1200px) {
        padding: 130px 0;
    }
    &__button{
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-top: 15px;
        @media (min-width:480px) {
            margin-top: 0;
            justify-content: flex-end;
            
        }
        a{
        align-items: center;
        font-weight: 600;
        line-height: 1.3;
        font-size: 18px;
        display: flex;
        color: #FD4C5C;
        @media (min-width:576px) {
                font-size: 20px;
                
        }
        &::after {
            display: inline-block;
            padding-left: 8px;
            content: "\0279E";
            transition: transform 0.3s ease-out;
        }
        &:hover {
            &::after {
                transform: translateX(4px);
            }
        }
    }
    }
    &__card{
        margin-top: 35px;
        @media (min-width:768px) {
            margin-top: 60px;
            
        }
        .slick-slide {
            margin: 0 12px;
        }
    }
}
