.service-section-02{
    padding: 55px 0 60px;
    background: rgb(43, 89, 255,0.02);
    @media (min-width:768px) {
        padding: 75px 0 80px;
    }
    @media (min-width:992px) {
        padding: 95px 0 100px;
    }
    @media (min-width:1200px) {
        padding: 130px 0;
    }
    position: relative;
    .section-title{
        margin-bottom: 35px;
        @media (min-width:576px) {
            margin-bottom: 60px;
        }
    }
    &__card{
        padding: 50px 45px;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #E8ECFB;
        border-radius: 10px;
        transition: .3s;
        &:hover{
            border-color: transparent;
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        }
        @media (min-width:576px) {
            padding: 35px 30px;

        }
        @media (min-width:1200px) {
            padding: 50px 64px;
        }
        .widget-icon{
            min-width: 70px;
            min-height: 70px;
            max-width: 70px;
            max-height: 70px;
            border-radius: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;
            @media (min-width:768px) {
                min-width: 80px;
                min-height: 80px;
                max-width: 80px;
                max-height: 80px;
            }
        }
        .widget-title{
            font-weight: bold;
            font-size: 18px;
            line-height: 1.444444444444444;
            @media (min-width:576px) {
                font-size: 20px;
            }
        }
        .widget-text{
            font-size: 16px;
            line-height: 26px;
            color: rgb(0, 11, 51,0.8);
        }
    }
    .col-lg-4{
        &:nth-child(1){
            .service-section-02__card{
                .widget-icon{
                    background: rgb(253, 76, 92,0.1);
                }
            }
        }
        &:nth-child(2){
            .service-section-02__card{
                .widget-icon{
                    background: rgb(0, 187, 152,0.1);
                }
            }
        }
        &:nth-child(3){
            .service-section-02__card{
                .widget-icon{
                    background: rgb(55, 124, 253,0.1);
                }
            }
        }
        &:nth-child(4){
            .service-section-02__card{
                .widget-icon{
                    background: rgb(120, 27, 255,0.1);
                }
            }
        }
        &:nth-child(5){
            .service-section-02__card{
                .widget-icon{
                    background: rgb(255, 200, 62,0.1);
                }
            }
        }
        &:nth-child(6){
            .service-section-02__card{
                .widget-icon{
                    background: rgb(0, 28, 128,0.1);
                }
            }
        }
    }
}