.content-section-22 {
    padding: 60px 0;
    background: rgb(43, 89, 255, 0.02);

    @media (min-width:768px) {
        padding: 80px 0;
    }

    @media (min-width:992px) {
        padding: 100px 0;
    }
    @media (min-width:1200px) {
        padding: 130px 0;
    }

    &__image-group {
        position: relative;

        img {
            border-radius: 10px;
            filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
        }

        &--shape {
            bottom: -19%;
            right: 25%;
            position: absolute;
            width: 92.7%;
            z-index: -1;
        }
    }

    &__content {
        &--list {
            margin: 0;
            padding: 0;
            margin-top: 30px;
            list-style: none;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                .icon {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #2B59FF;
                    border-radius: 500px;
                }

                span {
                    margin-left: 10px;
                    display: block;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: #000B33;
                }
            }
        }
        .btn{
            margin-top: 30px;
            @media (min-width:576px) {
                margin-top: 40px;
            }
            min-width: 150px;
        }
    }
}