.testimonil-section-05 {
    padding: 55px 0 60px;
    background: rgb(55, 124, 253,0.02);
    @media (min-width:768px) {
        padding: 75px 0 80px;
      }
    
      @media (min-width:992px) {
        padding: 95px 0 100px;
      }
      @media (min-width:1200px) {
        padding: 125px 0 130px;
    
      }
    .content-title {
        margin-bottom: 30px;

        @media (min-width:768px) {
            margin-bottom: 60px;
        }
    }
    &__slider{
        .slick-list{
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
            border-radius: 10px;
        }
    }
    &__content {
        text-align: center;
        padding: 30px;
        @media (min-width:480px) {
            padding: 40px 60px;
        }
        @media (min-width:1200px) {
            padding: 60px 83px;
            
        }
        .text {
            font-size: 18px;
            line-height: 26px;
            padding-bottom: 42px;
        }
        .client-info {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            @media (min-width:374px) {
                flex-direction: row;
            }
            &--img {
                img {
                    min-width: 70px;
                    min-height: 70px;
                    max-width: 70px;
                    max-height: 70px;
                    object-fit: cover;
                    margin-bottom: 24.29px;
                    border-radius: 500px;
                    @media (min-width:374px) {
                        margin-right: 24.29px;
                        margin-bottom: 0px;
                        
                    }
                }
            }

            &--texts {
                text-align: start;
                .name {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 4px;
                }

                .profession {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
        .quote{
            position: absolute;
            right: 20px;
            bottom: 40px;
        }
    }
    .testimonil-slider--02{
        .slick-list{
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        }
    }
}