/* Header Css Start */
@forward "header";
/* Hero Css Start */
@forward "hero";
/* Fact Css Start */
@forward "fact";
/* About Css Start */
@forward "about";
/* Video Css Start */
@forward "video";
/* Testimonial Css Start */
@forward "testimonial";
/* Cta Css Start */
@forward "cta";
/* Feature Css Start */
@forward "feature";
/* Content Css Start */
@forward "contentOne";
/* Content Css Start */
@forward "contentTwo";
/* Screenshot Css Start */
@forward "screenshot";

