.counter-section-01{
    padding-top: 50px;
    padding-bottom: 50px;
    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 70px;

    }
    @media (min-width:992px) {
        padding-top: 90px;
        padding-bottom: 100px;

    }
    @media (min-width:992px) {
        .col-xl-3{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                height: 60px;
                width: 1px;
                background-color:rgb(43, 89, 255,0.1);
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:last-child{
                &::before{
                    display: none;
                }
            }
        }
    }
}

