.mb-mobile-md{
    margin-bottom: 30px;
    @media (min-width:768px) {
        margin-bottom: 0px;
        
    }
}
.mb-mobile-lg{
    margin-bottom: 30px;
    @media (min-width:992px) {
        margin-bottom: 0px;
        
    }
}
.mb-24{
    margin-bottom: 24px;
}
.mb-20{
    margin-bottom: 15px;
    @media (min-width:576px) {
        margin-bottom: 20px;
        
    }
}
.mt-40{
margin-top: 40px;
@media (min-width:576px) {
    margin-top: 30px;
}
}
.z-index-1{
    z-index: 1;
}

.three-row-mobile-slider,
.four-row-mobile-slider{
    .slick-slide {
        margin: 0 12px;
    }
}