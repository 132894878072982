.about-section-04 {
    padding-top: 60px;
    padding-bottom: 84px;
    position: relative;
    background: rgb(0, 28, 128,0.02);
    z-index: 1;

    @media (min-width:768px) {
       padding-top: 80px;
       padding-bottom: 112px;
    }

    @media (min-width:992px) {
        padding-top: 95px;
        padding-bottom: 160px;
    }
    @media (min-width:1200px) {
        padding-top:125px ;
        padding-bottom:185px ;
    }
    @media (min-width:1400px) {
        padding-bottom:230px ;
    }
    &__circle-1{
        position: absolute;
        top: 53%;
        left: 5%;
        width: 20px;
        height: 20px;
        background: #FFC83E;
        border-radius: 500px;
    }
    &__circle-2{
        position: absolute;
        top: 75%;
        right: 27%;
        width: 20px;
        height: 20px;
        border: 3px solid #00BB98;
        border-radius: 500px;
    }

    &__content {
        .btn {
            border-radius: 10px;
            min-width: 150px;
            margin-top: 35px;
            @media (min-width:992px) {
                margin-top: 40px;

            }
        }
    }

    &__image-group {
        margin-bottom: 50px;
        @media (min-width:576px) {
            margin-bottom: 62px;
            
        }
        @media (min-width:768px) {
            margin-bottom: 68px;
            
        }
        @media (min-width:992px) {
            margin-bottom: 0px;
            
        }
        position: relative;
        img {
            filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
            border-radius: 10px;
        }

        @media (min-width:1200px) {
            padding-left: 77px;
        }

        &--card {
            position: absolute;
            bottom: -10%;
            left: 0;
            width: 27.6%;
            img {
                filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
                border-radius: 10px;
            }
        }
    }

    &--shape {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        img {
            width: 100%;
        }
    }
}