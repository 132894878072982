@use "../../theme/theme-3" as *;
.cta-section-03 {
    padding: 60px 0;
    z-index: 1;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($secondary, .95);
        z-index: -1;
    }

    @media (min-width: 768px) {
        padding: 80px 0;
    }

    @media (min-width: 992px) {
        padding: 100px 0;
    }
    &__content{
        margin-bottom: 20px;
        @media (min-width:768px) {
            margin-bottom: 40px;
        }
    }

    &__button-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-right: -5px;
        @media (min-width:445px) {
            margin-right: -24px;
        }
        a {
            display: block;
            max-width: 142px;
            min-width: 142px;
            margin-top: 10px;
            margin-right: 5px;
            @media (min-width:420px) {
                margin-right: 5px;
                margin-bottom: 0px;
                
            }
            @media (min-width:445px) {
                margin-right: 24px;
                margin-bottom: 0px;
                
            }
            @media (min-width:480px) {
                max-width: 150px;
                min-width: 150px;
            }
            @media (min-width:768px) {
                max-width: unset;
                min-width: unset;
            }
            img{
                @media (max-width:768px) {
                    width: 100%;
                }
            }
        }
    }
}