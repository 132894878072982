.about-section-03 {
    padding: 55px 0 60px;
    background: rgb(55, 124, 253, 0.02);

    @media (min-width:768px) {
        padding: 85px 0 80px;

    }

    @media (min-width:992px) {
        padding: 100px 0;

    }
    @media (min-width:1200px) {
        padding: 130px 0;

    }

    &__widget {
        &--wrapper{

                @media (min-width:992px) {
                    width: 440px;
                }
                padding: 30px;
                background: #FFFFFF;
                box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
                border-radius: 10px;
                margin-bottom: 30px;
    
                &:nth-child(1) {
                    
                    @media (min-width:1200px) {
                    margin-left: 50px;    
                    }
                    .widget-icon {
                        background: rgb(55, 124, 253,0.1)
                    }
                }
    
                &:nth-child(2) {
                    .widget-icon {
                        background: rgb(253, 76, 92,0.1);
                    }
                }
    
                &:nth-child(3) {
                    
                    @media (min-width:1200px) {
                    margin-left: 50px;    
                    }
                    .widget-icon {
                        background: rgb(0, 187, 152,0.1);
                    }
                    margin-bottom: 0;
                }
    
                .widget-icon {
                    margin-right: 20px !important;
                    min-width: 50px;
                    min-height: 50px;
                    max-width: 50px;
                    max-height: 50px;
                    display: flex;
                    border-radius: 500px;
                    align-items: center;
                    justify-content: center;
                }
    
                .widget-title {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 1.3;
                    margin-bottom: 10px;
    
                    @media (min-width:576px) {
                        font-size: 20px;
                    }
                }
    
                .widget-text {
                    color: rgb(0, 11, 51, 0.8);
                    font-size: 16px;
                    line-height: 26px;
                }
        
        }
    }
    &__content{
        .btn{
            min-width: 150px;
            margin-top: 30px;
            @media (min-width:576px) {
                margin-top: 40px;
                
            }
        }
    }
}