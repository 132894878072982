.blogcontent-section-02 {
    padding: 60px 0;

    @media (min-width:768px) {
        padding: 80px 0;
    }

    @media (min-width:992px) {
        padding: 130px 0;
    }
}

/* blog detail card css */
.blogcontent-card {
    background: white;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;

    &--img {
        img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    &__body {
        padding: 20px;

        @media (min-width:576px) {
            padding: 40px;
        }

        &--meta {
            display: flex;
            align-items: center;

            .admmin,
            .date,
            .comment {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                flex-wrap: wrap;
                justify-content: center;
                margin-right: 10px;
                @media (min-width:480px) {
                    margin-right: 60px;
                    
                }

                &:last-child {
                    margin-right: 0;
                }

                svg {
                    fill: #377CFD;
                    margin-right: 10px;
                }

                span {
                    color: #377CFD;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }

        &--texts {
            margin-bottom: 30px;

            h6 {
                font-size: 20px;
                line-height: 1.3;
                font-weight: 600;
                padding-bottom: 15px;
            }

            p {
                font-size: 16px;
                line-height: 26px;
            }
        }

        &--lists {
            margin-bottom: 30px;
            h6 {
                font-size: 20px;
                line-height: 1.3;
                font-weight: 600;
                padding-bottom: 15px;
            }
            ul{
                li {
                    position: relative;
                    list-style: none;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: rgb(0, 11, 51, 0.8);
                    padding: 7.5px 0;
                    padding-left: 20px;
    
                    &:first-child {
                        padding-top: 0;
                    }
    
                    &:last-child {
                        padding-bottom: 0;
                    }
    
                    &::before {
                        position: absolute;
                        content: "";
                        width: 8px;
                        height: 8px;
                        background: #FD4C5C;
                        border-radius: 500px;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        &--social {
            h6 {
                font-size: 20px;
                line-height: 1.3;
                font-weight: 600;
                padding-bottom: 20px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: 0;
                display: flex;
                align-items: center;

                li {
                    list-style: none;
                    padding: 0 7.5px;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                    a {
                        color: rgb(0, 11, 51, 0.8);
                    }
                }
            }
        }
    }
}

/* blog detail next prev buttn css */
.blogcontent-buttons {
    margin: 30px 0;

    @media (min-width:576px) {
        margin: 60px 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__button {
        .tag {
            margin-bottom: 20px;

            span {
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
                color: #377CFD;
            }
        }
    }

    &__previous {
        .prev-content {
            display: flex;
            align-items: center;

            &__text {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                margin-left: 15px;
            }
        }
    }

    &__next {
        .next-content {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;

            &__arrow {
                transform: rotate(180deg);
                margin-top: 5px;
            }

            &__text {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                margin-right: 15px;
            }
        }
    }
}

/* blog detail comment  css */
.blogcontent-comment {
    background: #FFFFFF;
    padding: 40px 20px;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    margin-bottom: 30px;
    @media (min-width:576px) {
        margin-bottom: 60px;
    }

    &__content {
        margin-bottom: 30px;

        .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 1.3;
            padding-bottom: 30px;
            color: #2B59FF !important;

            @media (min-width:420px) {
                font-size: 20px;
            }
        }
    }
}

/* blog detail comment card  css */
.comment-card-01 {
    display: flex;
    padding: 20px;
    padding-right: 20px;
    flex-direction: column;
    @media (min-width:576px) {
        flex-direction: row;

    }
    &--img {
        margin-bottom: 20px;
        @media (min-width:576px) {
            margin-right: 30px;
            margin-bottom: 0px;
        }
        img {
            min-width: 60px;
            min-height: 60px;
            max-width: 60px;
            max-height: 60px;
            object-fit: cover;
            border-radius: 500px;
            @media (min-width:576px) {
                min-width: 80px;
                min-height: 80px;
                max-width: 80px;
                max-height: 80px;
            }
        }
    }
    &__body {
        &--text {
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 20px;
        }

        &--info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .data {
                .name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                }

                .date {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .texts {
                a {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    transition: .3s;
                    &.active{
                        color: rgb(253, 76, 92,0.8) !important;
                    }
                    &:hover{
                     color: rgb(253, 76, 92,0.8) !important;
                    }
                }
            }
        }
    }
}
.comment-card-02 {
    margin-top: 15px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 5px;
    padding: 30px 26px 27px 36px;
    margin-left: 30px;
    flex-direction: column;
    @media (min-width:576px) {
        flex-direction: row;
        margin-left: 105px;

    }
    &--img {
        margin-bottom: 20px;
        @media (min-width:576px) {
            margin-bottom: 0px;
            margin-right: 20px;

        }
        img {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
            object-fit: cover;
            border-radius: 500px;
            @media (min-width:576px) {
                min-width: 50px;
                min-height: 50px;
                max-width: 50px;
                max-height: 50px;
            }
        }
    }
    &__body {
        &--text {
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 20px;
        }

        &--info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .data {
                .name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                }

                .date {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .texts {
                a {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    transition: .3s;
                    &.active{
                        color: rgb(253, 76, 92,0.8) !important;
                    }
                    &:hover{
                     color: rgb(253, 76, 92,0.8) !important;
                    }
                }
            }
        }
    }
}

/* blog detail form box css */
.blogcontent-from {
    padding: 30px 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;

    @media (min-width:576px) {
        padding: 40px;
    }

    &__content {
        margin-bottom: 30px;

        .title {
            color: #2B59FF !important;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.3;
            padding-bottom: 14px;

            @media (min-width:420px) {
                font-size: 20px;
            }
        }

        .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
        }
    }

    .from {
        &__input-group {
            .form-control {
                background: rgb(55, 124, 253, 0.05);
                border-radius: 5px;
                height: 55px;
                border: 0;
                padding: 30px;
                margin-bottom: 20px;
                color: #000B33;

                &::placeholder {
                    color: rgb(0, 11, 51, 0.5);
                }
            }

            &--box {
                background: rgb(55, 124, 253, 0.05);
                border-radius: 5px;
                padding: 30px;
                width: 100%;
                border: 0;
                margin-bottom: 20px;
                color: #000B33;
                max-height: 200px;
                &::placeholder {
                    color: rgb(0, 11, 51, 0.5);
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .btn {
            margin-top: 20px;
            min-width: 150px;
        }
    }
}