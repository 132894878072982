@use "../abstracts/mixins"as utils;

.widget {
  display: flex;

  &-title {
    margin-bottom: 5px;
    @media (min-width:768px) {
      margin-bottom: 10px;
    }
    font-size: 20px;
    line-height: 26px;
  }

  &-icon {
    &--circle {
      border-radius: 500px;
    }
  }

  &-column,
  &-card {
    flex-direction: column;

    .widget-icon {
      // min-height: 68px;
      margin-bottom: 40px;
    }

    &-center {
      text-align: center;
      align-items: center;
    }

  }

  &:not(.widget-column, .widget-card) {
    .widget-tag {
      margin-right: 30px;
      @include utils.square(50px, 50%);
      @include utils.flexAllCenter;
    }
  }

  &.widget-column-till-md {
    flex-direction: column;

    .widget-tag,
    .widget-icon {
      margin-right: 0;
      margin-bottom: 25px;
    }

    @media (min-width:768px) {
      flex-direction: row;

      .widget-tag,
      .widget-icon {
        margin-right: 30px;
        text-align: center;
        margin-bottom: 0px;

        img {
          max-width: 100%;
        }
      }
    }

    &.icon-sizeY-68 {
      min-height: 68px;
      max-height: 68px;
    }
  }


}

.feature-widgets-row,
.widgets-row,
.card-row {
  margin-bottom: -24px;

  [class^="col"] {
    margin-bottom: 24px;
  }
}
.feature-widgets-row-02,
.widgets-row-02,
.card-row-02 {
  margin-bottom: -45px;

  [class^="col"] {
    margin-bottom: 45px;
  }
}


.widget-card-01 {
  padding-top: 31px;
  padding-bottom: 25px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width:480px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width:575px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  .widget-icon {
    margin-bottom: 23px;
  }
}

.widget-counter-lg {
  .widget-title {
    font-size: 42px;
    line-height: 1.097222222222222;
    letter-spacing: -0.5px;

    @media(min-width: 576px) {
      font-size: 52px;
    }

    @media(min-width: 768px) {
      font-size: 62px;

    }

    @media(min-width: 992px) {
      font-size: 72px;

    }
  }

  .widget-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;

    @media(min-width: 768px) {
      font-size: 20px;
    }
  }
}

.widget-counter {
  justify-content: center;
  @media (min-width:768px) {
    flex-direction: row;
    
  }
  .widget-icon {
    margin-bottom: 24px;
    @media (min-width:768px) {
      margin-right: 30px;
      margin-bottom: 0px;
    }
  }

  .widget-title {
    line-height: 1.2;
    letter-spacing: -0.5px;
    font-size: 40px;
    margin-bottom: 10px;

    @media(min-width: 768px) {
      font-size: 50px;

    }

    @media(min-width: 992px) {
      font-size: 60px;

    }
  }

  .widget-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    line-height: 24px;
  }
}