.hero-section-02 {
    padding-top: 95px;
    padding-bottom: 100px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    @media (min-width:480px) {
        padding-bottom: 130px;
    }
    @media (min-width:768px) {
        padding: 110px 0 150px;
    }
    @media (min-width:992px) {
        padding-top: 170px;
        padding-bottom: 120px;
    }
    @media (min-width:1200px) {
        padding-top: 140px;
        padding-bottom: 56px;
    }
    &__shape {
        position: absolute;
        bottom: -3px;
        left: -14px;
        width: 104.5%;
        z-index: -1;
    }

    &__image-group {
        @media (min-width:992px) {
            margin-right: -60%;
            margin-left: -24px;
        }
        @media (min-width:1600px) {
            margin-right: -90%;
        }
    }

    &__content {
        margin-top: 0;
        text-align: center;
        @media (min-width:992px) {
            text-align: start;
            margin-top: -35px;
        }
        .title {
            margin-bottom: 14px;
            @media (min-width:768px) {
                margin-bottom: 20px;
            }
        }

        .btn {
            margin-top: 35px;
            min-width: 236px;
            height: 60px;
            @media (min-width:992px) {
                margin-top: 42px;
            }
        }
        @media (min-width:768px) {
            margin-bottom: 30px;
        }
        @media (min-width:992px) {
            margin-bottom: 0px;
        }
    }
}