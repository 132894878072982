.cta-section-05 {
    &__wrapper {
        @media (min-width:768px) {
            background: #001C80;
        }

        position: relative;
        padding: 60px 0;
        border-radius: 30px;
        z-index: 1;

        @media (min-width:768px) {
            padding: 80px 0;
        }

        &--shape-1 {
            display: none;

            @media (min-width:768px) {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 9.2%;
                z-index: -1;

                svg {
                    width: 100%;
                    border-top-right-radius: 30px;
                }
            }
        }

        &--shape-2 {
            display: none;

            @media (min-width:768px) {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 19%;
                z-index: -2;

                svg {
                    width: 100%;
                    border-top-right-radius: 30px;
                }
            }
        }

        &--shape-3 {
            display: none;

            @media (min-width:768px) {
                display: block;
                position: absolute;
                top: 26%;
                left: 0;
                width: 3%;
                z-index: -2;

                svg {
                    width: 100%;
                }
            }
        }

        &--shape-4 {
            display: none;

            @media (min-width:768px) {
                display: block;
                position: absolute;
                top: 0;
                right: 35.5%;
                width: 18%;
                z-index: -2;

                svg {
                    width: 100%;
                    border-top-right-radius: 30px;
                }
            }
        }
    }

    &__newsletter {
        margin: auto;
        margin-top: 40px;
    }

}

.cta-bg-group-2 {
    background: #001C80;
    position: relative;

    @media (min-width:768px) {
        background: inherit;
    }

    &--shape-1 {
        position: absolute;
        top: 0;
        right: 0;
        width: 9.2%;
        z-index: 1;

        svg {
            width: 100%;
        }

        @media (min-width:768px) {
            display: none;
        }
    }

    &--shape-2 {
        position: absolute;
        top: 0;
        right: 0;
        width: 19%;

        svg {
            width: 100%;
        }

        @media (min-width:768px) {
            display: none;
        }
    }
}