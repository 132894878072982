[data-pricing-dynamic][data-value-active="yearly"] {
  .dynamic-value {
    &:before {
      display: inline-block;
      content: attr(data-yearly);
    }
  }
  [data-pricing-trigger] {
    // background: $primary;
    span {
      left: calc(100% - 33px);
    }
  }
}
[data-pricing-dynamic][data-value-active="monthly"] {
  .dynamic-value {
    &:before {
      display: inline-block;
      content: attr(data-monthly);
    }
  }
}

.dynamic-value {
  &:before {
    display: inline-block;
    content: attr(data-active);
  }
}
.static-value {
  &:before {
    display: inline-block;
    content: attr(data-active);
  }
}

.pricing-btn {
  .btn-toggle {
    position: relative;
    width: 46px !important;
    height: 26px;
    border-radius: 15px;
    border: none;
    // background-color: $white !important;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
      // background-color: $white !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    }
    &:checked {
      // background-color: $electric-violet-2 !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
  }
  label {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 25px;
    // color: var(--color-headings-2);
    margin: 0 15px;
    cursor: pointer;
  }
  .badge {
    margin-bottom: 0;
    // color: $shark;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 96px;
    height: 30px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 22px;
  }
}
