@charset "UTF-8";
.section-title .subtitle,
.block-title .subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}
@media (min-width: 576px) {
  .section-title .subtitle,
.block-title .subtitle {
    font-size: 20px;
  }
}
.section-title .title,
.block-title .title {
  line-height: 1.20833;
  letter-spacing: -0.5px;
  font-size: 31px;
}
@media (min-width: 576px) {
  .section-title .title,
.block-title .title {
    font-size: 34px;
  }
}
@media (min-width: 768px) {
  .section-title .title,
.block-title .title {
    font-size: 38px;
  }
}
@media (min-width: 1200px) {
  .section-title .title,
.block-title .title {
    font-size: 48px;
  }
}
.section-title > p,
.block-title > p {
  font-size: 17px;
  line-height: 1.69;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .section-title > p,
.block-title > p {
    margin-top: 15px;
    font-size: 18px;
  }
}

.hero-content .title {
  line-height: 1.0972222222;
  letter-spacing: -0.5px;
  font-size: 36px;
}
@media (min-width: 768px) {
  .hero-content .title {
    font-size: 41px;
  }
}
@media (min-width: 992px) {
  .hero-content .title {
    font-size: 54px;
  }
}
@media (min-width: 1200px) {
  .hero-content .title {
    font-size: 62px;
  }
}
@media (min-width: 1600px) {
  .hero-content .title {
    font-size: 72px;
  }
}
.hero-content > p {
  font-size: 17px;
  line-height: 1.69;
}
@media (min-width: 768px) {
  .hero-content > p {
    font-size: 18px;
  }
}

.bg-yellow {
  background-color: #FFC83E !important;
}

.floating-Y-animation {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.floating-Y-animation-reverse {
  animation-name: floating-reverse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@keyframes floating-reverse {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(15px);
  }
}
.floating-Y-animation-02 {
  animation-name: floating-02;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.floating-Y-animation-reverse-02 {
  animation-name: floating-reverse-02;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.floating-Y-animation-reverse-03 {
  animation-name: floating-reverse-03;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.floating-X-animation-reverse-01 {
  animation-name: floating-X-reverse-01;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes floating-02 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}
@keyframes floating-reverse-02 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(200px);
  }
}
@keyframes floating-reverse-03 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}
@keyframes floating-X-reverse-01 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(150px);
  }
}
.circle-animation {
  animation: animationShapeCircle 20s infinite linear;
}

@keyframes animationShapeCircle {
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg) scale(1);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}
.circle-animation-02 {
  animation: animationShapeCircle-02 20s infinite linear;
}

.spin-animation {
  animation: spin 20s infinite linear;
}

@keyframes animationShapeCircle-02 {
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(-73px, 1px) rotate(36deg) scale(0.9);
  }
  40% {
    transform: translate(-141px, -72px) rotate(72deg) scale(1);
  }
  60% {
    transform: translate(-83px, -122px) rotate(108deg) scale(1.2);
  }
  80% {
    transform: translate(40px, -72px) rotate(144deg) scale(1.1);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.two-way-circle .round-1, .two-way-circle path:first-child {
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}
.two-way-circle .round-2, .two-way-circle path:nth-child(2) {
  -webkit-animation: spin-reverse 5s linear infinite;
  -moz-animation: spin-reverse 5s linear infinite;
  animation: spin-reverse 5s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

.particle {
  position: absolute;
  z-index: -1;
}

@keyframes particle-animation-1 {
  100% {
    transform: translate3d(54vw, 37vh, 54px);
  }
}
.particle:nth-child(1) {
  animation: particle-animation-1 60s infinite;
  animation-delay: -0.2s;
  transform: translate3d(49vw, 30vh, 100px);
}

@keyframes particle-animation-2 {
  100% {
    transform: translate3d(66vw, 75vh, 2px);
  }
}
.particle:nth-child(2) {
  animation: particle-animation-2 60s infinite;
  animation-delay: -0.4s;
  transform: translate3d(40vw, 6vh, 59px);
}

@keyframes particle-animation-3 {
  100% {
    transform: translate3d(72vw, 33vh, 55px);
  }
}
.particle:nth-child(3) {
  animation: particle-animation-3 60s infinite;
  animation-delay: -0.6s;
  transform: translate3d(35vw, 58vh, 46px);
}

@keyframes particle-animation-4 {
  100% {
    transform: translate3d(87vw, 21vh, 10px);
  }
}
.particle:nth-child(4) {
  animation: particle-animation-4 60s infinite;
  animation-delay: -0.8s;
  transform: translate3d(71vw, 39vh, 52px);
}

@keyframes particle-animation-5 {
  100% {
    transform: translate3d(44vw, 87vh, 25px);
  }
}
.particle:nth-child(5) {
  animation: particle-animation-5 60s infinite;
  animation-delay: -1s;
  transform: translate3d(29vw, 76vh, 89px);
}

@keyframes particle-animation-6 {
  100% {
    transform: translate3d(11vw, 31vh, 10px);
  }
}
.particle:nth-child(6) {
  animation: particle-animation-6 60s infinite;
  animation-delay: -1.2s;
  transform: translate3d(89vw, 11vh, 63px);
}

@keyframes particle-animation-7 {
  100% {
    transform: translate3d(79vw, 48vh, 80px);
  }
}
.particle:nth-child(7) {
  animation: particle-animation-7 60s infinite;
  animation-delay: -1.4s;
  transform: translate3d(55vw, 7vh, 100px);
}

@keyframes particle-animation-8 {
  100% {
    transform: translate3d(88vw, 60vh, 1px);
  }
}
.particle:nth-child(8) {
  animation: particle-animation-8 60s infinite;
  animation-delay: -1.6s;
  transform: translate3d(19vw, 45vh, 17px);
}

@keyframes particle-animation-9 {
  100% {
    transform: translate3d(62vw, 55vh, 48px);
  }
}
.particle:nth-child(9) {
  animation: particle-animation-9 60s infinite;
  animation-delay: -1.8s;
  transform: translate3d(62vw, 59vh, 92px);
}

@keyframes particle-animation-10 {
  100% {
    transform: translate3d(31vw, 49vh, 37px);
  }
}
.particle:nth-child(10) {
  animation: particle-animation-10 60s infinite;
  animation-delay: -2s;
  transform: translate3d(24vw, 60vh, 24px);
}

@keyframes particle-animation-11 {
  100% {
    transform: translate3d(42vw, 37vh, 26px);
  }
}
.particle:nth-child(11) {
  animation: particle-animation-11 60s infinite;
  animation-delay: -2.2s;
  transform: translate3d(87vw, 28vh, 69px);
}

@keyframes particle-animation-12 {
  100% {
    transform: translate3d(82vw, 51vh, 47px);
  }
}
.particle:nth-child(12) {
  animation: particle-animation-12 60s infinite;
  animation-delay: -2.4s;
  transform: translate3d(6vw, 69vh, 93px);
}

@keyframes particle-animation-13 {
  100% {
    transform: translate3d(33vw, 76vh, 46px);
  }
}
.particle:nth-child(13) {
  animation: particle-animation-13 60s infinite;
  animation-delay: -2.6s;
  transform: translate3d(61vw, 10vh, 70px);
}

@keyframes particle-animation-14 {
  100% {
    transform: translate3d(66vw, 47vh, 12px);
  }
}
.particle:nth-child(14) {
  animation: particle-animation-14 60s infinite;
  animation-delay: -2.8s;
  transform: translate3d(27vw, 60vh, 21px);
}

@keyframes particle-animation-15 {
  100% {
    transform: translate3d(43vw, 73vh, 97px);
  }
}
.particle:nth-child(15) {
  animation: particle-animation-15 60s infinite;
  animation-delay: -3s;
  transform: translate3d(1vw, 49vh, 84px);
}

@keyframes particle-animation-16 {
  100% {
    transform: translate3d(58vw, 24vh, 8px);
  }
}
.particle:nth-child(16) {
  animation: particle-animation-16 60s infinite;
  animation-delay: -3.2s;
  transform: translate3d(40vw, 82vh, 20px);
}

@keyframes particle-animation-17 {
  100% {
    transform: translate3d(53vw, 14vh, 30px);
  }
}
.particle:nth-child(17) {
  animation: particle-animation-17 60s infinite;
  animation-delay: -3.4s;
  transform: translate3d(13vw, 47vh, 89px);
}

@keyframes particle-animation-18 {
  100% {
    transform: translate3d(35vw, 88vh, 37px);
  }
}
.particle:nth-child(18) {
  animation: particle-animation-18 60s infinite;
  animation-delay: -3.6s;
  transform: translate3d(59vw, 17vh, 71px);
}

@keyframes particle-animation-19 {
  100% {
    transform: translate3d(60vw, 77vh, 90px);
  }
}
.particle:nth-child(19) {
  animation: particle-animation-19 60s infinite;
  animation-delay: -3.8s;
  transform: translate3d(20vw, 20vh, 71px);
}

@keyframes particle-animation-20 {
  100% {
    transform: translate3d(38vw, 22vh, 31px);
  }
}
.particle:nth-child(20) {
  animation: particle-animation-20 60s infinite;
  animation-delay: -4s;
  transform: translate3d(59vw, 12vh, 95px);
}

@keyframes particle-animation-21 {
  100% {
    transform: translate3d(32vw, 75vh, 56px);
  }
}
.particle:nth-child(21) {
  animation: particle-animation-21 60s infinite;
  animation-delay: -4.2s;
  transform: translate3d(57vw, 13vh, 79px);
}

@keyframes particle-animation-22 {
  100% {
    transform: translate3d(38vw, 63vh, 85px);
  }
}
.particle:nth-child(22) {
  animation: particle-animation-22 60s infinite;
  animation-delay: -4.4s;
  transform: translate3d(48vw, 88vh, 30px);
}

@keyframes particle-animation-23 {
  100% {
    transform: translate3d(50vw, 43vh, 3px);
  }
}
.particle:nth-child(23) {
  animation: particle-animation-23 60s infinite;
  animation-delay: -4.6s;
  transform: translate3d(67vw, 48vh, 24px);
}

@keyframes particle-animation-24 {
  100% {
    transform: translate3d(54vw, 80vh, 68px);
  }
}
.particle:nth-child(24) {
  animation: particle-animation-24 60s infinite;
  animation-delay: -4.8s;
  transform: translate3d(69vw, 61vh, 98px);
}

@keyframes particle-animation-25 {
  100% {
    transform: translate3d(18vw, 10vh, 83px);
  }
}
.particle:nth-child(25) {
  animation: particle-animation-25 60s infinite;
  animation-delay: -5s;
  transform: translate3d(75vw, 80vh, 71px);
}

@keyframes particle-animation-26 {
  100% {
    transform: translate3d(64vw, 37vh, 3px);
  }
}
.particle:nth-child(26) {
  animation: particle-animation-26 60s infinite;
  animation-delay: -5.2s;
  transform: translate3d(36vw, 47vh, 59px);
}

@keyframes particle-animation-27 {
  100% {
    transform: translate3d(89vw, 45vh, 82px);
  }
}
.particle:nth-child(27) {
  animation: particle-animation-27 60s infinite;
  animation-delay: -5.4s;
  transform: translate3d(70vw, 45vh, 70px);
}

@keyframes particle-animation-28 {
  100% {
    transform: translate3d(77vw, 57vh, 51px);
  }
}
.particle:nth-child(28) {
  animation: particle-animation-28 60s infinite;
  animation-delay: -5.6s;
  transform: translate3d(5vw, 64vh, 58px);
}

@keyframes particle-animation-29 {
  100% {
    transform: translate3d(44vw, 44vh, 36px);
  }
}
.particle:nth-child(29) {
  animation: particle-animation-29 60s infinite;
  animation-delay: -5.8s;
  transform: translate3d(18vw, 57vh, 24px);
}

@keyframes particle-animation-30 {
  100% {
    transform: translate3d(24vw, 2vh, 76px);
  }
}
.particle:nth-child(30) {
  animation: particle-animation-30 60s infinite;
  animation-delay: -6s;
  transform: translate3d(4vw, 28vh, 21px);
}

@keyframes rotation {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(180deg);
  }
}
@-webkit-keyframes animateMark {
  0% {
    background-size: 0% 6px;
  }
  100% {
    background-size: 100% 6px;
  }
}
@keyframes animateMark {
  0% {
    background-size: 0% 6px;
  }
  100% {
    background-size: 100% 6px;
  }
}
@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}
@keyframes sonarWave-02 {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(3.5);
    opacity: 0;
  }
}
.transform-origin-top-right {
  transform-origin: top right;
}

.shape-x-1 {
  animation-name: shape-x-animation-1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes shape-x-animation-1 {
  0% {
    transform: translateX(-8%);
  }
  100% {
    transform: translateX(0);
  }
}
.shape-x-2 {
  animation-name: shape-x-animation-2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes shape-x-animation-2 {
  0% {
    transform: translateX(8%);
  }
  100% {
    transform: translateX(0);
  }
}
.circle-x {
  animation-name: circle-x-animation;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes circle-x-animation {
  0% {
    transform: translateX(-1270%);
  }
  100% {
    transform: translateX(50%);
  }
}
.circle-x-02 {
  animation-name: circle-x-animation-02;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes circle-x-animation-02 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(500%);
  }
}
.text-bule {
  color: #2B59FF !important;
}

.text-yellow {
  color: #FFC83E !important;
}

.text-green {
  color: #00BB98 !important;
}

.text-red {
  color: #FD4C5C !important;
}

.text-violet {
  color: #781BFF !important;
}

.text-dodger-blue {
  color: #001C80;
}

.bg-dodger-blue {
  background-color: #001C80;
}

.group-green {
  border-color: #00BB98 !important;
  color: #00BB98 !important;
}

.group-violet {
  border-color: #781BFF !important;
  color: #781BFF !important;
}

.group-red {
  border-color: #FD4C5C !important;
  color: #FD4C5C !important;
}

.group-yellow {
  border-color: #FFC83E !important;
  color: #FFC83E !important;
}

.text-gray {
  color: rgba(0, 11, 51, 0.5) !important;
}

.text-grren {
  color: #00BB98 !important;
}

/* Background css Start */
/* Common Animations  css Start */
/* Typography css Start */
/* Common colors  css Start */
.transform-origin-top-left {
  transform-origin: top left;
}

.transform-origin-bottom-20p {
  transform-origin: 100% -20%;
}

.pointer-none {
  pointer-events: none;
}

.preloader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  z-index: 999999;
  justify-content: center;
  background: #fff;
}

.preloader-wrapper .text {
  color: #FD4C5C;
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}
.preloader-wrapper .bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
}
.preloader-wrapper .bounceball:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #FD4C5C;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
@media (min-width: 992px) {
  .row-lg {
    display: flex;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slideRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@media (max-width: 991px) {
  .menu-block-wrapper {
    position: relative;
  }
}

.site-menu-main {
  margin-bottom: 0;
  padding-left: 0;
}
@media (min-width: 992px) {
  .site-menu-main {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
  }
}
.site-menu-main li {
  list-style: none;
}
.site-menu-main ul {
  list-style: none;
  margin-left: 0;
}
.site-menu-main a {
  transition: 0.3s;
}
.site-menu-main .nav-item {
  display: inherit;
}
@media (min-width: 992px) {
  .site-menu-main .nav-item:hover > a > i {
    transform: rotate(180deg) !important;
  }
}
.site-menu-main .nav-link-item {
  color: var(--color-headings-2);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5;
  position: relative;
}
@media (min-width: 992px) {
  .site-menu-main .nav-link-item {
    padding-top: 25px !important;
    padding-bottom: 23px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}
@media (min-width: 1200px) {
  .site-menu-main .nav-link-item {
    padding-top: 27px !important;
    padding-bottom: 25px !important;
    padding-left: 27.5px !important;
    padding-right: 27.5px !important;
  }
}

.site-menu-main .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #fff;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  transition: top 0.3s ease, margin-top 0.3s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
  transition: 0.4s;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu {
    pointer-events: none;
  }
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu:not(.megamenu) {
    top: 100%;
    min-width: 227px;
    max-width: 227px;
  }
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu .sub-menu {
    top: 0 !important;
    left: 100%;
  }
}
.site-menu-main .sub-menu--item {
  color: #000B33 !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.75;
  transition: 0.4s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu--item {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
}
.site-menu-main .sub-menu--item > a {
  color: inherit;
  transition: 0.4s;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu--item > a {
    transition: 0.4s;
    padding-top: 7px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.site-menu-main .sub-menu--item > a > i {
  margin-top: 0px;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-menu-main .sub-menu--item:hover > a {
  color: inherit;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu--item:first-child a {
    padding-top: 0;
    padding-bottom: 7px;
  }
}
.site-menu-main .sub-menu--item:last-child {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}
.site-menu-main .sub-menu--item:last-child a {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-left: 40px;
    padding-right: 40px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    will-change: transform;
    top: 100%;
    box-shadow: 0 42px 54px rgba(0, 0, 0, 0.09);
    border: 1px solid #e7e9ed;
    border-radius: 8px;
    visibility: hidden;
    z-index: -99;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    min-width: auto;
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-right {
    right: 0%;
    transform: translate(-12%, 10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-left {
    left: 0%;
    transform: translate(-16%, 10px);
  }
}
.site-menu-main .sub-menu.megamenu .dropdown-image-block {
  max-height: 336px;
}
@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    border: 0;
  }
}
@media (min-width: 1200px) {
  .site-menu-main .sub-menu.megamenu.megadropdown-right {
    right: 0%;
    transform: translate(-12%, 10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-left {
    left: 0%;
    transform: translate(-13%, 10px);
  }
}
@media (min-width: 1366px) {
  .site-menu-main .sub-menu.megamenu.megadropdown-right {
    right: 0%;
    transform: translate(-12%, 0);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-left {
    left: 0%;
    transform: translate(-12%, 0);
  }
}
.site-menu-main .sub-menu.megamenu .single-dropdown-block .mega-drop-menu-item {
  padding-top: 14px;
  padding-bottom: 14px;
  display: block;
}
.site-menu-main .sub-menu.megamenu .single-dropdown-block h3 {
  color: rgba(0, 11, 51, 0.5) !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.75;
  transition: 0.4s;
  margin-left: 10px;
  padding-top: 12px;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu .single-dropdown-block h3 {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 15px;
  }
}
.site-menu-main .sub-menu.megamenu .single-dropdown-block .mega-drop-menu-item {
  padding-top: 8px;
  padding-bottom: 8px;
  display: block;
}
.site-menu-main .sub-menu.megamenu .single-dropdown-block a {
  color: #000B33 !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.75;
  transition: 0.4s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu .single-dropdown-block a {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
}
.site-menu-main .sub-menu.megamenu .single-dropdown-block a:hover {
  color: inherit;
}
.site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 16px;
  font-weight: 700;
  transition: 0.4s;
  color: #000;
}
@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    color: var(--color-headings);
    border-top: 1px solid var(--border-color-3);
    padding-left: 20px;
    padding-bottom: 13px;
    padding-top: 13px;
  }
}
@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    padding-left: 10px;
    padding-right: 10px;
  }
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item:hover {
    color: inherit !important;
  }
}
@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu [class*=col-] {
    padding-left: 0;
    padding-right: 0;
  }
  .site-menu-main .sub-menu.megamenu [class*=row-] {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  li.nav-item-has-children:not(.has-megamenu) {
    position: relative;
  }
}
@media (min-width: 992px) {
  li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 99;
    pointer-events: visible;
    border-radius: 0px 0px 15px 15px;
    border-top: 3px solid inherit;
  }
}

.site-menu-main > li .sub-menu > ul > li > a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}

.site-menu-main > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media (max-width: 991px) {
  .site-header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .site-header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: var(--color-headings-2);
    width: 24px;
    position: relative;
  }

  .site-header .mobile-menu-trigger span:before,
.site-header .mobile-menu-trigger span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-headings-2);
  }

  .dark-mode-texts .mobile-menu-trigger span {
    background-color: #fff;
  }

  .dark-mode-texts .mobile-menu-trigger span:before,
.dark-mode-texts .mobile-menu-trigger span:after {
    background-color: #fff;
  }

  .site-header .mobile-menu-trigger span:before {
    top: -6px;
  }

  .site-header .mobile-menu-trigger span:after {
    top: 6px;
  }

  .site-header .item-right {
    align-items: center;
  }

  .site-header .menu-block {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }

  .site-header .menu-block.active {
    transform: translate(0%);
  }

  .site-menu-main > li {
    line-height: 1;
    margin: 0;
    display: block;
  }

  .site-menu-main > li > a {
    line-height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 991px) and (min-width: 992px) {
  .site-menu-main > li > a {
    display: block;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li > a i {
    position: absolute;
    top: 5px;
    right: 0;
    transform: rotate(-90deg);
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .mobile-menu-head {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .mobile-menu-head.active .go-back {
    display: block;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    visibility: hidden;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .mobile-menu-head.active .current-menu-title {
    visibility: visible;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }
}
@media (max-width: 991px) {
  .site-header .menu-block .site-menu-main {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li .sub-menu.mega-menu,
.site-menu-main > li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li .sub-menu.active {
    display: block;
    z-index: 99;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li .sub-menu > ul > li > a,
.site-menu-main > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }
}
@media (max-width: 991px) {
  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }
}
@media (max-width: 991px) {
  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}
/*Custom Css for menu*/
@media (min-width: 992px) {
  .has-megamenu ul {
    margin-left: auto;
  }

  .site-menu-main a {
    display: flex;
    align-items: center;
  }
  .site-menu-main i {
    transition: 0.3s;
    margin-left: 15px;
    font-size: 15px;
  }
}
.nav-item-has-children a {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .reveal-header .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 992px) {
  .reveal-header .nav-link-item {
    color: var(--color-headings-2) !important;
  }
}

@media (min-width: 992px) {
  .reveal-header li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
  }
  .reveal-header li.nav-item-has-children:hover > .megamenu {
    top: 100%;
  }
}

.menu-block.active .nav-link-item {
  color: #000B33 !important;
}

@media (min-width: 480px) {
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
/*Preloader cs@s*/
.site div#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

div#loading img {
  z-index: 9999;
  width: 25%;
}
@media (min-width: 576px) {
  div#loading img {
    width: 17%;
  }
}
@media (min-width: 768px) {
  div#loading img {
    width: 15%;
  }
}
@media (min-width: 992px) {
  div#loading img {
    width: 7%;
  }
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff !important;
}

.preloader img {
  width: 500px;
}

/*Header Css StaRT*/
.site-navbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .site-navbar {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-top: 0px;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .site-navbar .menu-block-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .site-navbar .site-header__btns {
    display: flex;
  }
}

@media (max-width: 992px) {
  .site-header {
    padding-top: 15px;
  }
}
.site-header--absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.site-header--sticky:not(.mobile-sticky-enable) {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}
@media (min-width: 992px) {
  .site-header--sticky:not(.mobile-sticky-enable) {
    position: fixed !important;
    transition: 0.4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).scrolling {
    transform: translateY(-100%);
    transition: 0.4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header {
    transform: translateY(0%);
    box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    z-index: 1000;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header a {
    color: #000B33;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header .btn-link {
    color: #000B33;
  }
}
.site-header--sticky.mobile-sticky-enable {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  transition: 0.4s;
}
.site-header--sticky.mobile-sticky-enable.scrolling {
  transform: translateY(-100%);
  transition: 0.4s;
}
.site-header--sticky.mobile-sticky-enable.reveal-header {
  transform: translateY(0%);
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
  z-index: 9999;
}
.site-header--menu-center {
  --button-width:265px;
  --translateLG:35px;
  --translateXL:30px;
}
.site-header--menu-center .container {
  position: relative;
}
.site-header--menu-center .menu-block-wrapper {
  position: static;
}
@media (min-width: 992px) {
  .site-header--menu-center .menu-block-inner {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .sub-menu.megamenu {
    left: 50%;
    transform: translateX(-50%) translateY(10px);
  }
}
@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(-50%) translateY(-2px);
    left: 50%;
  }
}
.site-header--menu-left .menu-block .site-menu-main {
  justify-content: flex-start;
}
@media (min-width: 992px) {
  .site-header--menu-left .menu-block .site-menu-main {
    padding-left: 55px;
  }
}
@media (min-width: 1200px) {
  .site-header--menu-left .menu-block {
    width: 100%;
  }
}
.site-header--menu-right .menu-block {
  margin-left: auto;
}
@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .sub-menu.megamenu {
    left: 100%;
    transform: translateX(-100%) translateY(10px);
  }
}
@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(-100%) translateY(-10px);
    left: 100%;
  }
}
.site-header__brand .logo-white {
  display: none;
}
.site-header__brand .logo-black {
  display: block;
}
.site-header .btn-group {
  position: relative;
  display: inline-flex;
  align-items: center;
}

@media (min-width: 480px) {
  .header-btns {
    margin-right: 15px;
  }
}
@media (min-width: 992px) {
  .header-btns {
    margin-right: 0;
  }
}

.dynamic-sticky-bg.reveal-header {
  background: var(--bg);
}
.dynamic-sticky-bg.dark-mode-texts .menu-block.active .nav-link-item {
  color: #000B33 !important;
}
.dynamic-sticky-bg.dark-mode-texts .menu-social-share li a {
  color: var(--color-headings-2);
}

.sticky-bg-white.reveal-header {
  background: #fff;
}
.sticky-bg-white.reveal-header .dark-version-logo {
  display: none !important;
}
.sticky-bg-white.reveal-header .light-version-logo {
  display: block !important;
}
.sticky-bg-white.reveal-header .menu-sep-left {
  position: relative;
}
.sticky-bg-white.reveal-header .menu-sep-left::before {
  content: "";
  border-left: rgba(0, 11, 51, 0.5) !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 26px;
  z-index: 9;
}

.notification-block {
  position: relative;
}
.notification-block .count {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -4px;
  margin-right: -5px;
}

.site-header--dark .nav-link-item {
  color: #fff;
}
.site-header--dark .mobile-menu-trigger span {
  background-color: rgba(255, 255, 255, 0.8);
}
.site-header--dark .mobile-menu-trigger span:before, .site-header--dark .mobile-menu-trigger span:after {
  background-color: rgba(255, 255, 255, 0.8);
}

/*Custer button block */
.site-header__btns--01 {
  display: flex;
}

.btn-group .btn {
  height: 45px;
  min-width: 120px;
}

@media (max-width: 992px) {
  .site-header__btns {
    display: flex;
    padding: 25px 20px 0 !important;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .menu-block-inner {
    display: flex;
    align-items: center;
  }
}

/* Header Center */
.site-header--menu-center .menu-block .site-menu-main {
  padding: 0;
}
.site-header--menu-center .site-menu-main {
  margin: auto;
}

@media (max-width: 992px) {
  .menu-block:not(.active) {
    opacity: 0;
  }
}

/* Header Adjustment */
@media (min-width: 992px) {
  .site-header--menu-center-adjustment .menu-block-inner {
    margin-left: 1.5%;
  }
}

/* Header Logo Dark */
.site-header--logo-dark .site-header__brand .logo-white {
  display: block;
}
.site-header--logo-dark .site-header__brand .logo-black {
  display: none;
}

/* Header trigger Dark */
.mobile-menu-trigger-dark .mobile-menu-trigger span {
  background-color: black;
}
.mobile-menu-trigger-dark .mobile-menu-trigger span::before, .mobile-menu-trigger-dark .mobile-menu-trigger span::after {
  background-color: black;
}

.btn {
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  min-width: 180px;
  line-height: 1.2;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-reset {
  background: transparent;
  border: 0;
}
.btn .btn-l1 {
  min-width: 180px;
  min-height: 50px;
}

button:focus {
  outline: none;
}

.btn-white {
  background-color: white !important;
  color: #781bff !important;
  transition: 0.3s;
}

.pricing-section-01__button {
  --round-offset: 4px;
}
.pricing-section-01__button .btn-toggle {
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
}
.pricing-section-01__button .btn-toggle + label {
  background: linear-gradient(90deg, #8c30f5 0%, #ff4b5c 100%);
  border-radius: 15px;
  width: 46px;
  height: 26px;
  position: relative;
  cursor: pointer;
}
.pricing-section-01__button .btn-toggle + label::before {
  width: 18px;
  height: 18px;
  top: var(--round-offset);
  left: var(--round-offset);
  position: absolute;
  border-radius: 500px;
  background: #fff;
  content: "";
  transition: 0.4s;
}
.pricing-section-01__button .btn-toggle:checked + label:before {
  left: calc(100% - var(--round-offset));
  transform: translateX(-100%);
}

.pricing-section-07__button {
  --round-offset: 4px;
}
.pricing-section-07__button .btn-toggle {
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
}
.pricing-section-07__button .btn-toggle + label {
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  border-radius: 15px;
  width: 46px;
  height: 26px;
  position: relative;
}
.pricing-section-07__button .btn-toggle + label::before {
  width: 18px;
  height: 18px;
  top: var(--round-offset);
  left: var(--round-offset);
  position: absolute;
  border-radius: 500px;
  background: #fff;
  content: "";
  transition: 0.4s;
}
.pricing-section-07__button .btn-toggle:checked + label:before {
  left: calc(100% - var(--round-offset));
  transform: translateX(-100%);
}

/* Video Button */
.video-btn-1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
  border-radius: 500px;
  z-index: 1;
}
@media (min-width: 576px) {
  .video-btn-1 {
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    max-height: 90px;
    border-radius: 500px;
  }
}
.video-btn-1 i {
  font-size: 18px;
  transform: translateX(2px);
}
.video-btn-1::after {
  animation: sonarWave 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: white;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.btn-primary-outline, .card-pricing--03:not(.active) .btn, .card-pricing--04:not(.active) .btn {
  border-color: #2B59FF;
  color: #2B59FF;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.btn-primary-outline:before, .card-pricing--03:not(.active) .btn:before, .card-pricing--04:not(.active) .btn:before {
  background: #2B59FF;
  content: "";
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
  position: absolute;
  z-index: -1;
  transition: transform 0.4s;
  transform-origin: left;
  transform: translateX(-100%);
}
.btn-primary-outline:hover, .card-pricing--03:not(.active) .btn:hover, .card-pricing--04:not(.active) .btn:hover {
  color: #fff !important;
  background: transparent;
}
.btn-primary-outline:hover:before, .card-pricing--03:not(.active) .btn:hover:before, .card-pricing--04:not(.active) .btn:hover:before {
  transition: transform 0.4s;
  transform-origin: right;
  transform: translateX(0%);
}
.btn-primary-outline-reverse, .card-pricing--03.active .btn, .card-pricing--04.active .btn {
  color: #fff !important;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: transparent;
  border: 1px solid #2B59FF;
}
.btn-primary-outline-reverse:before, .card-pricing--03.active .btn:before, .card-pricing--04.active .btn:before {
  background: #2B59FF;
  content: "";
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
  position: absolute;
  z-index: -1;
  transition: transform 0.4s;
  transform-origin: left;
  transform: translateX(0);
}
.btn-primary-outline-reverse:hover, .card-pricing--03.active .btn:hover, .card-pricing--04.active .btn:hover {
  color: #2B59FF !important;
  border-color: #2B59FF;
}
.btn-primary-outline-reverse:hover:before, .card-pricing--03.active .btn:hover:before, .card-pricing--04.active .btn:hover:before {
  transition: transform 0.4s;
  transform-origin: right;
  transform: translateX(-100%);
}

.btn-rounded {
  border-radius: 500px !important;
}

.border-2 {
  border-width: 2px !important;
}

.btn-w-arrow i {
  margin-left: 10px;
}

@media (min-width: 768px) {
  .hero-btn {
    font-size: 18px !important;
  }
}

.btn-arrow-anim-01 g {
  stroke: #fff;
}
.btn-arrow-anim-01 circle.arrow-icon--circle {
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
  transition: 0.4s;
}
.btn-arrow-anim-01:hover circle.arrow-icon--circle {
  stroke-dashoffset: 0;
  transition: 0.4s;
}

.widget {
  display: flex;
}
.widget-title {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 26px;
}
@media (min-width: 768px) {
  .widget-title {
    margin-bottom: 10px;
  }
}
.widget-icon--circle {
  border-radius: 500px;
}
.widget-column, .widget-card {
  flex-direction: column;
}
.widget-column .widget-icon, .widget-card .widget-icon {
  margin-bottom: 40px;
}
.widget-column-center, .widget-card-center {
  text-align: center;
  align-items: center;
}
.widget:not(.widget-column, .widget-card) .widget-tag {
  margin-right: 30px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget.widget-column-till-md {
  flex-direction: column;
}
.widget.widget-column-till-md .widget-tag,
.widget.widget-column-till-md .widget-icon {
  margin-right: 0;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .widget.widget-column-till-md {
    flex-direction: row;
  }
  .widget.widget-column-till-md .widget-tag,
.widget.widget-column-till-md .widget-icon {
    margin-right: 30px;
    text-align: center;
    margin-bottom: 0px;
  }
  .widget.widget-column-till-md .widget-tag img,
.widget.widget-column-till-md .widget-icon img {
    max-width: 100%;
  }
}
.widget.widget-column-till-md.icon-sizeY-68 {
  min-height: 68px;
  max-height: 68px;
}

.feature-widgets-row,
.widgets-row,
.card-row {
  margin-bottom: -24px;
}
.feature-widgets-row [class^=col],
.widgets-row [class^=col],
.card-row [class^=col] {
  margin-bottom: 24px;
}

.feature-widgets-row-02,
.widgets-row-02,
.card-row-02 {
  margin-bottom: -45px;
}
.feature-widgets-row-02 [class^=col],
.widgets-row-02 [class^=col],
.card-row-02 [class^=col] {
  margin-bottom: 45px;
}

.widget-card-01 {
  padding-top: 31px;
  padding-bottom: 25px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 480px) {
  .widget-card-01 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 575px) {
  .widget-card-01 {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.widget-card-01 .widget-icon {
  margin-bottom: 23px;
}

.widget-counter-lg .widget-title {
  font-size: 42px;
  line-height: 1.0972222222;
  letter-spacing: -0.5px;
}
@media (min-width: 576px) {
  .widget-counter-lg .widget-title {
    font-size: 52px;
  }
}
@media (min-width: 768px) {
  .widget-counter-lg .widget-title {
    font-size: 62px;
  }
}
@media (min-width: 992px) {
  .widget-counter-lg .widget-title {
    font-size: 72px;
  }
}
.widget-counter-lg .widget-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .widget-counter-lg .widget-text {
    font-size: 20px;
  }
}

.widget-counter {
  justify-content: center;
}
@media (min-width: 768px) {
  .widget-counter {
    flex-direction: row;
  }
}
.widget-counter .widget-icon {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .widget-counter .widget-icon {
    margin-right: 30px;
    margin-bottom: 0px;
  }
}
.widget-counter .widget-title {
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-size: 40px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .widget-counter .widget-title {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .widget-counter .widget-title {
    font-size: 60px;
  }
}
.widget-counter .widget-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  line-height: 24px;
}

.card-title {
  font-size: 20px;
  line-height: 1.3;
}

/* Landiing-2 Pricing Card */
.card-pricing--01 {
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 45px;
  background: #ffffff;
  border: 1px solid #e8e0f4;
  border-radius: 10px;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media (min-width: 992px) {
  .card-pricing--01.active {
    position: relative;
    box-shadow: none;
  }
  .card-pricing--01.active:before {
    content: "";
    background: #fff;
    top: 3px;
    left: 3px;
    position: absolute;
    z-index: -1;
    border-radius: 10px;
    bottom: 3px;
    right: 3px;
  }
  .card-pricing--01.active:after {
    content: "";
    background-image: linear-gradient(90deg, #8C30F5 0%, #FF4B5C 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -2;
    border-radius: 10px;
  }
}
.card-pricing--01__head {
  padding-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(120, 27, 255, 0.1);
}
.card-pricing--01__plan-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 20px;
}
.card-pricing--01__price {
  font-weight: 400;
  font-size: 72px;
  line-height: 79px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
.card-pricing--01__plan-text {
  background: -webkit-linear-gradient(0deg, #8C30F5 0%, #FF4B5C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  line-height: 1.625;
}
.card-pricing--01__list {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 20px;
}
.card-pricing--01__list li {
  font-size: 16px;
  line-height: 26px;
  color: #000b33;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.card-pricing--01__list li .icon-check {
  position: relative;
  top: -3px;
  margin-right: 8px;
}
.card-pricing--01 .btn {
  --radius: 3px;
  min-width: 150px;
  height: 50px;
}

/* Landiing-4 Pricing Card */
.card-pricing--02 {
  position: relative;
  border: 0;
  text-align: center;
  background: white;
  padding: 40px 45px 50px 45px;
  border-radius: 10px;
  z-index: 1;
  border: 1px solid #E9E5EF;
}
@media (min-width: 992px) {
  .card-pricing--02 {
    border: 0;
    box-shadow: -20px 10px 60px rgba(0, 0, 0, 0.03);
  }
  .card-pricing--02.active {
    box-shadow: none;
  }
  .card-pricing--02.active:before {
    content: "";
    background: #fff;
    /* width: 100%; */
    /* height: 100%; */
    top: 3px;
    left: 3px;
    position: absolute;
    z-index: -1;
    border-radius: 10px;
    bottom: 3px;
    right: 3px;
  }
  .card-pricing--02.active:after {
    content: "";
    background-image: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -2;
    border-radius: 10px;
  }
}
.card-pricing--02__head {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(120, 27, 255, 0.1);
}
.card-pricing--02__plan-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 34px;
}
.card-pricing--02__plan-img {
  margin: auto;
  margin-bottom: 30px;
}
.card-pricing--02__price {
  font-weight: 600;
  font-size: 72px;
  line-height: 79px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
.card-pricing--02__plan-text {
  font-size: 16px;
  line-height: 1.625;
}
.card-pricing--02__list {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 20px;
}
.card-pricing--02__list li {
  font-size: 16px;
  line-height: 26px;
  color: #000b33;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.card-pricing--02__list li .icon-check {
  position: relative;
  top: -3px;
  margin-right: 8px;
}
.card-pricing--02 .btn {
  --radius: 3px;
  min-width: 150px;
  height: 50px;
}

/* Landiing-6 Pricing Card */
.card-pricing--03 {
  border: 0;
  border-radius: 10px;
  text-align: center;
  background-color: #ffffff;
  padding: 45px;
  border: 1px solid #EFEAF7;
}
.card-pricing--03__head {
  padding-bottom: 30px;
  text-align: start;
  border-bottom: 1px solid rgba(120, 27, 255, 0.1);
}
.card-pricing--03__plan-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
}
.card-pricing--03__plan-img {
  margin: auto;
  margin-bottom: 30px;
}
.card-pricing--03__price {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.5px;
  margin-bottom: 2px;
}
.card-pricing--03__price span {
  color: #000b33;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
.card-pricing--03__plan-text {
  font-size: 16px;
  line-height: 1.625;
}
.card-pricing--03__list {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 17px;
}
.card-pricing--03__list li {
  font-size: 16px;
  line-height: 26px;
  color: #000b33;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.card-pricing--03__list li .icon-check {
  position: relative;
  top: -3px;
  margin-right: 8px;
}
.card-pricing--03 .btn {
  --radius: 3px;
  min-width: 150px;
  height: 50px;
}
/* Landiing-6 Pricing Card */
.card-pricing--04 {
  border: 0;
  border-radius: 10px;
  border: 1px solid #E9E5EF;
  text-align: center;
  border-radius: 5px;
  padding: 40px 45px;
}
@media (min-width: 992px) {
  .card-pricing--04 {
    border: none;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  }
}
.card-pricing--04__head {
  padding-bottom: 30px;
}
.card-pricing--04__plan-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}
.card-pricing--04__plan-img {
  margin: auto;
  margin-bottom: 30px;
}
.card-pricing--04__price {
  font-weight: 400;
  font-size: 60px;
  line-height: 1.0972222222;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-pricing--04__price {
    font-size: 72px;
  }
}
.card-pricing--04__plan-text {
  font-size: 16px;
  line-height: 1.625;
}
.card-pricing--04__list {
  padding-bottom: 8px;
}
.card-pricing--04__list li {
  border-top: 1px solid rgba(0, 11, 51, 0.1);
  font-size: 16px;
  line-height: 26px;
  color: #000b33;
  padding: 15px;
}
.card-pricing--04 .btn {
  --radius: 3px;
  min-width: 150px;
  height: 50px;
}

/* Landiing-10 Pricing Card */
.card-pricing--05 {
  border: 0;
  border-radius: 10px;
  text-align: center;
  background-color: #ffffff;
  padding: 50px;
  border: 1px solid #E9E5EF;
  transition: 0.3s;
}
@media (min-width: 992px) {
  .card-pricing--05 {
    border: none;
  }
  .card-pricing--05.active {
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  }
}
.card-pricing--05__head {
  padding-bottom: 30px;
  text-align: start;
  border-bottom: 1px solid rgba(120, 27, 255, 0.1);
}
.card-pricing--05__plan-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}
.card-pricing--05__price {
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-size: 50px;
  margin-bottom: 15px;
}
.card-pricing--05__price span {
  color: #000b33;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin-right: 8px;
}
@media (min-width: 1200px) {
  .card-pricing--05__price {
    font-size: 60px;
  }
}
.card-pricing--05__plan-text {
  font-size: 18px;
  line-height: 26px;
}
.card-pricing--05__list {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 17px;
}
.card-pricing--05__list li {
  display: flex;
  align-items: center;
}
.card-pricing--05__list li .icon-check {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2B59FF;
  border-radius: 500px;
}
.card-pricing--05__list li .icon-check svg {
  width: 11.3px;
  height: 8.22px;
}
.card-pricing--05__list li span {
  display: block;
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000b33;
  padding-top: 8.5px;
  padding-bottom: 8.5px;
}
.card-pricing--05 .btn {
  --radius: 3px;
  min-width: 150px;
  height: 50px;
}

/* Pricing-2 Pricing Card */
.card-pricing--06 {
  border: 0;
  border-radius: 10px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #E9E5EF;
  padding: 40px 45px;
}
@media (min-width: 992px) {
  .card-pricing--06 {
    border: none;
    box-shadow: -6px 6px 60px rgba(0, 0, 0, 0.08);
  }
}
.card-pricing--06__head {
  padding-bottom: 30px;
}
.card-pricing--06__plan-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}
.card-pricing--06__price {
  font-weight: 400;
  font-size: 60px;
  line-height: 1.0972222222;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-pricing--06__price {
    font-size: 72px;
  }
}
.card-pricing--06__plan-text {
  font-size: 18px;
  line-height: 26px;
}
.card-pricing--06__list li {
  border-top: 1px solid rgba(0, 11, 51, 0.1);
  font-size: 16px;
  line-height: 26px;
  color: #000b33;
  padding: 15px;
}
.card-pricing--06__button .btn {
  --radius: 3px;
  min-width: 150px;
  height: 50px;
}
.card-pricing--06__button .btn-rounded-secondary {
  --radius: 30px;
  --border-width: 2px;
  background: #fff;
  border-width: var(--border-width);
  border-style: solid;
  border-color: transparent;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  color: #FD4C5C;
}
.card-pricing--06__button .btn-rounded-secondary:before {
  content: "";
  background: #FD4C5C;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  border-radius: 3px;
  transition: 0.4s;
}
.card-pricing--06__button .btn-rounded-secondary:after {
  content: "";
  background: #fff;
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  right: var(--border-width);
  bottom: var(--border-width);
  border-radius: 3px;
  z-index: -1;
  transition: 0.4s;
}
.card-pricing--06__button .btn-rounded-secondary.active, .card-pricing--06__button .btn-rounded-secondary:hover {
  color: #fff;
}
.card-pricing--06__button .btn-rounded-secondary.active:after, .card-pricing--06__button .btn-rounded-secondary:hover:after {
  left: 100%;
}
.card-pricing--06__button .btn-rounded-secondary.active:hover {
  color: #000;
}
.card-pricing--06__button .btn-rounded-secondary.active:hover:after {
  left: var(--border-width);
}

/* Landiing-2 Testimonial Card */
.card-testimonial-01 {
  display: block;
}
.card-testimonial-01__img {
  margin-left: 33px;
  border-radius: 500px;
  border: 6.43px solid transparent;
  background: linear-gradient(90deg, rgba(123, 36, 255, 0.8) 0%, rgba(253, 78, 93, 0.8) 100%) border-box;
  display: inline-block;
}
.card-testimonial-01__img img {
  border-radius: 500px;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .card-testimonial-01__img img {
    min-width: 77.14px;
    min-height: 77.14px;
    max-width: 77.14px;
    max-height: 77.14px;
  }
}
.card-testimonial-01__content {
  background: #ffffff;
  border-radius: 10px;
  padding: 86px 30px 30px 30px;
  border: 1px solid #E9E5EF;
  margin-top: -55px;
}
@media (min-width: 992px) {
  .card-testimonial-01__content {
    border: none;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  }
}
.card-testimonial-01__content p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.card-testimonial-01__content .client-info {
  margin-bottom: 16px;
}
.card-testimonial-01__content .client-info--detail {
  margin-right: 20px;
}
.card-testimonial-01__content .client-info--detail h6 {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.card-testimonial-01__content .client-info--detail small {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
.card-testimonial-01__content .client-info--rating {
  color: #FD4C5C;
  margin-top: 12px;
}
@media (min-width: 1200px) {
  .card-testimonial-01__content .client-info {
    display: flex;
    justify-content: space-between;
  }
  .card-testimonial-01__content .client-info--rating {
    margin-top: 0;
  }
}

/* Landiing-3 Testimonial Card */
.card-testimonil-02 {
  text-align: center;
  border: 0;
}
.card-testimonil-02 .card-text {
  position: relative;
  padding: 30px;
  background: #FFFFFF;
  border: 1px solid #E9E5EF;
  border-radius: 10px;
  z-index: 1;
}
.card-testimonil-02 .card-text p {
  line-height: 26px;
}
.card-testimonil-02 .card-text::before {
  content: "";
  position: absolute;
  margin: auto;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 18px;
  height: 18px;
  transform: rotate(45deg);
  border-right: 1px solid #E9E5EF;
  border-bottom: 1px solid #E9E5EF;
  background-color: white;
  z-index: -1;
}
.card-testimonil-02 .card-info {
  margin-top: 34px;
}
.card-testimonil-02 .card-info--img {
  margin-bottom: 15px;
}
.card-testimonil-02 .card-info--img img {
  border: 5.71px solid #fff;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  max-width: 68.57px;
  max-height: 68.57px;
  min-width: 68.57px;
  min-height: 68.57px;
  object-fit: cover;
  border-radius: 500px;
  margin: auto;
}
.card-testimonil-02 .card-info--details h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.card-testimonil-02 .card-info--details small {
  font-size: 16px;
  line-height: 26px;
}

/* Landiing-4 Testimonial Card */
.card-testimonil-03 {
  border: 0;
  background: #FFFFFF;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}
@media (min-width: 768px) {
  .card-testimonil-03 {
    padding: 50px 40px;
  }
}
.card-testimonil-03 .card-text {
  font-size: 18px;
  line-height: 26px;
}
.card-testimonil-03 .card-info {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-testimonil-03 .card-info--img {
  margin-right: 24.29px;
}
.card-testimonil-03 .card-info--img img {
  max-width: 70px;
  max-height: 70px;
  min-width: 70px;
  min-height: 70px;
  object-fit: cover;
  border-radius: 500px;
}
.card-testimonil-03 .card-info .card-body--name {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.card-testimonil-03 .card-info .card-body--profession {
  font-size: 16px;
  line-height: 26px;
}

/* Landiing-7 Testimonial Card */
.card-testimonial-04__content {
  padding: 40px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  border: 1px solid #E9E5EF;
}
@media (min-width: 992px) {
  .card-testimonial-04__content {
    border: none;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  }
  .card-testimonial-04__content::before {
    position: absolute;
    content: "";
    bottom: -26px;
    left: 22px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent white transparent transparent;
  }
}
.card-testimonial-04__content .text {
  font-size: 18px;
  line-height: 26px;
}
.card-testimonial-04__content--rataing {
  margin: 0 -1px;
  margin-top: 30px;
}
.card-testimonial-04__content--rataing i {
  color: #FD4C5C;
  margin: 0 1px;
}
.card-testimonial-04__info {
  margin-top: 36px;
  display: flex;
  align-items: center;
}
.card-testimonial-04__info--img {
  margin-right: 24.29px;
}
.card-testimonial-04__info--img img {
  border: 5px solid white;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  max-width: 70px;
  max-height: 70px;
  min-width: 70px;
  min-height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 500px;
}
.card-testimonial-04__info--details .name {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.card-testimonial-04__info--details .position {
  font-size: 16px;
  line-height: 26px;
}

/* Landiing-10 Testimonial Card */
.card-testimonial-05 {
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  padding: 40px;
}
.card-testimonial-05 .text {
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  .card-testimonial-05 .text {
    padding-bottom: 40px;
  }
}
.card-testimonial-05__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.card-testimonial-05__body--info {
  display: flex;
  align-items: center;
}
.card-testimonial-05__body--info .client-img {
  margin-right: 20px;
}
.card-testimonial-05__body--info .client-img img {
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  object-fit: cover;
  border-radius: 500px;
}
.card-testimonial-05__body--info .client-info .name {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
}
.card-testimonial-05__body--info .client-info .skill {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.card-testimonial-05__body--ratting {
  color: #FFC83E;
}

/* Landiing-9 Testimonial Card */
.card-testimonial-06 {
  display: block;
  background: #ffffff;
  border-radius: 10px;
  padding: 40px;
  border: 1px solid #E9E5EF;
}
@media (min-width: 992px) {
  .card-testimonial-06 {
    border: none;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  }
}
.card-testimonial-06__img {
  margin-bottom: 15px;
}
.card-testimonial-06__img img {
  border-radius: 500px;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  object-fit: cover;
}
.card-testimonial-06__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.card-testimonial-06__content .client-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.card-testimonial-06__content .client-info--deatail {
  margin-right: 25px;
}
.card-testimonial-06__content .client-info--deatail h6 {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.card-testimonial-06__content .client-info--deatail small {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
.card-testimonial-06__content .client-info--rataing {
  color: #FFC83E;
}

/* Landiing-5 About Card */
.card-about-01 {
  background: #FFFFFF;
  padding: 30px 38px;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  text-align: center;
  margin: 12px 0;
}
.card-about-01__body {
  margin-top: 25px;
}
.card-about-01__body--title {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
}
.card-about-01__body--text {
  font-size: 16px;
  line-height: 26px;
}

/* Landiing-5 About Card */
.card-feature-01--img {
  position: relative;
}
.card-feature-01--img img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-feature-01--img .subject {
  position: absolute;
  bottom: -15px;
  padding: 3px 17px;
  left: 20px;
  border: 0.5px solid #00BB98;
  background: #FFFFFF;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.card-feature-01__body {
  background: #FFFFFF;
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 26.54px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #E9E5EF;
}
@media (min-width: 1200px) {
  .card-feature-01__body {
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border: none;
  }
}
.card-feature-01__body--title {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}
.card-feature-01__body--ratting {
  margin-top: 15px;
}
.card-feature-01__body--ratting i {
  color: #FD4C5C;
}
.card-feature-01__body--button-group {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-feature-01__body--button-group-price {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
.card-feature-01__body--button-group-price del {
  font-size: 16px;
  line-height: 24px;
  margin-left: 5px;
  color: rgba(0, 11, 51, 0.5);
}
.card-feature-01__body--button-group .btn {
  height: 30px;
  min-width: 101px;
}

/* Landiing-8 Team Card */
.card-team-02 {
  position: relative;
}
@media (min-width: 1200px) {
  .card-team-02 {
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  }
}
.card-team-02--img img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}
.card-team-02__body {
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  border: 1px solid #E9E5EF;
}
@media (min-width: 1200px) {
  .card-team-02__body {
    border: none;
  }
}
.card-team-02__body--category {
  font-weight: 600;
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
}
.card-team-02__body--name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
}
.card-team-02__body--specialist {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
.card-team-02__body--info {
  padding-top: 3px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.card-team-02__body--button-group {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-team-02__body--button-group .text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.card-team-02__body--button-group .btn-2 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  min-width: 101px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-team-02--tag {
  position: absolute;
  top: 15px;
  left: 20px;
}
.card-team-02 .ratting {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #00BB98;
  background-color: white;
  padding: 3px 13px;
  border: 0.5px solid #00BB98;
  border-radius: 10px;
}
.card-team-02 .ratting i {
  font-size: 14px;
  margin-right: 10px;
}

/* Blog Card 01*/
.blog-card-01 {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
.blog-card-01--img img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog-card-01__body {
  padding: 30px 20px;
}
.blog-card-01__body--meta {
  display: flex;
  align-items: center;
  color: #377CFD;
  margin-bottom: 20px;
}
.blog-card-01__body--meta svg {
  margin-right: 10px;
  fill: #377CFD;
}
.blog-card-01__body--meta .admmin,
.blog-card-01__body--meta .date {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.blog-card-01__body--meta .admmin {
  margin-right: 30px;
}
.blog-card-01__body--content .title {
  font-family: 600;
  line-height: 1.3;
  font-size: 18px;
  padding-bottom: 15px;
}
@media (min-width: 576px) {
  .blog-card-01__body--content .title {
    font-size: 20px;
  }
}
.blog-card-01__body--content .text {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 20px;
}
.blog-card-01__body--content .more-btn {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FD4C5C;
}
.blog-card-01__body--content .more-btn svg {
  margin-left: 10px;
  fill: #FD4C5C;
}

.mb-mobile-md {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .mb-mobile-md {
    margin-bottom: 0px;
  }
}

.mb-mobile-lg {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .mb-mobile-lg {
    margin-bottom: 0px;
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-20 {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .mb-20 {
    margin-bottom: 20px;
  }
}

.mt-40 {
  margin-top: 40px;
}
@media (min-width: 576px) {
  .mt-40 {
    margin-top: 30px;
  }
}

.z-index-1 {
  z-index: 1;
}

.three-row-mobile-slider .slick-slide,
.four-row-mobile-slider .slick-slide {
  margin: 0 12px;
}

/*=== Footer 1 ===*/
.footer-area--01 {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .footer-area--01 {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .footer-area--01 {
    padding-top: 110px;
  }
}

.footer-content {
  margin-bottom: 45px;
}
@media (min-width: 992px) {
  .footer-content {
    margin-bottom: 0px;
  }
}
.footer-content--logo {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .footer-content--logo {
    margin-bottom: 29px;
  }
}
.footer-content--text {
  font-size: 16px;
  line-height: 26px;
}
.footer-content--social {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 25px;
  justify-content: center;
  display: flex;
  align-items: center;
}
@media (min-width: 576px) {
  .footer-content--social {
    justify-content: start;
  }
}
@media (min-width: 992px) {
  .footer-content--social {
    margin-top: 35px;
  }
}
.footer-content--social li {
  margin: 0 19px;
}
.footer-content--social li:first-child {
  margin-left: 0;
}
.footer-content--social li:last-child {
  margin-right: 0;
}
.footer-content--social li a {
  color: #000B33;
  transition: 0.3s;
}
.footer-content--social li a:hover {
  color: #FD4C5C;
}

.footer-widget--title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .footer-widget--title {
    margin-bottom: 30px;
  }
}
.footer-widget--list li a {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
  margin-bottom: 12px;
  display: block;
  transition: 0.3s;
}
@media (min-width: 992px) {
  .footer-widget--list li a {
    margin-bottom: 15px;
  }
}
.footer-widget--list li a:hover {
  color: #FD4C5C;
}
.footer-widget--info li {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}
.footer-widget--info li a {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
  transition: 0.3s;
}
.footer-widget--info li a:hover {
  color: #FD4C5C;
}
.footer-widget--info-brand {
  margin-top: 30px;
}
.footer-widget--info .footer-2-datails {
  margin-top: 10px;
}
.footer-widget--info .footer-2-datails i {
  color: #FD4C5C;
  margin-right: 5px;
}
.footer-widget--info-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px -4px 0 -4px;
}
@media (min-width: 576px) {
  .footer-widget--info-brand {
    justify-content: start;
  }
}
.footer-widget--info-brand a {
  margin: 0 4px;
}
.footer-widget--01 {
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .footer-widget--01 {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer-widget--01 {
    margin-left: 45px;
  }
}
@media (min-width: 1200px) {
  .footer-widget--01 {
    margin-left: 112px;
  }
}
.footer-widget--02 {
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .footer-widget--02 {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer-widget--02 {
    margin-left: 35px;
  }
}
@media (min-width: 1200px) {
  .footer-widget--02 {
    margin-left: 83px;
  }
}
@media (min-width: 992px) {
  .footer-widget--03 {
    margin-left: 0px;
  }
}
@media (min-width: 1200px) {
  .footer-widget--03 {
    margin-left: 43px;
  }
}

.copyright-area {
  border-top: 1px solid rgba(0, 11, 51, 0.1);
  padding: 20px 0;
  margin-top: 45px;
}
@media (min-width: 768px) {
  .copyright-area {
    padding-top: 40px;
    padding-bottom: 34px;
    margin-top: 64px;
  }
}
.copyright-area p {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(0, 11, 51, 0.8);
}

.slick-slide:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}

.mobile-slider .slick-dots {
  padding: 0;
  margin: 0;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-slider .slick-dots li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 6px;
}
.mobile-slider .slick-dots li button {
  border: none;
  list-style: none;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 500px;
  background-color: rgba(253, 76, 92, 0.2);
  color: transparent;
  font-size: 0;
}
.mobile-slider .slick-dots li button:focus {
  outline: none;
  box-shadow: none;
}
.mobile-slider .slick-dots li.slick-active {
  background: #FD4C5C !important;
  width: 8px;
  height: 8px;
  border-radius: 500px;
}

.row.three-column-slider .slick-list {
  display: flex;
}

.screenshots-dots {
  margin-top: 35px;
}
@media (min-width: 576px) {
  .screenshots-dots {
    margin-top: 57px;
  }
}
@media (min-width: 992px) {
  .screenshots-dots {
    margin-top: 86px;
  }
}
.screenshots-dots .slick-dots {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.screenshots-dots .slick-dots li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 6px;
}
.screenshots-dots .slick-dots li button {
  border: none;
  list-style: none;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 500px;
  background-color: rgba(253, 76, 92, 0.2);
  color: transparent;
  font-size: 0;
}
.screenshots-dots .slick-dots li button:focus {
  outline: none;
  box-shadow: none;
}
.screenshots-dots .slick-dots li.slick-active {
  background: #fd4c5c !important;
  width: 8px;
  height: 8px;
  border-radius: 500px;
}

.form-subscribe-inline--01 {
  position: relative;
}
@media (min-width: 768px) {
  .form-subscribe-inline--01 {
    max-width: 535px;
    width: 100%;
  }
}
.form-subscribe-inline--01 .form-control {
  border-radius: 5px;
  min-height: 60px;
  width: 100%;
  font-weight: 700;
  background-color: #fff;
  font-size: 16px;
  color: #000B33;
  border-color: transparent;
}
.form-subscribe-inline--01 .form-control::placeholder {
  opacity: 0.6;
}
.form-subscribe-inline--01 .form-control:focus {
  border-color: #FD4C5C;
}
@media (min-width: 576px) {
  .form-subscribe-inline--01 .form-control {
    padding-right: 188px;
  }
}
.form-subscribe-inline--01 .btn {
  margin-top: 10px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}
@media (min-width: 576px) {
  .form-subscribe-inline--01 .btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 5px;
    width: 173px !important;
  }
}

[data-pricing-dynamic][data-value-active=yearly] .dynamic-value:before {
  display: inline-block;
  content: attr(data-yearly);
}
[data-pricing-dynamic][data-value-active=yearly] [data-pricing-trigger] span {
  left: calc(100% - 33px);
}

[data-pricing-dynamic][data-value-active=monthly] .dynamic-value:before {
  display: inline-block;
  content: attr(data-monthly);
}

.dynamic-value:before {
  display: inline-block;
  content: attr(data-active);
}

.static-value:before {
  display: inline-block;
  content: attr(data-active);
}

.pricing-btn .btn-toggle {
  position: relative;
  width: 46px !important;
  height: 26px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}
.pricing-btn .btn-toggle:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}
.pricing-btn .btn-toggle:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.pricing-btn label {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  margin: 0 15px;
  cursor: pointer;
}
.pricing-btn .badge {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  height: 30px;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 22px;
}

.brand-section-01 {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .brand-section-01 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .brand-section-01 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.brand-section-01__wrapper {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 1200px) {
  .brand-section-01__wrapper {
    justify-content: space-between;
  }
}
.brand-section-01__wrapper .single-brand {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.brand-section-01__wrapper .single-brand img {
  opacity: 70%;
  transition: 0.3s;
}
.brand-section-01__wrapper .single-brand img:hover {
  opacity: 100%;
}
@media (max-width: 480px) {
  .brand-section-01__wrapper .single-brand {
    width: 50%;
  }
  .brand-section-01__wrapper .single-brand img {
    width: 100%;
  }
}

.brand-section-02 {
  padding: 45px 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .brand-section-02 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
.brand-section-02__wrapper {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 1200px) {
  .brand-section-02__wrapper {
    justify-content: space-between;
  }
}
.brand-section-02__wrapper .single-brand {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bg-bule {
  background: #2B59FF;
}

.cta-section-04__wrapper {
  position: relative;
  padding: 54px 0 60px;
  border-radius: 30px;
  z-index: 1;
}
@media (min-width: 768px) {
  .cta-section-04__wrapper {
    background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  }
}
@media (min-width: 768px) {
  .cta-section-04__wrapper {
    padding: 74px 0 80px;
  }
}
.cta-section-04__wrapper--shape-1 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-04__wrapper--shape-1 {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    z-index: -1;
  }
  .cta-section-04__wrapper--shape-1 svg {
    width: 100%;
    border-top-right-radius: 30px;
  }
}
.cta-section-04__wrapper--shape-2 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-04__wrapper--shape-2 {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
    z-index: -1;
  }
  .cta-section-04__wrapper--shape-2 svg {
    width: 100%;
    border-top-right-radius: 25px;
  }
}
.cta-section-04__wrapper--shape-3 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-04__wrapper--shape-3 {
    display: block;
    position: absolute;
    top: 23%;
    left: 0;
    width: 3%;
    z-index: -1;
  }
  .cta-section-04__wrapper--shape-3 svg {
    width: 100%;
  }
}
.cta-section-04__wrapper--shape-4 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-04__wrapper--shape-4 {
    display: block;
    position: absolute;
    bottom: 7%;
    left: 32%;
    width: 2%;
    z-index: -1;
  }
  .cta-section-04__wrapper--shape-4 svg {
    width: 100%;
  }
}
.cta-section-04__wrapper--shape-5 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-04__wrapper--shape-5 {
    display: block;
    position: absolute;
    top: 0;
    left: 54%;
    width: 18%;
    z-index: -1;
  }
  .cta-section-04__wrapper--shape-5 svg {
    width: 100%;
  }
}
.cta-section-04__contennt {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .cta-section-04__contennt {
    margin-bottom: 0;
  }
}
.cta-section-04__button-group {
  text-align: end;
  height: 100%;
  display: flex;
  align-items: flex-end;
  max-width: 300px;
}
@media (min-width: 576px) {
  .cta-section-04__button-group {
    max-width: 380px;
  }
}
@media (min-width: 992px) {
  .cta-section-04__button-group {
    justify-content: flex-end;
    max-width: 100%;
  }
}
.cta-section-04__button-group a {
  margin-right: 20px;
}
.cta-section-04__button-group a:last-child {
  margin-right: 0px;
}

.cta-bg-group-1 {
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  position: relative;
}
.cta-bg-group-1--shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}
.cta-bg-group-1--shape-1 svg {
  width: 100%;
}
@media (min-width: 768px) {
  .cta-bg-group-1--shape-1 {
    display: none;
  }
}
.cta-bg-group-1--shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
}
.cta-bg-group-1--shape-2 svg {
  width: 100%;
}
@media (min-width: 768px) {
  .cta-bg-group-1--shape-2 {
    display: none;
  }
}
@media (min-width: 768px) {
  .cta-bg-group-1 {
    background: inherit;
  }
}

.breadcrumb-section {
  padding-top: 100px;
  padding-bottom: 60px;
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  position: relative;
  z-index: 11;
  overflow: hidden;
}
.breadcrumb-section__bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 35%;
}
.breadcrumb-section__bg-shape img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
@media (min-width: 768px) {
  .breadcrumb-section {
    padding-top: 122px;
    padding-bottom: 76px;
  }
}
@media (min-width: 992px) {
  .breadcrumb-section {
    padding-top: 152px;
    padding-bottom: 96px;
  }
}
@media (min-width: 1200px) {
  .breadcrumb-section {
    padding-top: 149px;
    padding-bottom: 148px;
  }
}
.breadcrumb-section__content .breadcrumb {
  margin: 0;
  margin-top: 30px;
}
@media (min-width: 992px) {
  .breadcrumb-section__content .breadcrumb {
    margin-top: 40px;
  }
}
.breadcrumb-section__content .breadcrumb-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
}
.breadcrumb-section__content .breadcrumb-item + .breadcrumb-item {
  padding-left: 10px;
}
.breadcrumb-section__content .breadcrumb-item + .breadcrumb-item::before {
  padding-right: 10px;
  content: "";
  font-size: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: white;
}
.breadcrumb-section__shape-1 {
  position: absolute;
  bottom: 5%;
  right: 0%;
  width: 6.5%;
  z-index: -1;
}
.breadcrumb-section__shape-2 {
  position: absolute;
  top: 37%;
  left: 12%;
  width: 3%;
  z-index: -1;
}
.breadcrumb-section__shape-3 {
  position: absolute;
  bottom: 6%;
  left: 0%;
  width: 3%;
  z-index: -1;
}

/* Preloader css Start */
/* Common Header  css Start */
/* Common Button  css Start */
/* Common Widgets  css Start */
/* Common Cards  css Start */
/* Common Spacings  css Start */
/* Common Footer  css Start */
/* Common Sliders  css Start */
/* Common Form  css Start */
/* Common Cta  css Start */
/* Common Brands  css Start */
/* Common Cta  css Start */
/* Breadcrumb Css Start */
/* Landing One Header Button */
.site-header__btns--01 {
  margin-left: auto;
}
.site-header__btns--01 .btn {
  height: 45px;
}
.site-header__btns--01 .btn-1 {
  min-width: 130px;
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--01 .btn-1 {
    color: #fff;
  }
}
.site-header__btns--01 .btn-2 {
  min-width: 120px;
}
@media (max-width: 992px) {
  .site-header__btns--01 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-01 {
  position: relative;
  z-index: 1;
  padding-top: 80px;
}
@media (min-width: 992px) {
  .hero-section-01 {
    padding-top: 0;
  }
}
.hero-section-01 .shape {
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 100%;
  z-index: -1;
}
.hero-section-01 .shape img {
  width: 100%;
}
.hero-section-01__content {
  padding-bottom: 95px;
}
@media (min-width: 576px) {
  .hero-section-01__content {
    padding-bottom: 191px;
  }
}
@media (min-width: 992px) {
  .hero-section-01__content {
    padding-top: 200px;
  }
}
@media (min-width: 1200px) {
  .hero-section-01__content {
    padding-top: 232px;
  }
}
.hero-section-01__content .title {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .hero-section-01__content .title {
    margin-bottom: 30px;
  }
}
.hero-section-01__content p {
  margin-bottom: 42px;
}
@media (min-width: 1400px) {
  .hero-section-01__content p {
    padding-right: 10%;
  }
}
.hero-section-01__form {
  max-width: 526px;
}
.hero-section-01__form .form-control {
  height: 66px;
  font-weight: 400;
}
@media (min-width: 576px) {
  .hero-section-01__form .btn {
    margin: 8px;
  }
}
.hero-section-01__image-group {
  position: relative;
  z-index: 1;
  margin-bottom: -10px;
}
@media (min-width: 992px) {
  .hero-section-01__image-group {
    margin-bottom: 0;
    margin-top: 10%;
    width: 85%;
  }
}
@media (min-width: 1200px) {
  .hero-section-01__image-group {
    width: 85%;
  }
}
@media (min-width: 1400px) {
  .hero-section-01__image-group {
    width: 85%;
  }
}
.hero-section-01__half-image {
  position: absolute;
  left: -18%;
  top: -1%;
  width: 49%;
  z-index: -1;
}
.hero-section-01__shape-1 {
  position: absolute;
  width: 14%;
  left: -11%;
  top: 21%;
  z-index: -3;
}
.hero-section-01__shape-1 img,
.hero-section-01__shape-1 svg {
  width: 100%;
}
.hero-section-01__shape-2 {
  position: absolute;
  right: -1.5%;
  top: 15%;
  z-index: -1;
}
.hero-section-01__shape-2 img,
.hero-section-01__shape-2 svg {
  width: 100%;
}

.hero-form-01 {
  position: relative;
  max-width: calc(100% - 205px);
}
.hero-form-01 .form-control {
  border-radius: 5px;
  min-height: 66px;
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  color: #000B33;
  border-color: transparent;
}
.hero-form-01 .form-control::placeholder {
  opacity: 0.6;
}
.hero-form-01 .form-control:focus {
  border-color: #FD4C5C;
}
.hero-form-01 .btn {
  margin-top: 10px;
  min-width: 180px;
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  border-radius: 5px;
}
@media (min-width: 576px) {
  .hero-form-01 .btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
  }
}

.feature-section-01 {
  background: rgba(43, 89, 255, 0.02);
  padding-top: 50px;
  padding-bottom: 61px;
}
@media (min-width: 768px) {
  .feature-section-01 {
    padding-top: 70px;
    padding-bottom: 81px;
  }
}
@media (min-width: 768px) {
  .feature-section-01 {
    padding-top: 72px;
    padding-bottom: 77px;
  }
}
@media (min-width: 992px) {
  .feature-section-01 {
    padding-top: 90px;
    padding-bottom: 133px;
  }
}
@media (min-width: 1200px) {
  .feature-section-01 {
    padding-top: 120px;
    padding-bottom: 162px;
  }
}
.feature-section-01 .section-title {
  padding-bottom: 45px;
}
@media (min-width: 768px) {
  .feature-section-01 .section-title {
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .feature-section-01 .section-title {
    padding-bottom: 93px;
  }
}
@media (max-width: 575px) {
  .feature-section-01 .widget-feature-01 {
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .feature-section-01 .widget-feature-01 .widget-icon {
    margin-bottom: 20px;
  }
}
.feature-section-01 .widget-feature-01 .widget-text p {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}
@media (min-width: 992px) {
  .feature-section-01 .widget-feature-01 .widget-text p {
    padding-right: 3%;
  }
}
@media (min-width: 1200px) {
  .feature-section-01 .widget-feature-01 .widget-text p {
    padding-right: 5%;
  }
}
@media (min-width: 1400px) {
  .feature-section-01 .widget-feature-01 .widget-text p {
    padding-right: 15%;
  }
}

.video-section-01 {
  background: rgba(43, 89, 255, 0.02);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .video-section-01 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .video-section-01 {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .video-section-01 {
    padding-top: 178px;
    padding-bottom: 130px;
  }
}
@media (min-width: 992px) {
  .video-section-01 .block-title p {
    padding-right: 11%;
  }
}
@media (min-width: 1200px) {
  .video-section-01 .block-title p {
    padding-right: 11%;
  }
}
@media (min-width: 1400px) {
  .video-section-01 .block-title p {
    padding-right: 23%;
  }
}
.video-section-01 .block-title .btn {
  margin-top: 40px;
}
.video-section-01 .video-block {
  position: relative;
}
.video-section-01 .video-block img {
  border-radius: 5px;
}
.video-section-01 .video-block .video-button-block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 11, 51, 0.5);
  flex-direction: column;
  border-radius: 5px;
}
.video-section-01 .video-block--text {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.25;
  margin-top: 15px;
}
@media (min-width: 1400px) {
  .video-section-01 .video-block--text {
    font-size: 20px;
  }
}
@media (min-width: 1400px) {
  .video-section-01 .video-block--text {
    font-size: 24px;
  }
}

.team-section-01 {
  background: rgba(43, 89, 255, 0.02);
  padding-top: 50px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .team-section-01 {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .team-section-01 {
    padding-top: 92px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .team-section-01 {
    padding-top: 120px;
    padding-bottom: 130px;
  }
}
.team-section-01 .section-title--team-01 {
  margin-bottom: 35px;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .team-section-01 .section-title--team-01 {
    margin-bottom: 55px;
  }
}
@media (min-width: 992px) {
  .team-section-01 .section-title--team-01 {
    margin-bottom: 67px;
  }
}
.team-section-01 .card-team-01 {
  background: #FFFFFF;
  padding: 20px 20px 25px 20px;
  border: 0;
  transition: 0.3s;
  border-radius: 5px;
}
.team-section-01 .card-team-01:hover {
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}
.team-section-01 .card-image img {
  border-radius: 5px;
  width: 100%;
}
.team-section-01 .card-texts {
  margin-top: 33px;
}
@media (min-width: 425px) {
  .team-section-01 .team-slider--01 {
    width: 60%;
    margin: auto;
  }
}
@media (min-width: 576px) {
  .team-section-01 .team-slider--01 {
    width: 100%;
    margin: inherit;
  }
}
.team-section-01 .team-slider--01 .card-team-01 {
  text-align: center;
}
@media (min-width: 576px) {
  .team-section-01 .team-slider--01 .card-team-01 {
    text-align: start;
  }
}

.content-section-01 {
  background: rgba(43, 89, 255, 0.93);
  position: relative;
  z-index: 11;
  padding: 60px 0;
}
@media (min-width: 768px) {
  .content-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-01 {
    padding: 0;
  }
}
.content-section-01 .section-shape {
  position: absolute;
  top: -1px;
  right: 0;
  z-index: -1;
}
.content-section-01__image-group {
  max-width: 80%;
  margin: auto;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .content-section-01__image-group {
    margin-bottom: 0px;
    width: 100%;
    margin: inherit;
    margin-bottom: 0;
    margin: -40px 0;
  }
}
@media (min-width: 1200px) {
  .content-section-01__image-group {
    margin-bottom: 0px;
    max-width: 100%;
    margin: inherit;
    margin-bottom: 0;
    margin: -40px 0;
  }
}
@media (min-width: 1400px) {
  .content-section-01__image-group {
    padding-right: 30px;
  }
}
.content-section-01__image {
  position: relative;
}
.content-section-01__image .shape-half-circle {
  position: absolute;
  top: 33%;
  left: -22%;
  width: 22%;
  z-index: -11;
}
.content-section-01__image .shape-half-circle img, .content-section-01__image .shape-half-circle svg {
  width: 100%;
}
.content-section-01__image .small-shape {
  position: absolute;
  top: 16.5%;
  left: -28%;
  width: 7%;
}
.content-section-01__image .small-shape img,
.content-section-01__image .small-shape svg {
  width: 100%;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@media (min-width: 768px) {
  .content-section-01__text {
    padding-left: 30px;
  }
}
@media (min-width: 992px) {
  .content-section-01__text {
    padding-left: 45px;
  }
}
@media (min-width: 1200px) {
  .content-section-01__text {
    padding-left: 0px;
  }
}
.content-section-01__button-group {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .content-section-01__button-group {
    margin-top: 57px;
  }
}
.content-section-01__button-group a {
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  max-width: 130px;
}
@media (min-width: 480px) {
  .content-section-01__button-group a {
    max-width: 160px;
  }
}
@media (min-width: 992px) {
  .content-section-01__button-group a {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .content-section-01__button-group img {
    width: 100%;
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
.content-section-02 {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}
@media (min-width: 768px) {
  .content-section-02 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .content-section-02 {
    padding-top: 93px;
    padding-bottom: 93px;
  }
}
@media (min-width: 1200px) {
  .content-section-02 {
    padding-top: 192px;
    padding-bottom: 192px;
  }
}
@media (min-width: 992px) {
  .content-section-02__image {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }
}
.content-section-02__widget {
  margin-top: 54px;
}
@media (min-width: 992px) {
  .content-section-02__widget {
    padding-left: 20px;
  }
}
.content-section-02__widget .col-xl-12 {
  margin-bottom: 30px;
}
.content-section-02__widget .col-xl-12:last-child {
  margin-bottom: 0px;
}
.content-section-02__widget .widget-content .widget-icon {
  margin-right: 42px !important;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
}
.content-section-02__widget .widget-content .widget-title {
  margin-bottom: 10px;
}
.content-section-02__widget .widget-content p {
  line-height: 26px;
}

.testimonial-section-01 {
  padding-bottom: 60px;
  padding-top: 37px;
  background: rgba(43, 89, 255, 0.02);
}
@media (min-width: 768px) {
  .testimonial-section-01 {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .testimonial-section-01 {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .testimonial-section-01 {
    padding-top: 100px;
    padding-bottom: 88px;
  }
}
.testimonial-section-01 .testimonial-title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .testimonial-section-01 .testimonial-title {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .testimonial-section-01 .testimonial-title {
    margin-bottom: 60px;
  }
}
.testimonial-section-01 .testimonil-slider--01 .slick-list {
  margin: 0 -12px;
}
.testimonial-section-01 .testimonil-slider--01 .slick-slide {
  margin: 0 12px;
}
.testimonial-section-01 .testimonil-content img {
  margin: 0 auto 30px;
}
@media (min-width: 992px) {
  .testimonial-section-01 .testimonil-content img {
    margin: 0 0 30px;
  }
}
.testimonial-section-01 .testimonil-content .text {
  font-size: 18px;
  line-height: 1.69;
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .testimonial-section-01 .testimonil-content .text {
    padding-right: 6%;
  }
}
.testimonial-section-01 .testimonil-content .client-info h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000B33;
  margin-bottom: 5px;
}
.testimonial-section-01 .testimonil-content .client-info span {
  font-size: 16px;
  line-height: 26px;
  color: #000B33;
  opacity: 0.7;
}
.testimonial-section-01 .testimonil-imageGroup {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .testimonial-section-01 .testimonil-imageGroup {
    margin-bottom: 0;
  }
}
.testimonial-section-01 .testimonil-imageGroup img {
  max-width: 100%;
}
.testimonial-section-01 .testimonil-slider--01__control-buttons {
  display: flex;
  margin: 0 -10px;
  margin-top: 40px;
  justify-content: center;
}
@media (min-width: 992px) {
  .testimonial-section-01 .testimonil-slider--01__control-buttons {
    justify-content: start;
  }
}
.testimonial-section-01 .testimonil-slider--01__control-buttons .button {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: #FD4C5C;
  margin: 0 10px;
  transition: 0.3s;
}
.testimonial-section-01 .testimonil-slider--01__control-buttons .button svg {
  fill: white;
}
.testimonial-section-01 .testimonil-slider--01__control-buttons .button:hover {
  background: white;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.2);
}
.testimonial-section-01 .testimonil-slider--01__control-buttons .button:hover svg {
  fill: #FD4C5C;
}

.counter-section-01 {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .counter-section-01 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .counter-section-01 {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .counter-section-01 .col-xl-3 {
    position: relative;
  }
  .counter-section-01 .col-xl-3::before {
    content: "";
    position: absolute;
    height: 60px;
    width: 1px;
    background-color: rgba(43, 89, 255, 0.1);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .counter-section-01 .col-xl-3:last-child::before {
    display: none;
  }
}

.cta-section-01 {
  position: relative;
  z-index: 1;
  padding: 40px 0 50px;
}
@media (min-width: 768px) {
  .cta-section-01 {
    padding: 70px 0 80px;
  }
}
@media (min-width: 992px) {
  .cta-section-01 {
    padding: 100px 0;
  }
}
.cta-section-01__newsletter {
  margin: auto;
  margin-top: 40px;
}
.cta-section-01--shapes .cta-shape {
  position: absolute;
  z-index: -1;
}
.cta-section-01--shapes .cta-shape img, .cta-section-01--shapes .cta-shape svg {
  width: 100%;
}
.cta-section-01--shapes .cta-shape svg {
  height: 100%;
}
.cta-section-01--shapes .cta-shape--01 {
  top: 0;
  right: 0;
  width: 10%;
  max-width: 165.5px;
  min-width: 100px;
}
@media (min-width: 576px) {
  .cta-section-01--shapes .cta-shape--01 {
    right: 3%;
    top: -20%;
  }
}
.cta-section-01--shapes .cta-shape--02 {
  top: 45%;
  left: 3.5%;
  width: 30px;
}
.cta-section-01--shapes .cta-shape--02 svg {
  width: 100%;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.cta-section-01--shapes .cta-shape--03 {
  margin-bottom: -1.5px;
  left: 0;
  width: 7%;
  bottom: 0px;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
/* Header Css Start */
/* Hero Css Start */
/* Feature Css Start */
/* Video Css Start */
/* Team Css Start */
/* Content Css Start */
/* Content Css Start */
/* Testimonial Css Start */
/* Fact Css Start */
/* Cta Css Start */
/* Landing One Header Button */
.site-header__btns--02 .btn {
  height: 45px;
}
.site-header__btns--02 .btn-1 {
  margin-right: 20px;
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--02 .btn-1 {
    min-width: 80px;
    color: #fff;
  }
}
.site-header__btns--02 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #781BFF;
}
@media (min-width: 992px) {
  .site-header__btns--02 .btn-2 {
    min-width: 120px;
    color: #000B33;
    background-color: #fff;
  }
}
@media (max-width: 992px) {
  .site-header__btns--02 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-02 {
  padding-top: 95px;
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (min-width: 480px) {
  .hero-section-02 {
    padding-bottom: 130px;
  }
}
@media (min-width: 768px) {
  .hero-section-02 {
    padding: 110px 0 150px;
  }
}
@media (min-width: 992px) {
  .hero-section-02 {
    padding-top: 170px;
    padding-bottom: 120px;
  }
}
@media (min-width: 1200px) {
  .hero-section-02 {
    padding-top: 140px;
    padding-bottom: 56px;
  }
}
.hero-section-02__shape {
  position: absolute;
  bottom: -3px;
  left: -14px;
  width: 104.5%;
  z-index: -1;
}
@media (min-width: 992px) {
  .hero-section-02__image-group {
    margin-right: -60%;
    margin-left: -24px;
  }
}
@media (min-width: 1600px) {
  .hero-section-02__image-group {
    margin-right: -90%;
  }
}
.hero-section-02__content {
  margin-top: 0;
  text-align: center;
}
@media (min-width: 992px) {
  .hero-section-02__content {
    text-align: start;
    margin-top: -35px;
  }
}
.hero-section-02__content .title {
  margin-bottom: 14px;
}
@media (min-width: 768px) {
  .hero-section-02__content .title {
    margin-bottom: 20px;
  }
}
.hero-section-02__content .btn {
  margin-top: 35px;
  min-width: 236px;
  height: 60px;
}
@media (min-width: 992px) {
  .hero-section-02__content .btn {
    margin-top: 42px;
  }
}
@media (min-width: 768px) {
  .hero-section-02__content {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .hero-section-02__content {
    margin-bottom: 0px;
  }
}

.content-section-03 {
  padding-top: 38px;
  padding-bottom: 60px;
  background-color: white;
}
@media (min-width: 768px) {
  .content-section-03 {
    padding-top: 48px;
    padding-bottom: 85px;
  }
}
@media (min-width: 992px) {
  .content-section-03 {
    padding-top: 70px;
    padding-bottom: 87px;
  }
}
@media (min-width: 1200px) {
  .content-section-03 {
    padding-top: 85px;
    padding-bottom: 95px;
  }
}
@media (min-width: 992px) {
  .content-section-03__texts {
    padding-left: 40px;
  }
}
.content-section-03__texts .subtitle {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .content-section-03__texts .subtitle {
    margin-bottom: 20px;
  }
}
.content-section-03__texts .btn {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .content-section-03__texts .btn {
    margin-top: 40px;
  }
}

.content-section-04 {
  background: rgba(120, 27, 255, 0.03);
  position: relative;
  padding-top: 70px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .content-section-04 {
    padding-top: 97px;
    padding-bottom: 75px;
  }
}
@media (min-width: 992px) {
  .content-section-04 {
    padding-top: 130px;
    padding-bottom: 95px;
  }
}
@media (min-width: 1200px) {
  .content-section-04 {
    padding-top: 198px;
    padding-bottom: 125px;
  }
}
.content-section-04 .scetion-shape {
  position: absolute;
  top: -10px;
  width: 100%;
}
.content-section-04 .content-texts {
  margin-bottom: 45px;
}
@media (min-width: 992px) {
  .content-section-04 .content-texts {
    margin-bottom: 0px;
  }
}
.content-section-04 .content-texts .subtitle {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .content-section-04 .content-texts .subtitle {
    margin-bottom: 20px;
  }
}
.content-section-04 .content-texts .btn {
  margin-top: 30px;
  min-width: 150px !important;
}
@media (min-width: 1200px) {
  .content-section-04 .content-texts .btn {
    margin-top: 40px;
  }
}
.content-section-04__widget {
  margin-bottom: -40px;
}
@media (min-width: 992px) {
  .content-section-04__widget {
    padding-left: 30px;
  }
}
.content-section-04__widget [class^=col] {
  margin-bottom: 40px;
}
.content-section-04__widget [class^=col]:last-child .widget-icon::before {
  display: none;
}

@media (max-width: 576px) {
  .widget-content--02 {
    align-items: center;
    text-align: center;
  }
}
.widget-content--02 .widget-icon {
  background: #781bff;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  position: relative;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
@media (min-width: 992px) {
  .widget-content--02 .widget-icon::before {
    content: "";
    position: absolute;
    height: 61px;
    width: 2px;
    background: linear-gradient(90deg, #8c30f5 0%, #ff4b5c 100%);
    opacity: 0.2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);
  }
}
.widget-content--02 .widget-text .widget-title {
  margin-bottom: 5px;
}
.widget-content--02 .widget-text p {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}

.about-section-01 {
  background: rgba(120, 27, 255, 0.03);
  padding-top: 60px;
  padding-bottom: 78px;
  position: relative;
}
@media (min-width: 768px) {
  .about-section-01 {
    padding-top: 80px;
    padding-bottom: 125px;
  }
}
@media (min-width: 992px) {
  .about-section-01 {
    padding-top: 100px;
    padding-bottom: 160px;
  }
}
@media (min-width: 1200px) {
  .about-section-01 {
    padding-top: 130px;
    padding-bottom: 230px;
  }
}
.about-section-01 .scetion-shape {
  position: absolute;
  bottom: -2px;
  width: 100%;
}
.about-section-01 .video-block {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.about-section-01 .video-block .video-button-block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(120, 27, 255, 0.05);
}
.about-section-01 .video-block img {
  border-radius: 10px;
}
.about-section-01__content .subtitle {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .about-section-01__content .subtitle {
    margin-bottom: 20px;
  }
}
.about-section-01__content .btn-l2 {
  margin-top: 30px;
  min-width: 150px;
}
@media (min-width: 576px) {
  .about-section-01__content .btn-l2 {
    margin-top: 42px;
  }
}

.cta-section-02 {
  position: relative;
  z-index: 1;
  padding: 40px 0 50px;
}
@media (min-width: 768px) {
  .cta-section-02 {
    padding: 70px 0 80px;
  }
}
@media (min-width: 992px) {
  .cta-section-02 {
    padding: 90px 0 100px;
  }
}
.cta-section-02__newsletter {
  margin: auto;
  margin-top: 40px;
}
.cta-section-02__newsletter .form-control {
  font-weight: 600;
}

.counter-section-02 {
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgba(120, 27, 255, 0.03);
}
@media (min-width: 768px) {
  .counter-section-02 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .counter-section-02 {
    padding: 100px 0;
  }
}
.counter-section-02 .widget-counter .widget-icon {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .counter-section-02 .widget-counter .widget-icon {
    margin-bottom: 24px;
  }
}
@media (min-width: 768px) {
  .counter-section-02 .widget-counter {
    align-items: flex-start;
  }
  .counter-section-02 .widget-counter .widget-icon {
    margin-top: 10px;
  }
  .counter-section-02 .widget-counter .widget-title {
    margin-bottom: 5px;
  }
  .counter-section-02 .widget-counter .widget-text {
    color: #000B33;
  }
}

.testimonial-section-02 {
  padding-top: 55px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .testimonial-section-02 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .testimonial-section-02 {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .testimonial-section-02 {
    padding-top: 120px;
    padding-bottom: 130px;
  }
}
.testimonial-section-02__title {
  margin-bottom: 45px;
}
.testimonial-section-02__title .subtitle {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .testimonial-section-02__title {
    margin-bottom: 80px;
  }
}

.feature-section-02 {
  padding-top: 25px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .feature-section-02 {
    padding-top: 0;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .feature-section-02 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .feature-section-02 {
    padding: 60px 0 125px;
  }
}
.feature-section-02 .widget-feature-02 p {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}

.pricing-section-01 {
  padding: 56px 0;
}
@media (min-width: 768px) {
  .pricing-section-01 {
    padding: 76px 0;
  }
}
@media (min-width: 992px) {
  .pricing-section-01 {
    padding: 95px 0;
  }
}
@media (min-width: 1200px) {
  .pricing-section-01 {
    padding: 125px 0 125px;
  }
}
.pricing-section-01__title {
  margin-bottom: 45px;
}
.pricing-section-01__title .subtitle {
  margin-bottom: 20px;
}
.pricing-section-01__title .title {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .pricing-section-01__title {
    margin-bottom: 67px;
  }
}
.pricing-section-01__button {
  display: flex;
  justify-content: center;
}
.pricing-section-01__button .btn-toggle__wrapper {
  display: flex;
  justify-content: center;
  margin: 0 20px;
  cursor: pointer;
}
.pricing-section-01__button--text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 425px) {
  .pricing-section-01 .pricing-sider {
    width: 80%;
    margin: auto;
  }
}
@media (min-width: 768px) {
  .pricing-section-01 .pricing-sider {
    width: 100%;
    margin: inherit;
  }
}
.pricing-section-01 .pricing-sider .slick-slide {
  margin: 0 12px;
}

/* Header Css Start */
/* Hero Css Start */
/* Content Css Start */
/* Content Css Start */
/* About Css Start */
/* Cta Css Start */
/* Fact Css Start */
/* Testimonial Css Start */
/* Feature Css Start */
/* Pricing Css Start */
/* Landing One Header Button */
.site-header__btns--03 .btn {
  height: 45px;
}
.site-header__btns--03 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--03 .btn-1 {
    margin-right: 20px;
    min-width: 80px;
    color: #fff;
  }
}
.site-header__btns--03 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #FD4C5C;
}
@media (min-width: 992px) {
  .site-header__btns--03 .btn-2 {
    min-width: 120px;
  }
}
@media (max-width: 992px) {
  .site-header__btns--03 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-03 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
}
@media (min-width: 575px) {
  .hero-section-03 {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .hero-section-03 {
    padding-top: 20px;
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .hero-section-03 {
    padding-top: 20px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .hero-section-03 {
    padding-top: 20px;
  }
}
@media (min-width: 1400px) {
  .hero-section-03 {
    padding-top: 0;
  }
}
.hero-section-03::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(120, 27, 255, 0.95);
  z-index: -1;
}
.hero-section-03__content {
  padding-bottom: 40px;
  text-align: center;
}
@media (min-width: 992px) {
  .hero-section-03__content {
    padding-top: 100px;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .hero-section-03__content {
    padding-top: 68px;
    padding-bottom: 0px;
  }
}
.hero-section-03__content h1 {
  margin-bottom: 20px;
}
.hero-section-03__content--button-group {
  margin: 0 0 -10px -10px;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .hero-section-03__content--button-group {
    margin-top: 40px;
  }
}
.hero-section-03__content--button-group a {
  margin: 0 0 10px 10px;
  display: inline-block;
  max-width: 142px;
  min-width: 142px;
}
@media (min-width: 480px) {
  .hero-section-03__content--button-group a {
    max-width: 150px;
    min-width: 150px;
  }
}
@media (min-width: 768px) {
  .hero-section-03__content--button-group a {
    max-width: unset;
    min-width: unset;
  }
}
@media (max-width: 768px) {
  .hero-section-03__content--button-group a img {
    width: 100%;
  }
}
.hero-section-03__image-group {
  z-index: 1;
  position: relative;
  padding-top: 80px;
}
@media (min-width: 768px) {
  .hero-section-03__image-group {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .hero-section-03__image-group {
    padding-top: 157px;
  }
}
@media (min-width: 1200px) {
  .hero-section-03__image-group {
    margin-bottom: -100px;
    margin-left: -20%;
  }
}
@media (min-width: 1400px) {
  .hero-section-03__image-group {
    margin-bottom: -125px;
    margin-left: -15%;
  }
}
.hero-section-03__image-group img {
  width: 100%;
}
@media (min-width: 1400px) {
  .hero-section-03__image-group img {
    width: inherit;
  }
}
.hero-section-03__shape {
  position: absolute;
  bottom: -2px;
  width: 101%;
}

@keyframes spin-gradient {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.content-section-05 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .content-section-05 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-05 {
    padding: 130px 0;
  }
}
.content-section-05__image-group {
  max-width: 60%;
  margin: auto;
  text-align: center;
  position: relative;
}
.content-section-05__image-group img {
  max-width: unset;
  width: 100%;
}
.content-section-05__image-group::before {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  min-width: 151.32%;
  min-height: 76%;
  border-radius: 500px;
  transform: translate(-50%, -50%) rotate(0deg);
  background: linear-gradient(90deg, #7e25ff 0%, #FD4C5C 100%);
  z-index: -1;
}
@media (min-width: 768px) {
  .content-section-05__texts {
    padding: 0 5%;
  }
}
@media (min-width: 992px) {
  .content-section-05__texts {
    padding: 0;
  }
}
.content-section-05__texts .content-title {
  margin-bottom: 25px;
}
@media (min-width: 575px) {
  .content-section-05__texts .content-title {
    margin-bottom: 35px;
  }
}
@media (min-width: 768px) {
  .content-section-05__texts .content-title {
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .content-section-05__texts .content-title {
    margin-bottom: 58px;
  }
}
.content-section-05__texts .content-title h2 {
  margin-bottom: 16px;
}
.content-section-05__texts--widgets .singel-widget {
  margin-bottom: 24px;
}
@media (min-width: 575px) {
  .content-section-05__texts--widgets .singel-widget {
    margin-bottom: 34px;
  }
}
.content-section-05__texts--widgets .singel-widget:last-child {
  margin-bottom: 0;
}
.content-section-05__texts--widgets .singel-widget h5 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 15px;
}
.content-section-05__texts--widgets .singel-widget p {
  font-size: 16px;
  line-height: 26px;
}
.content-section-05__texts .button {
  margin-top: 36px;
}
@media (min-width: 480px) {
  .content-section-05__texts .button {
    margin-top: 30px;
  }
}
@media (min-width: 575px) {
  .content-section-05__texts .button {
    margin-top: 38px;
  }
}
.content-section-05__texts .button .btn {
  min-width: 150px;
}

.content-section-06 {
  background: rgba(120, 27, 255, 0.02);
}
.content-section-06__image-group {
  text-align: center;
  position: relative;
}
.content-section-06__image-group img {
  max-width: 100%;
  filter: drop-shadow(30px 4px 60px rgba(46, 25, 55, 0.19));
}
.content-section-06__image-group .content-card-1 {
  position: absolute;
  top: 23%;
  right: -3%;
  width: 34.1%;
}
.content-section-06__image-group .content-card-1 img {
  filter: drop-shadow(0px 4px 40px rgba(43, 89, 255, 0.08));
  width: 100%;
}
.content-section-06__image-group .content-card-2 {
  position: absolute;
  top: 44%;
  left: 0;
  width: 31.3%;
}
.content-section-06__image-group .content-card-2 img {
  filter: drop-shadow(0px 34px 90px rgba(41, 75, 198, 0.18));
  width: 100%;
}
.content-section-06__texts {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .content-section-06__texts {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .content-section-06__texts {
    padding-top: 0;
  }
}
.content-section-06__texts .content-title h2 {
  margin-bottom: 20px;
}
.content-section-06__texts .content-title .btn {
  min-width: 150px;
  margin-top: 28px;
}
@media (min-width: 480px) {
  .content-section-06__texts .content-title .btn {
    margin-top: 34px;
  }
}
@media (min-width: 575px) {
  .content-section-06__texts .content-title .btn {
    margin-top: 41px;
  }
}

.content-section-07 {
  padding-top: 50px;
  padding-bottom: 65px;
}
@media (min-width: 768px) {
  .content-section-07 {
    padding-top: 70px;
    padding-bottom: 85px;
  }
}
@media (min-width: 992px) {
  .content-section-07 {
    padding-top: 90px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .content-section-07 {
    padding-top: 119px;
    padding-bottom: 130px;
  }
}
.content-section-07 .section-title {
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .content-section-07 .section-title {
    margin-bottom: 57px;
  }
}
@media (min-width: 992px) {
  .content-section-07 .section-title {
    margin-bottom: 97px;
  }
}

.screenshot-slider-wrapper-07 {
  position: relative;
}
.screenshot-slider-wrapper-07 .phone-bg-img {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 11;
  width: 267px;
  transform: translate(-50%, -50%);
}
@media (min-width: 690px) {
  .screenshot-slider-wrapper-07 .phone-bg-img {
    width: 256px;
    transform: translate(-50%, -50%);
    display: block;
  }
}
.screenshot-slider-wrapper-07 .phone-bg-img > img {
  width: 100%;
}
.screenshot-slider-wrapper-07 .screenshot-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}
@media (min-width: 690px) {
  .screenshot-slider-wrapper-07 .screenshot-image {
    width: 230px;
  }
}
.screenshot-slider-wrapper-07 .screenshot-image > img {
  max-width: 100%;
  border: 1px solid #efeaf7;
  filter: drop-shadow(0px 18px 26px rgba(65, 32, 103, 0.06));
  border-radius: 10px;
}
.screenshot-slider-wrapper-07 .screenshot-image .screenshot-slider .slick-list {
  margin: 0 -23.5px;
}
.screenshot-slider-wrapper-07 .screenshot-image .screenshot-slider .slick-slide {
  opacity: 0.5;
  transition: 0.4s;
}
.screenshot-slider-wrapper-07 .screenshot-image .screenshot-slider .slick-center {
  opacity: 1;
  border-radius: 10px;
}
@media (min-width: 850px) {
  .screenshot-slider-wrapper-07 .screenshot-image .screenshot-slider .slick-center {
    transform: scale(1.1);
  }
}
.screenshot-slider-wrapper-07 .screenshot-image .screenshot-slider .slick-center img {
  border-radius: 10px;
}

.cta-section-03 {
  padding: 60px 0;
  z-index: 1;
  position: relative;
}
.cta-section-03::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(120, 27, 255, 0.95);
  z-index: -1;
}
@media (min-width: 768px) {
  .cta-section-03 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .cta-section-03 {
    padding: 100px 0;
  }
}
.cta-section-03__content {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .cta-section-03__content {
    margin-bottom: 40px;
  }
}
.cta-section-03__button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: -5px;
}
@media (min-width: 445px) {
  .cta-section-03__button-group {
    margin-right: -24px;
  }
}
.cta-section-03__button-group a {
  display: block;
  max-width: 142px;
  min-width: 142px;
  margin-top: 10px;
  margin-right: 5px;
}
@media (min-width: 420px) {
  .cta-section-03__button-group a {
    margin-right: 5px;
    margin-bottom: 0px;
  }
}
@media (min-width: 445px) {
  .cta-section-03__button-group a {
    margin-right: 24px;
    margin-bottom: 0px;
  }
}
@media (min-width: 480px) {
  .cta-section-03__button-group a {
    max-width: 150px;
    min-width: 150px;
  }
}
@media (min-width: 768px) {
  .cta-section-03__button-group a {
    max-width: unset;
    min-width: unset;
  }
}
@media (max-width: 768px) {
  .cta-section-03__button-group a img {
    width: 100%;
  }
}

.testimonil-section-03 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .testimonil-section-03 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .testimonil-section-03 {
    padding-top: 106px;
    padding-bottom: 130px;
  }
}
.testimonil-section-03__title {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .testimonil-section-03__title {
    margin-bottom: 60px;
  }
}
.testimonil-section-03 .testimonial-slider--03 {
  /* the parent */
}
.testimonil-section-03 .testimonial-slider--03 .slick-slide {
  margin: 0 12px;
}
.testimonil-section-03 .testimonial-slider--03 .slick-list {
  margin: 0 -12px;
}

.counter-section-03 {
  padding: 50px 0;
  background: rgba(120, 27, 255, 0.02);
}
@media (min-width: 768px) {
  .counter-section-03 {
    padding: 70px 0;
  }
}
@media (min-width: 992px) {
  .counter-section-03 {
    padding: 100px 0;
  }
}
@media (min-width: 768px) {
  .counter-section-03 .widget-counter {
    align-items: flex-start;
  }
}

.feature-section-03 {
  background: rgba(120, 27, 255, 0.02);
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .feature-section-03 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .feature-section-03 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.feature-section-03 .section-title {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .feature-section-03 .section-title {
    margin-bottom: 60px;
  }
}
.feature-section-03 .widget-card-01 {
  background: #FFFFFF;
  /* 01 */
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}

/* Header Css Start */
/* Hero Css Start */
/* Content Css Start */
/* Content Css Start */
/* Screenshot Css Start */
/* Cta Css Start */
/* Testimonial Css Start */
/* Fact Css Start */
/* Feature Css Start */
/* Landing One Header Button */
.site-header__btns--04 .btn {
  height: 45px;
}
.site-header__btns--04 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--04 .btn-1 {
    margin-right: 20px;
    min-width: 80px;
    color: #fff;
  }
}
.site-header__btns--04 .btn-2 {
  min-width: 120px;
  color: #fff;
  background: #FD4C5C;
  border-radius: 500px !important;
}
@media (min-width: 992px) {
  .site-header__btns--04 .btn-2 {
    background: rgba(255, 255, 255, 0.2);
    min-width: 120px;
  }
}
@media (max-width: 992px) {
  .site-header__btns--04 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-04 {
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  position: relative;
  z-index: 11;
  overflow: hidden;
}
.hero-section-04__bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 30%;
}
.hero-section-04__bg-shape img {
  width: 100%;
  height: 100%;
}
.hero-section-04__content {
  padding-top: 139px;
  padding-bottom: 45px;
}
@media (min-width: 768px) {
  .hero-section-04__content {
    padding-bottom: 65px;
  }
}
.hero-section-04__content .title {
  margin-bottom: 20px;
}
.hero-section-04__content--form {
  margin: auto;
  margin-top: 36px;
}
@media (min-width: 768px) {
  .hero-section-04__content--form {
    max-width: 526px;
  }
}
.hero-section-04__content--form .form-control {
  min-height: 66px;
  padding-left: 30px;
}
@media (min-width: 576px) {
  .hero-section-04__content--form .form-control {
    border-radius: 500px;
  }
}
@media (min-width: 576px) {
  .hero-section-04__content--form .btn {
    min-width: 181px !important;
    margin: 8px;
    border-radius: 500px;
  }
}
.hero-section-04__image-group {
  text-align: center;
  position: relative;
  z-index: 1;
}
.hero-section-04__image-group img {
  max-width: 80%;
}
@media (min-width: 576px) {
  .hero-section-04__image-group img {
    max-width: 100%;
  }
}
.hero-section-04__image-group .wrap {
  z-index: -11;
}
.hero-section-04__image-group--half-shape {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.hero-section-04__shape-1 {
  position: absolute;
  width: 4.5%;
  bottom: 31%;
  right: 0;
  z-index: -1;
}
.hero-section-04__shape-1 img {
  width: 100%;
}
.hero-section-04__shape-2 {
  position: absolute;
  width: 4.4%;
  left: -3px;
  bottom: 3%;
  z-index: -1;
}
.hero-section-04__shape-2 img {
  width: 100%;
}
.hero-section-04__shape-3 {
  position: absolute;
  width: 4.3%;
  top: 52%;
  left: 24%;
  z-index: -1;
}
.hero-section-04__shape-3 img {
  width: 100%;
}

.feature-section-04 {
  padding: 60px 0 55px;
}
@media (min-width: 768px) {
  .feature-section-04 {
    padding: 80px 0 72px;
  }
}
@media (min-width: 992px) {
  .feature-section-04 {
    padding-top: 100px;
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .feature-section-04 {
    padding-top: 125px;
    padding-bottom: 0;
  }
}
.feature-section-04__image-group {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .feature-section-04__image-group {
    padding-top: 55px;
  }
}
.feature-section-04__image-group img {
  max-width: 60%;
}
@media (min-width: 992px) {
  .feature-section-04__image-group img {
    max-width: 77%;
    margin-bottom: -40px;
  }
}
@media (min-width: 1200px) {
  .feature-section-04__image-group img {
    max-width: 100%;
    margin-bottom: -108px;
  }
}
.feature-section-04__image-group--shape {
  position: absolute;
  bottom: 16%;
  width: 35.6%;
  right: 10%;
  z-index: -1;
}
@media (min-width: 992px) {
  .feature-section-04__image-group--shape {
    right: 0%;
  }
}
.feature-section-04__image-group--shape svg {
  width: 100%;
}
.feature-section-04__widget {
  margin-top: 36px;
}
@media (min-width: 768px) {
  .feature-section-04__widget {
    margin-top: 46px;
  }
}
.feature-section-04__widget .widget-content .widget-icon {
  margin-right: 23px;
  min-height: 50px;
  max-height: 50px;
  min-width: 50px;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
}
@media (min-width: 576px) {
  .feature-section-04__widget .widget-content .widget-icon {
    min-height: 60px;
    max-height: 60px;
    min-width: 60px;
    max-width: 60px;
  }
}
.feature-section-04__widget .widget-content .widget-icon img {
  width: 20px;
  height: 20px;
}
.feature-section-04__widget .widget-content .widget-text {
  font-size: 16px;
  line-height: 26px;
  color: #000B33;
  opacity: 0.8;
}

.content-section-09 {
  background: rgba(43, 89, 255, 0.03);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .content-section-09 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-09 {
    padding-top: 100px;
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .content-section-09 {
    padding-top: 128px;
    padding-bottom: 0;
  }
}
.content-section-09__image-group {
  position: relative;
  overflow: hidden;
}
.content-section-09__image-group img {
  max-width: 60%;
}
@media (min-width: 992px) {
  .content-section-09__image-group img {
    max-width: 70%;
    margin-bottom: -108px;
  }
}
@media (min-width: 1200px) {
  .content-section-09__image-group img {
    max-width: 100%;
    margin-bottom: -135px;
  }
}
.content-section-09__image-group--shape-1 {
  position: absolute;
  top: 12.4%;
  right: 0;
  width: 54.5%;
  z-index: -1;
}
.content-section-09__image-group--shape-1 svg {
  width: 100%;
}
.content-section-09__image-group--shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 54.5%;
  z-index: -1;
}
.content-section-09__image-group--shape-2 svg {
  width: 100%;
}
.content-section-09__texts .title {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .content-section-09__texts .title {
    margin-bottom: 17px;
  }
}
.content-section-09__texts .btn {
  margin-top: 28px;
  border-radius: 30px;
  min-width: 150px;
}
@media (min-width: 768px) {
  .content-section-09__texts .btn {
    margin-top: 38px;
  }
}

.content-section-08 {
  padding-top: 38px;
  padding-bottom: 60px;
  position: relative;
  background: #001C80;
  z-index: 11;
}
@media (min-width: 768px) {
  .content-section-08 {
    padding-top: 57px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .content-section-08 {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .content-section-08 {
    padding-top: 105px;
    padding-bottom: 159px;
  }
}
.content-section-08__shape-1 {
  position: absolute;
  bottom: -40px;
  right: 6.4%;
  width: 6.7%;
  z-index: -1;
}
.content-section-08__shape-1 img, .content-section-08__shape-1 svg {
  width: 100%;
}
.content-section-08__shape-2 {
  position: absolute;
  top: 10%;
  left: 0;
  width: 5%;
  z-index: -1;
}
.content-section-08__shape-2 img, .content-section-08__shape-2 svg {
  width: 100%;
}
.content-section-08__image-group {
  margin-bottom: 30px;
  position: relative;
}
@media (min-width: 576px) {
  .content-section-08__image-group {
    margin-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .content-section-08__image-group {
    margin-right: 73px;
    margin-bottom: 0px;
  }
}
@media (min-width: 1200px) {
  .content-section-08__image-group {
    margin-right: 73px;
  }
}
@media (min-width: 1400px) {
  .content-section-08__image-group {
    margin-left: -41px;
    margin-right: 73px;
  }
}
.content-section-08__image-group--card {
  position: absolute;
  right: -11.3%;
  bottom: 13%;
  width: 31.7%;
}
.content-section-08__image-group--card img {
  width: 100%;
  filter: drop-shadow(0px 32px 54px rgba(65, 74, 85, 0.22));
}
.content-section-08__image-group--shape {
  position: absolute;
  left: 9.6%;
  width: 13.1%;
  z-index: -1;
  bottom: -24.7%;
}
@media (min-width: 992px) {
  .content-section-08__image-group--shape {
    bottom: -14.7%;
  }
}
.content-section-08__image-group--shape svg {
  width: 100%;
}
@media (min-width: 1200px) {
  .content-section-08__content {
    padding-left: 70px;
  }
}
.content-section-08__content .btn {
  margin-top: 20px;
  min-width: 150px;
  min-width: 174px;
  border-radius: 30px;
}
@media (min-width: 1200px) {
  .content-section-08__content .btn {
    min-width: 174px;
    margin-top: 40px;
  }
}

.nav-tab--content-2.nav-tab--content-2--02 {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  transition: 0.4s;
  margin-bottom: 35px;
  margin-left: -10px;
  border-bottom: 0;
}
@media (min-width: 576px) {
  .nav-tab--content-2.nav-tab--content-2--02 {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  }
}
@media (min-width: 992px) {
  .nav-tab--content-2.nav-tab--content-2--02 {
    margin-bottom: 60px;
    margin-bottom: 91px;
    justify-content: space-between;
  }
}
.nav-tab--content-2.nav-tab--content-2--02 .nav-item {
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 576px) {
  .nav-tab--content-2.nav-tab--content-2--02 .nav-item {
    margin-left: 0;
    margin-right: 0;
  }
}
.nav-tab--content-2.nav-tab--content-2--02 .nav-link {
  color: #fff !important;
  border: none;
  padding: 0 0 10px;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1.3;
  display: inline-flex;
  opacity: 0.7;
  transition: 0.4s;
  margin-right: 10px;
  border-bottom: 2px solid transparent;
  justify-content: space-around;
}
@media (min-width: 480px) {
  .nav-tab--content-2.nav-tab--content-2--02 .nav-link {
    padding: 18px 0;
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .nav-tab--content-2.nav-tab--content-2--02 .nav-link {
    font-size: 20px;
  }
}
.nav-tab--content-2.nav-tab--content-2--02 .nav-link.active {
  background-color: transparent;
  color: #FFC83E !important;
  opacity: 1;
  border-bottom: 2px solid #FFC83E !important;
}
.nav-tab--content-2.nav-tab--content-2--02 .nav-link:last-child {
  margin-right: 0;
}

.testimonil-section-04 {
  padding: 55px 0 60px;
}
@media (min-width: 768px) {
  .testimonil-section-04 {
    padding: 80px 0 80px;
  }
}
@media (min-width: 768px) {
  .testimonil-section-04 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-04 {
    padding: 125px 0 130px;
  }
}
.testimonil-section-04__title {
  text-align: center;
  margin-bottom: 45px;
}
@media (min-width: 992px) {
  .testimonil-section-04__title {
    margin-bottom: 80px;
  }
}
.testimonil-section-04__slider {
  position: relative;
}
.testimonil-section-04__slider .slick-slide {
  margin: 0 12px;
}
.testimonil-section-04__slider .slick-list {
  margin: 0 -12px;
}
.testimonil-section-04__slider .testimonial-slider--04__control-buttons {
  display: none;
}
@media (min-width: 768px) {
  .testimonil-section-04__slider .testimonial-slider--04__control-buttons {
    display: block;
  }
}
.testimonil-section-04__slider .testimonial-slider--04__control-buttons .button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: #FD4C5C;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.testimonil-section-04__slider .testimonial-slider--04__control-buttons .button svg {
  fill: white;
}
.testimonil-section-04__slider .testimonial-slider--04__control-buttons .button:hover {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.18);
}
.testimonil-section-04__slider .testimonial-slider--04__control-buttons .button:hover svg {
  fill: #FD4C5C;
}
.testimonil-section-04__slider .testimonial-slider--04__control-buttons .button--prev {
  left: -20px;
  cursor: pointer;
}
.testimonil-section-04__slider .testimonial-slider--04__control-buttons .button--next {
  right: -20px;
  cursor: pointer;
}
.testimonil-section-04__slider .card-testimonil-03 {
  box-shadow: none;
  border: 1px solid #E9E5EF;
}

.pricing-section-02 {
  background: rgba(43, 89, 255, 0.02);
  padding: 56px 0;
}
@media (min-width: 768px) {
  .pricing-section-02 {
    padding: 76px 0;
  }
}
@media (min-width: 992px) {
  .pricing-section-02 {
    padding: 95px 0 105px;
  }
}
@media (min-width: 1200px) {
  .pricing-section-02 {
    padding: 125px 0;
  }
}
.pricing-section-02__title {
  margin-bottom: 35px;
}
.pricing-section-02__title .subtitle {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .pricing-section-02__title {
    margin-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .pricing-section-02__title {
    margin-bottom: 80px;
  }
}

.counter-section-04 {
  padding: 60px 0;
  background: #001C80;
  position: relative;
}
@media (min-width: 768px) {
  .counter-section-04 {
    padding: 85px 0;
  }
}
@media (min-width: 992px) {
  .counter-section-04 {
    padding: 100px 0;
  }
}
@media (min-width: 768px) {
  .counter-section-04 .widget-counter {
    align-items: flex-start;
  }
}
.counter-section-04 .widget-counter .widget-icon {
  min-height: 50px;
  max-height: 50px;
  min-width: 50px;
  max-width: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
}
@media (min-width: 576px) {
  .counter-section-04 .widget-counter .widget-icon {
    min-height: 60px;
    max-height: 60px;
    min-width: 60px;
    max-width: 60px;
  }
}
@media (min-width: 768px) {
  .counter-section-04 .widget-counter .widget-icon {
    margin-top: 10px;
  }
}
.counter-section-04__shape {
  position: absolute;
  top: 0;
  left: 0;
}
.counter-section-04__shape svg {
  width: 100%;
}

/* Header Css Start */
/* Hero Css Start */
/* Feature Css Start */
/* Content Css Start */
/* Content Css Start */
/* Testimonial Css Start */
/* Pricing Css Start */
/* Fact Css Start */
/* Landing One Header Button */
.site-header__btns--05 .btn {
  height: 45px;
}
.site-header__btns--05 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--05 .btn-1 {
    margin-right: 20px;
    min-width: 80px;
  }
}
.site-header__btns--05 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #FD4C5C;
}
@media (min-width: 992px) {
  .site-header__btns--05 .btn-2 {
    min-width: 120px;
    color: #fff;
  }
}
@media (max-width: 992px) {
  .site-header__btns--05 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-05 {
  background-color: #EFF3F9;
  position: relative;
  overflow: hidden;
  padding-top: 100px;
}
@media (min-width: 992px) {
  .hero-section-05 {
    padding-top: 151px;
  }
}
@media (min-width: 575px) {
  .hero-section-05__content {
    margin-bottom: 70px;
  }
}
@media (min-width: 768px) {
  .hero-section-05__content {
    margin-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .hero-section-05__content {
    margin-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .hero-section-05__content {
    margin-bottom: 210px;
  }
}
.hero-section-05__content .text {
  font-weight: 600;
}
.hero-section-05__content .btn {
  margin-top: 25px;
  min-width: 156px;
  height: 50px;
}
.hero-section-05__content .btn:focus {
  box-shadow: none !important;
}
@media (min-width: 992px) {
  .hero-section-05__content .btn {
    margin-top: 40px;
    min-width: 196px;
    height: 60px;
  }
}
.hero-section-05__image-group {
  position: relative;
}
@media (min-width: 575px) {
  .hero-section-05__image-group {
    margin-bottom: -82%;
    margin-right: -24%;
  }
}
@media (min-width: 768px) {
  .hero-section-05__image-group {
    margin-bottom: -23%;
  }
}
@media (min-width: 992px) {
  .hero-section-05__image-group {
    margin-right: -46.5%;
    margin-left: -18px;
    margin-bottom: 0%;
  }
}
@media (min-width: 1200px) {
  .hero-section-05__image-group {
    margin-right: -46.5%;
    margin-left: -18px;
  }
}
.hero-section-05__image-group--shape {
  position: absolute;
  top: 6.3%;
  left: 34%;
  width: 14.1%;
}
.hero-section-05__shape-1 {
  position: absolute;
  top: 14.8%;
  left: 0%;
  width: 3.6%;
}
.hero-section-05__shape-2 {
  position: absolute;
  bottom: 11%;
  right: 0%;
  width: 4%;
}
.hero-section-05__shape-3 {
  position: absolute;
  bottom: 16%;
  left: 47%;
  width: 2.4%;
}

.counter-section-05 {
  padding: 55px 0 50px;
  position: relative;
}
@media (min-width: 768px) {
  .counter-section-05 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .counter-section-05 {
    padding: 90px 0 100px;
  }
}
.counter-section-05__widget .col-xl-3:nth-child(1) .widget .widget-icon i {
  position: relative;
  font-size: 20px;
  color: #FD4C5C;
}
.counter-section-05__widget .col-xl-3:nth-child(1) .widget .widget-icon i::after {
  animation: sonarWave-02 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: rgba(253, 76, 92, 0.8);
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.counter-section-05__widget .col-xl-3:nth-child(2) .widget .widget-icon i {
  position: relative;
  font-size: 20px;
  color: #00BB98;
}
.counter-section-05__widget .col-xl-3:nth-child(2) .widget .widget-icon i::after {
  animation: sonarWave-02 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: rgba(0, 187, 152, 0.8);
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.counter-section-05__widget .col-xl-3:nth-child(3) .widget .widget-icon i {
  position: relative;
  font-size: 20px;
  color: #FFC83E;
}
.counter-section-05__widget .col-xl-3:nth-child(3) .widget .widget-icon i::after {
  animation: sonarWave-02 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: rgba(255, 200, 62, 0.8);
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
.counter-section-05__widget .col-xl-3:nth-child(4) .widget .widget-icon i {
  position: relative;
  font-size: 20px;
  color: #781BFF;
}
.counter-section-05__widget .col-xl-3:nth-child(4) .widget .widget-icon i::after {
  animation: sonarWave-02 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  background-color: rgba(120, 27, 255, 0.8);
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}
@media (min-width: 768px) {
  .counter-section-05 .widget-text {
    text-align: start;
  }
}

.about-section-02 {
  padding: 48px 0 60px;
  background: rgba(0, 28, 128, 0.02);
  position: relative;
}
@media (min-width: 768px) {
  .about-section-02 {
    padding: 70px 0 80px;
  }
}
@media (min-width: 992px) {
  .about-section-02 {
    padding: 90px 0 90px;
  }
}
@media (min-width: 1200px) {
  .about-section-02 {
    padding: 130px 0;
  }
}
.about-section-02__content {
  margin-top: 12px;
}
@media (min-width: 992px) {
  .about-section-02__content {
    margin-top: 0;
    padding-left: 40px;
  }
}
@media (min-width: 1200px) {
  .about-section-02__content {
    padding-left: 0px;
  }
}
.about-section-02__content .btn {
  margin-top: 25px;
  min-width: 150px;
}
@media (min-width: 992px) {
  .about-section-02__content .btn {
    margin-top: 40px;
  }
}
.about-section-02__shape {
  position: absolute;
  bottom: -58px;
  left: 0;
  width: 4%;
}

.video-section-02 {
  padding: 80px 0;
  position: relative;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.video-section-02::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(41, 87, 255, 0.8);
  z-index: -1;
}
@media (min-width: 768px) {
  .video-section-02 {
    padding: 100px 0;
  }
}
@media (min-width: 992px) {
  .video-section-02 {
    padding: 150px 0;
  }
}
@media (min-width: 1200px) {
  .video-section-02 {
    padding: 210px 0;
  }
}
.video-section-02 .video-block .video-btn-1 {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 500px;
}
@media (min-width: 576px) {
  .video-section-02 .video-block .video-btn-1 {
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    max-height: 90px;
    border-radius: 500px;
  }
}
.video-section-02 .video-block .video-btn-1 i {
  font-size: 18px;
}
.video-section-02 .video-block--text {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 25px;
}

.testimonil-section-10 {
  padding: 55px 0 60px;
}
@media (min-width: 768px) {
  .testimonil-section-10 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .testimonil-section-10 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-10 {
    padding: 125px 0 130px;
  }
}
.testimonil-section-10 .content-title {
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .testimonil-section-10 .content-title {
    margin-bottom: 80px;
  }
}
.testimonil-section-10__content {
  position: relative;
  text-align: center;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
}
@media (min-width: 480px) {
  .testimonil-section-10__content {
    padding: 40px 60px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-10__content {
    padding: 60px 83px;
  }
}
.testimonil-section-10__content .text {
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 42px;
}
.testimonil-section-10__content .client-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 374px) {
  .testimonil-section-10__content .client-info {
    flex-direction: row;
  }
}
.testimonil-section-10__content .client-info--img img {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  object-fit: cover;
  margin-bottom: 24.29px;
  border-radius: 500px;
}
@media (min-width: 374px) {
  .testimonil-section-10__content .client-info--img img {
    margin-right: 24.29px;
    margin-bottom: 0px;
  }
}
.testimonil-section-10__content .client-info--texts {
  text-align: start;
}
.testimonil-section-10__content .client-info--texts .name {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.testimonil-section-10__content .client-info--texts .profession {
  font-size: 16px;
  line-height: 26px;
}
.testimonil-section-10__content .quote {
  position: absolute;
  right: 20px;
  bottom: 40px;
}
.testimonil-section-10 .testimonil-slider--02 .slick-list {
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}

.cta-section-05__wrapper {
  position: relative;
  padding: 60px 0;
  border-radius: 30px;
  z-index: 1;
}
@media (min-width: 768px) {
  .cta-section-05__wrapper {
    background: #001C80;
  }
}
@media (min-width: 768px) {
  .cta-section-05__wrapper {
    padding: 80px 0;
  }
}
.cta-section-05__wrapper--shape-1 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-05__wrapper--shape-1 {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 9.2%;
    z-index: -1;
  }
  .cta-section-05__wrapper--shape-1 svg {
    width: 100%;
    border-top-right-radius: 30px;
  }
}
.cta-section-05__wrapper--shape-2 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-05__wrapper--shape-2 {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 19%;
    z-index: -2;
  }
  .cta-section-05__wrapper--shape-2 svg {
    width: 100%;
    border-top-right-radius: 30px;
  }
}
.cta-section-05__wrapper--shape-3 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-05__wrapper--shape-3 {
    display: block;
    position: absolute;
    top: 26%;
    left: 0;
    width: 3%;
    z-index: -2;
  }
  .cta-section-05__wrapper--shape-3 svg {
    width: 100%;
  }
}
.cta-section-05__wrapper--shape-4 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-05__wrapper--shape-4 {
    display: block;
    position: absolute;
    top: 0;
    right: 35.5%;
    width: 18%;
    z-index: -2;
  }
  .cta-section-05__wrapper--shape-4 svg {
    width: 100%;
    border-top-right-radius: 30px;
  }
}
.cta-section-05__newsletter {
  margin: auto;
  margin-top: 40px;
}

.cta-bg-group-2 {
  background: #001C80;
  position: relative;
}
@media (min-width: 768px) {
  .cta-bg-group-2 {
    background: inherit;
  }
}
.cta-bg-group-2--shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 9.2%;
  z-index: 1;
}
.cta-bg-group-2--shape-1 svg {
  width: 100%;
}
@media (min-width: 768px) {
  .cta-bg-group-2--shape-1 {
    display: none;
  }
}
.cta-bg-group-2--shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 19%;
}
.cta-bg-group-2--shape-2 svg {
  width: 100%;
}
@media (min-width: 768px) {
  .cta-bg-group-2--shape-2 {
    display: none;
  }
}

.feature-section-05 {
  background: rgba(0, 28, 128, 0.02);
  padding-top: 55px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .feature-section-05 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .feature-section-05 {
    padding-top: 95px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .feature-section-05 {
    padding-top: 125px;
    padding-bottom: 135px;
  }
}
.feature-section-05 .content-title {
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .feature-section-05 .content-title {
    margin-bottom: 80px;
  }
}
.feature-section-05 .content-title .subtitle {
  color: #00BB98 !important;
  margin-bottom: 20px;
}
.feature-section-05 .feature-card .card-feature-01 {
  padding-left: 12px;
  padding-right: 12px;
}
@media (min-width: 992px) {
  .feature-section-05 .feature-card .card-feature-01 {
    padding: 0;
  }
}
.feature-section-05 .slick-slide {
  margin: 0 12px;
}

.content-section-11 {
  padding: 60px 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .content-section-11 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-11 {
    padding: 90px 0 100px;
  }
}
@media (min-width: 1200px) {
  .content-section-11 {
    padding: 125px 0 130px;
  }
}
@media (min-width: 992px) {
  .content-section-11__image-group {
    margin-right: -70%;
  }
}
@media (min-width: 1200px) {
  .content-section-11__image-group {
    margin-right: -93%;
    margin-left: -24px;
  }
}
.content-section-11__texts .subtitle {
  margin-bottom: 20px;
}
.content-section-11__texts--accordion {
  margin-top: 40px;
}
.content-section-11__texts--accordion .accordion-item {
  border: 0;
  margin-bottom: 30px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  position: relative;
}
.content-section-11__texts--accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.content-section-11__texts--accordion .accordion-item .accordion-header {
  transition: 0.3s;
  display: flex;
  padding: 20px 20px;
}
@media (min-width: 480px) {
  .content-section-11__texts--accordion .accordion-item .accordion-header {
    padding: 20px 30px;
  }
}
.content-section-11__texts--accordion .accordion-item .accordion-header .icon-block {
  font-weight: bold;
  font-size: 24px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 20px;
}
@media (min-width: 576px) {
  .content-section-11__texts--accordion .accordion-item .accordion-header .icon-block {
    font-size: 30px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
}
.content-section-11__texts--accordion .accordion-item .accordion-header .icon-block.icon-green {
  background-color: rgba(0, 187, 152, 0.2);
  color: #00BB98 !important;
}
.content-section-11__texts--accordion .accordion-item .accordion-header .icon-block.icon-red {
  background-color: rgba(253, 76, 92, 0.2);
  color: #FD4C5C !important;
}
.content-section-11__texts--accordion .accordion-item .accordion-header .icon-block.icon-blue {
  background-color: rgba(43, 89, 255, 0.2);
  color: #2B59FF !important;
}
.content-section-11__texts--accordion .accordion-item .accordion-header .accordion-button {
  position: static;
  transition: 0.3s;
  border: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  color: rgba(0, 11, 51, 0.8) !important;
  box-shadow: none;
  background-color: inherit !important;
  padding: 0;
}
@media (min-width: 576px) {
  .content-section-11__texts--accordion .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .content-section-11__texts--accordion .accordion-item .accordion-header .accordion-button {
    font-size: 20px;
  }
}
.content-section-11__texts--accordion .accordion-item .accordion-header .accordion-button:before {
  content: "";
  width: 100%;
  transition: 0.3s;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0.25px solid rgba(0, 187, 152, 0.25);
  border-radius: 10px;
  opacity: 0;
}
.content-section-11__texts--accordion .accordion-item .accordion-header .accordion-button.collapsed {
  transition: 0.3s;
}
.content-section-11__texts--accordion .accordion-item .accordion-header .accordion-button.collapsed:before {
  opacity: 1;
  transition: 0.3s;
}
.content-section-11__texts--accordion .accordion-item .accordion-header .accordion-button::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  background-image: none;
}
.content-section-11__texts--accordion .accordion-collapse {
  transition: 0.3s;
  margin-left: 10px;
}
.content-section-11__texts--accordion .accordion-collapse.show {
  margin-top: -18px;
}
@media (max-width: 575px) {
  .content-section-11__texts--accordion .accordion-collapse.show {
    margin-top: -10px;
  }
}
.content-section-11__texts--accordion .accordion-body {
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 0;
  font-size: 14px;
  line-height: 1.625;
  padding-left: 72px;
}
@media (min-width: 480px) {
  .content-section-11__texts--accordion .accordion-body {
    padding-left: 81px;
  }
}
@media (min-width: 576px) {
  .content-section-11__texts--accordion .accordion-body {
    padding-left: 100px;
    font-size: 18px;
  }
}

.content-section-12 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .content-section-12 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-12 {
    padding: 100px 0 170px;
  }
}
@media (min-width: 1200px) {
  .content-section-12 {
    padding: 130px 0 200px;
  }
}
.content-section-12 .content-title .btn {
  margin-top: 40px;
  min-width: 150px;
}
.content-section-12__image-group {
  margin-bottom: 53px;
  text-align: center;
  position: relative;
}
@media (min-width: 380px) {
  .content-section-12__image-group {
    margin-bottom: 70px;
  }
}
@media (min-width: 576px) {
  .content-section-12__image-group {
    margin-bottom: 64px;
  }
}
@media (min-width: 768px) {
  .content-section-12__image-group {
    margin-bottom: 72px;
  }
}
@media (min-width: 992px) {
  .content-section-12__image-group {
    margin-bottom: 0px;
  }
}
.content-section-12__image-group img {
  border-radius: 10px;
  max-width: 75%;
}
@media (min-width: 992px) {
  .content-section-12__image-group img {
    max-width: 100%;
  }
}
.content-section-12__image-group--group {
  position: absolute;
  width: 30.9%;
  left: 6%;
  bottom: -15.5%;
}
.content-section-12__image-group--group img {
  border-radius: 10px;
}
.content-section-12__image-group--dots {
  position: absolute;
  width: 27.4%;
  bottom: 6%;
  left: -1px;
  z-index: -1;
}
.content-section-12__image-group--shape {
  position: absolute;
  width: 31.8%;
  top: 24.5%;
  right: 0%;
  z-index: -1;
}
@media (min-width: 1200px) {
  .content-section-12__image-group--shape {
    right: 6%;
  }
}

.content-section-10 {
  padding: 55px 0 60px;
  background: rgba(0, 28, 128, 0.02);
  overflow: hidden;
}
@media (min-width: 768px) {
  .content-section-10 {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .content-section-10 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 992px) {
  .content-section-10 {
    padding: 125px 0 130px;
  }
}
.content-section-10__content {
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .content-section-10__content {
    margin-bottom: 57px;
  }
}
@media (min-width: 992px) {
  .content-section-10__content {
    margin-bottom: 97px;
  }
}

.screenshot-slider-wrapper-10 {
  position: relative;
}
.screenshot-slider-wrapper-10 .phone-bg-img {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 11;
  width: 267px;
  transform: translate(-50%, -50%);
}
@media (min-width: 690px) {
  .screenshot-slider-wrapper-10 .phone-bg-img {
    width: 256px;
    transform: translate(-50%, -50%);
    display: block;
  }
}
.screenshot-slider-wrapper-10 .phone-bg-img > img {
  width: 100%;
}
.screenshot-slider-wrapper-10 .screenshot-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}
@media (min-width: 690px) {
  .screenshot-slider-wrapper-10 .screenshot-image {
    width: 230px;
  }
}
.screenshot-slider-wrapper-10 .screenshot-image > img {
  max-width: 100%;
  border: 1px solid #efeaf7;
  filter: drop-shadow(0px 18px 26px rgba(65, 32, 103, 0.06));
  border-radius: 10px;
}
.screenshot-slider-wrapper-10 .screenshot-image .screenshot-slider .slick-list {
  margin: 0 -23.5px;
}
.screenshot-slider-wrapper-10 .screenshot-image .screenshot-slider .slick-slide {
  opacity: 0.5;
  transition: 0.4s;
}
.screenshot-slider-wrapper-10 .screenshot-image .screenshot-slider .slick-center {
  opacity: 1;
  border-radius: 10px;
}
@media (min-width: 850px) {
  .screenshot-slider-wrapper-10 .screenshot-image .screenshot-slider .slick-center {
    transform: scale(1.1);
  }
}
.screenshot-slider-wrapper-10 .screenshot-image .screenshot-slider .slick-center img {
  border-radius: 10px;
}

/* Header Css Start */
/* Hero Css Start */
/* Fact Css Start */
/* About Css Start */
/* Video Css Start */
/* Testimonial Css Start */
/* Cta Css Start */
/* Feature Css Start */
/* Content Css Start */
/* Content Css Start */
/* Screenshot Css Start */
/* Landing One Header Button */
.site-header__btns--06 .btn {
  height: 45px;
}
.site-header__btns--06 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--06 .btn-1 {
    min-width: 80px;
    margin-right: 20px;
    color: #fff;
  }
}
.site-header__btns--06 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #FD4C5C;
}
@media (min-width: 992px) {
  .site-header__btns--06 .btn-2 {
    min-width: 120px;
  }
}
@media (max-width: 992px) {
  .site-header__btns--06 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.service-section-01 {
  padding: 55px 0 60px;
  position: relative;
}
@media (min-width: 768px) {
  .service-section-01 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .service-section-01 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .service-section-01 {
    padding: 125px 0 130px;
  }
}
.service-section-01 .section-title {
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .service-section-01 .section-title {
    margin-bottom: 80px;
  }
}
.service-section-01__shape {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 22.9%;
}
.service-section-01__card {
  padding: 30px 25px;
  text-align: center;
  background: #FFFFFF;
  border: 0.25px solid rgba(0, 11, 51, 0.25);
  box-sizing: border-box;
  border-radius: 10px;
  transition: 0.3s;
}
@media (min-width: 576px) {
  .service-section-01__card {
    padding: 35px 30px;
  }
}
@media (min-width: 1200px) {
  .service-section-01__card {
    padding: 50px 64px;
  }
}
.service-section-01__card:hover {
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border: 0.25px solid transparent;
}
.service-section-01__card .widget-icon {
  background: rgba(55, 124, 253, 0.1);
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 25px;
}
@media (min-width: 575px) {
  .service-section-01__card .widget-icon {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .service-section-01__card .widget-icon {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  }
}
.service-section-01__card .widget-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.4444444444;
}
@media (min-width: 576px) {
  .service-section-01__card .widget-title {
    font-size: 20px;
  }
}
.service-section-01__card .widget-text {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}

.about-section-03 {
  padding: 55px 0 60px;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .about-section-03 {
    padding: 85px 0 80px;
  }
}
@media (min-width: 992px) {
  .about-section-03 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .about-section-03 {
    padding: 130px 0;
  }
}
.about-section-03__widget--wrapper {
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .about-section-03__widget--wrapper {
    width: 440px;
  }
}
@media (min-width: 1200px) {
  .about-section-03__widget--wrapper:nth-child(1) {
    margin-left: 50px;
  }
}
.about-section-03__widget--wrapper:nth-child(1) .widget-icon {
  background: rgba(55, 124, 253, 0.1);
}
.about-section-03__widget--wrapper:nth-child(2) .widget-icon {
  background: rgba(253, 76, 92, 0.1);
}
.about-section-03__widget--wrapper:nth-child(3) {
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .about-section-03__widget--wrapper:nth-child(3) {
    margin-left: 50px;
  }
}
.about-section-03__widget--wrapper:nth-child(3) .widget-icon {
  background: rgba(0, 187, 152, 0.1);
}
.about-section-03__widget--wrapper .widget-icon {
  margin-right: 20px !important;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  display: flex;
  border-radius: 500px;
  align-items: center;
  justify-content: center;
}
.about-section-03__widget--wrapper .widget-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .about-section-03__widget--wrapper .widget-title {
    font-size: 20px;
  }
}
.about-section-03__widget--wrapper .widget-text {
  color: rgba(0, 11, 51, 0.8);
  font-size: 16px;
  line-height: 26px;
}
.about-section-03__content .btn {
  min-width: 150px;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .about-section-03__content .btn {
    margin-top: 40px;
  }
}

.hero-section-06 {
  padding-top: 150px;
  padding-bottom: 80px;
  background: #377CFD;
}
@media (min-width: 768px) {
  .hero-section-06 {
    padding-top: 115px;
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .hero-section-06 {
    padding-top: 216px;
    padding-bottom: 197px;
  }
}
.hero-section-06__content .title {
  margin-bottom: 15px;
}
.hero-section-06__content--btn-group {
  margin-top: 40px;
  display: inline-flex;
  flex-direction: column;
}
.hero-section-06__content--btn-group .btn {
  min-width: 180px;
  margin-bottom: 25px;
  height: 60px;
}
.hero-section-06__content--btn-group .btn-2 {
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
}
.hero-section-06__content--btn-group .btn-2 img {
  margin-right: 10px;
}
.hero-section-06__image-group {
  position: relative;
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .hero-section-06__image-group {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .hero-section-06__image-group {
    margin-right: -66%;
  }
}
.hero-section-06__image-group img {
  filter: drop-shadow(-5px 5px 50px rgba(108, 113, 114, 0.1));
  border-radius: 2%;
}
.hero-section-06__image-group--card {
  position: absolute;
  bottom: -5.6%;
  left: -5.7%;
  width: 22.7%;
}
.hero-section-06__image-group--card img {
  filter: drop-shadow(0px 140.083px 344.604px rgba(121, 149, 199, 0.21));
}
.hero-section-06__image-group--circel {
  position: absolute;
  top: -25%;
  left: -6%;
  width: 94%;
  z-index: -1;
}
@media (nin-width: 576px) {
  .hero-section-06__image-group--circel {
    left: -15%;
  }
}

.cta-section-06__wrapper {
  position: relative;
  padding: 70px 0;
  border-radius: 30px;
  z-index: 1;
}
@media (min-width: 768px) {
  .cta-section-06__wrapper {
    background: #377CFD;
  }
}
.cta-section-06__wrapper--shape-1 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-06__wrapper--shape-1 {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    z-index: -1;
  }
  .cta-section-06__wrapper--shape-1 svg {
    width: 100%;
    border-top-right-radius: 30px;
  }
}
.cta-section-06__wrapper--shape-3 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-06__wrapper--shape-3 {
    display: block;
    position: absolute;
    top: 23%;
    left: 0;
    width: 3%;
    z-index: -1;
  }
  .cta-section-06__wrapper--shape-3 svg {
    width: 100%;
  }
}
.cta-section-06__wrapper--shape-4 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-06__wrapper--shape-4 {
    display: block;
    position: absolute;
    bottom: 7%;
    left: 32%;
    width: 2%;
    z-index: -1;
  }
  .cta-section-06__wrapper--shape-4 svg {
    width: 100%;
  }
}
.cta-section-06__wrapper--shape-5 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-06__wrapper--shape-5 {
    display: block;
    position: absolute;
    top: 0;
    left: 54%;
    width: 18%;
    z-index: -1;
  }
  .cta-section-06__wrapper--shape-5 svg {
    width: 100%;
  }
}
.cta-section-06__contennt {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .cta-section-06__contennt {
    margin-bottom: 0;
  }
}
.cta-section-06__button-group {
  text-align: end;
  height: 100%;
  display: flex;
  align-items: flex-end;
  max-width: 300px;
}
@media (min-width: 576px) {
  .cta-section-06__button-group {
    max-width: 380px;
  }
}
@media (min-width: 992px) {
  .cta-section-06__button-group {
    justify-content: flex-end;
    max-width: 100%;
  }
}
.cta-section-06__button-group a {
  margin-right: 20px;
}
.cta-section-06__button-group a:last-child {
  margin-right: 0px;
}

.cta-bg-group-3 {
  background: #377CFD;
  position: relative;
}
.cta-bg-group-3--shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}
.cta-bg-group-3--shape-1 svg {
  width: 100%;
}
@media (min-width: 768px) {
  .cta-bg-group-3--shape-1 {
    display: none;
  }
}
@media (min-width: 768px) {
  .cta-bg-group-3 {
    background-color: inherit;
  }
}

.content-section-14 {
  padding: 60px 0 60px;
  position: relative;
  background: rgba(55, 124, 253, 0.02);
  z-index: 1;
}
@media (min-width: 768px) {
  .content-section-14 {
    padding: 83px 0 51px;
  }
}
@media (min-width: 992px) {
  .content-section-14 {
    padding: 95px 0 118px;
  }
}
@media (min-width: 1200px) {
  .content-section-14 {
    padding: 125px 0 150px;
  }
}
@media (min-width: 1400px) {
  .content-section-14 {
    padding: 125px 0 170px;
  }
}
.content-section-14__content .btn {
  min-width: 150px;
  margin-top: 20px;
}
@media (min-width: 992px) {
  .content-section-14__content .btn {
    margin-top: 40px;
  }
}
.content-section-14__image-group {
  margin-bottom: 50px;
  position: relative;
}
@media (min-width: 576px) {
  .content-section-14__image-group {
    margin-bottom: 62px;
  }
}
@media (min-width: 768px) {
  .content-section-14__image-group {
    margin-bottom: 68px;
  }
}
@media (min-width: 992px) {
  .content-section-14__image-group {
    margin-bottom: 0px;
  }
}
.content-section-14__image-group img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
@media (min-width: 1200px) {
  .content-section-14__image-group {
    padding-left: 77px;
  }
}
.content-section-14__image-group--card {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 27.6%;
}
.content-section-14__image-group--card img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
.content-section-14__shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 23.3%;
  z-index: -1;
}
.content-section-14__shape img {
  width: 100%;
}

.content-section-13 {
  padding: 55px 0 60px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .content-section-13 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .content-section-13 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .content-section-13 {
    padding: 130px 0;
  }
}
.content-section-13__content .btn {
  min-width: 150px;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .content-section-13__content .btn {
    margin-top: 40px;
  }
}
.content-section-13__image-group img {
  filter: drop-shadow(0px 32px 54px rgba(65, 74, 85, 0.22));
}
@media (min-width: 1200px) {
  .content-section-13__image-group {
    margin-left: -18%;
  }
}
.content-section-13__shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 23.3%;
  z-index: -1;
}
.content-section-13__shape img {
  width: 100%;
}

.pricing-section-03 {
  padding: 55px 0 60px;
  position: relative;
}
.pricing-section-03__shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 23.3%;
  z-index: -1;
}
.pricing-section-03__shape img {
  width: 100%;
}
@media (min-width: 768px) {
  .pricing-section-03 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .pricing-section-03 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .pricing-section-03 {
    padding: 125px 0 130px;
  }
}
.pricing-section-03__title {
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .pricing-section-03__title {
    margin-bottom: 80px;
  }
}
.pricing-section-03 .pricing-sider .slick-slide {
  margin: 0 12px;
}

.counter-section-06 {
  padding: 60px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .counter-section-06 {
    padding: 0px 0;
  }
}
@media (min-width: 992px) {
  .counter-section-06 {
    padding: 100px 0;
  }
}
.counter-section-06 .widget-counter {
  justify-content: start;
  padding: 20px 40px;
  border-radius: 10px;
}
.counter-section-06 .widget-counter .widget-title {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .counter-section-06 .widget-counter .widget-title {
    margin-right: 20px;
  }
}
.counter-section-06 .widget-counter .widget-text {
  color: #000B33;
}
.counter-section-06 .col-xl-3:nth-child(1) .widget-counter {
  background: rgba(0, 187, 152, 0.1);
}
.counter-section-06 .col-xl-3:nth-child(2) .widget-counter {
  background: rgba(120, 27, 255, 0.1);
}
.counter-section-06 .col-xl-3:nth-child(3) .widget-counter {
  background: rgba(253, 76, 92, 0.1);
}
.counter-section-06 .col-xl-3:nth-child(4) .widget-counter {
  background: rgba(255, 200, 62, 0.1);
}

.testimonil-section-05 {
  padding: 55px 0 60px;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .testimonil-section-05 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .testimonil-section-05 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-05 {
    padding: 125px 0 130px;
  }
}
.testimonil-section-05 .content-title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .testimonil-section-05 .content-title {
    margin-bottom: 60px;
  }
}
.testimonil-section-05__slider .slick-list {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
.testimonil-section-05__content {
  text-align: center;
  padding: 30px;
}
@media (min-width: 480px) {
  .testimonil-section-05__content {
    padding: 40px 60px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-05__content {
    padding: 60px 83px;
  }
}
.testimonil-section-05__content .text {
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 42px;
}
.testimonil-section-05__content .client-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 374px) {
  .testimonil-section-05__content .client-info {
    flex-direction: row;
  }
}
.testimonil-section-05__content .client-info--img img {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  object-fit: cover;
  margin-bottom: 24.29px;
  border-radius: 500px;
}
@media (min-width: 374px) {
  .testimonil-section-05__content .client-info--img img {
    margin-right: 24.29px;
    margin-bottom: 0px;
  }
}
.testimonil-section-05__content .client-info--texts {
  text-align: start;
}
.testimonil-section-05__content .client-info--texts .name {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.testimonil-section-05__content .client-info--texts .profession {
  font-size: 16px;
  line-height: 26px;
}
.testimonil-section-05__content .quote {
  position: absolute;
  right: 20px;
  bottom: 40px;
}
.testimonil-section-05 .testimonil-slider--02 .slick-list {
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}

/* Header Css Start */
/* Service Css Start */
/* About Css Start */
/* Hero Css Start */
/* Cta Css Start */
/* Content Css Start */
/* Content Css Start */
/* Pricing Css Start */
/* Fact Css Start */
/* Testimonial Css Start */
/* Landing One Header Button */
.site-header__btns--07 .btn {
  height: 45px;
}
.site-header__btns--07 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--07 .btn-1 {
    min-width: 80px;
    margin-right: 20px;
    color: #fff;
  }
}
.site-header__btns--07 .btn-2 {
  min-width: 120px;
  color: #fff;
  border-radius: 10px !important;
  background-color: #FD4C5C;
}
@media (min-width: 992px) {
  .site-header__btns--07 .btn-2 {
    min-width: 120px;
    background-color: white;
  }
}
@media (max-width: 992px) {
  .site-header__btns--07 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-07 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.hero-section-07__circle-1 {
  position: absolute;
  top: 17%;
  left: 3%;
  border-radius: 500px;
  width: 20px;
  height: 20px;
  background: #FFC83E;
}
.hero-section-07__circle-2 {
  position: absolute;
  top: 19%;
  right: 13%;
  border: 3px solid #00BB98;
  border-radius: 500px;
  width: 20px;
  height: 20px;
}
.hero-section-07__circle-3 {
  position: absolute;
  top: 60%;
  right: 2%;
  border-radius: 500px;
  width: 20px;
  height: 20px;
  background: #FD4C5C;
}
.hero-section-07__circle-4 {
  position: absolute;
  top: 80%;
  left: 44%;
  border-radius: 500px;
  width: 20px;
  height: 20px;
  border: 3px solid #FFC83E;
}
.hero-section-07__circle-5 {
  position: absolute;
  bottom: 17%;
  left: 2%;
  border-radius: 500px;
  width: 10px;
  height: 10px;
  background: #FD4C5C;
}
.hero-section-07__bottom-shape {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
}
.hero-section-07__bottom-shape img {
  width: 100%;
}
.hero-section-07__content {
  margin-top: 120px;
}
@media (min-width: 992px) {
  .hero-section-07__content {
    margin-top: -110px;
  }
}
.hero-section-07__content .text {
  margin-top: 15px;
}
.hero-section-07__content--btn-group {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.hero-section-07__content--btn-group .btn {
  min-width: 200px;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 17px;
  margin-right: 55px;
}
@media (min-width: 768px) {
  .hero-section-07__content--btn-group .btn {
    min-width: 220px;
    height: 60px;
    margin-bottom: 0;
    margin-right: 17px;
  }
}
@media (min-width: 1200px) {
  .hero-section-07__content--btn-group .btn {
    min-width: 280px;
    height: 70px;
  }
}
.hero-section-07__content--btn-group .btn-2 {
  display: flex;
  align-items: center;
}
.hero-section-07__content--btn-group .btn-2 .icon {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 500px;
  margin-right: 16px;
}
.hero-section-07__content--btn-group .btn-2 span {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: white;
}
.hero-section-07__image-group {
  position: relative;
  z-index: -1;
}
@media (min-width: 992px) {
  .hero-section-07__image-group {
    margin-top: 95px;
  }
}
.hero-section-07__image-group img {
  max-width: 100%;
}
.hero-section-07__image-group--shape {
  position: absolute;
  top: 18%;
  right: 5%;
  width: 49%;
  z-index: -1;
}
.hero-section-07__shape-1 {
  position: absolute;
  bottom: 2%;
  left: 12.5%;
  width: 6.9%;
}

.content-section-15 {
  padding: 55px 0 65px;
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .content-section-15 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .content-section-15 {
    padding: 100px 0 100px;
  }
}
@media (min-width: 1200px) {
  .content-section-15 {
    padding: 130px 0;
  }
}
.content-section-15__circle-1 {
  position: absolute;
  top: 2%;
  right: 3%;
  background: #FD4C5C;
  width: 20px;
  height: 20px;
  border-radius: 500px;
}
.content-section-15__circle-2 {
  position: absolute;
  top: 49%;
  left: 45%;
  border: 3px solid #FFC83E;
  width: 20px;
  height: 20px;
  border-radius: 500px;
}
.content-section-15__circle-3 {
  position: absolute;
  top: 27%;
  left: 2%;
  background: #FD4C5C;
  width: 10px;
  height: 10px;
  border-radius: 500px;
}
.content-section-15__content .btn {
  min-width: 150px;
  margin-top: 30px;
  border-radius: 10px;
}
@media (min-width: 576px) {
  .content-section-15__content .btn {
    margin-top: 40px;
  }
}
.content-section-15__card-group .col-xl-6:nth-child(1) .widget-card .widget-icon {
  background: rgba(0, 187, 152, 0.1);
}
.content-section-15__card-group .col-xl-6:nth-child(2) .widget-card .widget-icon {
  background: rgba(43, 89, 255, 0.1);
}
.content-section-15__card-group .col-xl-6:nth-child(3) .widget-card .widget-icon {
  background: rgba(253, 76, 92, 0.1);
}
.content-section-15__card-group .col-xl-6:nth-child(4) .widget-card .widget-icon {
  background: rgba(255, 200, 62, 0.1);
}
.content-section-15__card-group .widget-card {
  padding: 40px 30px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
.content-section-15__card-group .widget-card .widget-icon {
  background: rgba(55, 124, 253, 0.1);
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .content-section-15__card-group .widget-card .widget-icon {
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
  }
}
.content-section-15__card-group .widget-card .widget-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .content-section-15__card-group .widget-card .widget-title {
    font-size: 20px;
  }
}
.content-section-15__card-group .widget-card .widget-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}

.content-section-16 {
  padding: 55px 0 60px;
  position: relative;
  z-index: 11;
  background: rgba(0, 28, 128, 0.02);
}
@media (min-width: 768px) {
  .content-section-16 {
    padding: 90px 0 80px;
  }
}
@media (min-width: 992px) {
  .content-section-16 {
    padding: 120px 0 100px;
  }
}
@media (min-width: 1200px) {
  .content-section-16 {
    padding: 156px 0 130px;
  }
}
.content-section-16__shape-1 {
  position: absolute;
  top: -9px;
  left: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .content-section-16__shape-1 {
    top: 0;
  }
}
.content-section-16__shape-2 {
  position: absolute;
  top: -16%;
  left: 0;
  width: 12%;
  z-index: 0;
}
.content-section-16__image-group {
  position: relative;
}
.content-section-16__image-group--card {
  position: absolute;
  right: 3%;
  bottom: 28%;
  width: 29.3%;
}
.content-section-16__image-group--card img {
  border-radius: 10px;
  width: 100%;
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
}
.content-section-16__image-group img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
.content-section-16__content--button-group {
  margin: 0 -9px;
  margin-top: 40px;
  display: inline-flex;
  align-items: center;
}
.content-section-16__content--button-group a {
  margin: 0 9px;
}
.content-section-16 .nav-tab--content-2 {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: 0.4s;
  margin-bottom: 60px;
  border: 0;
  margin-left: -10px;
}
@media (min-width: 768px) {
  .content-section-16 .nav-tab--content-2 {
    flex-direction: row;
    border-bottom: 2px solid rgba(0, 28, 128, 0.2);
  }
}
@media (min-width: 992px) {
  .content-section-16 .nav-tab--content-2 {
    margin-bottom: 91px;
    justify-content: space-between;
  }
}
.content-section-16 .nav-tab--content-2 .nav-link {
  border: none;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1.3;
  padding: 15px 0;
  display: inline-flex;
  color: #2B59FF;
  opacity: 0.7;
  transition: 0.4s;
  margin-right: 10px;
  border-bottom: 2px solid transparent;
  justify-content: space-around;
}
@media (min-width: 480px) {
  .content-section-16 .nav-tab--content-2 .nav-link {
    padding: 18px 0;
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .content-section-16 .nav-tab--content-2 .nav-link {
    font-size: 20px;
  }
}
.content-section-16 .nav-tab--content-2 .nav-link.active {
  background-color: transparent;
  color: #FD4C5C;
  opacity: 1;
  border-bottom: 2px solid #FD4C5C;
}
.content-section-16 .nav-tab--content-2 .nav-link:last-child {
  margin-right: 0;
}

.about-section-04 {
  padding-top: 60px;
  padding-bottom: 84px;
  position: relative;
  background: rgba(0, 28, 128, 0.02);
  z-index: 1;
}
@media (min-width: 768px) {
  .about-section-04 {
    padding-top: 80px;
    padding-bottom: 112px;
  }
}
@media (min-width: 992px) {
  .about-section-04 {
    padding-top: 95px;
    padding-bottom: 160px;
  }
}
@media (min-width: 1200px) {
  .about-section-04 {
    padding-top: 125px;
    padding-bottom: 185px;
  }
}
@media (min-width: 1400px) {
  .about-section-04 {
    padding-bottom: 230px;
  }
}
.about-section-04__circle-1 {
  position: absolute;
  top: 53%;
  left: 5%;
  width: 20px;
  height: 20px;
  background: #FFC83E;
  border-radius: 500px;
}
.about-section-04__circle-2 {
  position: absolute;
  top: 75%;
  right: 27%;
  width: 20px;
  height: 20px;
  border: 3px solid #00BB98;
  border-radius: 500px;
}
.about-section-04__content .btn {
  border-radius: 10px;
  min-width: 150px;
  margin-top: 35px;
}
@media (min-width: 992px) {
  .about-section-04__content .btn {
    margin-top: 40px;
  }
}
.about-section-04__image-group {
  margin-bottom: 50px;
  position: relative;
}
@media (min-width: 576px) {
  .about-section-04__image-group {
    margin-bottom: 62px;
  }
}
@media (min-width: 768px) {
  .about-section-04__image-group {
    margin-bottom: 68px;
  }
}
@media (min-width: 992px) {
  .about-section-04__image-group {
    margin-bottom: 0px;
  }
}
.about-section-04__image-group img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
@media (min-width: 1200px) {
  .about-section-04__image-group {
    padding-left: 77px;
  }
}
.about-section-04__image-group--card {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 27.6%;
}
.about-section-04__image-group--card img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
.about-section-04--shape {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
}
.about-section-04--shape img {
  width: 100%;
}

.counter-section-07 {
  padding: 60px 0;
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
}
@media (min-width: 768px) {
  .counter-section-07 {
    padding: 85px 0;
  }
}
.counter-section-07 .col-xl-3:nth-child(1) .widget-icon {
  background: rgba(0, 187, 152, 0.3);
}
.counter-section-07 .col-xl-3:nth-child(2) .widget-icon {
  background: rgba(255, 200, 62, 0.3);
}
.counter-section-07 .col-xl-3:nth-child(3) .widget-icon {
  background: rgba(253, 76, 92, 0.3);
}
.counter-section-07 .col-xl-3:nth-child(4) .widget-icon {
  background: rgba(255, 255, 255, 0.3);
}
@media (min-width: 768px) {
  .counter-section-07 .widget-counter {
    align-items: flex-start;
  }
}
.counter-section-07 .widget-counter .widget-icon {
  min-height: 50px;
  max-height: 50px;
  min-width: 50px;
  max-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
}
@media (min-width: 576px) {
  .counter-section-07 .widget-counter .widget-icon {
    min-height: 60px;
    max-height: 60px;
    min-width: 60px;
    max-width: 60px;
  }
}
@media (min-width: 768px) {
  .counter-section-07 .widget-counter .widget-icon {
    margin-top: 10px;
  }
}
@media (min-width: 992px) {
  .counter-section-07 .widget-counter .widget-text {
    text-align: start;
  }
}

.cta-section-07__wrapper {
  position: relative;
  padding: 60px 0;
  border-radius: 20px;
  z-index: 1;
}
@media (min-width: 768px) {
  .cta-section-07__wrapper {
    background: #001C80;
  }
}
@media (min-width: 768px) {
  .cta-section-07__wrapper {
    padding: 80px 0;
  }
}
.cta-section-07__wrapper--top-shape {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-07__wrapper--top-shape {
    display: block;
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
  }
  .cta-section-07__wrapper--top-shape img {
    width: 100%;
  }
}
.cta-section-07__wrapper--shape-1 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-07__wrapper--shape-1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 12%;
    z-index: -1;
  }
  .cta-section-07__wrapper--shape-1 svg {
    width: 100%;
    border-top-left-radius: 18px;
  }
}
.cta-section-07__wrapper--shape-2 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-07__wrapper--shape-2 {
    display: block;
    position: absolute;
    top: 52%;
    left: 5.8%;
    width: 5%;
    z-index: -2;
    height: 5%;
  }
  .cta-section-07__wrapper--shape-2 svg {
    width: 100%;
    height: 100%;
    border-top-right-radius: 30px;
  }
}
.cta-section-07__wrapper--shape-3 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-07__wrapper--shape-3 {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 13%;
    z-index: -2;
  }
  .cta-section-07__wrapper--shape-3 svg {
    width: 100%;
  }
}
.cta-section-07__newsletter {
  margin: auto;
  margin-top: 40px;
}

.cta-bg-group-4 {
  background: #001C80;
  position: relative;
}
@media (min-width: 768px) {
  .cta-bg-group-4 {
    background: inherit;
  }
}

.testimonil-section-06 {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}
.testimonil-section-06__circle-1 {
  position: absolute;
  top: 5%;
  left: 3%;
  width: 10px;
  height: 10px;
  background: #FFC83E;
  border-radius: 500px;
}
.testimonil-section-06__circle-2 {
  position: absolute;
  top: 5%;
  right: 3%;
  width: 10px;
  height: 10px;
  background: #FD4C5C;
  border-radius: 500px;
}
@media (min-width: 768px) {
  .testimonil-section-06 {
    padding-top: 75px;
    padding-bottom: 78px;
  }
}
@media (min-width: 992px) {
  .testimonil-section-06 {
    padding-top: 95px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-06 {
    padding-top: 125px;
    padding-bottom: 130px;
  }
}
.testimonil-section-06__title {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .testimonil-section-06__title {
    margin-bottom: 60px;
  }
}
.testimonil-section-06 .slick-slide {
  margin: 0 12px;
}

.pricing-section-04 {
  padding-top: 55px;
  padding-bottom: 80px;
  position: relative;
  background: rgba(0, 28, 128, 0.02);
}
@media (min-width: 768px) {
  .pricing-section-04 {
    padding-top: 75px;
    padding-bottom: 110px;
  }
}
@media (min-width: 992px) {
  .pricing-section-04 {
    padding-top: 95px;
    padding-bottom: 136px;
  }
}
@media (min-width: 1200px) {
  .pricing-section-04 {
    padding-top: 125px;
    padding-bottom: 161px;
  }
}
.pricing-section-04__shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.pricing-section-04__circle-1 {
  position: absolute;
  top: 8%;
  left: 3%;
  width: 20px;
  height: 20px;
  background: #FFC83E;
  border-radius: 500px;
}
.pricing-section-04__circle-2 {
  position: absolute;
  top: 22%;
  right: 17%;
  width: 20px;
  height: 20px;
  border: 3px solid #00BB98;
  border-radius: 500px;
}
.pricing-section-04__circle-3 {
  position: absolute;
  top: 42%;
  right: 4%;
  width: 20px;
  height: 20px;
  background: #FD4C5C;
  border-radius: 500px;
}
.pricing-section-04__circle-4 {
  position: absolute;
  bottom: 7%;
  left: 27%;
  width: 20px;
  height: 20px;
  border: 3px solid #FFC83E;
  border-radius: 500px;
}
.pricing-section-04__circle-5 {
  position: absolute;
  top: 54%;
  left: 1%;
  width: 10px;
  height: 10px;
  background: #FD4C5C;
  border-radius: 500px;
}
.pricing-section-04__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .pricing-section-04__title {
    margin-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .pricing-section-04__price-card {
    margin: 0 54px;
  }
}
.pricing-section-04__price-card .card-pricing--04 {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .pricing-section-04__price-card .card-pricing--04 {
    margin-bottom: 0px;
  }
}
.pricing-section-04__price-card .col-lg-4:first-child .card-pricing--04__list li:last-child {
  display: none;
}
.pricing-section-04__price-card .col-lg-4:last-child .card-pricing--04__list li:last-child {
  display: none;
}

/* Header Css Start */
/* Hero Css Start */
/* Content Css Start */
/* Content Css Start */
/* About Css Start */
/* Fact Css Start */
/* Cta Css Start */
/* Testimonial Css Start */
/* Pricing Css Start */
/* Landing One Header Button */
.site-header__btns--08 .btn {
  height: 45px;
}
.site-header__btns--08 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--08 .btn-1 {
    margin-right: 20px;
    min-width: 80px;
    color: #fff;
  }
}
.site-header__btns--08 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #FD4C5C;
}
@media (min-width: 992px) {
  .site-header__btns--08 .btn-2 {
    min-width: 120px;
  }
}
@media (max-width: 992px) {
  .site-header__btns--08 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-08 {
  padding-top: 100px;
  background: #2B59FF;
  z-index: 1;
}
@media (min-width: 576px) {
  .hero-section-08 {
    padding-top: 120px;
  }
}
@media (min-width: 992px) {
  .hero-section-08 {
    padding-top: 0;
  }
}
.hero-section-08__content--button-group {
  margin-top: 30px;
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-left: -12px;
  margin-right: -12px;
}
@media (min-width: 424px) {
  .hero-section-08__content--button-group {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 43px;
  }
}
@media (min-width: 992px) {
  .hero-section-08__content--button-group {
    max-width: 100%;
  }
}
.hero-section-08__content--button-group a {
  margin-left: 12px;
  margin-right: 12px;
}
.hero-section-08__image-group {
  position: relative;
  z-index: 11;
}
@media (min-width: 992px) {
  .hero-section-08__image-group {
    margin-top: -20px;
    margin-right: -48%;
    margin-left: -45%;
  }
}
.hero-section-08__image-group--shape {
  position: absolute;
  top: 27%;
  left: 23%;
  width: 31.1%;
  z-index: -1;
}
.hero-section-08__image-group--chat-1 {
  position: absolute;
  top: 34.8%;
  right: 7%;
  width: 25.1%;
}
.hero-section-08__image-group--chat-2 {
  position: absolute;
  top: 60%;
  right: 22%;
  width: 8%;
}

.about-section-05 {
  position: relative;
  padding: 60px 0;
  background: rgba(43, 89, 255, 0.02);
}
@media (min-width: 768px) {
  .about-section-05 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .about-section-05 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .about-section-05 {
    padding: 130px 0;
  }
}
@media (min-width: 992px) {
  .about-section-05__image-group {
    margin-left: -70%;
  }
}
@media (min-width: 1200px) {
  .about-section-05__image-group {
    margin-left: -106%;
    margin-right: -23%;
  }
}
.about-section-05__content .btn {
  margin-top: 30px;
  min-width: 150px;
}
@media (min-width: 768px) {
  .about-section-05__content .btn {
    margin-top: 40px;
  }
}
.about-section-05__shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 12.9%;
}

.feature-section-06 {
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;
}
.feature-section-06__shape {
  position: absolute;
  bottom: -11%;
  left: 0;
  width: 5%;
  z-index: 11;
}
@media (min-width: 768px) {
  .feature-section-06 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .feature-section-06 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.feature-section-06__widget-card {
  padding: 0 25px;
}
@media (min-width: 992px) {
  .feature-section-06__widget-card {
    padding: 0 30px;
  }
}
.feature-section-06__widget-card .widget-icon {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 500px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-section-06__widget-card .widget-text p {
  line-height: 26px;
}
.feature-section-06 .col-lg-4:nth-child(1) .feature-section-06__widget-card .widget-icon {
  background: rgba(43, 89, 255, 0.3);
}
.feature-section-06 .col-lg-4:nth-child(2) .feature-section-06__widget-card .widget-icon {
  background: rgba(253, 76, 92, 0.3);
}
.feature-section-06 .col-lg-4:nth-child(3) .feature-section-06__widget-card .widget-icon {
  background: rgba(0, 187, 152, 0.3);
}

.content-section-17 {
  padding-top: 80px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .content-section-17 {
    padding-top: 102px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .content-section-17 {
    padding-top: 95px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .content-section-17 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
.content-section-17__content {
  margin-bottom: 30px;
}
.content-section-17__widget .col-xl-12:nth-child(1) .widget-content {
  margin-bottom: 30px;
}
.content-section-17__widget .col-xl-12:nth-child(1) .widget-content .widget-icon {
  background: rgba(253, 76, 92, 0.1);
}
.content-section-17__widget .col-xl-12:nth-child(2) .widget-content .widget-icon {
  background: rgba(0, 187, 152, 0.1);
}
.content-section-17__widget .widget-content .widget-icon {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 500px;
}
.content-section-17__button {
  margin-top: 40px;
}
.content-section-17__button .btn {
  min-width: 150px;
}
.content-section-17__image-group {
  position: relative;
}
.content-section-17__image-group img {
  border-radius: 10px;
}
.content-section-17__image-group--shape-1 {
  position: absolute;
  bottom: -12%;
  left: -9%;
  width: 27.9%;
}
.content-section-17__image-group--shape-2 {
  position: absolute;
  top: -14.6%;
  right: -9%;
  width: 35.3%;
  z-index: -1;
}
.content-section-17__image-group::before {
  position: absolute;
  content: "";
  top: -20px;
  right: -20px;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 62, 0.5);
  border-radius: 10px;
  z-index: -2;
}
@media (min-width: 992px) {
  .content-section-17__image-group::before {
    top: -35px;
    right: -35px;
  }
}

.content-section-18 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .content-section-18 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-18 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .content-section-18 {
    padding: 130px 0 130px;
  }
}
.content-section-18__image-group {
  position: relative;
}
.content-section-18__image-group img {
  border-radius: 10px;
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
}
.content-section-18__image-group--card {
  position: absolute;
  bottom: 0%;
  right: 13%;
  width: 38.6%;
  z-index: 11;
}
.content-section-18__image-group--card img {
  filter: drop-shadow(0px 32px 54px rgba(65, 74, 85, 0.22));
}
@media (min-width: 768px) {
  .content-section-18__image-group--card {
    transform: translateY(-50%);
    top: 50%;
  }
}
@media (min-width: 992px) {
  .content-section-18__image-group--card {
    right: 0%;
  }
}
@media (min-width: 1200px) {
  .content-section-18__image-group--card {
    right: 13%;
  }
}
.content-section-18__image-group--shape {
  bottom: -24%;
  right: 18%;
  position: absolute;
  width: 28.4%;
}
@media (min-width: 768px) {
  .content-section-18__image-group--shape {
    bottom: -4%;
  }
}
@media (min-width: 992px) {
  .content-section-18__image-group--shape {
    right: 0%;
  }
}
@media (min-width: 1200px) {
  .content-section-18__image-group--shape {
    right: 18%;
  }
}
.content-section-18__image-group--dotst {
  top: -17%;
  left: -9%;
  position: absolute;
  width: 35.3%;
  z-index: -1;
}
.content-section-18__content--list {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  list-style: none;
}
.content-section-18__content--list li {
  display: flex;
  align-items: center;
  color: #000B33;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
}
.content-section-18__content--list li:last-child {
  margin-bottom: 0;
}
.content-section-18__content--list li img {
  margin-right: 10px;
}

.service-section-02 {
  padding: 55px 0 60px;
  background: rgba(43, 89, 255, 0.02);
  position: relative;
}
@media (min-width: 768px) {
  .service-section-02 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .service-section-02 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .service-section-02 {
    padding: 130px 0;
  }
}
.service-section-02 .section-title {
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .service-section-02 .section-title {
    margin-bottom: 60px;
  }
}
.service-section-02__card {
  padding: 50px 45px;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #E8ECFB;
  border-radius: 10px;
  transition: 0.3s;
}
.service-section-02__card:hover {
  border-color: transparent;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}
@media (min-width: 576px) {
  .service-section-02__card {
    padding: 35px 30px;
  }
}
@media (min-width: 1200px) {
  .service-section-02__card {
    padding: 50px 64px;
  }
}
.service-section-02__card .widget-icon {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .service-section-02__card .widget-icon {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  }
}
.service-section-02__card .widget-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.4444444444;
}
@media (min-width: 576px) {
  .service-section-02__card .widget-title {
    font-size: 20px;
  }
}
.service-section-02__card .widget-text {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}
.service-section-02 .col-lg-4:nth-child(1) .service-section-02__card .widget-icon {
  background: rgba(253, 76, 92, 0.1);
}
.service-section-02 .col-lg-4:nth-child(2) .service-section-02__card .widget-icon {
  background: rgba(0, 187, 152, 0.1);
}
.service-section-02 .col-lg-4:nth-child(3) .service-section-02__card .widget-icon {
  background: rgba(55, 124, 253, 0.1);
}
.service-section-02 .col-lg-4:nth-child(4) .service-section-02__card .widget-icon {
  background: rgba(120, 27, 255, 0.1);
}
.service-section-02 .col-lg-4:nth-child(5) .service-section-02__card .widget-icon {
  background: rgba(255, 200, 62, 0.1);
}
.service-section-02 .col-lg-4:nth-child(6) .service-section-02__card .widget-icon {
  background: rgba(0, 28, 128, 0.1);
}

.cta-section-08 {
  background-color: white;
}
.cta-section-08__wrapper {
  overflow: hidden;
  position: relative;
  padding: 70px 0;
  border-radius: 30px;
  z-index: 1;
}
@media (min-width: 768px) {
  .cta-section-08__wrapper {
    background: #2B59FF;
  }
}
.cta-section-08__wrapper--shape-1 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-08__wrapper--shape-1 {
    display: block;
    position: absolute;
    top: -9%;
    right: -3%;
  }
}
.cta-section-08__wrapper--shape-3 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-08__wrapper--shape-3 {
    display: block;
    position: absolute;
    top: 23%;
    left: 0;
    width: 3%;
    z-index: -1;
  }
  .cta-section-08__wrapper--shape-3 svg {
    width: 100%;
  }
}
.cta-section-08__wrapper--shape-4 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-08__wrapper--shape-4 {
    display: block;
    position: absolute;
    bottom: 7%;
    left: 32%;
    width: 2%;
    z-index: -1;
  }
  .cta-section-08__wrapper--shape-4 svg {
    width: 100%;
  }
}
.cta-section-08__wrapper--shape-5 {
  display: none;
}
@media (min-width: 768px) {
  .cta-section-08__wrapper--shape-5 {
    display: block;
    position: absolute;
    top: 0;
    left: 54%;
    width: 18%;
    z-index: -1;
  }
  .cta-section-08__wrapper--shape-5 svg {
    width: 100%;
  }
}
.cta-section-08__contennt {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .cta-section-08__contennt {
    margin-bottom: 0;
  }
}
.cta-section-08__button-group {
  text-align: end;
  height: 100%;
  display: flex;
  align-items: flex-end;
  max-width: 300px;
}
@media (min-width: 576px) {
  .cta-section-08__button-group {
    max-width: 380px;
  }
}
@media (min-width: 1200px) {
  .cta-section-08__button-group {
    justify-content: flex-end;
    max-width: 100%;
  }
}
.cta-section-08__button-group a {
  margin-right: 20px;
}
.cta-section-08__button-group a:last-child {
  margin-right: 0px;
}

.cta-bg-group-5 {
  background: #2B59FF;
  position: relative;
}
@media (min-width: 768px) {
  .cta-bg-group-5 {
    background-color: inherit;
  }
}

.team-section-02 {
  padding: 55px 0 60px;
  background: rgba(43, 89, 255, 0.02);
}
@media (min-width: 768px) {
  .team-section-02 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .team-section-02 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .team-section-02 {
    padding: 130px 0;
  }
}
.team-section-02__button {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 15px;
}
@media (min-width: 480px) {
  .team-section-02__button {
    margin-top: 0;
    justify-content: flex-end;
  }
}
.team-section-02__button a {
  align-items: center;
  font-weight: 600;
  line-height: 1.3;
  font-size: 18px;
  display: flex;
  color: #FD4C5C;
}
@media (min-width: 576px) {
  .team-section-02__button a {
    font-size: 20px;
  }
}
.team-section-02__button a::after {
  display: inline-block;
  padding-left: 8px;
  content: "➞";
  transition: transform 0.3s ease-out;
}
.team-section-02__button a:hover::after {
  transform: translateX(4px);
}
.team-section-02__card {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .team-section-02__card {
    margin-top: 60px;
  }
}
.team-section-02__card .slick-slide {
  margin: 0 12px;
}

.counter-section-08 {
  background: #FFFFFF;
  padding: 60px 0;
}
@media (min-width: 992px) {
  .counter-section-08 {
    padding: 0;
    padding-bottom: 62px;
    background-color: inherit;
  }
}
@media (min-width: 768px) {
  .counter-section-08__wrapper .widget-counter {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .counter-section-08__wrapper {
    margin-top: -117px;
    z-index: 11;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    padding: 60px 0;
  }
  .counter-section-08__wrapper .col-xl-3 {
    position: relative;
  }
  .counter-section-08__wrapper .col-xl-3::before {
    content: "";
    position: absolute;
    height: 60px;
    width: 1px;
    background-color: rgba(43, 89, 255, 0.1);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .counter-section-08__wrapper .col-xl-3:last-child::before {
    display: none;
  }
}

.testimonil-section-07 {
  padding: 55px 0 60px;
  position: relative;
}
@media (min-width: 768px) {
  .testimonil-section-07 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .testimonil-section-07 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-07 {
    padding: 125px 0 130px;
  }
}
.testimonil-section-07__shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 12.9%;
}
.testimonil-section-07__dots {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 7%;
}
.testimonil-section-07 .content-title {
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .testimonil-section-07 .content-title {
    margin-bottom: 60px;
  }
}
.testimonil-section-07__content {
  position: relative;
  text-align: center;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
}
@media (min-width: 480px) {
  .testimonil-section-07__content {
    padding: 40px 60px;
  }
}
@media (min-width: 1200px) {
  .testimonil-section-07__content {
    padding: 60px 83px;
  }
}
.testimonil-section-07__content .text {
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 42px;
}
.testimonil-section-07__content .client-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 374px) {
  .testimonil-section-07__content .client-info {
    flex-direction: row;
  }
}
.testimonil-section-07__content .client-info--img img {
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  object-fit: cover;
  margin-bottom: 24.29px;
  border-radius: 500px;
}
@media (min-width: 374px) {
  .testimonil-section-07__content .client-info--img img {
    margin-right: 24.29px;
    margin-bottom: 0px;
  }
}
.testimonil-section-07__content .client-info--texts {
  text-align: start;
}
.testimonil-section-07__content .client-info--texts .name {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.testimonil-section-07__content .client-info--texts .profession {
  font-size: 16px;
  line-height: 26px;
}
.testimonil-section-07__content .quote {
  position: absolute;
  right: 20px;
  bottom: 40px;
}
.testimonil-section-07__slider {
  position: relative;
}
.testimonil-section-07__slider .testimonial-slider--06 .slick-list {
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}
.testimonil-section-07__slider .testimonial-slider--06__control-buttons {
  display: none;
}
@media (min-width: 768px) {
  .testimonil-section-07__slider .testimonial-slider--06__control-buttons {
    display: block;
  }
}
.testimonil-section-07__slider .testimonial-slider--06__control-buttons .button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: #FD4C5C;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.testimonil-section-07__slider .testimonial-slider--06__control-buttons .button svg {
  fill: white;
}
.testimonil-section-07__slider .testimonial-slider--06__control-buttons .button:hover {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.18);
}
.testimonil-section-07__slider .testimonial-slider--06__control-buttons .button:hover svg {
  fill: #FD4C5C;
}
.testimonil-section-07__slider .testimonial-slider--06__control-buttons .button--prev {
  transform: rotate(180deg);
  left: -70px;
  cursor: pointer;
}
@media (min-width: 992px) {
  .testimonil-section-07__slider .testimonial-slider--06__control-buttons .button--prev {
    left: -109px;
  }
}
.testimonil-section-07__slider .testimonial-slider--06__control-buttons .button--next {
  right: -70px;
  cursor: pointer;
}
@media (min-width: 992px) {
  .testimonil-section-07__slider .testimonial-slider--06__control-buttons .button--next {
    right: -109px;
  }
}

.brand-section-modifi--02 {
  background: #2B59FF;
}
@media (min-width: 992px) {
  .brand-section-modifi--02 {
    background: #2B59FF;
    padding-top: 80px !important;
    padding-bottom: 197px !important;
  }
}

/* Header Css Start */
/* Hero Css Start */
/* About Css Start */
/* Feature Css Start */
/* Content Css Start */
/* Content Css Start */
/* Service Css Start */
/* Cta Css Start */
/* Team Css Start */
/* Fact Css Start */
/* Testimonial Css Start */
/* Brand Css Start */
/* Landing One Header Button */
.site-header__btns--09 .btn {
  height: 45px;
}
.site-header__btns--09 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--09 .btn-1 {
    margin-right: 20px;
    min-width: 80px;
  }
}
.site-header__btns--09 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #FD4C5C;
}
@media (min-width: 992px) {
  .site-header__btns--09 .btn-2 {
    min-width: 120px;
  }
}
@media (max-width: 992px) {
  .site-header__btns--09 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-09 {
  background: rgba(0, 187, 152, 0.05);
  padding-top: 107px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 768px) {
  .hero-section-09 {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .hero-section-09 {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .hero-section-09 {
    padding-top: 204px;
    padding-bottom: 144px;
  }
}
.hero-section-09__bg-shpae {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}
.hero-section-09__bg-shpae img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (min-width: 992px) {
  .hero-section-09__content {
    margin-bottom: 40px;
  }
}
.hero-section-09__image-group {
  position: relative;
}
@media (min-width: 992px) {
  .hero-section-09__image-group {
    margin-right: -55%;
    margin-left: 63px;
  }
}
.hero-section-09__image-group--shape-1 {
  position: absolute;
  left: -10%;
  width: 21.5%;
  bottom: 11.5%;
  z-index: -1;
}
.hero-section-09__image-group--shape-2 {
  position: absolute;
  top: -13.9%;
  right: 9%;
  width: 18.5%;
  z-index: -1;
}
.hero-section-09__content .text {
  padding-top: 15px;
  font-weight: 600;
}
.hero-section-09__content--form {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .hero-section-09__content--form {
    margin-top: 40px;
  }
}
.hero-section-09__content--form .form-control {
  height: 66px;
  font-weight: 400;
}
@media (min-width: 576px) {
  .hero-section-09__content--form .btn {
    margin: 8px;
  }
}
.hero-section-09__shape {
  position: absolute;
  left: 0;
  top: 19%;
  width: 5.5%;
}

.cta-section-09 {
  padding: 55px 0 60px;
  z-index: 1;
  background: #2B59FF;
  position: relative;
  overflow: hidden;
}
.cta-section-09__bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 40%;
  z-index: -2;
}
.cta-section-09__bg-shape img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .cta-section-09 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .cta-section-09 {
    padding: 90px 0 100px;
  }
}
.cta-section-09__content {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .cta-section-09__content {
    margin-bottom: 40px;
  }
}
.cta-section-09__button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: -5px;
}
@media (min-width: 445px) {
  .cta-section-09__button-group {
    margin-right: -24px;
  }
}
.cta-section-09__button-group a {
  display: block;
  max-width: 142px;
  min-width: 142px;
  margin-top: 10px;
  margin-right: 5px;
}
@media (min-width: 420px) {
  .cta-section-09__button-group a {
    margin-right: 5px;
    margin-bottom: 0px;
  }
}
@media (min-width: 445px) {
  .cta-section-09__button-group a {
    margin-right: 24px;
    margin-bottom: 0px;
  }
}
@media (min-width: 480px) {
  .cta-section-09__button-group a {
    max-width: 150px;
    min-width: 150px;
  }
}
@media (min-width: 768px) {
  .cta-section-09__button-group a {
    max-width: unset;
    min-width: unset;
  }
}
@media (max-width: 768px) {
  .cta-section-09__button-group a img {
    width: 100%;
  }
}

.pricing-section-05 {
  padding: 60px 0;
  position: relative;
  background: rgba(43, 89, 255, 0.02);
}
.pricing-section-05__shape-1 {
  position: absolute;
  left: 0;
  top: -1px;
  width: 6.8%;
}
.pricing-section-05__shape-2 {
  position: absolute;
  right: 0;
  bottom: -1px;
  width: 6%;
}
@media (min-width: 768px) {
  .pricing-section-05 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .pricing-section-05 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .pricing-section-05 {
    padding: 130px 0;
  }
}
.pricing-section-05__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .pricing-section-05__title {
    margin-bottom: 60px;
  }
}
.pricing-section-05__price-card .card-pricing--04 {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .pricing-section-05__price-card .card-pricing--04 {
    margin-bottom: 0px;
  }
}
.pricing-section-05__price-card .col-lg-4:first-child .card-pricing--04__list li:last-child {
  display: none;
}
.pricing-section-05__price-card .col-lg-4:last-child .card-pricing--04__list li:last-child {
  display: none;
}

.about-section-06 {
  padding: 55px 0 60px;
  position: relative;
  z-index: 1;
}
.about-section-06__shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6%;
}
@media (min-width: 768px) {
  .about-section-06 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .about-section-06 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .about-section-06 {
    padding: 130px 0;
  }
}
.about-section-06__content .btn {
  min-width: 150px;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .about-section-06__content .btn {
    margin-top: 40px;
  }
}
.about-section-06__card-group .widget-card {
  padding: 40px 30px;
  text-align: start;
  background: #FFFFFF;
  border-radius: 10px;
  transition: 0.3s;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}
@media (min-width: 768px) {
  .about-section-06__card-group .widget-card {
    box-shadow: none;
  }
  .about-section-06__card-group .widget-card:hover {
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  }
}
.about-section-06__card-group .widget-card .widget-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .about-section-06__card-group .widget-card .widget-title {
    font-size: 20px;
  }
}
.about-section-06__card-group .widget-card .widget-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}

.content-section-19 {
  background: rgba(43, 89, 255, 0.02);
  padding: 65px 0 62px;
  position: relative;
}
.content-section-19__dots {
  position: absolute;
  right: 0;
  bottom: 8%;
  width: 5%;
}
@media (min-width: 768px) {
  .content-section-19 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-19 {
    padding-top: 100px;
    padding-bottom: 160px;
  }
}
@media (min-width: 1200px) {
  .content-section-19 {
    padding-top: 130px;
    padding-bottom: 187px;
  }
}
.content-section-19__image-group {
  margin-bottom: 60px;
  position: relative;
}
@media (min-width: 992px) {
  .content-section-19__image-group {
    margin-bottom: 0px;
  }
}
.content-section-19__image-group img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 20px;
}
.content-section-19__image-group--card {
  position: absolute;
  bottom: -13%;
  right: 0;
  width: 54.5%;
}
.content-section-19__image-group--card img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 20px;
}
.content-section-19__content--list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 200px));
  padding: 0px;
  margin: 0;
  margin-top: 30px;
  overflow: hidden;
  list-style: none;
  margin-bottom: -15px;
}
@media (min-width: 375px) {
  .content-section-19__content--list {
    grid-template-columns: repeat(2, minmax(175px, 200px));
  }
}
@media (min-width: 575px) {
  .content-section-19__content--list {
    grid-template-columns: repeat(2, minmax(220px, 200px));
  }
}
.content-section-19__content--list li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.content-section-19__content--list li svg {
  margin-right: 10px;
}

.content-section-20 {
  padding: 60px 0;
  background: rgba(43, 89, 255, 0.02);
}
@media (min-width: 768px) {
  .content-section-20 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-20 {
    padding-top: 98px;
    padding-bottom: 110px;
  }
}
@media (min-width: 1200px) {
  .content-section-20 {
    padding-top: 130px;
    padding-bottom: 145px;
  }
}
.content-section-20__image-group {
  margin-bottom: 35px;
  text-align: end;
  position: relative;
}
@media (min-width: 992px) {
  .content-section-20__image-group {
    margin-bottom: 0px;
  }
}
.content-section-20__image-group img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
.content-section-20__image-group--card {
  position: absolute;
  bottom: -3%;
  left: 0;
  width: 29.5%;
}
.content-section-20__image-group--card img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
.content-section-20__content .btn {
  min-width: 150px;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .content-section-20__content .btn {
    margin-top: 40px;
  }
}

.counter-section-09 {
  padding-top: 50px;
  position: relative;
  padding-bottom: 50px;
  background: rgba(0, 187, 152, 0.05);
  z-index: 1;
  overflow: hidden;
}
@media (min-width: 768px) {
  .counter-section-09 {
    padding-top: 78px;
    padding-bottom: 80px;
  }
}
.counter-section-09__bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.counter-section-09__bg-shape img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.counter-section-09 .widget-counter {
  flex-direction: column;
}
.counter-section-09 .widget-counter .widget-text {
  color: rgba(0, 11, 51, 0.8);
}

.testimonial-section-08 {
  padding: 60px 0;
  position: relative;
}
@media (min-width: 768px) {
  .testimonial-section-08 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .testimonial-section-08 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .testimonial-section-08 {
    padding: 130px 0;
  }
}
.testimonial-section-08__dots {
  position: absolute;
  left: 0;
  top: 8%;
  width: 5%;
}
.testimonial-section-08__title {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .testimonial-section-08__title {
    margin-bottom: 80px;
  }
}
.testimonial-section-08__slider .slick-slide {
  margin: 0 12px;
}
.testimonial-section-08 .card-testimonial-06__content p {
  font-size: 18px;
}

.content-section-21 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .content-section-21 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-21 {
    padding: 100px 0;
  }
}
@media (min-width: 992px) {
  .content-section-21 {
    padding: 130px 0;
  }
}
.content-section-21__image-group {
  position: relative;
}
.content-section-21__image-group img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
.content-section-21__image-group--card {
  position: absolute;
  top: 50%;
  right: 12%;
  transform: translateY(-50%);
  width: 28.2%;
}
.content-section-21__tab {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .content-section-21__tab {
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .content-section-21__tab {
    margin-right: 17%;
  }
}
.content-section-21__tab .nav-tabs {
  border: 0;
  margin-bottom: 20px;
  justify-content: center;
}
@media (min-width: 374px) {
  .content-section-21__tab .nav-tabs {
    border-bottom: 2px solid rgba(0, 11, 51, 0.2);
    justify-content: start;
  }
}
@media (min-width: 480px) {
  .content-section-21__tab .nav-tabs .nav-item {
    margin-right: 16%;
  }
  .content-section-21__tab .nav-tabs .nav-item:last-child {
    margin-right: 0;
  }
}
.content-section-21__tab .nav-tabs .nav-link {
  padding: 20px;
  border: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 11, 51, 0.8);
}
.content-section-21__tab .nav-tabs .nav-link.active {
  color: #FD4C5C;
  background: none;
  border-bottom: 2px solid #FD4C5C;
}
.content-section-21__tab .tab-pane__content .text {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
.content-section-21__tab .tab-pane__content .btn {
  margin-top: 30px;
  min-width: 150px;
}
@media (min-width: 768px) {
  .content-section-21__tab .tab-pane__content .btn {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .content-section-21__tab .tab-pane__content .btn {
    min-width: 174px;
  }
}

/* Header Css Start */
/* Hero Css Start */
/* Cta Css Start */
/* Pricing Css Start */
/* About Css Start */
/* Content Css Start */
/* Content Css Start */
/* Fact Css Start */
/* Testimonial Css Start */
/* Content Css Start */
/* Landing One Header Button */
.site-header__btns--10 .btn {
  height: 45px;
}
.site-header__btns--10 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--10 .btn-1 {
    margin-right: 20px;
    min-width: 80px;
    color: #fff;
  }
}
.site-header__btns--10 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #2B59FF;
}
@media (min-width: 992px) {
  .site-header__btns--10 .btn-2 {
    min-width: 120px;
  }
}
@media (max-width: 992px) {
  .site-header__btns--10 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.hero-section-10 {
  padding-top: 131px;
  margin-bottom: 80px;
  position: relative;
  background: rgba(0, 28, 128, 0.95);
  z-index: 1;
}
@media (min-width: 772px) {
  .hero-section-10 {
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .hero-section-10 {
    margin-bottom: 300px;
  }
}
@media (min-width: 1200px) {
  .hero-section-10 {
    margin-bottom: 325px;
  }
}
.hero-section-10__rectangle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.hero-section-10__rectangle img {
  width: 100%;
}
.hero-section-10__content {
  text-align: center;
}
.hero-section-10__content .text {
  margin-top: 20px;
}
.hero-section-10__content--btn-group {
  margin-top: 32px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.hero-section-10__content--btn-group .btn {
  min-width: 150px !important;
}
.hero-section-10__content--btn-group .btn-2 {
  margin-top: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.hero-section-10__content--btn-group .btn-2 img {
  margin-right: 10px;
}
.hero-section-10__image-group {
  margin-top: 35px;
  margin-bottom: -30px;
  z-index: 11;
}
@media (min-width: 992px) {
  .hero-section-10__image-group {
    margin-top: 49px;
    margin-bottom: -218px;
  }
}
.hero-section-10__shape-1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 6%;
}
.hero-section-10__shape-2 {
  position: absolute;
  left: 25%;
  top: 57%;
  width: 15.9%;
  z-index: -1;
}
.hero-section-10__shape-3 {
  position: absolute;
  top: 38%;
  right: 0;
  width: 5.7%;
}
.hero-section-10__shape-4 {
  position: absolute;
  bottom: -27%;
  right: 0;
  width: 3.4%;
}

.cta-section-10 {
  padding: 60px 0;
  z-index: 1;
  background: rgba(0, 28, 128, 0.95);
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .cta-section-10 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .cta-section-10 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.cta-section-10__button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.cta-section-10__button-group a {
  display: block;
  margin-right: 0px;
  margin-bottom: 20px;
}
@media (min-width: 420px) {
  .cta-section-10__button-group a {
    margin-right: 5px;
    margin-bottom: 0px;
  }
}
@media (min-width: 445px) {
  .cta-section-10__button-group a {
    margin-right: 24px;
    margin-bottom: 0px;
  }
}
.cta-section-10__button-group a:last-child {
  margin-right: 0;
  margin-bottom: 0px;
}
.cta-section-10__shape-1 {
  position: absolute;
  top: 8%;
  left: 8.5%;
  width: 7%;
}
.cta-section-10__shape-2 {
  position: absolute;
  top: 41%;
  right: -3%;
  width: 6.8%;
}
.cta-section-10__shape-3 {
  position: absolute;
  bottom: -22%;
  left: 33.5%;
  width: 9.3%;
}

.feature-section-07 {
  padding: 55px 0 60px;
  position: relative;
}
@media (min-width: 768px) {
  .feature-section-07 {
    padding: 75px 0 80px;
  }
}
@media (min-width: 992px) {
  .feature-section-07 {
    padding: 95px 0 100px;
  }
}
@media (min-width: 1200px) {
  .feature-section-07 {
    padding: 125px 0 130px;
  }
}
.feature-section-07 .section-title {
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .feature-section-07 .section-title {
    margin-bottom: 80px;
  }
}
.feature-section-07__card {
  padding: 0px 30px;
}
@media (min-width: 1200px) {
  .feature-section-07__card {
    padding: 0px 60px;
  }
}
.feature-section-07__card .widget-icon {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .feature-section-07__card .widget-icon {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 30px;
  }
}
.feature-section-07__card .widget-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.4444444444;
}
@media (min-width: 576px) {
  .feature-section-07__card .widget-title {
    font-size: 20px;
  }
}
.feature-section-07__card .widget-text {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
}
.feature-section-07 .col-lg-4:nth-child(1) .feature-section-07__card .widget-icon {
  background: #fd4c5c;
}
.feature-section-07 .col-lg-4:nth-child(2) .feature-section-07__card .widget-icon {
  background: #00bb98;
}
.feature-section-07 .col-lg-4:nth-child(3) .feature-section-07__card .widget-icon {
  background: #377cfd;
}
.feature-section-07 .col-lg-4:nth-child(4) .feature-section-07__card .widget-icon {
  background: #781bff;
}
.feature-section-07 .col-lg-4:nth-child(5) .feature-section-07__card .widget-icon {
  background: #ffc83e;
}
.feature-section-07 .col-lg-4:nth-child(6) .feature-section-07__card .widget-icon {
  background: #001c80;
}

.pricing-section-06 {
  padding: 60px 0;
  position: relative;
}
.pricing-section-06__shape {
  position: absolute;
  top: 15%;
  left: 0;
  width: 3.4%;
}
@media (min-width: 768px) {
  .pricing-section-06 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .pricing-section-06 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .pricing-section-06 {
    padding: 130px 0;
  }
}
.pricing-section-06__title {
  margin-bottom: 35px;
}
@media (min-width: 992px) {
  .pricing-section-06__title {
    margin-bottom: 80px;
  }
}
.pricing-section-06 .pricing-sider .slick-slide {
  margin: 0 12px;
}
.pricing-section-06 .pricing-sider .slick-slide:not(.slick-current) .card {
  box-shadow: none;
  transition: 0.4s;
}

.content-section-22 {
  padding: 60px 0;
  background: rgba(43, 89, 255, 0.02);
}
@media (min-width: 768px) {
  .content-section-22 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-22 {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .content-section-22 {
    padding: 130px 0;
  }
}
.content-section-22__image-group {
  position: relative;
}
.content-section-22__image-group img {
  border-radius: 10px;
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
}
.content-section-22__image-group--shape {
  bottom: -19%;
  right: 25%;
  position: absolute;
  width: 92.7%;
  z-index: -1;
}
.content-section-22__content--list {
  margin: 0;
  padding: 0;
  margin-top: 30px;
  list-style: none;
}
.content-section-22__content--list li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.content-section-22__content--list li:last-child {
  margin-bottom: 0;
}
.content-section-22__content--list li .icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2B59FF;
  border-radius: 500px;
}
.content-section-22__content--list li span {
  margin-left: 10px;
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000B33;
}
.content-section-22__content .btn {
  margin-top: 30px;
  min-width: 150px;
}
@media (min-width: 576px) {
  .content-section-22__content .btn {
    margin-top: 40px;
  }
}

.content-section-23 {
  padding: 60px 0;
  background: rgba(43, 89, 255, 0.02);
}
@media (min-width: 768px) {
  .content-section-23 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-23 {
    padding: 95px 0;
  }
}
@media (min-width: 1200px) {
  .content-section-23 {
    padding: 120px 0;
  }
}
.content-section-23__widget .widget-content {
  margin-top: 30px;
}
.content-section-23__widget .widget-content .widget-icon {
  margin-right: 20px;
}
.content-section-23__image-group {
  position: relative;
}
.content-section-23__image-group img {
  filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
}
@media (min-width: 1200px) {
  .content-section-23__image-group {
    margin-left: 24px;
  }
}
.content-section-23__image-group--shape {
  position: absolute;
  bottom: 3%;
  right: -25%;
  width: 83.4%;
  z-index: -1;
}
.content-section-23__image-group--m-1 {
  position: absolute;
  top: 9%;
  right: -7%;
  width: 50.4%;
}
.content-section-23__image-group--m-2 {
  position: absolute;
  bottom: 12%;
  left: -6%;
  width: 50.4%;
}

.counter-section-10 {
  padding-bottom: 45px;
}
@media (min-width: 768px) {
  .counter-section-10 {
    padding-bottom: 63px;
  }
}
.counter-section-10__widget {
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter-section-10__widget .widget-counter {
  position: relative;
  padding-right: 30px;
  padding-left: 30px;
}
.counter-section-10__widget .widget-counter:first-child {
  padding-left: 0;
}
.counter-section-10__widget .widget-counter:first-child .widget-title::before {
  background: rgba(0, 187, 152, 0.1);
}
.counter-section-10__widget .widget-counter:last-child {
  padding-right: 0;
}
.counter-section-10__widget .widget-counter:last-child::before {
  display: none;
}
.counter-section-10__widget .widget-counter::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 50px;
  background-color: rgba(43, 89, 255, 0.2);
}
@media (min-width: 768px) {
  .counter-section-10__widget .widget-counter {
    flex-direction: column;
  }
}
.counter-section-10__widget .widget-counter .widget-title {
  position: relative;
}
.counter-section-10__widget .widget-counter .widget-title::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 50px;
  height: 50px;
  border-radius: 500px;
  background: rgba(253, 76, 92, 0.1);
  z-index: -1;
}
.counter-section-10__content .title span {
  color: #2B59FF;
}

.brand-section-modifi--04 {
  padding-top: 0;
  padding-bottom: 45px;
}
@media (min-width: 992px) {
  .brand-section-modifi--04 {
    padding-bottom: 80px;
  }
}

.testimonial-section-09 {
  padding: 60px 0;
  background: rgba(43, 89, 255, 0.02);
}
@media (min-width: 768px) {
  .testimonial-section-09 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .testimonial-section-09 {
    padding-top: 83px;
    padding-bottom: 75px;
  }
}
@media (min-width: 1200px) {
  .testimonial-section-09 {
    padding-top: 126px;
    padding-bottom: 110px;
  }
}
.testimonial-section-09__title {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .testimonial-section-09__title {
    margin-bottom: 60px;
  }
}
.testimonial-section-09__slider {
  /* the slides */
  /* the parent */
}
.testimonial-section-09__slider .slick-slide {
  margin: 0 12px;
}
.testimonial-section-09__slider .slick-list {
  margin: 0 -12px;
}
.testimonial-section-09__slider.mobile-slider .slick-dots li button {
  width: 17px;
  height: 8px;
  border-radius: 4px;
}
.testimonial-section-09__slider.mobile-slider .slick-dots li.slick-active {
  width: 45px;
  height: 8px;
  border-radius: 4px;
}

.testimonial-section-09--02 .card-testimonial-05__body--ratting {
  margin-left: 37%;
  margin-top: 10px;
}
@media (min-width: 480px) {
  .testimonial-section-09--02 .card-testimonial-05__body--ratting {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .testimonial-section-09--02 .card-testimonial-05__body--ratting {
    margin-left: 37%;
    margin-top: 10px;
  }
}
@media (min-width: 992px) {
  .testimonial-section-09--02 .card-testimonial-05__body--ratting {
    margin: 0;
  }
}

.brand-section-modifi--03 {
  background: rgba(55, 124, 253, 0.02);
}

/*
    Note: This section is similar to landing 6 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.about-section-03 {
  padding: 60px 0;

  @media (min-width: 768px) {
    padding: 80px 0;
  }
  @media (min-width: 992px) {
    padding: 130px 0;
  }
  &__widget {
    .widget-content {
      @media (min-width: 992px) {
        width: 440px;
      }
      padding: 30px;
      background: #ffffff;
      box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
      border-radius: 10px;
      margin-bottom: 30px;

      &:nth-child(1) {
        @media (min-width: 1200px) {
          margin-left: 50px;
        }
        .widget-icon {
          background: rgb(55, 124, 253, 0.1);
        }
      }

      &:nth-child(2) {
        .widget-icon {
          background: rgb(253, 76, 92, 0.1);
        }
      }

      &:nth-child(3) {
        @media (min-width: 1200px) {
          margin-left: 50px;
        }
        .widget-icon {
          background: rgb(0, 187, 152, 0.1);
        }
        margin-bottom: 0;
      }

      .widget-icon {
        margin-right: 20px !important;
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        display: flex;
        border-radius: 500px;
        align-items: center;
        justify-content: center;
      }

      .widget-title {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 10px;

        @media (min-width: 576px) {
          font-size: 20px;
        }
      }

      .widget-text {
        color: rgb(0, 11, 51, 0.8);
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  &__content {
    .btn {
      min-width: 150px;
      margin-top: 30px;
      @media (min-width: 576px) {
        margin-top: 40px;
      }
    }
  }
}*/
/*duplicated code ends*/
.about-section-03--2 {
  background: rgba(55, 124, 253, 0.02);
  position: relative;
}
.about-section-03--2__shape {
  position: absolute;
  left: 0%;
  width: 5%;
  bottom: 26%;
}

/*
    Note: This section is similar to landing 6 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.counter-section-06 {
    padding: 50px 0;
    background: rgb(55, 124, 253,0.02);
    @media (min-width:768px) {
        padding: 70px 0;
    }

    @media (min-width:992px) {
        padding: 100px 0;
    }

    .widget-counter {
        justify-content: start;
        padding: 20px 40px;
        border-radius: 10px;
        .widget-title{
            @media (min-width:768px) {
                margin-right: 20px;
            }
            margin-bottom: 0;
        }
        .widget-text{
            color: #000B33;
        }
    }
    .col-xl-3 {
        &:nth-child(1){
            .widget-counter {
                background: rgb(0, 187, 152,0.1);
            }
        } 
        &:nth-child(2){
            .widget-counter {
                background: rgb(120, 27, 255,0.1);
            }
        } 
        &:nth-child(3){
            .widget-counter {
                background: rgb(253, 76, 92,0.1);
            }
        } 
        &:nth-child(4){
            .widget-counter {
                background: rgb(255, 200, 62,0.1);
            }
        } 
    }
}
*/
/*duplicated code ends*/
/*
    Note: This section is similar to landing 9 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.content-section-19{
    background: rgb(43, 89, 255,0.02);
    padding: 60px 0;
    position: relative;
    &__dots{
        position: absolute;
        right: 0;
        bottom: 8%;
        width: 5%;
    }
    @media (min-width:768px) {
        padding: 80px 0;

    }
    @media (min-width:992px) {
        padding-top: 130px;
        padding-bottom: 187px;
    }
    &__image-group{
        margin-bottom: 60px;
        @media (min-width:992px) {
            margin-bottom: 0px;

        }
        position: relative;
        img{
        filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
        border-radius: 20px;
        }
        &--card{
            position: absolute;
            bottom: -13%;
            right: 0;
            width: 54.5%;
            img{
            filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
            border-radius: 20px;
            }
        }
    }
    &__content{
        &--list{
            display: grid;
            grid-template-columns: repeat(2, minmax(0px, 200px));
            @media (min-width:375px) {
                grid-template-columns: repeat(2, minmax(175px, 200px));
            }
            @media (min-width:575px) {
                grid-template-columns: repeat(2, minmax(220px, 200px));
            }
            padding: 0px;
            margin: 0;
            margin-top: 30px;
            overflow: hidden;
            list-style: none;
            margin-bottom: -15px;
            li{
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                svg{
                    margin-right: 10px;
                }
            }
        }
    }
}
*/
/*duplicated code ends*/
.content-section-19--2 {
  background: rgba(55, 124, 253, 0.02);
}

/*
    Note: This section is similar to landing 1 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*duplicated code ends*/
.team-section-01--2 {
  position: relative;
}
.team-section-01--2__shape {
  position: absolute;
  right: 0%;
  width: 5%;
  top: 5%;
  transform: rotate(180deg);
}

/*
    Note: This section is similar to landing 1 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.video-section-01 {
    background: rgb(43, 89, 255, 0.02);
    padding: 60px 0;

    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 170px;
        padding-bottom: 130px;
    }

    .block-title {
        p {
            @media(min-width:992px) {
                padding-right: 11%;
            }

            @media(min-width:1200px) {
                padding-right: 11%;
            }

            @media(min-width:1400px) {
                padding-right: 23%;
            }
        }

        .btn {
            margin-top: 40px;
        }
    }

    .video-block {
        position: relative;
        border-radius: 10px;
        .video-button-block {
            @include utils.absolute(0, 0, 100%, 100%);
            @include utils.flexAllCenter;
            background: rgba(color.$stratos, .5);
            flex-direction: column
        }
    }
}
*/
.video-btn-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
  border-radius: 500px;
}
@media (min-width: 576px) {
  .video-btn-1 {
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    max-height: 90px;
    border-radius: 500px;
  }
}
.video-btn-1 i {
  font-size: 18px;
  transform: translateX(2px);
}

/*duplicated code ends*/
.video-section-01--2 .video-block {
  overflow: inherit;
}
.video-section-01--2 .video-block .video-button-block {
  background: rgba(0, 11, 51, 0.1);
}
.video-section-01--2 .video-block .dots {
  position: absolute;
  top: -6.5%;
  right: -3.5%;
  width: 32.2%;
  z-index: -1;
}

/* Brand Css Start */
/* About Css Start */
/* Fact Css Start */
/* Content Css Start */
/* Team Css Start */
/* video Css Start */
/*
    Note: This section is similar to landing 6 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.service-section-01 {
    padding: 60px 0;

    @media (min-width:768px) {
        padding: 80px 0;

    }

    @media (min-width:992px) {

        padding: 130px 0;
    }

    position: relative;

    .section-title {
        margin-bottom: 35px;

        @media (min-width:576px) {
            margin-bottom: 80px;
        }
    }

    &__shape {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 22.9%;
    }

    &__card {
        padding: 50px 45px;
        text-align: center;
        background: #FFFFFF;
        border: 0.25px solid #000B33;
        box-sizing: border-box;
        border-radius: 10px;
        transition: .3s;

        @media (min-width:576px) {
            padding: 35px 30px;

        }

        @media (min-width:1200px) {
            padding: 50px 64px;
        }

        &:hover {
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
            border: 0.25px solid transparent;
        }

        .widget-icon {
            background: rgb(55, 124, 253, 0.1);
            min-width: 70px;
            min-height: 70px;
            max-width: 70px;
            max-height: 70px;
            border-radius: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;

            @media (min-width:768px) {
                min-width: 80px;
                min-height: 80px;
                max-width: 80px;
                max-height: 80px;
            }
        }

        .widget-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 1.444444444444444;

            @media (min-width:576px) {
                font-size: 20px;
            }
        }

        .widget-text {
            font-size: 16px;
            line-height: 26px;
            color: rgb(0, 11, 51, 0.8);
        }
    }
}
*/
/*duplicated code ends*/
.service-section-01--2 {
  position: relative;
  background: rgba(55, 124, 253, 0.02);
}
.service-section-01--2__shape-1 {
  position: absolute;
  left: 0%;
  width: 5%;
  bottom: 6%;
}
.service-section-01--2__shape-2 {
  position: absolute;
  right: 0%;
  width: 3%;
  top: 8%;
}
.service-section-01--2__card {
  border: 0;
}
.service-section-01--2__card:hover {
  border: 0;
  box-shadow: -6px 6px 60px rgba(0, 0, 0, 0.08);
}
.service-section-01--2 .col-lg-4:nth-child(1) .service-section-01--2__card .widget-icon {
  background: rgba(253, 76, 92, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(2) .service-section-01--2__card .widget-icon {
  background: rgba(0, 187, 152, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(3) .service-section-01--2__card .widget-icon {
  background: rgba(55, 124, 253, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(4) .service-section-01--2__card .widget-icon {
  background: rgba(255, 200, 62, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(5) .service-section-01--2__card .widget-icon {
  background: rgba(120, 27, 255, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(6) .service-section-01--2__card .widget-icon {
  background: rgba(0, 28, 128, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(7) .service-section-01--2__card .widget-icon {
  background: rgba(0, 187, 152, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(8) .service-section-01--2__card .widget-icon {
  background: rgba(253, 76, 92, 0.1);
}
.service-section-01--2 .col-lg-4:nth-child(9) .service-section-01--2__card .widget-icon {
  background: rgba(255, 200, 62, 0.1);
}

/*
    Note: This section is similar to landing 4 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*.content-section-08{
    padding-top: 38px;
    padding-bottom: 80px;
    position: relative;
    z-index: 11;
    @media (min-width:768px) {
        padding-top: 57px;
        padding-bottom: 100px; 
    }
    @media (min-width:992px) {
        padding-top: 105px;
        padding-bottom: 159px;
    }
    &__shape-1{
    position: absolute;
    bottom: -40px;
    right: 6.4%;
    width: 6.7%;
    z-index: -1;
        img,svg{
            width: 100%;
        }
    }
    &__shape-2{
        position: absolute;
        top: 10%;
        left: 0;
        width: 5%; 
        z-index: -1;  
        img,svg{
            width: 100%;
        }
    }
    &__image-group{
        margin-bottom: 30px;
        position: relative;
        @media (min-width:576px) {
            margin-bottom: 50px;

        }
        @media (min-width:992px) {
            margin-left: -41px;
            margin-right: 73px;
            margin-bottom: 0px;
        }
        @media (min-width:1200px) {
            margin-left: -41px;
            margin-right: 73px;
        }
        &--card{
            position: absolute;
            right: -11.3%;
            bottom: 13%;
            width: 31.7%;
            img{
                width: 100%;
                filter: drop-shadow(0px 32px 54px rgba(65, 74, 85, 0.22));
            }
        }
        &--shape{
            position: absolute;
            bottom: -14.7%;
            left: 9.6%;
            width: 13.1%;
            z-index: -1;      
            svg{
                width: 100%;
            }
        }
    }
    &__content{
        @media (min-width:1200px) {
            padding-left: 70px;
        }
        .btn{
            margin-top: 20px;
            min-width: 150px;
            min-width: 174px;
            border-radius: 30px;
            @media (min-width:1200px) {
                min-width: 174px;
                margin-top: 40px;
            }
        }
    }

    // .nav-tab--content-2 {
    //     text-align: center;
    //     margin: 0 auto;
    //     display: flex;
    //     flex-wrap: nowrap;
    //     align-items: center;
    //     justify-content: space-around;
    //     transition: 0.4s;
    //     border-bottom: 2px solid rgb(255, 255, 255,0.2);
    //     margin-bottom: 60px;
    //     margin-left: -10px;
    //     @media (min-width:992px) {
    //         margin-bottom: 91px;
    //         justify-content: space-between;
    //     }

    //     .nav-link {
    //         border: none;
    //         font-size: 17px;
    //         font-weight: 700;
    //         letter-spacing: normal;
    //         line-height: 1.3;
    //         padding: 15px 0;
    //         display: inline-flex;
    //         color: #FFFFFF;
    //         opacity: 0.7;
    //         transition: 0.4s;
    //         margin-right: 10px;
    //         border-bottom: 2px solid transparent;
    //         justify-content: space-around;
    //         @media (min-width:480px) {
    //             padding: 18px 0;
    //             font-size: 18px;
    //         }
    //         @media (min-width:992px) {
    //             font-size: 20px;
    //         }

    //         &.active {
    //             background-color: transparent;
    //             color: #FFC83E;
    //             opacity: 1;
    //             border-bottom: 2px solid #FFC83E;
    //         }
    //         &:last-child{
    //           margin-right: 0;
    //         }
    //     }
    // }
}*/
/*duplicated code ends*/
.content-section-08--2 {
  background: #001C80 !important;
}

/*
    Note: This section is similar to landing 4 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.pricing-section-02{
  background: rgb(43, 89, 255,0.02);
  padding: 56px 0;
  @media (min-width:768px) {
    padding: 76px 0;
  }
  @media (min-width:992px) {
    padding: 125px 0;

  }
  &__title{
    .subtitle{
      margin-bottom: 20px;
    }
    margin-bottom: 35px;
    @media (min-width:768px) {
      margin-bottom: 80px;
    }
  }
  .pricing-sider{
    .slick-slide{
      margin: 0 12px;
    }
  }
}*/
/*duplicated code ends*/
.pricing-section-02--2 {
  background: rgba(55, 124, 253, 0.02) !important;
}
.pricing-section-02--2 .section-title .subtitle {
  color: #00BB98 !important;
}
.pricing-section-02--2 .card-pricing--02__button .btn-primary-outline {
  border-radius: 3px !important;
  border-color: #FD4C5C !important;
  color: #FD4C5C !important;
}
.pricing-section-02--2 .card-pricing--02__button .btn-primary-outline:hover {
  color: #fff !important;
}
.pricing-section-02--2 .card-pricing--02__button .btn-primary-outline:before {
  background: #FD4C5C !important;
}
.pricing-section-02--2 .card-pricing--02__button .btn-primary-outline-reverse:before {
  background: #FD4C5C !important;
}
.pricing-section-02--2 .card-pricing--02__button .btn-primary-outline-reverse:hover {
  border-color: #FD4C5C !important;
}

/*
    Note: This section is similar to landing 5 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.testimonil-section-04 {
    padding: 60px 0;
    @media (min-width:768px) {
        padding: 80px 0;
    }

    @media (min-width:992px) {
        padding: 130px 0;
    }
    .content-title {
        margin-bottom: 40px;

        @media (min-width:768px) {
            margin-bottom: 80px;
        }
    }
    &__content {
        position: relative;
        text-align: center;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 30px;
        @media (min-width:480px) {
            padding: 40px 60px;
        }
        @media (min-width:1200px) {
            padding: 60px 83px;

        }
        .text {
            font-size: 18px;
            line-height: 26px;
            padding-bottom: 42px;
        }
        .client-info {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            @media (min-width:374px) {
                flex-direction: row;
            }
            &--img {
                img {
                    min-width: 70px;
                    min-height: 70px;
                    max-width: 70px;
                    max-height: 70px;
                    object-fit: cover;
                    margin-bottom: 24.29px;
                    border-radius: 500px;
                    @media (min-width:374px) {
                        margin-right: 24.29px;
                        margin-bottom: 0px;

                    }
                }
            }

            &--texts {
                text-align: start;
                .name {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 4px;
                }

                .profession {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
        .quote{
            position: absolute;
            right: 20px;
            bottom: 40px;
        }
    }
    .testimonil-slider--02{
        .slick-list{
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        }
    }
}
*/
/*duplicated code ends*/
/* Service Css Start */
/* Content Css Start */
/* Pricing Css Start */
/* Testimonial Css Start */
.content-section-24 {
  padding: 60px 0;
  position: relative;
}
@media (min-width: 768px) {
  .content-section-24 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-24 {
    padding: 130px 0;
  }
}
.content-section-24__shape-1 {
  position: absolute;
  left: 0%;
  width: 5%;
  top: 9%;
}
.content-section-24__shape-2 {
  position: absolute;
  right: 0%;
  width: 3%;
  bottom: 19%;
}
.content-section-24__tab {
  background: white;
  filter: drop-shadow(0px 4px 40px rgba(43, 89, 255, 0.08));
  border-radius: 10px;
}
.content-section-24__tab--title {
  padding: 25px 30px;
  padding-bottom: 28px;
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  border-radius: 10px 10px 0px 0px;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.4;
  color: white !important;
}
@media (min-width: 992px) {
  .content-section-24__tab--title {
    font-size: 30px;
  }
}
.content-section-24__tab .nav {
  padding: 40px 30px;
}
.content-section-24__tab .nav .nav-link {
  text-align: start;
  padding: 30px 0;
  border-bottom: 1px solid rgba(55, 124, 253, 0.1);
  font-size: 18px;
  line-height: 1.3;
  color: #000B33;
}
@media (min-width: 992px) {
  .content-section-24__tab .nav .nav-link {
    font-size: 20px;
  }
}
.content-section-24__tab .nav .nav-link.active {
  background: none;
  color: #FD4C5C;
}
.content-section-24__tab .nav .nav-link:first-child {
  padding-top: 0;
}
.content-section-24__tab .nav .nav-link:last-child {
  border: 0;
  padding-bottom: 0;
}
.content-section-24__tab-content--img {
  margin-bottom: 30px;
}
.content-section-24__tab-content--img img {
  border-radius: 10px;
}
.content-section-24__tab-content .text {
  font-size: 18px;
  line-height: 1.3;
}
@media (min-width: 992px) {
  .content-section-24__tab-content .text {
    font-size: 20px;
  }
}
.content-section-24__tab-content--widget {
  margin-top: 40px;
}
.content-section-24__tab-content--widget .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.4;
  color: #010C16 !important;
  padding-bottom: 16px;
}
@media (min-width: 576px) {
  .content-section-24__tab-content--widget .title {
    font-size: 30px;
  }
}
.content-section-24__tab-content--widget .widget {
  display: flex;
  margin-bottom: 30px;
}
.content-section-24__tab-content--widget .widget:last-child {
  margin-bottom: 0;
}
.content-section-24__tab-content--widget .widget--icon {
  margin-right: 20px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
}
@media (min-width: 768px) {
  .content-section-24__tab-content--widget .widget--icon {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
  }
}
.content-section-24__tab-content--widget .widget--icon.icon-bg-1 {
  background: rgba(55, 124, 253, 0.1);
}
.content-section-24__tab-content--widget .widget--icon.icon-bg-2 {
  background: rgba(253, 76, 92, 0.1);
}
.content-section-24__tab-content--widget .widget--icon.icon-bg-3 {
  background: rgba(0, 187, 152, 0.1);
}
.content-section-24__tab-content--widget .widget--text p {
  font-size: 14px;
}
@media (min-width: 992px) {
  .content-section-24__tab-content--widget .widget--text p {
    font-size: 16px;
  }
}
.content-section-24__tab-content--widget .widget--text p span {
  font-weight: 600;
  font-size: 18px;
  color: #377CFD;
  line-height: 1.3;
}
@media (min-width: 992px) {
  .content-section-24__tab-content--widget .widget--text p span {
    font-size: 20px;
  }
}

/* Content Css Start */
.service-section-03 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .service-section-03 {
    padding: 80px 0;
  }
}
@media (min-width: 768px) {
  .service-section-03 {
    padding: 130px 0;
  }
}
.service-section-03__sidebar {
  padding: 30px 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
@media (min-width: 420px) {
  .service-section-03__sidebar {
    padding: 40px;
  }
}
.service-section-03__sidebar .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 34px;
}
.service-section-03__sidebar--list li {
  list-style: none;
  padding: 18px 0;
}
.service-section-03__sidebar--list li:first-child {
  padding-top: 0;
}
.service-section-03__sidebar--list li:last-child {
  padding-bottom: 0;
}
.service-section-03__sidebar--list li a {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4444444444;
  color: #000B33;
}
@media (min-width: 420px) {
  .service-section-03__sidebar--list li a {
    font-size: 18px;
  }
}
.service-section-03__sidebar--list li.active a {
  color: #377CFD;
}
.service-section-03__wrapper--content .titel {
  margin-bottom: 24px;
}
.service-section-03__wrapper--job-list {
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .service-section-03__wrapper--job-list {
    margin-top: 60px;
  }
}
.service-section-03__wrapper--job-list li {
  margin-bottom: 24px;
  list-style: none;
}
.service-section-03__wrapper--job-list li:last-child {
  margin-bottom: 0;
}
.service-section-03__wrapper--job-list li a {
  background: #FFFFFF;
  border: 0.25px solid rgba(0, 11, 51, 0.3);
  border-radius: 10px;
  padding: 30px;
  display: block;
  transition: 0.3s;
}
@media (min-width: 576px) {
  .service-section-03__wrapper--job-list li a {
    padding: 40px;
  }
}
.service-section-03__wrapper--job-list li a:hover {
  border: 0.25px solid transparent;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
}
.service-section-03__wrapper--job-list li a:hover .list-content--icon svg {
  fill: #FD4C5C;
}
.service-section-03__wrapper--job-list li a .list-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-section-03__wrapper--job-list li a .list-content--texts h6 {
  line-height: 1.25;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 15px;
}
@media (min-width: 420px) {
  .service-section-03__wrapper--job-list li a .list-content--texts h6 {
    font-size: 20px;
  }
}
@media (min-width: 576px) {
  .service-section-03__wrapper--job-list li a .list-content--texts h6 {
    font-size: 24px;
  }
}
.service-section-03__wrapper--job-list li a .list-content--texts span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: rgba(0, 11, 51, 0.5);
}
@media (min-width: 420px) {
  .service-section-03__wrapper--job-list li a .list-content--texts span {
    font-size: 18px;
  }
}
@media (min-width: 576px) {
  .service-section-03__wrapper--job-list li a .list-content--texts span {
    font-size: 20px;
  }
}
.service-section-03__wrapper--job-list li a .list-content--icon svg {
  fill: #000B33;
  transition: 0.3s;
}

/*
    Note: This section is similar to landing 1 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*
.video-section-01 {
    background: rgb(43, 89, 255, 0.02);
    padding: 60px 0;

    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 170px;
        padding-bottom: 130px;
    }

    .block-title {
        p {
            @media(min-width:992px) {
                padding-right: 11%;
            }

            @media(min-width:1200px) {
                padding-right: 11%;
            }

            @media(min-width:1400px) {
                padding-right: 23%;
            }
        }

        .btn {
            margin-top: 40px;
        }
    }
}*/
/*duplicated code ends*/
.video-section-01--3 .video-block {
  overflow: inherit;
}
.video-section-01--3 .video-block .video-button-block {
  background: rgba(0, 11, 51, 0.1);
}
.video-section-01--3 .video-block .dots {
  position: absolute;
  top: -6.5%;
  right: -3.5%;
  width: 32.2%;
  z-index: -1;
}

/*
    Note: This section is similar to landing 6 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
.counter-section-06 {
  padding: 50px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .counter-section-06 {
    padding: 70px 0;
  }
}
@media (min-width: 992px) {
  .counter-section-06 {
    padding: 100px 0;
  }
}
.counter-section-06 .widget-counter {
  justify-content: start;
  padding: 20px 40px;
  border-radius: 10px;
}
.counter-section-06 .widget-counter .widget-title {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .counter-section-06 .widget-counter .widget-title {
    margin-right: 20px;
  }
}
.counter-section-06 .widget-counter .widget-text {
  color: #000B33;
}
.counter-section-06 .col-xl-3:nth-child(1) .widget-counter {
  background: rgba(0, 187, 152, 0.1);
}
.counter-section-06 .col-xl-3:nth-child(2) .widget-counter {
  background: rgba(120, 27, 255, 0.1);
}
.counter-section-06 .col-xl-3:nth-child(3) .widget-counter {
  background: rgba(253, 76, 92, 0.1);
}
.counter-section-06 .col-xl-3:nth-child(4) .widget-counter {
  background: rgba(255, 200, 62, 0.1);
}

/*duplicated code ends*/
/* Service Css Start */
/* Video Css Start */
/* Fact Css Start */
.content-section-25 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .content-section-25 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .content-section-25 {
    padding: 130px 0;
  }
}
.content-section-25__sidebar {
  padding: 30px 20px;
  background: white;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
@media (min-width: 576px) {
  .content-section-25__sidebar {
    padding: 40px;
  }
}
.content-section-25__sidebar .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}
@media (min-width: 420px) {
  .content-section-25__sidebar .title {
    font-size: 20px;
  }
}
.content-section-25__sidebar--lis {
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
.content-section-25__sidebar--lis li {
  color: #000B33;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  margin-bottom: 30px;
}
.content-section-25__sidebar--lis li:last-child {
  margin-bottom: 0;
}
.content-section-25__sidebar--lis li .download-btn-group {
  margin-top: 30px;
}
.content-section-25__sidebar--lis li .download-btn-group a {
  font-size: 18px;
  line-height: 26px;
  color: #377CFD;
  display: block;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(55, 124, 253, 0.1);
  margin-bottom: 15px;
}
.content-section-25__sidebar--lis li .download-btn-group a .icon {
  margin-right: 20px;
}
.content-section-25__sidebar--lis li .download-btn-group a:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.content-section-25__content {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  padding: 30px 20px;
}
@media (min-width: 420px) {
  .content-section-25__content {
    padding: 30px 50px 40px 30px;
  }
}
@media (min-width: 768px) {
  .content-section-25__content {
    padding: 40px 70px 60px 40px;
  }
}
.content-section-25__content--texts {
  margin-bottom: 35px;
}
.content-section-25__content--texts .title {
  margin-bottom: 24px;
}
@media (min-width: 576px) {
  .content-section-25__content--texts {
    margin-bottom: 65px;
  }
}
.content-section-25__content--list {
  margin-bottom: 35px;
}
@media (min-width: 576px) {
  .content-section-25__content--list {
    margin-bottom: 60px;
  }
}
.content-section-25__content--list .title {
  font-weight: 600;
  font-size: 26px;
  line-height: 1.25;
  margin-bottom: 25px;
}
@media (min-width: 576px) {
  .content-section-25__content--list .title {
    font-size: 36px;
    margin-bottom: 39px;
  }
}
.content-section-25__content--list ul li {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  list-style: none;
  position: relative;
  margin: 11px 0;
  padding-left: 22px;
  color: rgba(0, 11, 51, 0.8);
}
.content-section-25__content--list ul li:first-child {
  margin-top: 0;
}
.content-section-25__content--list ul li:last-child {
  margin-bottom: 0;
}
.content-section-25__content--list ul li::before {
  width: 10px;
  border-radius: 500px;
  height: 10px;
  position: absolute;
  content: "";
  background: #377CFD;
  top: 7px;
  left: 0;
}
.content-section-25__content .btn {
  min-width: 160px;
}

/* Content Css Start */
.services-section-03 {
  padding: 60px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .services-section-03 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .services-section-03 {
    padding: 130px 0;
  }
}
.services-section-03__tab {
  background: white;
  border-radius: 10px;
}
.services-section-03__tab--title {
  padding: 20px 25px;
  padding-bottom: 25px;
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  border-radius: 10px 10px 0px 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.4;
  color: white !important;
}
@media (min-width: 480px) {
  .services-section-03__tab--title {
    font-size: 26px;
    padding: 25px 30px;
    padding-bottom: 28px;
  }
}
@media (min-width: 992px) {
  .services-section-03__tab--title {
    font-size: 30px;
  }
}
.services-section-03__tab .nav {
  padding: 40px 30px;
  background: #FFFFFF;
  border-radius: 10px;
}
.services-section-03__tab .nav .nav-link {
  text-align: start;
  padding: 0;
  padding-bottom: 30px;
  font-size: 16px;
  line-height: 1.3;
  color: #000B33;
}
@media (min-width: 480px) {
  .services-section-03__tab .nav .nav-link {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .services-section-03__tab .nav .nav-link {
    font-size: 20px;
  }
}
.services-section-03__tab .nav .nav-link.active {
  background: none;
  color: #FD4C5C;
}
.services-section-03__tab .nav .nav-link:last-child {
  padding-bottom: 0;
}
.services-section-03__accordion .accordion-item {
  border: 0;
  margin-bottom: 30px;
  background: transparent;
  position: relative;
  z-index: 1;
  outline: none;
}
.services-section-03__accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.services-section-03__accordion .accordion-item .accordion-header .accordion-button {
  padding: 15px;
  border: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: #000B33;
  box-shadow: none;
  background-color: inherit !important;
  transition: 0.3s;
  z-index: unset !important;
  outline: none;
}
@media (min-width: 480px) {
  .services-section-03__accordion .accordion-item .accordion-header .accordion-button {
    padding: 30px;
    font-size: 18px;
  }
}
@media (min-width: 576px) {
  .services-section-03__accordion .accordion-item .accordion-header .accordion-button {
    font-size: 20px;
  }
}
.services-section-03__accordion .accordion-item .accordion-header .accordion-button.collapsed {
  border-top: 1px solid rgba(55, 124, 253, 0.1);
  border-bottom: 1px solid rgba(55, 124, 253, 0.1);
}
.services-section-03__accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  position: static;
  color: #FD4C5C;
  outline: none;
  box-shadow: none;
}
.services-section-03__accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::before {
  content: "";
  background: #fff;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 5px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  border: 0;
  outline: none;
}
.services-section-03__accordion .accordion-item .accordion-header .accordion-button::after {
  background-image: none;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  color: #000B33;
  font-size: 16px;
}
@media (min-width: 480px) {
  .services-section-03__accordion .accordion-item .accordion-header .accordion-button::after {
    font-size: 18px;
  }
}
.services-section-03__accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  transform: rotate(0deg);
  color: #FD4C5C;
  font-size: 16px;
}
@media (min-width: 480px) {
  .services-section-03__accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    font-size: 18px;
  }
}
.services-section-03__accordion .accordion-item .accordion-body {
  font-size: 14px;
  line-height: 26px;
  padding: 15px;
  padding-top: 0;
  margin-top: -6;
}
@media (min-width: 480px) {
  .services-section-03__accordion .accordion-item .accordion-body {
    font-size: 16px;
    padding: 30px;
  }
}
.services-section-03__accordion .accordion-item:first-child .accordion-header .accordion-button.collapsed {
  border-top: 0;
}
.services-section-03__accordion .accordion-item:last-child .accordion-header .accordion-button.collapsed {
  border-bottom: 0;
}

/* Services Css Start */
.blogcontent-section-01 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .blogcontent-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .blogcontent-section-01 {
    padding: 130px 0;
  }
}
.blogcontent-section-01__pagination {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .blogcontent-section-01__pagination {
    margin-top: 57px;
  }
}
.blogcontent-section-01__pagination .pagination {
  margin: 0 -10px;
}
.blogcontent-section-01__pagination .pagination .page-item {
  cursor: pointer;
  border-radius: 500px;
  margin: 0 10px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 0.25px solid rgba(0, 11, 51, 0.8);
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  transition: 0.3s;
}
.blogcontent-section-01__pagination .pagination .page-item.active {
  background: #FD4C5C;
  border-color: #FD4C5C;
}
.blogcontent-section-01__pagination .pagination .page-item.active a {
  color: white !important;
}
.blogcontent-section-01__pagination .pagination .page-item:hover {
  background: #FD4C5C;
  border-color: #FD4C5C;
}
.blogcontent-section-01__pagination .pagination .page-item:hover a {
  color: white !important;
}
@media (min-width: 576px) {
  .blogcontent-section-01__pagination .pagination .page-item {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
  }
}
.blogcontent-section-01__pagination .pagination .page-item a {
  color: rgba(0, 11, 51, 0.8);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
}

/* Blog Sigebar Start */
.blog-sidebar-01 {
  background: #FFFFFF;
  padding: 20px;
  padding-bottom: 42px;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 5px;
}
@media (min-width: 576px) {
  .blog-sidebar-01 {
    padding: 40px;
  }
}
.blog-sidebar-01--searchbox {
  position: relative;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .blog-sidebar-01--searchbox {
    margin-bottom: 30px;
  }
}
.blog-sidebar-01--searchbox .form-control {
  border: 0;
  outline: none;
  background: #FFFFFF;
  padding-left: 57px;
  padding-right: 30px;
  height: 50px;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 5px;
  font-size: 16px;
  line-height: 26px;
  color: #000B33;
}
.blog-sidebar-01--searchbox .form-control::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
@media (min-width: 576px) {
  .blog-sidebar-01--searchbox .form-control {
    height: 60px;
  }
}
.blog-sidebar-01--searchbox .icon {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-sidebar-01--tab .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 15px;
}
@media (min-width: 576px) {
  .blog-sidebar-01--tab .title {
    font-size: 20px;
  }
}
.blog-sidebar-01--tab .nav .nav-link {
  text-align: start;
  color: rgba(0, 11, 51, 0.8);
  line-height: 1.625;
  font-size: 16px;
  padding: 15px 0;
  border-top: 1px solid rgba(55, 124, 253, 0.1);
}
.blog-sidebar-01--tab .nav .nav-link:last-child {
  padding-bottom: 0;
}
.blog-sidebar-01--tab .nav .nav-link.active {
  background: none;
  color: #FD4C5C;
}
.blog-sidebar-01--posts {
  margin-top: 60px;
}
.blog-sidebar-01--posts .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(55, 124, 253, 0.1);
}
@media (min-width: 576px) {
  .blog-sidebar-01--posts .title {
    font-size: 20px;
  }
}
.blog-sidebar-01--posts .post-blogs {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.blog-sidebar-01--posts .post-blogs--img {
  margin-right: 20px;
}
.blog-sidebar-01--posts .post-blogs--img img {
  width: 100%;
  border-radius: 10px;
}
@media (min-width: 1200px) {
  .blog-sidebar-01--posts .post-blogs--img img {
    width: inherit;
  }
}
.blog-sidebar-01--posts .post-blogs--texts h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 10px;
}
.blog-sidebar-01--posts .post-blogs--texts span {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.blog-sidebar-01--tags {
  margin-top: 60px;
}
.blog-sidebar-01--tags .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(55, 124, 253, 0.1);
}
@media (min-width: 576px) {
  .blog-sidebar-01--tags .title {
    font-size: 20px;
  }
}
.blog-sidebar-01--tags .tag {
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog-sidebar-01--tags .tag-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px 27px;
  border: 0.5px solid #010C16;
  border-radius: 3px;
  display: inline-block;
  background: white;
  color: rgba(1, 12, 22, 0.8);
  margin: 15px;
  margin-left: 0;
}
.blog-sidebar-01--tags .tag-item.active {
  border-color: #FD4C5C;
  background: #FD4C5C;
  color: white;
}

/* Blog Sigebar End */
/* Content Css Start */
.blogcontent-section-02 {
  padding: 60px 0;
}
@media (min-width: 768px) {
  .blogcontent-section-02 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .blogcontent-section-02 {
    padding: 130px 0;
  }
}

/* blog detail card css */
.blogcontent-card {
  background: white;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
.blogcontent-card--img img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blogcontent-card__body {
  padding: 20px;
}
@media (min-width: 576px) {
  .blogcontent-card__body {
    padding: 40px;
  }
}
.blogcontent-card__body--meta {
  display: flex;
  align-items: center;
}
.blogcontent-card__body--meta .admmin,
.blogcontent-card__body--meta .date,
.blogcontent-card__body--meta .comment {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 10px;
}
@media (min-width: 480px) {
  .blogcontent-card__body--meta .admmin,
.blogcontent-card__body--meta .date,
.blogcontent-card__body--meta .comment {
    margin-right: 60px;
  }
}
.blogcontent-card__body--meta .admmin:last-child,
.blogcontent-card__body--meta .date:last-child,
.blogcontent-card__body--meta .comment:last-child {
  margin-right: 0;
}
.blogcontent-card__body--meta .admmin svg,
.blogcontent-card__body--meta .date svg,
.blogcontent-card__body--meta .comment svg {
  fill: #377CFD;
  margin-right: 10px;
}
.blogcontent-card__body--meta .admmin span,
.blogcontent-card__body--meta .date span,
.blogcontent-card__body--meta .comment span {
  color: #377CFD;
  font-size: 14px;
  line-height: 21px;
}
.blogcontent-card__body--texts {
  margin-bottom: 30px;
}
.blogcontent-card__body--texts h6 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  padding-bottom: 15px;
}
.blogcontent-card__body--texts p {
  font-size: 16px;
  line-height: 26px;
}
.blogcontent-card__body--lists {
  margin-bottom: 30px;
}
.blogcontent-card__body--lists h6 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  padding-bottom: 15px;
}
.blogcontent-card__body--lists ul li {
  position: relative;
  list-style: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.8);
  padding: 7.5px 0;
  padding-left: 20px;
}
.blogcontent-card__body--lists ul li:first-child {
  padding-top: 0;
}
.blogcontent-card__body--lists ul li:last-child {
  padding-bottom: 0;
}
.blogcontent-card__body--lists ul li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #FD4C5C;
  border-radius: 500px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.blogcontent-card__body--social h6 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  padding-bottom: 20px;
}
.blogcontent-card__body--social ul {
  margin: 0;
  padding: 0;
  list-style: 0;
  display: flex;
  align-items: center;
}
.blogcontent-card__body--social ul li {
  list-style: none;
  padding: 0 7.5px;
}
.blogcontent-card__body--social ul li:first-child {
  padding-top: 0;
}
.blogcontent-card__body--social ul li:last-child {
  padding-bottom: 0;
}
.blogcontent-card__body--social ul li a {
  color: rgba(0, 11, 51, 0.8);
}

/* blog detail next prev buttn css */
.blogcontent-buttons {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .blogcontent-buttons {
    margin: 60px 0;
  }
}
.blogcontent-buttons__button .tag {
  margin-bottom: 20px;
}
.blogcontent-buttons__button .tag span {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #377CFD;
}
.blogcontent-buttons__previous .prev-content {
  display: flex;
  align-items: center;
}
.blogcontent-buttons__previous .prev-content__text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-left: 15px;
}
.blogcontent-buttons__next .next-content {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.blogcontent-buttons__next .next-content__arrow {
  transform: rotate(180deg);
  margin-top: 5px;
}
.blogcontent-buttons__next .next-content__text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-right: 15px;
}

/* blog detail comment  css */
.blogcontent-comment {
  background: #FFFFFF;
  padding: 40px 20px;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .blogcontent-comment {
    margin-bottom: 60px;
  }
}
.blogcontent-comment__content {
  margin-bottom: 30px;
}
.blogcontent-comment__content .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 30px;
  color: #2B59FF !important;
}
@media (min-width: 420px) {
  .blogcontent-comment__content .title {
    font-size: 20px;
  }
}

/* blog detail comment card  css */
.comment-card-01 {
  display: flex;
  padding: 20px;
  padding-right: 20px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .comment-card-01 {
    flex-direction: row;
  }
}
.comment-card-01--img {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .comment-card-01--img {
    margin-right: 30px;
    margin-bottom: 0px;
  }
}
.comment-card-01--img img {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  object-fit: cover;
  border-radius: 500px;
}
@media (min-width: 576px) {
  .comment-card-01--img img {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  }
}
.comment-card-01__body--text {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 20px;
}
.comment-card-01__body--info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment-card-01__body--info .data .name {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.comment-card-01__body--info .data .date {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.comment-card-01__body--info .texts a {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  transition: 0.3s;
}
.comment-card-01__body--info .texts a.active {
  color: rgba(253, 76, 92, 0.8) !important;
}
.comment-card-01__body--info .texts a:hover {
  color: rgba(253, 76, 92, 0.8) !important;
}

.comment-card-02 {
  margin-top: 15px;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 5px;
  padding: 30px 26px 27px 36px;
  margin-left: 30px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .comment-card-02 {
    flex-direction: row;
    margin-left: 105px;
  }
}
.comment-card-02--img {
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .comment-card-02--img {
    margin-bottom: 0px;
    margin-right: 20px;
  }
}
.comment-card-02--img img {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  object-fit: cover;
  border-radius: 500px;
}
@media (min-width: 576px) {
  .comment-card-02--img img {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
}
.comment-card-02__body--text {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 20px;
}
.comment-card-02__body--info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment-card-02__body--info .data .name {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
.comment-card-02__body--info .data .date {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.comment-card-02__body--info .texts a {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  transition: 0.3s;
}
.comment-card-02__body--info .texts a.active {
  color: rgba(253, 76, 92, 0.8) !important;
}
.comment-card-02__body--info .texts a:hover {
  color: rgba(253, 76, 92, 0.8) !important;
}

/* blog detail form box css */
.blogcontent-from {
  padding: 30px 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
@media (min-width: 576px) {
  .blogcontent-from {
    padding: 40px;
  }
}
.blogcontent-from__content {
  margin-bottom: 30px;
}
.blogcontent-from__content .title {
  color: #2B59FF !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 14px;
}
@media (min-width: 420px) {
  .blogcontent-from__content .title {
    font-size: 20px;
  }
}
.blogcontent-from__content .text {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
.blogcontent-from .from__input-group .form-control {
  background: rgba(55, 124, 253, 0.05);
  border-radius: 5px;
  height: 55px;
  border: 0;
  padding: 30px;
  margin-bottom: 20px;
  color: #000B33;
}
.blogcontent-from .from__input-group .form-control::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
.blogcontent-from .from__input-group--box {
  background: rgba(55, 124, 253, 0.05);
  border-radius: 5px;
  padding: 30px;
  width: 100%;
  border: 0;
  margin-bottom: 20px;
  color: #000B33;
  max-height: 200px;
}
.blogcontent-from .from__input-group--box::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
.blogcontent-from .from__input-group--box:focus {
  outline: none;
}
.blogcontent-from .from .btn {
  margin-top: 20px;
  min-width: 150px;
}

/* Content Css Start */
.signin-section-01 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  min-height: 100vh;
}
@media (min-width: 768px) {
  .signin-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .signin-section-01 {
    padding: 100px 0;
  }
}
.signin-section-01__logo {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .signin-section-01__logo {
    margin-bottom: 100px;
  }
}

.signin-form-01 {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  padding: 50px 25px;
}
@media (min-width: 768px) {
  .signin-form-01 {
    padding: 80px 55px;
  }
}
@media (min-width: 992px) {
  .signin-form-01 {
    padding: 100px 75px;
  }
}
.signin-form-01__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .signin-form-01__title {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .signin-form-01__title {
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .signin-form-01 .from {
    width: 416px;
  }
}
.signin-form-01 .from__input-group {
  margin-bottom: 20px;
}
.signin-form-01 .from__input-group--label {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 15px;
  display: block;
}
@media (min-width: 992px) {
  .signin-form-01 .from__input-group--label {
    font-size: 20px;
  }
}
.signin-form-01 .from__input-group--input {
  height: 50px;
  width: 100%;
  padding: 0 30px;
  border: 0.25px solid rgba(43, 89, 255, 0.3);
  border-radius: 10px;
}
.signin-form-01 .from__input-group--input:focus-visible {
  outline: none;
}
.signin-form-01 .from__input-group--input::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
@media (min-width: 480px) {
  .signin-form-01 .from__input-group--input {
    height: 60px;
  }
}
.signin-form-01 .from__pass-box {
  position: relative;
}
.signin-form-01 .from__pass-box--input {
  padding-right: 60px;
}
.signin-form-01 .from__pass-box--input::placeholder {
  position: absolute;
  left: 30px;
  top: 60%;
  transform: translateY(-60%);
}
.signin-form-01 .from__pass-box--eye {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.signin-form-01 .from__check .form-check-input:checked {
  background-color: #FD4C5C !important;
  border-color: #FD4C5C !important;
}
.signin-form-01 .from__check .form-check-input:focus {
  box-shadow: none;
}
.signin-form-01 .from__check .form-check-label {
  padding-left: 8px;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.6);
}
.signin-form-01 .from__button-group--one {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
@media (min-width: 420px) {
  .signin-form-01 .from__button-group--one {
    flex-direction: row;
  }
}
.signin-form-01 .from__button-group--one .btn {
  min-width: 141px;
}
.signin-form-01 .from__button-group--one .forgot-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
  transition: 0.3s;
}
.signin-form-01 .from__button-group--one .forgot-btn:hover {
  text-decoration: underline;
}
@media (min-width: 420px) {
  .signin-form-01 .from__button-group--one .forgot-btn {
    font-size: 16px;
    margin-top: 0px;
    margin-left: 40px;
  }
}
.signin-form-01 .from__button-group--two {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 420px) {
  .signin-form-01 .from__button-group--two {
    flex-direction: row;
  }
}
.signin-form-01 .from__button-group--two .account-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  transition: 0.3s;
}
.signin-form-01 .from__button-group--two .account-btn:hover {
  text-decoration: underline;
}
@media (min-width: 420px) {
  .signin-form-01 .from__button-group--two .account-btn {
    font-size: 16px;
  }
}
.signin-form-01 .from__button-group--two .createanaccout-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  text-decoration-line: underline;
  color: #FD4C5C;
  margin-top: 10px;
}
@media (min-width: 420px) {
  .signin-form-01 .from__button-group--two .createanaccout-btn {
    font-size: 20px;
    margin-top: 0px;
    margin-left: 10px;
  }
}

/* Sign In 1 Css Start */
/* Sign In 1 Css End */
.signin-section-02 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 60px 0;
  min-height: 100vh;
}
@media (min-width: 768px) {
  .signin-section-02 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .signin-section-02 {
    padding: 100px 0;
  }
}
.signin-section-02__bg {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
@media (min-width: 992px) {
  .signin-section-02__bg {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .signin-section-02__bg {
    width: 53.5%;
  }
}
.signin-section-02__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.signin-section-02::after {
  position: absolute;
  content: "";
  top: 60px;
  left: 5px;
  right: 5px;
  bottom: 60px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(30px);
  z-index: -1;
}
@media (min-width: 480px) {
  .signin-section-02::after {
    left: 20px;
    right: 20px;
  }
}
@media (min-width: 992px) {
  .signin-section-02::after {
    background: unset;
    backdrop-filter: unset;
  }
}

.signin-form-02 {
  padding: 60px 0;
}
.signin-form-02__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .signin-form-02__title {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .signin-form-02 .from {
    width: 416px;
  }
}
.signin-form-02 .from__input-group {
  margin-bottom: 20px;
}
.signin-form-02 .from__input-group--label {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 15px;
  display: block;
}
@media (min-width: 992px) {
  .signin-form-02 .from__input-group--label {
    font-size: 20px;
  }
}
.signin-form-02 .from__input-group--input {
  height: 50px;
  width: 100%;
  padding: 0 30px;
  border: 0.25px solid rgba(43, 89, 255, 0.3);
  border-radius: 10px;
}
.signin-form-02 .from__input-group--input:focus-visible {
  outline: none;
}
.signin-form-02 .from__input-group--input::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
@media (min-width: 480px) {
  .signin-form-02 .from__input-group--input {
    height: 60px;
  }
}
.signin-form-02 .from__pass-box {
  position: relative;
}
.signin-form-02 .from__pass-box--input {
  padding-right: 60px;
}
.signin-form-02 .from__pass-box--input::placeholder {
  position: absolute;
  left: 30px;
  top: 60%;
  transform: translateY(-60%);
}
.signin-form-02 .from__pass-box--eye {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.signin-form-02 .from__check {
  justify-content: space-between;
}
@media (min-width: 420px) {
  .signin-form-02 .from__check {
    justify-content: inherit;
  }
}
.signin-form-02 .from__check .form-check-input:checked {
  background-color: #FD4C5C !important;
  border-color: #FD4C5C !important;
}
.signin-form-02 .from__check .form-check-input:focus {
  box-shadow: none;
}
.signin-form-02 .from__check .form-check-label {
  padding-left: 8px;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.6);
}
.signin-form-02 .from__check .forgot-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  transition: 0.3s;
}
.signin-form-02 .from__check .forgot-btn:hover {
  text-decoration: underline;
}
@media (min-width: 420px) {
  .signin-form-02 .from__check .forgot-btn {
    margin-left: 30px;
    font-size: 16px;
    margin-left: 40px;
  }
}
.signin-form-02 .from__button-group--one {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
@media (min-width: 420px) {
  .signin-form-02 .from__button-group--one {
    flex-direction: row;
  }
}
.signin-form-02 .from__button-group--one .btn {
  width: 100%;
}
.signin-form-02 .from__button-group--two {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 420px) {
  .signin-form-02 .from__button-group--two {
    justify-content: inherit;
  }
}
.signin-form-02 .from__button-group--two .account-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  transition: 0.3s;
}
.signin-form-02 .from__button-group--two .account-btn:hover {
  text-decoration: underline;
}
@media (min-width: 420px) {
  .signin-form-02 .from__button-group--two .account-btn {
    font-size: 16px;
  }
}
.signin-form-02 .from__button-group--two .createanaccout-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  text-decoration-line: underline;
  color: #FD4C5C;
}
@media (min-width: 420px) {
  .signin-form-02 .from__button-group--two .createanaccout-btn {
    font-size: 20px;
    margin-left: 10px;
  }
}

/* Sign In 2 Css Start */
/* Sign In 2 Css End */
.signup-section-01 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
@media (min-width: 768px) {
  .signup-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .signup-section-01 {
    padding: 100px 0;
  }
}
.signup-section-01__logo {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .signup-section-01__logo {
    margin-bottom: 100px;
  }
}

@media (min-width: 420px) {
  .signup-form-01 {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    padding: 50px 25px;
  }
}
@media (min-width: 576px) {
  .signup-form-01 {
    padding: 60px 45px;
  }
}
@media (min-width: 992px) {
  .signup-form-01 {
    padding: 80px 70px;
  }
}
@media (min-width: 1200px) {
  .signup-form-01 {
    padding: 100px 110px;
  }
}
.signup-form-01__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .signup-form-01__title {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .signup-form-01__title {
    margin-bottom: 60px;
  }
}
.signup-form-01 .from__input-group {
  margin-bottom: 20px;
}
.signup-form-01 .from__input-group--label {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 15px;
  display: block;
}
@media (min-width: 992px) {
  .signup-form-01 .from__input-group--label {
    font-size: 20px;
  }
}
.signup-form-01 .from__input-group--input {
  height: 50px;
  width: 100%;
  padding: 0 30px;
  border: 0.25px solid rgba(43, 89, 255, 0.3);
  border-radius: 10px;
}
.signup-form-01 .from__input-group--input:focus-visible {
  outline: none;
}
.signup-form-01 .from__input-group--input::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
@media (min-width: 480px) {
  .signup-form-01 .from__input-group--input {
    height: 60px;
  }
}
.signup-form-01 .from__pass-box {
  position: relative;
}
.signup-form-01 .from__pass-box--input {
  padding-right: 60px;
}
.signup-form-01 .from__pass-box--input::placeholder {
  position: absolute;
  left: 30px;
  top: 60%;
  transform: translateY(-60%);
}
.signup-form-01 .from__pass-box--eye {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.signup-form-01 .from__check--title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 11px;
  display: block;
}
@media (min-width: 420px) {
  .signup-form-01 .from__check--title {
    font-size: 20px;
  }
}
.signup-form-01 .from__check .form-check-input:checked {
  background-color: #FD4C5C !important;
  border-color: #FD4C5C !important;
}
.signup-form-01 .from__check .form-check-input:focus {
  box-shadow: none;
}
.signup-form-01 .from__check .form-check-label {
  padding-left: 8px;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.6);
}
.signup-form-01 .from__button-group--one {
  margin-top: 30px;
}
.signup-form-01 .from__button-group--one .btn {
  min-width: 215px;
}
.signup-form-01 .from__button-group--two {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 420px) {
  .signup-form-01 .from__button-group--two {
    flex-direction: row;
  }
}
.signup-form-01 .from__button-group--two .account-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  transition: 0.3s;
}
.signup-form-01 .from__button-group--two .account-btn:hover {
  text-decoration: underline;
}
@media (min-width: 420px) {
  .signup-form-01 .from__button-group--two .account-btn {
    font-size: 20px;
  }
}
.signup-form-01 .from__button-group--two .createanaccout-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  text-decoration-line: underline;
  color: #FD4C5C;
  margin-top: 10px;
}
@media (min-width: 420px) {
  .signup-form-01 .from__button-group--two .createanaccout-btn {
    font-size: 20px;
    margin-top: 0px;
    margin-left: 10px;
  }
}

/* Sign Up 1 Css Start */
/* Sign Up 1 Css End */
.signup-section-02 {
  position: relative;
  padding: 60px 0;
}
@media (min-width: 768px) {
  .signup-section-02 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .signup-section-02 {
    padding: 100px 0;
  }
}
.signup-section-02__bg {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
@media (min-width: 992px) {
  .signup-section-02__bg {
    width: 47%;
  }
}
@media (min-width: 1200px) {
  .signup-section-02__bg {
    width: 52%;
  }
}
.signup-section-02__bg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.signup-section-02::after {
  position: absolute;
  content: "";
  top: 60px;
  left: 5px;
  right: 5px;
  bottom: 60px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(30px);
  z-index: -1;
}
@media (min-width: 480px) {
  .signup-section-02::after {
    left: 20px;
    right: 20px;
  }
}
@media (min-width: 992px) {
  .signup-section-02::after {
    background: unset;
    backdrop-filter: unset;
  }
}

.signup-form-02 {
  padding: 60px 0;
}
.signup-form-02__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .signup-form-02__title {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .signup-form-02__title {
    margin-bottom: 60px;
  }
}
.signup-form-02 .from__input-group {
  margin-bottom: 20px;
}
.signup-form-02 .from__input-group--label {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 15px;
  display: block;
}
@media (min-width: 992px) {
  .signup-form-02 .from__input-group--label {
    font-size: 20px;
  }
}
.signup-form-02 .from__input-group--input {
  height: 50px;
  width: 100%;
  padding: 0 30px;
  border: 0.25px solid rgba(43, 89, 255, 0.3);
  border-radius: 10px;
}
.signup-form-02 .from__input-group--input:focus-visible {
  outline: none;
}
.signup-form-02 .from__input-group--input::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
@media (min-width: 480px) {
  .signup-form-02 .from__input-group--input {
    height: 60px;
  }
}
.signup-form-02 .from__pass-box {
  position: relative;
}
.signup-form-02 .from__pass-box--input {
  padding-right: 60px;
}
.signup-form-02 .from__pass-box--input::placeholder {
  position: absolute;
  left: 30px;
  top: 60%;
  transform: translateY(-60%);
}
.signup-form-02 .from__pass-box--eye {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.signup-form-02 .from__check--title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 11px;
  display: block;
}
@media (min-width: 420px) {
  .signup-form-02 .from__check--title {
    font-size: 20px;
  }
}
.signup-form-02 .from__check .form-check-input:checked {
  background-color: #FD4C5C !important;
  border-color: #FD4C5C !important;
}
.signup-form-02 .from__check .form-check-input:focus {
  box-shadow: none;
}
.signup-form-02 .from__check .form-check-label {
  padding-left: 8px;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 11, 51, 0.6);
}
.signup-form-02 .from__button-group--one {
  margin-top: 30px;
}
.signup-form-02 .from__button-group--one .btn {
  min-width: 215px;
}
.signup-form-02 .from__button-group--two {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 420px) {
  .signup-form-02 .from__button-group--two {
    align-items: center;
    flex-direction: row;
  }
}
.signup-form-02 .from__button-group--two .account-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  transition: 0.3s;
}
.signup-form-02 .from__button-group--two .account-btn:hover {
  text-decoration: underline;
}
@media (min-width: 420px) {
  .signup-form-02 .from__button-group--two .account-btn {
    font-size: 20px;
  }
}
.signup-form-02 .from__button-group--two .createanaccout-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  text-decoration-line: underline;
  color: #FD4C5C;
  margin-top: 10px;
}
@media (min-width: 420px) {
  .signup-form-02 .from__button-group--two .createanaccout-btn {
    font-size: 20px;
    margin-top: 0px;
    margin-left: 10px;
  }
}

/* Sign Up 2 Css Start */
/* Sign Up 2 Css End */
.reset-section-01 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  min-height: 100vh;
}
@media (min-width: 768px) {
  .reset-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .reset-section-01 {
    padding: 100px 0;
  }
}
.reset-section-01__logo {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .reset-section-01__logo {
    margin-bottom: 100px;
  }
}

@media (min-width: 420px) {
  .reset-form-01 {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    padding: 50px 25px;
  }
}
@media (min-width: 576px) {
  .reset-form-01 {
    padding: 60px 45px;
  }
}
@media (min-width: 992px) {
  .reset-form-01 {
    padding: 80px 70px;
  }
}
@media (min-width: 1400px) {
  .reset-form-01 {
    padding: 100px 110px;
  }
}
.reset-form-01__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .reset-form-01__title {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .reset-form-01__title {
    margin-bottom: 60px;
  }
}
.reset-form-01 .from__input-group {
  margin-bottom: 20px;
}
.reset-form-01 .from__input-group--label {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 15px;
  display: block;
}
@media (min-width: 992px) {
  .reset-form-01 .from__input-group--label {
    font-size: 20px;
  }
}
.reset-form-01 .from__input-group--input {
  height: 50px;
  width: 100%;
  padding: 0 30px;
  border: 0.25px solid rgba(43, 89, 255, 0.3);
  border-radius: 10px;
}
.reset-form-01 .from__input-group--input:focus-visible {
  outline: none;
}
.reset-form-01 .from__input-group--input::placeholder {
  color: rgba(0, 11, 51, 0.5);
}
@media (min-width: 480px) {
  .reset-form-01 .from__input-group--input {
    height: 60px;
  }
}
.reset-form-01 .from__button-group--one {
  margin-top: 10px;
}
.reset-form-01 .from__button-group--one .btn {
  width: 100%;
}
.reset-form-01 .from__button-group--two {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .reset-form-01 .from__button-group--two {
    flex-direction: row;
  }
}
.reset-form-01 .from__button-group--two .account-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  transition: 0.3s;
}
.reset-form-01 .from__button-group--two .account-btn:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .reset-form-01 .from__button-group--two .account-btn {
    font-size: 20px;
  }
}
.reset-form-01 .from__button-group--two .createanaccout-btn {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  text-decoration-line: underline;
  color: #FD4C5C;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .reset-form-01 .from__button-group--two .createanaccout-btn {
    font-size: 20px;
    margin-top: 0px;
    margin-left: 10px;
  }
}

/* Reset Password Css Start */
/* Reset Password Css End */
.comingsoom-section-01 {
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 60px 0;
  min-height: 100vh;
}
@media (min-width: 768px) {
  .comingsoom-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-01 {
    padding: 100px 0;
  }
}
.comingsoom-section-01__logo {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .comingsoom-section-01__logo {
    margin-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-01__logo {
    margin-bottom: 100px;
  }
}
.comingsoom-section-01__content--title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .comingsoom-section-01__content--title {
    margin-bottom: 60px;
  }
}
.comingsoom-section-01__content--counter {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .comingsoom-section-01__content--counter {
    margin-bottom: 80px;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
.comingsoom-section-01__content--counter .wrapper {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
}
@media (min-width: 411px) {
  .comingsoom-section-01__content--counter .wrapper {
    margin: 15px 20px;
  }
}
@media (min-width: 444px) {
  .comingsoom-section-01__content--counter .wrapper {
    margin: 15px 25px;
  }
}
@media (min-width: 474px) {
  .comingsoom-section-01__content--counter .wrapper {
    margin: 15px 30px;
  }
}
@media (min-width: 504px) {
  .comingsoom-section-01__content--counter .wrapper {
    margin: 15px 45px;
  }
}
@media (min-width: 576px) {
  .comingsoom-section-01__content--counter .wrapper {
    margin: 0 15px;
  }
}
@media (min-width: 768px) {
  .comingsoom-section-01__content--counter .wrapper {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-01__content--counter .wrapper {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
  }
}
.comingsoom-section-01__content--counter .wrapper .time {
  font-weight: 700 !important;
  color: #fff;
  line-height: 1.0972222222;
  letter-spacing: -0.5px;
  font-size: 36px;
}
@media (min-width: 768px) {
  .comingsoom-section-01__content--counter .wrapper .time {
    font-size: 41px;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-01__content--counter .wrapper .time {
    font-size: 54px;
  }
}
@media (min-width: 1200px) {
  .comingsoom-section-01__content--counter .wrapper .time {
    font-size: 62px;
  }
}
@media (min-width: 1600px) {
  .comingsoom-section-01__content--counter .wrapper .time {
    font-size: 72px;
  }
}
.comingsoom-section-01__content--counter .wrapper .label {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: #fff;
}
@media (min-width: 768px) {
  .comingsoom-section-01__content--counter .wrapper .label {
    font-size: 20px;
  }
}
.comingsoom-section-01__content--notify-form {
  margin: auto;
  padding: 0 20px;
}
@media (min-width: 576px) {
  .comingsoom-section-01__content--notify-form {
    width: 450px;
  }
}
@media (min-width: 768px) {
  .comingsoom-section-01__content--notify-form {
    width: 526px;
  }
}
.comingsoom-section-01__content--notify-form .form-group {
  position: relative;
}
.comingsoom-section-01__content--notify-form .form-group .form-control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
  padding: 0 30px;
  padding-right: 55px;
  color: white;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  background: transparent;
}
.comingsoom-section-01__content--notify-form .form-group .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
@media (min-width: 420px) {
  .comingsoom-section-01__content--notify-form .form-group .form-control {
    padding-right: 30px;
  }
}
.comingsoom-section-01__content--notify-form .form-group .arrow {
  position: absolute;
  top: 0;
  right: 13px;
  padding: 18px;
  cursor: pointer;
}
.comingsoom-section-01__content--social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 0 -15px;
}
.comingsoom-section-01__content--social li a {
  color: white;
  padding: 0 15px;
  transition: 0.3s;
}
.comingsoom-section-01__content--social li a:hover {
  color: #FD4C5C;
}
.comingsoom-section-01__shape-1 {
  position: absolute;
  left: 0%;
  top: 22%;
  width: 3%;
}
.comingsoom-section-01__shape-2 {
  position: absolute;
  left: 12%;
  top: 5%;
  width: 3%;
}
.comingsoom-section-01__shape-3 {
  position: absolute;
  right: 0%;
  top: 8%;
  width: 6.5%;
}
.comingsoom-section-01__shape-4 {
  position: absolute;
  right: 5%;
  bottom: 18%;
  width: 2%;
}

/* Coming Somm 1 Css Start*/
/* Coming Somm 1 Css End*/
.comingsoom-section-02 {
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  padding: 60px 0 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  min-height: 100vh;
}
@media (min-width: 768px) {
  .comingsoom-section-02 {
    padding: 80px 0 200px;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-02 {
    padding: 100px 0 266px;
  }
}
.comingsoom-section-02__shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
@media (min-width: 768px) {
  .comingsoom-section-02__shape {
    bottom: 0%;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-02__shape {
    bottom: -6%;
  }
}
.comingsoom-section-02__logo {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .comingsoom-section-02__logo {
    margin-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-02__logo {
    margin-bottom: 100px;
  }
}
.comingsoom-section-02__content--title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .comingsoom-section-02__content--title {
    margin-bottom: 60px;
  }
}
.comingsoom-section-02__content--counter {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  flex-direction: column;
}
@media (min-width: 576px) {
  .comingsoom-section-02__content--counter {
    flex-direction: row;
    margin-bottom: 47px;
  }
}
.comingsoom-section-02__content--counter > [class^=col-] {
  position: relative;
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .comingsoom-section-02__content--counter > [class^=col-] {
    margin-bottom: 0px;
    padding: 0 25px;
  }
  .comingsoom-section-02__content--counter > [class^=col-]::before {
    content: ":";
    position: absolute;
    top: 50%;
    right: -14px;
    transform: translateY(-62%);
    color: white;
    font-size: 76px;
  }
}
.comingsoom-section-02__content--counter > [class^=col-]:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .comingsoom-section-02__content--counter > [class^=col-] {
    padding: 0 45px;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-02__content--counter > [class^=col-] {
    padding: 0 55px;
  }
}
.comingsoom-section-02__content--counter > [class^=col-]:first-child {
  padding-left: 0;
}
.comingsoom-section-02__content--counter > [class^=col-]:last-child {
  padding-right: 0;
}
.comingsoom-section-02__content--counter > [class^=col-]:last-child::before {
  display: none;
}
.comingsoom-section-02__content--counter > [class^=col-]:nth-child(1)::before {
  color: #FFC83E;
}
.comingsoom-section-02__content--counter > [class^=col-]:nth-child(2)::before {
  color: #FD4C5C;
}
.comingsoom-section-02__content--counter > [class^=col-]:nth-child(3)::before {
  color: #00BB98;
}
.comingsoom-section-02__content--counter .wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.comingsoom-section-02__content--counter .wrapper .time {
  color: white;
  font-size: 66px;
  letter-spacing: -0.5px;
  line-height: 1.1;
  font-weight: 700;
}
@media (min-width: 576px) {
  .comingsoom-section-02__content--counter .wrapper .time {
    font-size: 76px;
  }
}
@media (min-width: 768px) {
  .comingsoom-section-02__content--counter .wrapper .time {
    font-size: 86px;
  }
}
@media (min-width: 992px) {
  .comingsoom-section-02__content--counter .wrapper .time {
    font-size: 96px;
  }
}
.comingsoom-section-02__content--counter .wrapper .label {
  color: white;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}
.comingsoom-section-02__content--social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
}
.comingsoom-section-02__content--social li {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 0 15px;
  transition: 0.3s;
}
.comingsoom-section-02__content--social li:hover a {
  color: #FD4C5C;
}
.comingsoom-section-02__content--social li a {
  color: #000B33;
  display: block;
  transition: 0.3s;
}
.comingsoom-section-02__shape-1 {
  position: absolute;
  left: 0%;
  top: 22%;
  width: 3%;
}
.comingsoom-section-02__shape-2 {
  position: absolute;
  left: 12%;
  top: 5%;
  width: 3%;
}
.comingsoom-section-02__shape-3 {
  position: absolute;
  right: 0%;
  top: 8%;
  width: 6.5%;
}
.comingsoom-section-02__shape-4 {
  position: absolute;
  right: 5%;
  bottom: 18%;
  width: 2%;
}

/* Coming Somm 2 Css Start*/
/* Coming Somm 2 Css End*/
/* Landing One Header Button */
.site-header__btns--404 .btn {
  height: 45px;
}
.site-header__btns--404 .btn-1 {
  color: #000B33;
}
@media (min-width: 992px) {
  .site-header__btns--404 .btn-1 {
    margin-right: 20px;
    min-width: 80px;
  }
}
.site-header__btns--404 .btn-2 {
  min-width: 120px;
  color: #fff;
  background-color: #FD4C5C !important;
  border-color: #FD4C5C !important;
}
@media (min-width: 992px) {
  .site-header__btns--404 .btn-2 {
    min-width: 120px;
  }
}
@media (max-width: 992px) {
  .site-header__btns--404 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.error-section {
  padding: 60px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .error-section {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .error-section {
    padding: 100px 0;
  }
}
@media (min-width: 1200px) {
  .error-section {
    padding: 130px 0;
  }
}
.error-section__content .title {
  padding-bottom: 16px;
}
@media (min-width: 1200px) {
  .error-section__content .title {
    padding-bottom: 30px;
  }
}
.error-section__content .btn {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .error-section__content .btn {
    margin-top: 40px;
    min-width: 192px;
  }
}

/* Header Css Start */
/* Header Css End */
/* Error Css Start */
/* Error Css End */
/*
    Note: This section is similar to landing 4 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/
/* duplicated code starts*/
/*.pricing-section-02{
  background: rgb(43, 89, 255,0.02);
  padding: 56px 0;
  @media (min-width:768px) {
    padding: 76px 0;
  }
  @media (min-width:992px) {
    padding: 125px 0;

  }
  &__title{
    .subtitle{
      margin-bottom: 20px;
    }
    margin-bottom: 35px;
    @media (min-width:768px) {
      margin-bottom: 80px;
    }
  }
  .pricing-sider{
    .slick-slide{
      margin: 0 12px;
    }
  }
}*/
/*duplicated code ends*/
.pricing-section-02--3 {
  background: rgba(55, 124, 253, 0.02) !important;
}
.pricing-section-02--3 .section-title .subtitle {
  color: #00BB98 !important;
}
.pricing-section-02--3 .card-pricing--02__button .btn-primary-outline {
  border-radius: 3px !important;
  border-color: #FD4C5C !important;
  color: #FD4C5C !important;
}
.pricing-section-02--3 .card-pricing--02__button .btn-primary-outline:hover {
  color: #fff !important;
}
.pricing-section-02--3 .card-pricing--02__button .btn-primary-outline:before {
  background: #FD4C5C !important;
}
.pricing-section-02--3 .card-pricing--02__button .btn-primary-outline.active {
  border-color: #FD4C5C !important;
  color: #fff !important;
  background: #FD4C5C !important;
}
.pricing-section-02--3 .card-pricing--02__button .btn-primary-outline.active:before {
  background: #FD4C5C !important;
}
.pricing-section-02--3 .card-pricing--02__button .btn-primary-outline-reverse:before {
  background: #FD4C5C !important;
}
.pricing-section-02--3 .card-pricing--02__button .btn-primary-outline-reverse:hover {
  border-color: #FD4C5C !important;
}

/* Pricing Css Start */
.pricing-section-07 {
  background: rgba(55, 124, 253, 0.02);
  padding: 56px 0;
}
@media (min-width: 768px) {
  .pricing-section-07 {
    padding: 76px 0;
  }
}
@media (min-width: 992px) {
  .pricing-section-07 {
    padding: 125px 0;
  }
}
.pricing-section-07__title {
  margin-bottom: 30px;
}
.pricing-section-07__title .subtitle {
  margin-bottom: 20px;
}
.pricing-section-07__title .title {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .pricing-section-07__title {
    margin-bottom: 67px;
  }
}
.pricing-section-07__button {
  display: flex;
  justify-content: center;
}
.pricing-section-07__button .btn-toggle__wrapper {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}
.pricing-section-07__button--text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.pricing-section-07 .col-lg-4:first-child .card-pricing--06__list li:last-child {
  display: none;
}
.pricing-section-07 .col-lg-4:last-child .card-pricing--06__list li:last-child {
  display: none;
}
.pricing-section-07 .pricing-sider .slick-slide {
  margin: 0 12px;
}

/* Pricing Css Start */
.contactform-section-01 {
  padding: 60px 0;
}
@media (min-width: 576px) {
  .contactform-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 576px) {
  .contactform-section-01 {
    padding: 130px 0;
  }
}
.contactform-section-01__sidebar {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
}
.contactform-section-01__sidebar .title {
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  border-radius: 10px 10px 0px 0px;
  padding: 25px 40px;
  padding-right: 20px;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.3;
  color: white !important;
}
@media (min-width: 576px) {
  .contactform-section-01__sidebar .title {
    font-size: 30px;
  }
}
.contactform-section-01__sidebar--body {
  padding: 50px 40px;
}
.contactform-section-01__sidebar--body .address {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(55, 124, 253, 0.1);
}
.contactform-section-01__sidebar--body .address--title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
}
@media (min-width: 420px) {
  .contactform-section-01__sidebar--body .address--title {
    font-size: 20px;
  }
}
.contactform-section-01__sidebar--body .address__box {
  display: flex;
  align-items: center;
}
.contactform-section-01__sidebar--body .address__box .icon {
  margin-right: 15px;
}
.contactform-section-01__sidebar--body .address__box span {
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #000B33;
}
.contactform-section-01__sidebar--body .info {
  padding-top: 30px;
}
.contactform-section-01__sidebar--body .info--title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
}
@media (min-width: 420px) {
  .contactform-section-01__sidebar--body .info--title {
    font-size: 20px;
  }
}
.contactform-section-01__sidebar--body .info__box--lists {
  list-style: none;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}
.contactform-section-01__sidebar--body .info__box--lists i {
  color: #FD4C5C;
  margin-right: 15px;
}
.contactform-section-01__sidebar--body .info__box--lists:last-child {
  margin-bottom: 0;
}
.contactform-section-01__sidebar--body .info__box--lists a {
  color: #000B33 !important;
}

/* contact form css */
.contact-form-01 {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  padding: 20px;
}
@media (min-width: 576px) {
  .contact-form-01 {
    padding: 40px;
  }
}
.contact-form-01--inpute-group .form-control {
  border: 0;
  background: rgba(55, 124, 253, 0.05);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 20px;
  color: #000b33;
  height: 55px;
}
.contact-form-01--inpute-group .form-control::placeholder {
  color: rgba(0, 11, 51, 0.8);
}
.contact-form-01 textarea {
  padding: 30px;
  border: 0;
  background: rgba(55, 124, 253, 0.05);
  border-radius: 5px;
  height: 150px;
  width: 100%;
}
.contact-form-01 textarea:focus {
  outline: none;
}
.contact-form-01 .btn {
  margin-top: 40px;
  min-width: 150px;
}

.contactmap-section__map {
  position: relative;
}
.contactmap-section__map--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Contact form css Start */
/* Contact form css End */
/* Contact map css Start */
/* Contact map css End */
.contactform-section-02 {
  background: rgba(55, 124, 253, 0.02);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .contactform-section-02 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .contactform-section-02 {
    padding: 130px 0;
  }
}
.contactform-section-02__map-form {
  margin-top: 36px;
}
@media (min-width: 768px) {
  .contactform-section-02__map-form {
    margin-top: 76px;
  }
}
.contactform-section-02__map {
  position: relative;
}
.contactform-section-02__map img {
  border-radius: 10px;
}
.contactform-section-02__map--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Contact card css start */
.contact-card {
  margin-bottom: 24px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  padding: 50px 0;
}
.contact-card__iocn {
  width: 60px;
  height: 60px;
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  margin: auto;
}
.contact-card__iocn i {
  color: white;
}
.contact-card__body {
  margin-top: 20px;
}
.contact-card__body p {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #000B33;
}

/* contact form css */
.contact-form-02 {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
  border-radius: 10px;
  padding: 20px;
}
@media (min-width: 576px) {
  .contact-form-02 {
    padding: 34px 40px;
  }
}
.contact-form-02--inpute-group .form-control {
  border: 0;
  background: rgba(55, 124, 253, 0.05);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 20px;
  color: #000b33;
  height: 55px;
}
.contact-form-02--inpute-group .form-control::placeholder {
  color: rgba(0, 11, 51, 0.8);
}
.contact-form-02 textarea {
  padding: 30px;
  border: 0;
  background: rgba(55, 124, 253, 0.05);
  border-radius: 5px;
  height: 150px;
  width: 100%;
}
.contact-form-02 textarea:focus {
  outline: none;
}
.contact-form-02 .btn {
  margin-top: 40px;
  min-width: 150px;
}

/* Contact Content Start */
/* Contact Content End */
.termscontent-section {
  padding: 60px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .termscontent-section {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .termscontent-section {
    padding: 130px 0;
  }
}
@media (min-width: 420px) {
  .termscontent-section__content {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    padding: 30px 60px;
  }
}
@media (min-width: 768px) {
  .termscontent-section__content {
    padding: 60px 110px;
  }
}
.termscontent-section__content--texts {
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .termscontent-section__content--texts {
    margin-bottom: 40px;
  }
}
.termscontent-section__content--texts:last-child {
  margin-bottom: 0;
}
.termscontent-section__content--texts .title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
}
@media (min-width: 576px) {
  .termscontent-section__content--texts .title {
    font-size: 30px;
  }
}
.termscontent-section__content--texts p {
  font-size: 16px;
  line-height: 26px;
}

/* Content Css Start */
/* Content Css End */
.protfoliocontent-section-01 {
  background: rgba(55, 124, 253, 0.02);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .protfoliocontent-section-01 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .protfoliocontent-section-01 {
    padding: 130px 0;
  }
}
.protfoliocontent-section-01__image-group--single {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .protfoliocontent-section-01__image-group--single:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .protfoliocontent-section-01__image-group--single {
    margin-bottom: 60px;
  }
}
.protfoliocontent-section-01__image-group--single img {
  border-radius: 10px;
}
.protfoliocontent-section-01__next-prev-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .protfoliocontent-section-01__next-prev-button {
    margin-top: 60px;
  }
}
.protfoliocontent-section-01__next-prev-button .prev-button, .protfoliocontent-section-01__next-prev-button .next-button {
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.protfoliocontent-section-01__next-prev-button .prev-button:hover i, .protfoliocontent-section-01__next-prev-button .next-button:hover i {
  color: #FD4C5C;
}
.protfoliocontent-section-01__next-prev-button .prev-button:hover p, .protfoliocontent-section-01__next-prev-button .next-button:hover p {
  color: #FD4C5C;
}
.protfoliocontent-section-01__next-prev-button .prev-button i, .protfoliocontent-section-01__next-prev-button .next-button i {
  color: #000B33;
  font-size: 18px;
  transition: 0.3s;
}
.protfoliocontent-section-01__next-prev-button .prev-button p, .protfoliocontent-section-01__next-prev-button .next-button p {
  color: #000B33;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
}

/* protfoliocontent-card css */
@media (min-width: 420px) {
  .protfoliocontent-card--01 {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    padding: 30px 20px;
  }
}
@media (min-width: 576px) {
  .protfoliocontent-card--01 {
    padding: 30px;
  }
}
@media (min-width: 768px) {
  .protfoliocontent-card--01 {
    padding: 60px;
  }
}
.protfoliocontent-card--01 .title {
  font-size: 24px;
  line-height: 1.4;
}
@media (min-width: 576px) {
  .protfoliocontent-card--01 .title {
    font-size: 30px;
  }
}
.protfoliocontent-card--01 .text {
  font-size: 16px;
  line-height: 1.4444444444;
}
@media (min-width: 576px) {
  .protfoliocontent-card--01 .text {
    font-size: 18px;
  }
}
.protfoliocontent-card--01__list {
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .protfoliocontent-card--01__list {
    margin-top: 50px;
  }
}
.protfoliocontent-card--01__list .list-item {
  list-style: none;
  position: relative;
  padding-left: 28px;
  margin-bottom: 20px;
}
.protfoliocontent-card--01__list .list-item::before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 12px;
  height: 12px;
  background: #377CFD;
  border-radius: 500px;
}
.protfoliocontent-card--01__list .list-item:last-child {
  margin-bottom: 0;
}
.protfoliocontent-card--01__list .list-item h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 20px;
}
@media (min-width: 420px) {
  .protfoliocontent-card--01__list .list-item h6 {
    font-size: 20px;
  }
}
.protfoliocontent-card--01__list .list-item a,
.protfoliocontent-card--01__list .list-item span {
  font-size: 16px;
  line-height: 1.4444444444;
  color: rgba(0, 11, 51, 0.8);
}
@media (min-width: 420px) {
  .protfoliocontent-card--01__list .list-item a,
.protfoliocontent-card--01__list .list-item span {
    font-size: 18px;
  }
}
.protfoliocontent-card--01__list .list-item .social {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  margin-top: 10px;
}
.protfoliocontent-card--01__list .list-item .social li {
  list-style: none;
  margin: 0 12px;
}
.protfoliocontent-card--01__list .list-item .social li a {
  color: rgba(0, 11, 51, 0.8);
  transition: 0.3s;
}
.protfoliocontent-card--01__list .list-item .social li a:hover {
  color: rgba(253, 76, 92, 0.8);
}

/* Protfolio Content css Start */
/* Protfolio Content css End */
.protfoliocontent-section-02 {
  background: rgba(55, 124, 253, 0.02);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .protfoliocontent-section-02 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .protfoliocontent-section-02 {
    padding: 130px 0;
  }
}
.protfoliocontent-section-02__slider {
  position: relative;
}
.protfoliocontent-section-02__slider--single img {
  border-radius: 10px;
}
.protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons {
  display: none;
}
@media (min-width: 768px) {
  .protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons {
    display: block;
  }
}
.protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons .button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: #FD4C5C;
  border-radius: 5px;
  border: 2px solid transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons .button svg {
  fill: white;
}
.protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons .button:hover {
  background: transparent;
  border-color: #FFFFFF;
}
.protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons .button--prev {
  left: 20px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons .button--prev {
    left: 40px;
  }
}
.protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons .button--next {
  right: 20px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .protfoliocontent-section-02__slider .portfolio-details-slider--01__control-buttons .button--next {
    right: 40px;
  }
}
.protfoliocontent-section-02__next-prev-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
@media (min-width: 768px) {
  .protfoliocontent-section-02__next-prev-button {
    margin-top: 40px;
  }
}
.protfoliocontent-section-02__next-prev-button .prev-button, .protfoliocontent-section-02__next-prev-button .next-button {
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.protfoliocontent-section-02__next-prev-button .prev-button:hover i, .protfoliocontent-section-02__next-prev-button .next-button:hover i {
  color: #FD4C5C;
}
.protfoliocontent-section-02__next-prev-button .prev-button:hover p, .protfoliocontent-section-02__next-prev-button .next-button:hover p {
  color: #FD4C5C;
}
.protfoliocontent-section-02__next-prev-button .prev-button i, .protfoliocontent-section-02__next-prev-button .next-button i {
  color: #000B33;
  font-size: 18px;
  transition: 0.3s;
}
.protfoliocontent-section-02__next-prev-button .prev-button p, .protfoliocontent-section-02__next-prev-button .next-button p {
  color: #000B33;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
}

/* protfoliocontent-card css */
.protfoliocontent-card--02 {
  margin-top: 60px;
}
@media (min-width: 420px) {
  .protfoliocontent-card--02 {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    padding: 30px 20px;
  }
}
@media (min-width: 576px) {
  .protfoliocontent-card--02 {
    padding: 30px;
  }
}
@media (min-width: 768px) {
  .protfoliocontent-card--02 {
    padding: 60px;
  }
}
.protfoliocontent-card--02 .title {
  font-size: 24px;
  line-height: 1.4;
}
@media (min-width: 576px) {
  .protfoliocontent-card--02 .title {
    font-size: 30px;
  }
}
.protfoliocontent-card--02 .text {
  font-size: 16px;
  line-height: 1.4444444444;
}
@media (min-width: 576px) {
  .protfoliocontent-card--02 .text {
    font-size: 18px;
  }
}
.protfoliocontent-card--02__list {
  margin: 0;
  padding: 0;
}
@media (min-width: 420px) {
  .protfoliocontent-card--02__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 260px));
    gap: 0px 10px;
  }
}
@media (min-width: 576px) {
  .protfoliocontent-card--02__list {
    gap: 0px 25px;
  }
}
@media (min-width: 1200px) {
  .protfoliocontent-card--02__list {
    margin-left: 80px;
  }
}
.protfoliocontent-card--02__list .list-item {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  padding-left: 28px;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .protfoliocontent-card--02__list .list-item {
    margin-bottom: 40px;
  }
}
.protfoliocontent-card--02__list .list-item::before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 12px;
  height: 12px;
  background: #377CFD;
  border-radius: 500px;
}
.protfoliocontent-card--02__list .list-item h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 20px;
}
@media (min-width: 420px) {
  .protfoliocontent-card--02__list .list-item h6 {
    font-size: 20px;
  }
}
.protfoliocontent-card--02__list .list-item a,
.protfoliocontent-card--02__list .list-item span {
  font-size: 16px;
  line-height: 1.4444444444;
  color: rgba(0, 11, 51, 0.8);
}
@media (min-width: 420px) {
  .protfoliocontent-card--02__list .list-item a,
.protfoliocontent-card--02__list .list-item span {
    font-size: 18px;
  }
}
.protfoliocontent-card--02__list .list-item .social {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  margin-top: 10px;
}
.protfoliocontent-card--02__list .list-item .social li {
  list-style: none;
  margin: 0 12px;
}
.protfoliocontent-card--02__list .list-item .social li a {
  color: rgba(0, 11, 51, 0.8);
  transition: 0.3s;
}
.protfoliocontent-card--02__list .list-item .social li a:hover {
  color: rgba(253, 76, 92, 0.8);
}

/* Protfolio Content css Start */
/* Protfolio Content css End */
.protfoliocontent-section-03 {
  background: rgba(55, 124, 253, 0.02);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .protfoliocontent-section-03 {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .protfoliocontent-section-03 {
    padding: 130px 0;
  }
}
.protfoliocontent-section-03__slider-small {
  margin-top: 50px;
}
@media (min-width: 768px) {
  .protfoliocontent-section-03__slider-small {
    margin-top: 100px;
  }
}
.protfoliocontent-section-03__slider-small .content-title {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .protfoliocontent-section-03__slider-small .content-title {
    margin-bottom: 60px;
  }
}
.protfoliocontent-section-03__slider-for .portfolio-details-slider--for__slider--single img {
  border-radius: 10px;
}
.protfoliocontent-section-03__slider-nav {
  position: relative;
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__slider--single img {
  border-radius: 10px;
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav .slick-slide {
  margin: 0 5px;
}
@media (min-width: 576px) {
  .protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav .slick-slide {
    margin: 0 12px;
  }
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav .slick-list {
  margin: 0 -5px;
}
@media (min-width: 576px) {
  .protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav .slick-list {
    margin: 0 -12px;
  }
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons {
  display: none;
}
@media (min-width: 768px) {
  .protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons {
    display: block;
  }
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons .button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background: #FD4C5C;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons .button svg {
  fill: white;
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons .button:hover {
  background: white;
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons .button:hover svg {
  fill: #FD4C5C;
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons .button--prev {
  left: 0px;
  cursor: pointer;
}
.protfoliocontent-section-03__slider-nav .portfolio-details-slider--nav__control-buttons .button--next {
  right: 0px;
  cursor: pointer;
}
.protfoliocontent-section-03__next-prev-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
@media (min-width: 768px) {
  .protfoliocontent-section-03__next-prev-button {
    margin-top: 40px;
  }
}
.protfoliocontent-section-03__next-prev-button .prev-button, .protfoliocontent-section-03__next-prev-button .next-button {
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.protfoliocontent-section-03__next-prev-button .prev-button:hover i, .protfoliocontent-section-03__next-prev-button .next-button:hover i {
  color: #FD4C5C;
}
.protfoliocontent-section-03__next-prev-button .prev-button:hover p, .protfoliocontent-section-03__next-prev-button .next-button:hover p {
  color: #FD4C5C;
}
.protfoliocontent-section-03__next-prev-button .prev-button i, .protfoliocontent-section-03__next-prev-button .next-button i {
  color: #000B33;
  font-size: 18px;
  transition: 0.3s;
}
.protfoliocontent-section-03__next-prev-button .prev-button p, .protfoliocontent-section-03__next-prev-button .next-button p {
  color: #000B33;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;
}

/* protfoliocontent-card css */
.protfoliocontent-card--03 {
  margin-top: 60px;
}
@media (min-width: 420px) {
  .protfoliocontent-card--03 {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 10px;
    padding: 30px 20px;
  }
}
@media (min-width: 576px) {
  .protfoliocontent-card--03 {
    padding: 30px;
  }
}
@media (min-width: 768px) {
  .protfoliocontent-card--03 {
    padding: 60px;
  }
}
.protfoliocontent-card--03 .title {
  font-size: 24px;
  line-height: 1.4;
}
@media (min-width: 576px) {
  .protfoliocontent-card--03 .title {
    font-size: 30px;
  }
}
.protfoliocontent-card--03 .text {
  font-size: 16px;
  line-height: 1.4444444444;
}
@media (min-width: 576px) {
  .protfoliocontent-card--03 .text {
    font-size: 18px;
  }
}
.protfoliocontent-card--03__list {
  margin: 0;
  padding: 0;
}
@media (min-width: 420px) {
  .protfoliocontent-card--03__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 260px));
    gap: 0px 10px;
  }
}
@media (min-width: 576px) {
  .protfoliocontent-card--03__list {
    gap: 0px 25px;
  }
}
@media (min-width: 1200px) {
  .protfoliocontent-card--03__list {
    margin-left: 80px;
  }
}
.protfoliocontent-card--03__list .list-item {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  padding-left: 28px;
  margin-bottom: 20px;
}
@media (min-width: 576px) {
  .protfoliocontent-card--03__list .list-item {
    margin-bottom: 40px;
  }
}
.protfoliocontent-card--03__list .list-item::before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 12px;
  height: 12px;
  background: #377CFD;
  border-radius: 500px;
}
.protfoliocontent-card--03__list .list-item h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 20px;
}
@media (min-width: 420px) {
  .protfoliocontent-card--03__list .list-item h6 {
    font-size: 20px;
  }
}
.protfoliocontent-card--03__list .list-item a,
.protfoliocontent-card--03__list .list-item span {
  font-size: 16px;
  line-height: 1.4444444444;
  color: rgba(0, 11, 51, 0.8);
}
@media (min-width: 420px) {
  .protfoliocontent-card--03__list .list-item a,
.protfoliocontent-card--03__list .list-item span {
    font-size: 18px;
  }
}
.protfoliocontent-card--03__list .list-item .social {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  margin-top: 10px;
}
.protfoliocontent-card--03__list .list-item .social li {
  list-style: none;
  margin: 0 12px;
}
.protfoliocontent-card--03__list .list-item .social li a {
  color: rgba(0, 11, 51, 0.8);
  transition: 0.3s;
}
.protfoliocontent-card--03__list .list-item .social li a:hover {
  color: rgba(253, 76, 92, 0.8);
}

/* Protfolio Content css Start */
/* Protfolio Content css End */
.portfoliogrid-section-01 {
  padding: 36px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .portfoliogrid-section-01 {
    padding: 56px 0;
  }
}
@media (min-width: 992px) {
  .portfoliogrid-section-01 {
    padding: 106px 0;
  }
}
.portfoliogrid-section-01__tab {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .portfoliogrid-section-01__tab {
    margin-bottom: 60px;
  }
}
.portfoliogrid-section-01__tab .btn {
  margin: 0 10px;
  margin-bottom: 10px;
  list-style: none;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FD4C5C;
  border: 2px solid #FD4C5C;
  background: transparent;
  border-radius: 3px;
  transition: 0.3s;
}
.portfoliogrid-section-01__tab .btn:last-child {
  margin-right: 0;
}
.portfoliogrid-section-01__tab .btn:first-child {
  margin-left: 0;
}
@media (min-width: 992px) {
  .portfoliogrid-section-01__tab .btn {
    margin-bottom: 0px;
    padding: 13px 30px;
    margin: 0 20px;
  }
}
.portfoliogrid-section-01__tab .btn.active {
  background: #FD4C5C;
  color: white;
}
.portfoliogrid-section-01 .portfolio-masonry--single {
  position: relative;
  display: block;
}
.portfoliogrid-section-01 .portfolio-masonry--single img {
  border-radius: 10px;
}
.portfoliogrid-section-01 .portfolio-masonry--single .icon {
  position: absolute;
  top: 30px;
  right: 30px;
  visibility: hidden;
}
.portfoliogrid-section-01 .portfolio-masonry--single .content {
  visibility: hidden;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.portfoliogrid-section-01 .portfolio-masonry--single .content h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 5px;
  color: white !important;
}
@media (min-width: 420px) {
  .portfoliogrid-section-01 .portfolio-masonry--single .content h6 {
    fon: 20px;
  }
}
.portfoliogrid-section-01 .portfolio-masonry--single .content span {
  font-size: 16px;
  line-height: 26px;
  color: white !important;
}
.portfoliogrid-section-01 .portfolio-masonry--single::before {
  position: absolute;
  content: "";
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  height: 100%;
  width: 0%;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
}
.portfoliogrid-section-01 .portfolio-masonry--single:hover::before {
  opacity: 0.7;
  visibility: visible;
  width: 100%;
}
.portfoliogrid-section-01 .portfolio-masonry--single:hover .icon {
  visibility: visible;
}
.portfoliogrid-section-01 .portfolio-masonry--single:hover .content {
  visibility: visible;
}

/* Protfolio Masonry Content css Start */
/* Protfolio Masonry Content css End */
.portfoliogrid-section-02 {
  padding: 36px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .portfoliogrid-section-02 {
    padding: 56px 0;
  }
}
@media (min-width: 992px) {
  .portfoliogrid-section-02 {
    padding: 106px 0;
  }
}
.portfoliogrid-section-02__tab {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .portfoliogrid-section-02__tab {
    margin-bottom: 60px;
  }
}
.portfoliogrid-section-02__tab .btn {
  margin: 0 10px;
  margin-bottom: 10px;
  list-style: none;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FD4C5C;
  border: 2px solid #FD4C5C;
  background: transparent;
  border-radius: 3px;
  transition: 0.3s;
}
.portfoliogrid-section-02__tab .btn:last-child {
  margin-right: 0;
}
.portfoliogrid-section-02__tab .btn:first-child {
  margin-left: 0;
}
@media (min-width: 992px) {
  .portfoliogrid-section-02__tab .btn {
    margin-bottom: 0px;
    padding: 13px 30px;
    margin: 0 20px;
  }
}
.portfoliogrid-section-02__tab .btn.active {
  background: #FD4C5C;
  color: white;
}
.portfoliogrid-section-02 .portfolio-masonry--single {
  position: relative;
  display: block;
}
.portfoliogrid-section-02 .portfolio-masonry--single img {
  border-radius: 10px;
}
.portfoliogrid-section-02 .portfolio-masonry--single .icon {
  position: absolute;
  top: 30px;
  right: 30px;
  visibility: hidden;
}
.portfoliogrid-section-02 .portfolio-masonry--single .content {
  visibility: hidden;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.portfoliogrid-section-02 .portfolio-masonry--single .content h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 5px;
  color: white !important;
}
@media (min-width: 420px) {
  .portfoliogrid-section-02 .portfolio-masonry--single .content h6 {
    fon: 20px;
  }
}
.portfoliogrid-section-02 .portfolio-masonry--single .content span {
  font-size: 16px;
  line-height: 26px;
  color: white !important;
}
.portfoliogrid-section-02 .portfolio-masonry--single::before {
  position: absolute;
  content: "";
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  height: 100%;
  width: 0%;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
}
.portfoliogrid-section-02 .portfolio-masonry--single:hover::before {
  opacity: 0.7;
  visibility: visible;
  width: 100%;
}
.portfoliogrid-section-02 .portfolio-masonry--single:hover .icon {
  visibility: visible;
}
.portfoliogrid-section-02 .portfolio-masonry--single:hover .content {
  visibility: visible;
}

/* Protfolio Masonry Content css Start */
/* Protfolio Masonry Content css End */
.portfoliogrid-section-03 {
  padding: 36px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .portfoliogrid-section-03 {
    padding: 56px 0;
  }
}
@media (min-width: 992px) {
  .portfoliogrid-section-03 {
    padding: 106px 0;
  }
}
.portfoliogrid-section-03__tab {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .portfoliogrid-section-03__tab {
    margin-bottom: 60px;
  }
}
.portfoliogrid-section-03__tab .btn {
  margin: 0 10px;
  margin-bottom: 10px;
  list-style: none;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FD4C5C;
  border: 2px solid #FD4C5C;
  background: transparent;
  border-radius: 3px;
  transition: 0.3s;
}
.portfoliogrid-section-03__tab .btn:last-child {
  margin-right: 0;
}
.portfoliogrid-section-03__tab .btn:first-child {
  margin-left: 0;
}
@media (min-width: 992px) {
  .portfoliogrid-section-03__tab .btn {
    margin-bottom: 0px;
    padding: 13px 30px;
    margin: 0 20px;
  }
}
.portfoliogrid-section-03__tab .btn.active {
  background: #FD4C5C;
  color: white;
}
.portfoliogrid-section-03 .portfolio-masonry--single {
  position: relative;
  display: block;
}
.portfoliogrid-section-03 .portfolio-masonry--single .icon {
  position: absolute;
  top: 30px;
  right: 30px;
  visibility: hidden;
}
.portfoliogrid-section-03 .portfolio-masonry--single .content {
  visibility: hidden;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.portfoliogrid-section-03 .portfolio-masonry--single .content h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 5px;
  color: white !important;
}
@media (min-width: 420px) {
  .portfoliogrid-section-03 .portfolio-masonry--single .content h6 {
    fon: 20px;
  }
}
.portfoliogrid-section-03 .portfolio-masonry--single .content span {
  font-size: 16px;
  line-height: 26px;
  color: white !important;
}
.portfoliogrid-section-03 .portfolio-masonry--single::before {
  position: absolute;
  content: "";
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  height: 100%;
  width: 0%;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.portfoliogrid-section-03 .portfolio-masonry--single:hover::before {
  opacity: 0.7;
  visibility: visible;
  width: 100%;
}
.portfoliogrid-section-03 .portfolio-masonry--single:hover .icon {
  visibility: visible;
}
.portfoliogrid-section-03 .portfolio-masonry--single:hover .content {
  visibility: visible;
}

/* Protfolio Masonry Content css Start */
/* Protfolio Masonry Content css End */
.portfoliogrid-section-04 {
  padding: 36px 0;
  background: rgba(55, 124, 253, 0.02);
}
@media (min-width: 768px) {
  .portfoliogrid-section-04 {
    padding: 56px 0;
  }
}
@media (min-width: 992px) {
  .portfoliogrid-section-04 {
    padding: 106px 0;
  }
}
.portfoliogrid-section-04__tab {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .portfoliogrid-section-04__tab {
    margin-bottom: 60px;
  }
}
.portfoliogrid-section-04__tab .btn {
  margin: 0 10px;
  margin-bottom: 10px;
  list-style: none;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FD4C5C;
  border: 2px solid #FD4C5C;
  background: transparent;
  border-radius: 3px;
  transition: 0.3s;
}
.portfoliogrid-section-04__tab .btn:last-child {
  margin-right: 0;
}
.portfoliogrid-section-04__tab .btn:first-child {
  margin-left: 0;
}
@media (min-width: 992px) {
  .portfoliogrid-section-04__tab .btn {
    margin-bottom: 0px;
    padding: 13px 30px;
    margin: 0 20px;
  }
}
.portfoliogrid-section-04__tab .btn.active {
  background: #FD4C5C;
  color: white;
}
.portfoliogrid-section-04 .portfolio-masonry--single {
  position: relative;
  display: block;
}
.portfoliogrid-section-04 .portfolio-masonry--single img {
  border-radius: 10px;
}
.portfoliogrid-section-04 .portfolio-masonry--single .icon {
  position: absolute;
  top: 30px;
  right: 30px;
  visibility: hidden;
}
.portfoliogrid-section-04 .portfolio-masonry--single .content {
  visibility: hidden;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.portfoliogrid-section-04 .portfolio-masonry--single .content h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  padding-bottom: 5px;
  color: white !important;
}
@media (min-width: 420px) {
  .portfoliogrid-section-04 .portfolio-masonry--single .content h6 {
    fon: 20px;
  }
}
.portfoliogrid-section-04 .portfolio-masonry--single .content span {
  font-size: 16px;
  line-height: 26px;
  color: white !important;
}
.portfoliogrid-section-04 .portfolio-masonry--single::before {
  position: absolute;
  content: "";
  background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
  height: 100%;
  width: 0%;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
}
.portfoliogrid-section-04 .portfolio-masonry--single:hover::before {
  opacity: 0.7;
  visibility: visible;
  width: 100%;
}
.portfoliogrid-section-04 .portfolio-masonry--single:hover .icon {
  visibility: visible;
}
.portfoliogrid-section-04 .portfolio-masonry--single:hover .content {
  visibility: visible;
}

/* Protfolio Masonry Content css Start */
/* Protfolio Masonry Content css End */
/*********** CSS TABLE OF CONTENTS *******************

|--- 01.Component -> { Header }
|--- 01.Component -> { Menu }
|--- 01.Component -> { Button }
|--- 01.Component -> { Lists }
|--- 01.Component -> { Background }
|--- 01.Component -> { Form }
|--- 01.Component -> { Cards }
|--- 01.Component -> { Modal }
|--- 01.Component -> { Tab }
|--- 01.Component -> { Acccordion }
|--- 01.Component -> { Sidebar }
|--- THEME CUSTOM STYLES

******************************************************/
/* ---------------------------------
-------> THEME CUSTOM STYLES 
---------------------------------
*/