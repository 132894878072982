.counter-section-09{
    padding-top: 50px;
    position: relative;
    padding-bottom: 50px;
    background: rgb(0, 187, 152,0.05);
    z-index: 1;
    overflow: hidden;
    @media (min-width:768px) {
        padding-top: 78px;
        padding-bottom: 80px;

    }
    &__bg-shape{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
    .widget-counter{
        flex-direction: column;
        .widget-text{
            color: rgb(0, 11, 51,0.8);
        }
    }
}

