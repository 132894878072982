.counter-section-10 {
    padding-bottom: 45px;
    @media (min-width:768px) {
        padding-bottom: 63px;
    }
    &__widget{
        display: flex;
        align-items: center;
        justify-content: center;
        .widget-counter {
            position: relative;
            padding-right: 30px;
            padding-left: 30px;
            &:first-child{
                padding-left: 0;
                .widget-title{
                    &::before{
                        background: rgb(0, 187, 152,0.1);
                    }
                }
            }
            &:last-child{
            padding-right: 0;
            &::before{
                display: none;
            }
            }
            &::before{
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 50px;
                background-color: rgb(43, 89, 255,0.2);

            }
            @media (min-width: 768px) {
                flex-direction: column;
            }
            .widget-title{
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -10px;
                    width: 50px;
                    height: 50px;
                    border-radius: 500px;
                    background: rgb(253, 76, 92,0.1);
                    z-index: -1;
                }
            }
        }
    }
    &__content{
        .title{
            span{
                color: #2B59FF;
            }
        }
    }
}