/*
    Note: This section is similar to landing 6 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/

/* duplicated code starts*/
.counter-section-06 {
    padding: 50px 0;
    background: rgb(55, 124, 253,0.02);
    @media (min-width:768px) {
        padding: 70px 0;
    }

    @media (min-width:992px) {
        padding: 100px 0;
    }

    .widget-counter {
        justify-content: start;
        padding: 20px 40px;
        border-radius: 10px;
        .widget-title{
            @media (min-width:768px) {
                margin-right: 20px;
            }
            margin-bottom: 0;
        }
        .widget-text{
            color: #000B33;
        }
    }
    .col-xl-3 {
        &:nth-child(1){
            .widget-counter {
                background: rgb(0, 187, 152,0.1);
            }
        } 
        &:nth-child(2){
            .widget-counter {
                background: rgb(120, 27, 255,0.1);
            }
        } 
        &:nth-child(3){
            .widget-counter {
                background: rgb(253, 76, 92,0.1);
            }
        } 
        &:nth-child(4){
            .widget-counter {
                background: rgb(255, 200, 62,0.1);
            }
        } 
    }
}
/*duplicated code ends*/
