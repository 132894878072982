.content-section-03 {
    padding-top: 38px;
    padding-bottom: 60px;
    background-color: white;

    @media (min-width:768px) {
        padding-top: 48px;
        padding-bottom: 85px;    
    }

    @media (min-width:992px) {
        padding-top: 70px;
        padding-bottom: 87px;

    }
    @media (min-width:1200px) {
        padding-top: 85px;
        padding-bottom: 95px;

    }

    &__texts {
        @media (min-width:992px) {
            padding-left: 40px;

        }

        .subtitle {
            margin-bottom: 15px;
            @media (min-width:576px) {
                margin-bottom: 20px;
            }
        }

        .btn {
            margin-top: 30px;
            @media (min-width:576px) {
                margin-top: 40px;

            }
        }
    }
}