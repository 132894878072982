.content-section-25{
    padding: 60px 0;
    @media (min-width:768px) {
        padding: 80px 0;
    }
    @media (min-width:992px) {
        padding: 130px 0;
    }
    &__sidebar{
        padding: 30px 20px;
        background: white;
        box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        border-radius: 10px;
        @media (min-width:576px) {
            padding: 40px;
            
        }
        .title{
            font-weight: 600;
            font-size: 18px;
            line-height: 1.3;
            @media (min-width:420px) {
                font-size: 20px;
            } 
        }
        &--lis{
            margin: 0;
            padding: 0;
            margin-top: 30px;
            li{
                color: #000B33;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                list-style: none;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
                .download-btn-group{
                    margin-top: 30px;
                    a{
                        .icon{
                            margin-right: 20px;
                        }
                        font-size: 18px;
                        line-height: 26px;
                        color: #377CFD;
                        display: block;
                        display: flex;
                        align-items: center;
                        padding-bottom: 15px;
                        border-bottom: 1px solid rgb(55, 124, 253,0.1);
                        margin-bottom: 15px;
                        &:last-child{
                            padding-bottom: 0;
                            margin-bottom: 0;
                            border-bottom:0 ;
                        }
                    }
                }
            }
        }
    }
    &__content{
        background: #FFFFFF;
        box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        border-radius: 10px; 
        padding: 30px 20px;
        @media (min-width:420px) {
            padding: 30px 50px 40px 30px;
            
        }
        @media (min-width:768px) {
            padding: 40px 70px 60px 40px;
            
        }
        &--texts{
            .title{
                margin-bottom: 24px;
            }
            margin-bottom: 35px;
            @media (min-width:576px) {
                margin-bottom: 65px;
            }
        }
        &--list{
            margin-bottom: 35px;
            @media (min-width:576px) {
                margin-bottom: 60px;
            }
            .title{
            font-weight: 600;
            font-size: 26px;
            line-height: 1.25;
            margin-bottom: 25px;
                @media (min-width:576px) {
                    font-size: 36px;
                    margin-bottom: 39px;
                }
            }
            ul{
                li{
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    list-style: none;
                    position: relative;
                    margin: 11px 0;
                    padding-left: 22px;
                    color: rgb(0, 11, 51,0.8);
                    &:first-child{
                        margin-top: 0;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &::before{
                        width: 10px;
                        border-radius: 500px;
                        height: 10px;
                        position: absolute;
                        content: "";
                        background: #377CFD;
                        top: 7px;
                        left: 0;
                    }
                }
            }
        }
        .btn{
            min-width: 160px;
        }
    }
}