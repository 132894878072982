.breadcrumb-section {
    padding-top: 100px;
    padding-bottom: 60px;
    background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
    position: relative;
    z-index: 11;
    overflow: hidden;
    &__bg-shape{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 35%;
        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
    @media (min-width:768px) {
        padding-top: 122px;
        padding-bottom: 76px;    
    }
    
    @media (min-width:992px) {
        padding-top: 152px;
        padding-bottom: 96px;
    }
    @media (min-width:1200px) {
        padding-top: 149px;
        padding-bottom: 148px;
    }
    &__content {
        .breadcrumb {
            margin: 0;
            margin-top: 30px;
            @media (min-width:992px) {
                margin-top: 40px;
            }

            &-item {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: white;
                +.breadcrumb-item{
                    padding-left: 10px;
                    &::before {
                        padding-right: 10px;
                        content: "\f054";
                        font-size: 10px;
                        font-family: "Font Awesome 5 Free"; font-weight: 900;
                        color: white;
                    }
                }
            }

        }
    }
    &__shape-1{
        position: absolute;
        bottom: 5%;
        right: 0%;
        width: 6.5%;
        z-index: -1;
    }
    &__shape-2{
        position: absolute;
        top: 37%;
        left: 12%;
        width: 3%;
        z-index: -1;
    }
    &__shape-3{
        position: absolute;
        bottom: 6%;
        left: 0%;
        width: 3%;
        z-index: -1;
    }
}