
.cta-section-06 {
    &__wrapper {
        @media (min-width:768px) {
            background: #377CFD;
        }

        position: relative;
        padding: 70px 0;
        border-radius: 30px;
        z-index: 1;

        &--shape-1 {
            display: none;
            @media (min-width:768px) {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 20%;
                z-index: -1;

                svg {
                    width: 100%;
                    border-top-right-radius: 30px;
                }
            }
        }

        &--shape-3 {
            display: none;
            @media (min-width:768px) {
                display: block;
                position: absolute;
                top: 23%;
                left: 0;
                width: 3%;
                z-index: -1;

                svg {
                    width: 100%;
                }
            }
        }
        &--shape-4 {
            display: none;
            @media (min-width:768px) {
                display: block;
                position: absolute;
                bottom: 7%;
                left: 32%;
                width: 2%;
                z-index: -1;

                svg {
                    width: 100%;
                }
            }
        }
        &--shape-5 {
            display: none;
            @media (min-width:768px) {
                display: block;
                position: absolute;
                top: 0;
                left: 54%;
                width: 18%;
                z-index: -1;

                svg {
                    width: 100%;
                }
            }
        }
    }
&__contennt{
    margin-bottom: 30px;
    @media (min-width:1200px) {
        margin-bottom: 0;
    }
}
    &__button-group {
        text-align: end;
        height: 100%;
        display: flex;
        align-items: flex-end;
        max-width: 300px;
        @media (min-width:576px) {
            max-width: 380px;

        }
        @media (min-width:992px) {
            justify-content: flex-end;
            max-width: 100%;
        }

        a {
            margin-right: 20px;

            &:last-child {
                margin-right: 0px;

            }
        }
    }
}

.cta-bg-group-3 {
    background: #377CFD;
    position: relative;
    &--shape-1 {
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;

        svg {
            width: 100%;
        }

        @media (min-width:768px) {
            display: none;
        }
    }

    @media (min-width:768px) {
        background-color: inherit;
    }
}