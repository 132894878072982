.comingsoom-section-01 {
    background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 60px 0;
    min-height: 100vh;
    @media (min-width:768px) {
        padding: 80px 0;
    }
    @media (min-width:992px) {
        padding: 100px 0;
    }
    &__logo {
        margin-bottom: 60px;
        @media (min-width:768px) {
            margin-bottom: 80px;
        }

        @media (min-width:992px) {
            margin-bottom: 100px;
        }
    }
    &__content {
        &--title {
            margin-bottom: 30px;

            @media (min-width:768px) {
                margin-bottom: 60px;
            }
        }
        &--counter {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 40px;
            @media (min-width:576px) {
                margin-bottom: 80px;
                flex-wrap: nowrap;
                flex-direction: row;
            }
            .wrapper {
                
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
                backdrop-filter: blur(20px);
                border: 1px solid rgba(255, 255, 255, 0.4);
                min-width: 100px;
                max-width: 100px;
                min-height: 100px;
                max-height: 100px;
                border-radius: 500px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 15px;
                @media (min-width:411px) {
                    margin: 15px 20px;
                }
                @media (min-width:444px) {
                    margin:15px 25px;
                }
                @media (min-width:474px) {
                    margin:15px 30px;
                }
                @media (min-width:504px) {
                    margin:15px 45px;
                }
                @media (min-width:576px) {
                    margin: 0 15px;
                }
                @media (min-width:768px) {
                    min-width: 150px;
                    max-width: 150px;
                    min-height: 150px;
                    max-height: 150px;
                }
                @media (min-width:992px) {
                    min-width: 200px;
                    max-width: 200px;
                    min-height: 200px;
                    max-height: 200px;
                }
                .time {
                    font-weight: 700 !important;
                    color: #fff;
                    line-height: 1.097222222222222;
                    letter-spacing: -0.5px;
                    font-size:36px;
                    @media (min-width:768px){
                      font-size:41px;
                    }
                    @media(min-width:992px){
                        font-size:54px;
                    }
                    @media (min-width:1200px){
                      font-size:62px;
                    }
                    @media (min-width:1600px){
                      font-size:72px;
                    }
                }

                .label {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 1.3;
                    color: #fff;
                    @media (min-width:768px) {
                        font-size: 20px;
                        
                    }
                }
            }
        }
        &--notify-form {
            margin: auto;
            padding: 0 20px;
            @media (min-width:576px) {
                width: 450px;
            }
            @media (min-width:768px) {
                width: 526px;
            }
            .form-group {
                position: relative;
                .form-control {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 60px;
                    padding: 0 30px;
                    padding-right: 55px;
                    color: white;
                    border: 1px solid #FFFFFF;
                    border-radius: 3px;
                    background: transparent;
                    &::placeholder{
                        color: rgb(255, 255, 255,0.8);
                    }
                    @media (min-width:420px) {
                        padding-right: 30px;
                    }
                }
                .arrow {
                    position: absolute;
                    top: 0;
                    right: 13px;
                    padding: 18px;
                    cursor: pointer;
                }
            }
        }
        &--social{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            padding: 0 -15px;
            li{
                a{
                    color: white;
                    padding: 0 15px;
                    transition: .3s;
                    &:hover{
                        color: #FD4C5C;
                    }
                }
            }
        }
    }
    &__shape-1{
        position: absolute;
        left: 0%;
        top: 22%;
        width: 3%;
    }
    &__shape-2{
        position: absolute;
        left: 12%;
        top: 5%;
        width: 3%;    
    }
    &__shape-3{
        position: absolute;
        right: 0%;
        top: 8%;
        width: 6.5%;
    }
    &__shape-4{
        position: absolute;
        right: 5%;
        bottom: 18%;
        width: 2%;
    }
}