@use "../abstracts/typography"as *;

.section-title,
.block-title {
  .subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    @media (min-width:576px) {
      font-size: 20px;
      
    }
  }

  .title {
    @include Section_Heading;
  }
  > p {
    font-size: 17px;
    line-height: 1.69;
    margin-top: 10px;
    @media (min-width:768px) {
      margin-top: 15px;
      font-size: 18px;
      
    }
  }
}

.hero-content {
  .title {
    @include Main_Heading;
  }

  > p {
    font-size: 17px;
    line-height: 1.69;
    @media (min-width:768px) {
      font-size: 18px;
      
    }
  }
}
