.testimonial-section-08 {
    padding: 60px 0;

    @media (min-width:768px) {

        padding: 80px 0;
    }

    @media (min-width:992px) {
        padding: 100px 0;
    }
    @media (min-width:1200px) {
        padding: 130px 0;
    }
    position: relative;
    &__dots{
        position: absolute;
        left: 0;
        top: 8%;
        width: 5%;
    }
    &__title {
        margin-bottom: 40px;
        @media (min-width:768px) {
            margin-bottom: 80px;
        }
    }
    &__slider{
        .slick-slide {
            margin: 0 12px;
        }
    }
    .card-testimonial-06{
        &__content{
            p{
                font-size: 18px;
            }
        }
    }

}