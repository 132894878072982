@use "../abstracts/mixins" as utils ;
@use "../bs-customization/colors" as color ;
.btn {
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  min-width: 180px;
  line-height: 1.2;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &-reset {
    background: transparent;
    border: 0;
  }

  .btn-l1 {
    min-width: 180px;
    min-height: 50px;
  }
}

button {
  &:focus {
    outline: none;
  }
}

.btn-white {
  background-color: white !important;
  color: #781bff !important;
  transition: 0.3s;
}

.pricing-section-01__button {
  --round-offset: 4px;
  .btn-toggle {
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
    + label {
      background: linear-gradient(90deg, #8c30f5 0%, #ff4b5c 100%);
      border-radius: 15px;
      width: 46px;
      height: 26px;
      position: relative;
      cursor: pointer;
      &::before {
        width: 18px;
        height: 18px;
        top: var(--round-offset);
        left: var(--round-offset);
        position: absolute;
        border-radius: 500px;
        background: #fff;
        content: "";
        transition: 0.4s;
        // transform: translateX(50%);
      }
    }
    &:checked {
      + label {
        &:before {
          left: calc(100% - var(--round-offset));
          transform: translateX(-100%);
        }
      }
    }
  }
}
.pricing-section-07__button {
  --round-offset: 4px;
  .btn-toggle {
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
    + label {
      background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
      border-radius: 15px;
      width: 46px;
      height: 26px;
      position: relative;
      &::before {
        width: 18px;
        height: 18px;
        top: var(--round-offset);
        left: var(--round-offset);
        position: absolute;
        border-radius: 500px;
        background: #fff;
        content: "";
        transition: 0.4s;
        // transform: translateX(50%);
      }
    }
    &:checked {
      + label {
        &:before {
          left: calc(100% - var(--round-offset));
          transform: translateX(-100%);
        }
      }
    }
  }
}
/* Video Button */
.video-btn-1{
  position: relative;
  @include utils.flexAllCenter;
  @include utils.square(70px,500px);
  @media (min-width:576px) {
      @include utils.square(90px,500px);
  }
  i{
      font-size: 18px;
      transform: translateX(2px);
  }
  z-index: 1;
  &::after{
      animation: sonarWave 2s linear infinite;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      content: "";
      background-color: white;
      z-index: -1;
      pointer-events: none;
      opacity: 0;
  }
}

.btn-primary-outline{
  border-color:color.$primary;
  color: color.$primary;
  overflow:hidden ;
  position: relative;
  z-index: 1;
  &:before{
    background: color.$primary;
    content: "";
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0;
    position: absolute;
    z-index: -1;
    transition: transform 0.4s;
    transform-origin: left;
    transform: translateX(-100%);
  }
  &:hover{
    color: #fff!important;
    background: transparent;
    &:before{
      transition: transform 0.4s;
      transform-origin: right;
      transform: translateX(0%);
    }
  }
  &-reverse{
    color: #fff!important;
    overflow:hidden ;
    position: relative;
    z-index: 1;
    background: transparent;
    border: 1px solid color.$primary;
    &:before{
      background: color.$primary;
      content: "";
      width: 100%;
      height: 100%;
      left: 0%;
      top: 0;
    position: absolute;
    z-index: -1;
    transition: transform 0.4s;
    transform-origin: left;
    transform: translateX(0);
  }
  &:hover{
    color: color.$primary!important;
    border-color:color.$primary;
    &:before{
      transition: transform 0.4s;
      transform-origin: right;
      transform: translateX(-100%);
    }
  }
  }
}

.btn-rounded{
  border-radius: 500px!important;
}

.border-2{
  border-width: 2px !important;
}

.btn-w-arrow{
  i{
    margin-left: 10px;
  }
}

.hero-btn{
  @media (min-width:768px) {
      font-size: 18px !important;
  }
}

.btn-arrow-anim-01{
  g{
    stroke: #fff;
  }
  circle.arrow-icon--circle {
    stroke-dasharray: 95;
    stroke-dashoffset: 95;
    transition: .4s;
  }
  &:hover{
    circle.arrow-icon--circle {
      stroke-dashoffset: 0;
      transition: .4s;
    }
  }
}