.counter-section-02 {
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(120, 27, 255, 0.03);

    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    @media (min-width:992px) {
        padding: 100px 0;
    }
    .widget-counter{
        .widget-icon{
            margin-bottom: 15px;
            @media (min-width:768px) {
                margin-bottom: 24px;
            }
        }
        @media (min-width:768px) {
        align-items: flex-start;
        .widget-icon{
            margin-top: 10px;
        }
        .widget-title{
            margin-bottom: 5px;
        }
        .widget-text{
            color: #000B33;
        }
        }
    }
}