.content-section-12{
    padding: 60px 0;
    @media (min-width:768px) {
        padding: 80px 0;
    }
    @media (min-width:992px) {
        padding: 100px 0 170px;
    }
    @media (min-width:1200px) {
        padding: 130px 0 200px;
    }
    .content-title{
        .btn{
            margin-top: 40px;
            min-width: 150px;
        }
    }
    &__image-group{
        margin-bottom: 53px;
        text-align: center;
        position: relative;
        @media (min-width:380px) {
            margin-bottom: 70px;
        }
        @media (min-width:576px) {
            margin-bottom: 64px;
        }
        @media (min-width:768px) {
            margin-bottom: 72px;
        }
        @media (min-width:992px) {
            margin-bottom: 0px;
            
        }
        img{
            border-radius: 10px;
            max-width: 75%;
            @media (min-width:992px) {
                max-width: 100%;
            }
        }
        &--group{
            position: absolute;
            width: 30.9%;
            left: 6%;
            bottom: -15.5%;
            img{
                border-radius: 10px;
            }
        }
        &--dots{
            position: absolute;
            width: 27.4%;
            bottom: 6%;
            left: -1px;
            z-index: -1;
        }
        &--shape{
            position: absolute;
            width: 31.8%;
            top: 24.5%;
            right: 0%;
            z-index: -1;
            @media (min-width:1200px) {
                right: 6%;
            }
        }
    }
}