.protfoliocontent-section-03{
    background: rgb(55, 124, 253,0.02);
    padding: 60px 0;
    @media (min-width:768px) {
        padding: 80px 0;
    }
    @media (min-width:992px) {
        padding: 130px 0;
    }
    &__slider-small{
        margin-top: 50px;
        @media (min-width:768px) {
            margin-top: 100px;
        }
        .content-title{
            text-align: center;
            margin-bottom: 30px;
            @media (min-width:768px) {
                margin-bottom: 60px;
            }
        }
    }
    &__slider-for{
       .portfolio-details-slider--for{
        &__slider--single{
            img{
                border-radius: 10px;
            }
        }
       } 
    }
    &__slider-nav{
        position: relative;
        .portfolio-details-slider--nav{
            &__slider--single{
                img{
                    border-radius: 10px;
                }
            }
            .slick-slide {
                margin: 0 5px;
                @media (min-width:576px) {
                    margin: 0 12px;
                }
            }
            .slick-list {
                margin: 0 -5px;
                @media (min-width:576px) {
                    margin: 0 -12px;
                }
            }
            &__control-buttons{
                display: none;
                @media (min-width:768px) {
                    display: block;
                }
                .button{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: 0;
                background: #FD4C5C;
                border-radius: 5px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .3s;
                svg{
                    fill: white;
                }
                &:hover{
                    background: white;
                    svg{
                        fill: #FD4C5C;
                    }
                }
                }
                .button--prev{
                    left: 0px;
                    cursor: pointer;
                }
                    .button--next{
                    right: 0px;
                    cursor: pointer;    
                }
            }
        }
    }
    &__next-prev-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        @media (min-width:768px) {
            margin-top: 40px;
        }
        .prev-button,.next-button{
            display: flex;
            align-items: center;
            transition: .3s;
            &:hover{
                i{
                    color: #FD4C5C;
                }
                p{
                    color: #FD4C5C;
                }
            }
            i{
                color: #000B33;
                font-size: 18px;
                transition: .3s;
            }
            p{
                color: #000B33;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                transition: .3s;
            }
        }
    }
}

/* protfoliocontent-card css */
.protfoliocontent-card--03{
    margin-top: 60px;
    @media (min-width:420px) {
        background: #FFFFFF;
        box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        border-radius: 10px;
        padding:30px 20px;
    }
    @media (min-width:576px) {
        padding: 30px;
    }
    @media (min-width:768px) {
        padding: 60px;
    }
    .title{
        font-size: 24px;
        line-height: 1.4;
        @media (min-width:576px) {
            font-size: 30px;
        }
    }
    .text{
        font-size: 16px;
        line-height: 1.444444444444444;
        @media (min-width:576px) {
            font-size: 18px;
        }
    }
    &__list{
        margin: 0;
        padding: 0;
        @media (min-width:420px) {
            display: grid;
            grid-template-columns: repeat(2, minmax(140px, 260px));
            gap: 0px 10px;
        }
        @media (min-width:576px) {
            gap: 0px 25px;
        }
        @media (min-width:1200px) {
            margin-left: 80px;
        }
        .list-item{
            margin: 0;
            padding: 0;
            list-style: none;
            position: relative;
            padding-left: 28px;
            margin-bottom: 20px;
            @media (min-width:576px) {
                margin-bottom: 40px;
            }
            &::before{
                position: absolute;
                content: "";
                top: 7px;
                left: 0;
                width: 12px;
                height: 12px;
                background: #377CFD;
                border-radius:500px ;
            }
            h6{
                font-weight: 600;
                font-size: 18px;
                line-height: 1.3;
                margin-bottom: 20px;
                @media (min-width:420px) {
                    font-size: 20px;
                }
            }
            a,
            span{
                font-size: 16px;
                line-height: 1.444444444444444;
                color: rgb(0, 11, 51,0.8);
                @media (min-width:420px) {
                    font-size: 18px;
                }
            }
            .social{
               margin: 0;
               padding: 0;
               display: flex;
               align-items: center;
               margin: 0 -12px;
               margin-top: 10px;
               li{
                   list-style: none;
                   margin: 0 12px;
                   a{
                    color: rgb(0, 11, 51,0.8);
                    transition: .3s;
                    &:hover{
                        color: rgb(253, 76, 92,0.8);
                    } 
                   }
               } 
            }
        }
    }
}