.counter-section-03 {
    padding: 50px 0;
    background: rgb(120, 27, 255, 0.02);

    @media (min-width:768px) {
        padding: 70px 0;
    }

    @media (min-width:992px) {
        padding: 100px 0;
    }

    .widget-counter {
        @media (min-width:768px) {
            align-items: flex-start;
        }
    }
}