.contactmap-section{
    &__map{
        position: relative;
        &--icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}