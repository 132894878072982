.hero-section-08{
    padding-top: 100px;
    @media (min-width:576px) {
        padding-top: 120px;
    }
    @media (min-width:992px) {
        padding-top: 0;
    }
    background: #2B59FF;
    z-index: 1;
    &__content{
        &--button-group{
            margin-top: 30px;
            max-width: 100%;
            display: flex;
            align-items: center;
            margin-left: -12px;
            margin-right: -12px;
            @media (min-width:424px) {
                max-width: 80%;
                margin: 0 auto;
                margin-top: 43px;
            }
            @media (min-width:992px) {
                max-width: 100%;
            }
            a{
                margin-left: 12px;
                margin-right: 12px;
            }
        }
    }
    &__image-group{
        position: relative;
        z-index: 11;
        @media (min-width:992px) {
            margin-top: -20px;
            margin-right: -48%;
            margin-left: -45%;
        }
        &--shape{
            position: absolute;
            top: 27%;
            left: 23%;
            width: 31.1%;
            z-index: -1;
           
        }
        &--chat-1{
            position: absolute;
            top: 34.8%;
            right: 7%;
            width: 25.1%;
        }
        &--chat-2{
            position: absolute;
            top: 60%;
            right: 22%;
            width: 8%;
        }
    }
}

// &--chat-2{
//     position: absolute;
//     top: 60%;
//     right: 22%;
//     width: 8%;
// }