/*=== Footer 1 ===*/
.footer-area--01{
  padding-top: 60px;
  @media (min-width:768px) {
    padding-top: 80px;

  }
  @media (min-width:992px) {
    padding-top: 110px;
  }
}
.footer-content{
  margin-bottom: 45px;
  @media (min-width:992px) {
    margin-bottom: 0px;

  }
  &--logo{
    margin-bottom: 20px;
    @media (min-width:992px) {
      margin-bottom: 29px;
      
    }
  }
  &--text{
  font-size: 16px;
  line-height: 26px;
  }
  &--social{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    justify-content: center;
    @media (min-width:576px) {
    justify-content: start;
    }
    @media (min-width:992px) {
      margin-top: 35px;
      
    }
    display: flex;
    align-items: center;
    li{
      margin:0 19px;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        color: #000B33;
        transition: .3s;
        &:hover{
          color: #FD4C5C;
        }
      }
    }
  }
}
.footer-widget{
  &--title{
    font-weight: 600;
font-size: 16px;
line-height: 24px;
    margin-bottom: 16px;
    @media (min-width:992px) {
      margin-bottom: 30px;
      
    }
  }
  &--list{
    li{
      a{
        font-size: 16px;
        line-height: 26px;
        color: rgb(0, 11, 51,0.8);
        margin-bottom: 12px;
        @media (min-width:992px) {
          margin-bottom: 15px;
        }
        display: block;
        transition: .3s;
        &:hover{
          color: #FD4C5C;
          
        }
      }
    }
  }
  &--info{
    li{
      font-size: 16px;
      line-height: 26px;
      color: rgb(0, 11, 51,0.8);
      a{
        font-size: 16px;
        line-height: 26px;
        color: rgb(0, 11, 51,0.8);
        transition: .3s;
        &:hover{
          color: #FD4C5C;
          
        }
      }
    }
    &-brand{
     margin-top: 30px; 
    }
    .footer-2-datails{
      margin-top: 10px;
      i{
        color: #FD4C5C;
        margin-right: 5px;
      }
    }
  }
  &--info-brand{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px -4px 0 -4px;
    @media (min-width:576px) {
      justify-content: start;
    }
    a{
      margin: 0 4px;
    }
  }
  &--01{
    margin-bottom: 35px;
    @media (min-width:576px) {
margin-bottom: 0;
    }
    @media (min-width:992px) {
      margin-left: 45px;
      
    }
    @media (min-width:1200px) {
      margin-left: 112px;
    }

  }
  &--02{
margin-bottom: 35px;
    @media (min-width:576px) {
margin-bottom: 0;
      
    }
    @media (min-width:992px) {
      margin-left: 35px;
    }
    @media (min-width:1200px) {
      margin-left: 83px;
    }

    
  }
  &--03{
    @media (min-width:992px) {
      margin-left: 0px;
      
    }
    @media (min-width:1200px) {
      margin-left: 43px;
      
    }

  }
}
.copyright-area{
  border-top: 1px solid rgb(0, 11, 51,0.1);
  padding: 20px 0;
  margin-top: 45px;
  @media (min-width:768px) {
    padding-top: 40px;
    padding-bottom: 34px;
    margin-top: 64px;
  }
  p{
font-size: 16px;
line-height: 26px;
text-align: center;
color: rgb(0, 11, 51,0.8);
  }
}