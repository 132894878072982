@use "../../theme/theme-3" as *;
.hero-section-03{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;
    // overflow: hidden;
    padding-bottom: 50px;
    @media (min-width:575px) {
        padding-top: 20px;
        padding-bottom: 60px;
    }
    @media (min-width:768px) {
        padding-top: 20px;
        padding-bottom: 70px;
    }
    @media (min-width:992px) {
        padding-top: 20px;
        padding-bottom: 80px;
    }
    @media (min-width:1200px) {
        padding-top: 20px;
    }
    @media (min-width:1400px) {
        padding-top: 0;
    }
    &::before{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($secondary, .95);
        z-index: -1;
    }
    &__content{
        padding-bottom: 40px;
        
        text-align: center;
        @media (min-width:992px) {
            padding-top: 100px;
            text-align: left;
        }
        @media (min-width:1200px) {
            padding-top: 68px;
            padding-bottom: 0px;
        }
        h1{
            margin-bottom: 20px;
        }
        &--button-group{
            margin: 0 0 -10px -10px;
            margin-top:30px;
            @media (min-width:768px) {
                margin-top:40px;
            }
            a{
                margin: 0 0 10px 10px;
                display: inline-block;
                max-width: 142px;
                min-width: 142px;
                @media (min-width:480px) {
                    max-width: 150px;
                    min-width: 150px;
                }
                @media (min-width:768px) {
                    max-width: unset;
                    min-width: unset;
                }
                img{
                    @media (max-width:768px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    &__image-group{
        z-index: 1;
        position: relative;
        padding-top: 80px;
        @media (min-width:768px) {
            padding-top: 100px;
        }
        @media (min-width:1200px) {
            padding-top: 157px;
        }
        
        @media (min-width:1200px) {
            margin-bottom: -100px;
            margin-left: -20%;
        }
        @media (min-width:1400px) {
            margin-bottom: -125px;
            // margin-left: 0%;
            margin-left: -15%;
        }
        img{
            width: 100%;
            @media (min-width:1400px) {
                width: inherit;
            }

        }
    }
    &__shape{
        position: absolute;
        bottom: -2px;
        width: 101%;
    }
}