.content-section-15 {
    padding: 55px 0 65px;
    position: relative;
    z-index: 1;
    @media (min-width:768px) {
        padding: 75px 0 80px;
     }
 
     @media (min-width:992px) {
         padding:100px 0 100px;
     }
     @media (min-width:1200px) {
         padding:130px 0;
     }
    &__circle-1{
        position: absolute;
        top: 2%;
        right: 3%;
        background: #FD4C5C;
        width: 20px;
        height: 20px;
        border-radius: 500px;
    }
    &__circle-2{
        position: absolute;
        top: 49%;
        left: 45%;
        border: 3px solid #FFC83E;
        width: 20px;
        height: 20px;
        border-radius: 500px;
    }
    &__circle-3{
        position: absolute;
        top: 27%;
        left: 2%;
        background: #FD4C5C;
        width: 10px;
        height: 10px;
        border-radius: 500px;
    }
    &__content{
        .btn{
            min-width: 150px;
            margin-top: 30px;
            border-radius: 10px;
            @media (min-width:576px) {
                margin-top: 40px;
            }
        }
    }
    &__card-group{
        .col-xl-6{
            &:nth-child(1){
                .widget-card{
                    .widget-icon{
                        background: rgb(0, 187, 152,0.1);
                    }
                }
            }
            &:nth-child(2){
                .widget-card{
                    .widget-icon{
                        background: rgb(43, 89, 255,0.1);
                    }
                }
            }
            &:nth-child(3){
                .widget-card{
                    .widget-icon{
                        background: rgb(253, 76, 92,0.1);
                    }
                }
            }
            &:nth-child(4){
                .widget-card{
                    .widget-icon{
                        background: rgb(255, 200, 62,0.1);
                    }
                }
            }
        }
        .widget-card{
            padding: 40px 30px;
            text-align: center;
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
            border-radius: 10px;
            .widget-icon{
                background: rgb(55, 124, 253,0.1);
                min-width: 50px;
                min-height: 50px;
                max-width: 50px;
                max-height: 50px;
                border-radius: 500px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                margin-bottom: 30px;
                @media (min-width:768px) {
                    min-width: 60px;
                    min-height: 60px;
                    max-width: 60px;
                    max-height: 60px;
                }
            }
            .widget-title{
                font-weight: bold;
                font-size: 18px;
                line-height: 1.3;
                margin-bottom: 10px;
                @media (min-width:576px) {
                    font-size: 20px;
                }
            }
            .widget-text{
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: rgb(0, 11, 51,0.8);
            }
        }
    }
}