.services-section-03 {
    padding: 60px 0;
    background: rgb(55, 124, 253, 0.02);

    @media (min-width:768px) {
        padding: 80px 0;
    }

    @media (min-width:992px) {
        padding: 130px 0;
    }

    &__tab {
        background: white;
        border-radius: 10px;
        &--title {
            padding: 20px 25px;
            padding-bottom: 25px;
            background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
            border-radius: 10px 10px 0px 0px;
            font-weight: bold;
            font-size: 20px;
            line-height: 1.4;
            color: white !important;
            
            @media (min-width:480px) {
                font-size: 26px;
                padding: 25px 30px;
                padding-bottom: 28px;
            }

            @media (min-width:992px) {
                font-size: 30px;
            }
        }

        .nav {
            padding: 40px 30px;
            background: #FFFFFF;
            border-radius: 10px;
            .nav-link {
                text-align: start;
                padding: 0;
                padding-bottom: 30px;
                font-size: 16px;
                line-height: 1.3;
                color: #000B33;
                @media (min-width:480px) {
                    font-size: 18px;
                }
                @media (min-width:992px) {
                    font-size: 20px;
                }

                &.active {
                    background: none;
                    color: #FD4C5C;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    &__accordion {
        .accordion-item {
            border: 0;
            margin-bottom: 30px;
            background: transparent;
            position: relative;
            z-index: 1;
            outline: none;

            &:last-child {
                margin-bottom: 0;
            }

            .accordion-header {
                .accordion-button {
                    padding: 15px;
                    border: 0;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 1.3;
                    color: #000B33;
                    box-shadow: none;
                    background-color: inherit !important;
                    transition: .3s;
                    z-index: unset !important;
                    outline: none;
                    @media (min-width:480px) {
                        padding: 30px;
                        font-size: 18px;
                    }
                    @media (min-width:576px) {
                        font-size: 20px;
                    }

                    &.collapsed {
                        border-top: 1px solid rgb(55, 124, 253, 0.1);
                        border-bottom: 1px solid rgb(55, 124, 253, 0.1);
                    }

                    &:not(.collapsed) {
                        position: static;
                        color: #FD4C5C;
                        outline: none;
                        box-shadow: none;

                        &::before {
                            content: "";
                            background: #fff;
                            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
                            border-radius: 5px;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            left: 0;
                            position: absolute;
                            z-index: -1;
                            border: 0;
                            outline: none;
                        }
                    }

                    &::after {
                        background-image: none;
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        content: "\f067";
                        color: #000B33;
                        font-size: 16px;
                        @media (min-width:480px) {
                            font-size: 18px;
                        }
                    }
                    
                    &:not(.collapsed)::after {
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        content: "\f068";
                        transform: rotate(0deg);
                        color: #FD4C5C;
                        font-size: 16px;
                        @media (min-width:480px) {
                            font-size: 18px;
                        }
                    }
                }
            }
            .accordion-body {
                font-size: 14px;
                line-height: 26px;
                padding: 15px;
                padding-top: 0;
                margin-top: -6;
                @media (min-width:480px) {
                    font-size: 16px;
                    padding: 30px;
                }
            }
            &:first-child{
                .accordion-header {
                    .accordion-button {
                        &.collapsed {
                            border-top: 0;
                        }
                    
                }}
            }
            &:last-child{
                    .accordion-header {
                        .accordion-button {
                            &.collapsed {
                                border-bottom: 0;
                            }
                        
                    }
                }
            }
        }
    }

}