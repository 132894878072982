.content-section-08{
    padding-top: 38px;
    padding-bottom: 60px;
    position: relative;
    background: #001C80;
    z-index: 11;
    @media (min-width:768px) {
        padding-top: 57px;
        padding-bottom: 80px; 
    }
    @media (min-width:992px) {
        padding-top: 80px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 105px;
        padding-bottom: 159px;
    }
    &__shape-1{
    position: absolute;
    bottom: -40px;
    right: 6.4%;
    width: 6.7%;
    z-index: -1;
        img,svg{
            width: 100%;
        }
    }
    &__shape-2{
        position: absolute;
        top: 10%;
        left: 0;
        width: 5%; 
        z-index: -1;  
        img,svg{
            width: 100%;
        }
    }
    &__image-group{
        margin-bottom: 30px;
        position: relative;
        @media (min-width:576px) {
            margin-bottom: 50px;

        }
        @media (min-width:992px) {
            // margin-left: -41px;
            margin-right: 73px;
            margin-bottom: 0px;
        }
        @media (min-width:1200px) {
            // margin-left: -41px;
            margin-right: 73px;
        }
        @media (min-width:1400px) {
            margin-left: -41px;
            margin-right: 73px;
        }
        &--card{
            position: absolute;
            right: -11.3%;
            bottom: 13%;
            width: 31.7%;
            img{
                width: 100%;
                filter: drop-shadow(0px 32px 54px rgba(65, 74, 85, 0.22));
            }
        }
        &--shape{
            position: absolute;
            left: 9.6%;
            width: 13.1%;
            z-index: -1;    
            bottom: -24.7%;
            @media (min-width:992px) {
                bottom: -14.7%;
            }
            svg{
                width: 100%;
            }
        }
    }
    &__content{
        @media (min-width:1200px) {
            padding-left: 70px;
        }
        .btn{
            margin-top: 20px;
            min-width: 150px;
            min-width: 174px;
            border-radius: 30px;
            @media (min-width:1200px) {
                min-width: 174px;
                margin-top: 40px;
            }
        }
    }
    
    
}

.nav-tab--content-2 {
    &.nav-tab--content-2--02{
        text-align: center;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        transition: 0.4s;
        margin-bottom: 35px;
        margin-left: -10px;
        border-bottom: 0;
        @media (min-width:576px) {
            border-bottom: 2px solid rgb(255, 255, 255,0.2);
        }
        @media (min-width:992px) {
            margin-bottom: 60px;
            margin-bottom: 91px;
            justify-content: space-between;
        }
        .nav-item{
            margin-left: 10px;
            margin-right: 10px;
            @media (min-width:576px) {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .nav-link {
            color: #fff!important;
            border: none;
            padding: 0 0 10px;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: normal;
            line-height: 1.3;
            display: inline-flex;
            opacity: 0.7;
            transition: 0.4s;
            margin-right: 10px;
            border-bottom: 2px solid transparent;
            justify-content: space-around;
            @media (min-width:480px) {
                padding: 18px 0;
                font-size: 18px;
            }
            @media (min-width:992px) {
                font-size: 20px;
            }
    
            &.active {
                background-color: transparent;
                color: #FFC83E!important;
                opacity: 1;
                border-bottom: 2px solid #FFC83E!important;
            }
            &:last-child{
              margin-right: 0;
            }
        }
    }
   
}