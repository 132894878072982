.feature-section-06 {
    padding-top: 55px;
    padding-bottom: 55px;
    position: relative;
    &__shape{
        position: absolute;
        bottom: -11%;
        left: 0;
        width: 5%;
        z-index: 11;    
    }
    @media (min-width:768px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &__widget-card {
        padding: 0 25px;
        @media (min-width:992px) {
            padding: 0 30px;
            
        }
        .widget-icon {
            min-width: 60px;
            min-height: 60px;
            max-width: 60px;
            max-height: 60px;
            border-radius: 500px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .widget-text {
            p {
                line-height: 26px;
            }
        }
    }

    .col-lg-4 {
        &:nth-child(1) {
        .feature-section-06__widget-card {
            .widget-icon {
                background: rgb(43, 89, 255,0.3);
                }

            }
        }
        &:nth-child(2) {
        .feature-section-06__widget-card {
            .widget-icon {
                background: rgb(253, 76, 92,0.3);
                }

            }
        }
        &:nth-child(3) {
        .feature-section-06__widget-card {
            .widget-icon {
                background: rgb(0, 187, 152,0.3);
                }

            }
        }
    }
}