@use "../abstracts/colors" as * ;
@use "./menu";
/*Preloader cs@s*/
.site
div#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

div#loading img {
  z-index: 9999;
  width: 25%;
  @media (min-width:576px){
    width: 17%;
  }
  @media (min-width:768px){
    width: 15%;
  }
  @media (min-width:992px) {
    width: 7%;
  }
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff !important;
}

.preloader img {
  width: 500px;
}

/*Header Css StaRT*/

.site-navbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width:992px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-top: 0px;
    align-items: center;
  }

  .menu-block-wrapper {
    @media (min-width:992px) {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
  .site-header__btns{
    @media (min-width:992px){
      display: flex;
    }
  }
}

.site-header {
  @media (max-width:992px) {
    padding-top: 15px;
  }
  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  &--sticky {
    &:not(.mobile-sticky-enable) {
      position: absolute !important;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;

      @media (min-width:992px) {
        position: fixed !important;
        transition: 0.4s;

        &.scrolling {
          transform: translateY(-100%);
          transition: 0.4s;
        }

        &.reveal-header {
          transform: translateY(0%);
          box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
          z-index: 1000;

          a {
            color: $header-link-color;
          }

          .btn-link {
            color: $btn-link-color;
          }
        }
      }
    }

    &.mobile-sticky-enable {
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;
      position: fixed !important;
      transition: 0.4s;

      &.scrolling {
        transform: translateY(-100%);
        transition: 0.4s;
      }

      &.reveal-header {
        transform: translateY(0%);
        box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
        z-index: 9999;
      }
    }
  }

  &--menu {
    
    &-center {
      --button-width:265px;
      --translateLG :35px;
      --translateXL :30px;
      .container {
        position: relative;
      }

      .menu-block-wrapper {
        position: static;
      }

      // .menu-block {
      //   // @media (min-width:992px) {
      //   //   margin-left: auto;
      //   //   margin-right: auto;
      //   // }
      //   // .site-menu-main{
      //   //   margin-left: auto;
      //   //   margin-right: auto;
      //   // }
      // }
      .menu-block-inner{
        @media (min-width:992px) {
          width: 100%;
        }
      }
    }
    &-left {
      .container-fluid {
        .sub-menu.megamenu {
          @media (min-width:992px) {
            left: 50%;
            transform: translateX(-50%) translateY(10px);
          }
        }

        .nav-item.has-megamenu:hover>.sub-menu {
          @media (min-width:992px) {
            transform: translateX(-50%) translateY(-2px);
            left: 50%;
          }
        }
      }

      .menu-block {
        
        .site-menu-main {
          justify-content: flex-start;
          // padding-left: 30px;
          @media (min-width:992px) {
            padding-left: 55px;
            
          }
        }

        @media (min-width:1200px) {
          width: 100%;
        }
      }
    }

    &-right {
      .menu-block {
        margin-left: auto;
      }

      >.container-fluid {
        .sub-menu.megamenu {
          @media (min-width:992px) {
            left: 100%;
            transform: translateX(-100%) translateY(10px);
          }
        }

        .nav-item.has-megamenu:hover>.sub-menu {
          @media (min-width:992px) {
            transform: translateX(-100%) translateY(-10px);
            left: 100%;
          }
        }
      }
    }
  }
  &__brand{
    .logo-white{
      display: none;
    }
    .logo-black{
      display: block;
    }
  } 

  .btn-group{
    position: relative;
    display: inline-flex;
    align-items: center;
  }
}

.header-btns {
  @media (min-width:480px) {
    margin-right: 15px;
  }

  @media (min-width:992px) {
    margin-right: 0;
  }
}

.dynamic-sticky-bg {
  &.reveal-header {
    background: var(--bg);
  }

  &.dark-mode-texts {
    .menu-block.active .nav-link-item {
      color: $header-link-color !important;
    }

    .menu-social-share li {
      a {
        color: var(--color-headings-2);
      }
    }
  }
  
}

.sticky-bg-white {
  &.reveal-header {
    background: #fff;

    .dark-version-logo {
      display: none !important;
    }

    .light-version-logo {
      display: block !important;
    }

    .menu-sep-left {
      position: relative;

      &::before {
        content: "";
        border-left: rgba($header-link-color, 0.5) !important;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 26px;
        z-index: 9;
      }
    }
  }
}

.notification-block {
  position: relative;
  .count {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -4px;
    margin-right: -5px;
  }
}


.site-header--dark {
  .nav-link-item {
    color: #fff;
  }
  .mobile-menu-trigger span{
    background-color:rgba(#fff,.8);
    &:before,&:after{
      background-color:rgba(#fff,.8);
    }
  }
}

/*Custer button block */
.site-header__btns--01{
  display: flex;
}
.btn-group{
  .btn{
    height: 45px;
    min-width: 120px;
  }
}

.site-header__btns{
    @media (max-width:992px) {
      display: flex;
      padding: 25px 20px 0!important;
      flex-direction: column;
    }
}

.menu-block-inner{
  @media (min-width:992px) {
    display: flex;
     align-items: center;
  }
}

/* Header Center */ 
.site-header--menu-center{
  .menu-block{
    .site-menu-main{
      padding: 0;
    }
  }
  .site-menu-main{
    margin: auto;
  }  
}

.menu-block:not(.active){
  @media (max-width:992px) {
    opacity: 0;
  }
}

/* Header Adjustment */ 
.site-header--menu-center-adjustment{
  @media (min-width:992px) {
    .menu-block-inner{
      margin-left: 1.5%;
    }
  }
}

/* Header Logo Dark */
.site-header--logo-dark{
  .site-header__brand {
    .logo-white {
        display: block;

    }
    .logo-black {
        display: none;
    }
}
}
/* Header trigger Dark */
.mobile-menu-trigger-dark{
  .mobile-menu-trigger{
  span{
    background-color: black;
    &::before,
    &::after{
      background-color: black;

    }
  }
}
}
