.hero-section-05{
    background-color: #EFF3F9;
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    @media (min-width:992px) {
        padding-top: 151px;
    }
    &__content{
        @media (min-width:575px) {
            margin-bottom: 70px;
        }
        @media (min-width:768px) {
            margin-bottom: 70px;
        }
        @media (min-width:992px) {
            margin-bottom: 100px;
        }
        @media (min-width:1200px) {
            margin-bottom: 210px;
        }
        .text{
            font-weight: 600;
        }
        .btn{
            margin-top: 25px;
            min-width: 156px;
            height: 50px;
            &:focus{
                box-shadow: none !important;
            }
            @media (min-width:992px) {
                margin-top: 40px;
                min-width: 196px;
                height: 60px;
            }
        }
    }
    &__image-group{
        position: relative;
        @media (min-width:575px) {
            margin-bottom: -82%;
            margin-right: -24%;
        }
        @media (min-width:768px) {
            margin-bottom: -23%;
        }
        @media (min-width:992px) {
            margin-right: -46.5%;
            margin-left: -18px;
            margin-bottom: 0%;
        }
        @media (min-width:1200px) {
            margin-right: -46.5%;
            margin-left: -18px;
            
        }
        &--shape{
            position: absolute;
            top: 6.3%;
            left: 34%;
            width: 14.1%;
        }
    }
    &__shape-1{
        position: absolute;
        top: 14.8%;
        left: 0%;
        width: 3.6%;    
    }
    &__shape-2{
        position: absolute;
        bottom: 11%;
        right: 0%;
        width: 4%;
    }
    &__shape-3{
        position: absolute;
        bottom: 16%;
        left: 47%;
        width: 2.4%;    
    }
}