.hero-section-04 {
    background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
    position: relative;
    z-index: 11;
    overflow: hidden;
    &__bg-shape{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        opacity: 30%;
        img{
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        padding-top: 139px;
        padding-bottom: 45px;
        @media (min-width:768px) {
            padding-bottom: 65px;
        }
        .title {
            margin-bottom: 20px;
        }

        &--form {
            margin: auto;
            margin-top: 36px;
            @media(min-width: 768px) {
                max-width: 526px;
            }
            .form-control {
                min-height: 66px;
                padding-left: 30px;
                @media (min-width: 576px){
                    border-radius: 500px;
                }
            }
            .btn{
                @media (min-width: 576px){
                    min-width: 181px !important;
                    margin: 8px;
                    border-radius: 500px;

                }
            }
        }
    }
    &__image-group{
        text-align: center;
        position: relative;
        z-index: 1;
        img{
            max-width: 80%;
            @media (min-width:576px) {
                max-width: 100%;
            }
        }
        .wrap{
            z-index: -11;
        }
        &--half-shape{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }

    }
    &__shape-1{
        position: absolute;
        width: 4.5%;
        bottom: 31%;
        right: 0;
        z-index: -1;
        img{
            width: 100%;
        }
    }
    &__shape-2{
        position: absolute;
        width: 4.4%;
        left: -3px;
        bottom: 3%;
        z-index: -1;
        img{
            width: 100%;
        }
    }
    &__shape-3{
        position: absolute;
        width: 4.3%;
        top: 52%;
        left: 24%; 
        z-index: -1;   
        img{
            width: 100%;
        }
    }
}