@use "../../abstracts/mixins" as utils ;
@use "../../abstracts/colors" as color ;
.about-section-01 {
    background: rgb(120, 27, 255, 0.03);
    padding-top: 60px;
    padding-bottom: 78px;
    position: relative;

    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 125px;
    }

    @media (min-width:992px) {
        padding-top: 100px;
        padding-bottom: 160px;
    }
    @media (min-width:1200px) {
        padding-top: 130px;
        padding-bottom: 230px;
    }

    .scetion-shape {
        position: absolute;
        bottom: -2px;
        width: 100%;
    }

    .video-block {
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        .video-button-block{
            @include utils.absolute(0,0,100%,100%);
            @include utils.flexAllCenter;
            background:  rgba(color.$electric-violet,0.05);
        }
        img{
            border-radius: 10px;
        }
    }

    &__content {
        .subtitle {
            margin-bottom: 15px;
            @media (min-width:576px) {
                margin-bottom: 20px;

            }
        }
        .btn-l2 {
            margin-top: 30px;
            min-width: 150px;
            @media (min-width:576px) {
                margin-top: 42px;
            }
        }
    }
}