.testimonial-section-02 {
    padding-top: 55px;
    padding-bottom: 60px;

    @media (min-width:768px) {
        padding-top: 75px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 90px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 120px;
        padding-bottom: 130px;
    }

    &__title {
        .subtitle{
            margin-bottom: 20px;
        }
        margin-bottom: 45px;
        @media (min-width:768px) {
            margin-bottom: 80px;
        }
    }
}