.blogcontent-section-01 {
    padding: 60px 0;

    @media (min-width:768px) {
        padding: 80px 0;
    }

    @media (min-width:992px) {
        padding: 130px 0;
    }
    &__pagination {
        margin-top: 35px;

        @media (min-width:768px) {
            margin-top: 57px;
        }

        .pagination {
            margin: 0 -10px;

            .page-item {
                cursor: pointer;
                border-radius: 500px;
                margin: 0 10px;
                min-width: 30px;
                min-height: 30px;
                max-width: 30px;
                max-height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                border: 0.25px solid rgb(0, 11, 51, 0.8);
                box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
                transition: .3s;

                &.active {
                    background: #FD4C5C;
                    border-color: #FD4C5C;

                    a {
                        color: white !important;
                    }
                }

                &:hover {
                    background: #FD4C5C;
                    border-color: #FD4C5C;

                    a {
                        color: white !important;
                    }
                }

                @media (min-width:576px) {
                    min-width: 40px;
                    min-height: 40px;
                    max-width: 40px;
                    max-height: 40px;
                }

                a {
                    color: rgb(0, 11, 51, 0.8);
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    transition: .3s;
                }
            }
        }
    }
}

/* Blog Sigebar Start */
.blog-sidebar-01 {
    background: #FFFFFF;
    padding: 20px;
    padding-bottom: 42px;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
    border-radius: 5px;
    @media (min-width:576px) {
        padding: 40px;
    }
    &--searchbox {
        position: relative;
        margin-bottom: 20px;

        @media (min-width:576px) {
            margin-bottom: 30px;
        }

        .form-control {
            border: 0;
            outline: none;
            background: #FFFFFF;
            padding-left: 57px;
            padding-right: 30px;
            height: 50px;
            box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
            border-radius: 5px;
            font-size: 16px;
            line-height: 26px;
            color: #000B33;

            &::placeholder {
                color: rgb(0, 11, 51, 0.5);
            }

            @media (min-width:576px) {
                height: 60px;
            }
        }

        .icon {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &--tab {
        .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 1.3;
            padding-bottom: 15px;

            @media (min-width:576px) {
                font-size: 20px;
            }
        }

        .nav {
            .nav-link {
                text-align: start;
                color: rgb(0, 11, 51, 0.8);
                line-height: 1.625;
                font-size: 16px;
                padding: 15px 0;
                border-top: 1px solid rgb(55, 124, 253, 0.1);

                &:last-child {
                    padding-bottom: 0;
                }

                &.active {
                    background: none;
                    color: #FD4C5C;
                }
            }
        }
    }

    &--posts {
        margin-top: 60px;

        .title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.3;
            padding-bottom: 15px;
            border-bottom: 1px solid rgb(55, 124, 253, 0.1);
            ;

            @media (min-width:576px) {
                font-size: 20px;
            }
        }

        .post-blogs {
            display: flex;
            align-items: center;
            margin-top: 15px;

            &--img {
                margin-right: 20px;
                img {
                    width: 100%;
                    @media (min-width:1200px) {
                        width: inherit;
                    }
                    border-radius: 10px;
                }
            }

            &--texts {
                h6 {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    padding-bottom: 10px;
                }

                span {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }
    }

    &--tags {
        margin-top: 60px;

        .title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.3;
            padding-bottom: 15px;
            border-bottom: 1px solid rgb(55, 124, 253, 0.1);
            ;

            @media (min-width:576px) {
                font-size: 20px;
            }
        }

        .tag {
            padding: 0;
            margin: 0;
            list-style: none;

            &-item {
                font-weight: 600;
                font-size: 16px;
                line-height: 1.5;
                padding: 10px 27px;
                border: 0.5px solid #010C16;
                border-radius: 3px;
                display: inline-block;
                background: white;
                color: rgb(1, 12, 22, 0.8);
                margin: 15px;
                margin-left: 0;

                &.active {
                    border-color: #FD4C5C;
                    background: #FD4C5C;
                    color: white;
                }
            }
        }
    }
}
/* Blog Sigebar End */