.content-section-02{
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    @media (min-width:768px){
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px){
        padding-top: 93px;
        padding-bottom: 93px;
    }
    @media (min-width:1200px){
        padding-top: 192px;
        padding-bottom: 192px;
    }
    &__image{
        @media (min-width:992px){
            position: absolute;
            left: 5%;
            top: 50%;
            transform: translateY(-50%);
            width: 50%; 
    
        }
    }
    &__widget{
        margin-top: 54px;
        @media (min-width:992px){
            padding-left: 20px;
    
        }
        .col-xl-12{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0px;
    
            }
        }
        .widget-content{
            .widget-icon{
                margin-right: 42px !important;
                min-width: 48px;
                max-width: 48px;
                min-height: 48px;
                max-height: 48px;
            }
            .widget-title {
                margin-bottom: 10px;
            }
            p{
                line-height: 26px;
            }
        }
    }
}