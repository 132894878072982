.content-section-20{
    padding: 60px 0;
    background: rgb(43, 89, 255,0.02);
    @media (min-width:768px) {
        padding: 80px 0;  
    }
    @media (min-width:992px) {
        padding-top: 98px;
        padding-bottom: 110px;    
    }
    @media (min-width:1200px) {
        padding-top: 130px;
        padding-bottom: 145px;
    }
    &__image-group{
        margin-bottom: 35px;
        @media (min-width:992px) {
            margin-bottom: 0px;
            
        }
        text-align: end;
        position: relative;
        img{
            filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
            border-radius: 10px;
        }
        &--card{
            position: absolute;
            bottom: -3%;
            left: 0;
            width: 29.5%;        
            img{
                filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
                border-radius: 10px;
            }
        }
    }
    &__content{
        .btn{
            min-width: 150px;
            margin-top: 30px;
            @media (min-width:576px) {
                margin-top: 40px;
            }
        }
    }
}