@use "../abstracts/colors" as color;

.form-subscribe-inline--01{
    position: relative;
    @media (min-width:768px) {
        max-width: 535px;
        width: 100%;
    }
    .form-control{
        border-radius: 5px;
        min-height: 60px;
        width: 100%;
        font-weight: 700;
        background-color: #fff;
        font-size: 16px;
        color: color.$stratos;
        border-color: transparent;
        &::placeholder{
            opacity: 0.6;
        }
        &:focus{
            border-color: color.$carnation;
        }
        @media (min-width:576px) {
            padding-right: 188px;
        }
    }
    .btn{
        margin-top: 10px;
        text-align: center;
        border-radius: 5px;
        width: 100%;
        height: 50px;
        @media (min-width:576px) {
            position: absolute;
            top: 0px;
            right: 0px;
            margin: 5px;
            width: 173px !important;
        }
    }
}