.feature-section-05 {
    background: rgb(0, 28, 128,0.02);
    padding-top: 55px;
    padding-bottom: 60px;

    @media (min-width:768px) {
        padding-top: 75px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 95px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 125px;
        padding-bottom: 135px;
    }

    .content-title {
        margin-bottom: 35px;
        @media (min-width:992px) {
            margin-bottom: 80px;
        }
        .subtitle{
            color: #00BB98 !important;
            margin-bottom: 20px;
        }
    }
    .feature-card{
        .card-feature-01{
            padding-left: 12px;
            padding-right: 12px;
            @media (min-width:992px) {
                padding: 0;
            }
        }
    }
      .slick-slide {
        margin: 0 12px;
      }
}