.feature-section-03{
    background: rgba(#781BFF,.02);
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px){
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width:992px){
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .section-title{
        margin-bottom: 40px;
        @media (min-width:768px){
            margin-bottom: 60px;
        }
    }
    .widget-card-01{
        background: #FFFFFF;
        /* 01 */
        box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
        border-radius: 10px;  
    }
}
