/*
    Note: This section is similar to landing 1 about section. if you have used that section you can delete the duplicate codes poointed bellow.
*/

/* duplicated code starts*/
// .team-section-01 {
//     background: rgb(43, 89, 255, 0.02);
//     padding-top: 50px;
//     padding-bottom: 60px;

//     @media (min-width:768px) {
//         padding-top: 70px;
//         padding-bottom: 80px;
//     }

//     @media (min-width:992px) {
//         padding-top: 122px;
//         padding-bottom: 138px;
//     }

//     .section-title--team-01 {
//         margin-bottom: 35px;
//         border-radius: 5px;

//         @media (min-width:768px) {
//             margin-bottom: 55px;
//         }

//         @media (min-width:992px) {
//             margin-bottom: 67px;
//         }
//     }

//     .card-team-01 {
//         background: #FFFFFF;
//         padding: 20px 20px 25px 20px;
//         border: 0;
//         transition: .3s;

//         &:hover {
//             box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
//         }
//     }

//     .card {
//         &-image {
//             img {
//                 border-radius: 5px;
//                 width: 100%;
//             }
//         }

//         &-texts {
//             margin-top: 33px;
//         }
//     }
// }
/*duplicated code ends*/
.team-section-01--2 {
    position: relative;
    &__shape {
        position: absolute;
        right: 0%;
        width: 5%;
        top: 5%;
        transform: rotate(180deg);
      }
}