.content-section-16{
    padding: 55px 0 60px;
    position: relative;
    z-index: 11;
    background: rgb(0, 28, 128,0.02);
    @media (min-width:768px) {
        padding: 90px 0 80px;
    }
    @media (min-width:992px) {
        padding: 120px 0 100px;
    }
    @media (min-width:1200px) {
        padding: 156px 0 130px;
    }
    &__shape-1{
        position: absolute;
        top: -9px;
        left: 0;
        width: 100%;
        @media (min-width:768px) {
            top: 0;
        }
    }
    &__shape-2{
        position: absolute;
        top: -16%;
        left: 0;
        width: 12%;
        z-index: 0;
    }
    &__image-group{
        position: relative;
        &--card{
            position: absolute;
            right: 3%;
            bottom: 28%;
            width: 29.3%;
            img{
                border-radius: 10px;
                width: 100%;
                filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
            }
        }
        img{
            filter: drop-shadow(-6px 6px 60px rgba(0, 0, 0, 0.08));
            border-radius: 10px; 
        }
    }
    &__content{
        &--button-group{
            margin: 0 -9px;
           margin-top: 40px;
           display: inline-flex;
           align-items: center;
           a{
               margin: 0 9px;
           }
        }
    }
    
    
    .nav-tab--content-2 {
        text-align: center;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        transition: 0.4s;
        margin-bottom: 60px;
        border: 0;
        margin-left: -10px;
        @media (min-width:768px) {
            flex-direction: row;
            border-bottom: 2px solid rgb(0, 28, 128,0.2);
        }
        @media (min-width:992px) {
            margin-bottom: 91px;
            justify-content: space-between;
        }
    
        .nav-link {
            border: none;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: normal;
            line-height: 1.3;
            padding: 15px 0;
            display: inline-flex;
            color: #2B59FF;
            opacity: 0.7;
            transition: 0.4s;
            margin-right: 10px;
            border-bottom: 2px solid transparent;
            justify-content: space-around;
            @media (min-width:480px) {
                padding: 18px 0;
                font-size: 18px;
            }
            @media (min-width:992px) {
                font-size: 20px;
            }
    
            &.active {
                background-color: transparent;
                color: #FD4C5C;
                opacity: 1;
                border-bottom: 2px solid #FD4C5C;
            }
            &:last-child{
              margin-right: 0;
            }
        }
    }
}