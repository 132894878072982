.counter-section-05 {
    padding: 55px 0 50px;
    position: relative;

    @media (min-width:768px) {
        padding: 75px 0 80px;
    }

    @media (min-width:992px) {
        padding: 90px 0 100px;
    }
    &__widget {
        .col-xl-3 {
            &:nth-child(1) {
                .widget {
                    .widget-icon {
                        i {
                            position: relative;
                            font-size: 20px;
                            color: #FD4C5C;
                            &::after {
                                animation: sonarWave-02 2s linear infinite;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                content: "";
                                background-color: rgb(253, 76, 92,0.8);
                                z-index: -1;
                                pointer-events: none;
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            &:nth-child(2) {
                .widget {
                    .widget-icon {
                        i {
                            position: relative;
                            font-size: 20px;
                            color: #00BB98;
                            &::after {
                                animation: sonarWave-02 2s linear infinite;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                content: "";
                                background-color: rgb(0, 187, 152,0.8);
                                z-index: -1;
                                pointer-events: none;
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                .widget {
                    .widget-icon {
                        i {
                            position: relative;
                            font-size: 20px;
                            color: #FFC83E;
                            &::after {
                                animation: sonarWave-02 2s linear infinite;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                content: "";
                                background-color: rgba(255, 200, 62,0.8);
                                z-index: -1;
                                pointer-events: none;
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            &:nth-child(4) {
                .widget {
                    .widget-icon {
                        i {
                            position: relative;
                            font-size: 20px;
                            color: #781BFF;
                            &::after {
                                animation: sonarWave-02 2s linear infinite;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                content: "";
                                background-color: rgba(120, 27, 255,0.8);
                                z-index: -1;
                                pointer-events: none;
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .widget-text {
        @media (min-width:768px) {
            text-align: start;
        }
    }
}