@use "../../abstracts/colors"as color;

.hero-section-01 {
    position: relative;
    z-index: 1;
    padding-top: 80px;

    @media(min-width:992px) {
        padding-top: 0;
    }

    .shape {
        position: absolute;
        bottom: -1px;
        right: 0;
        width: 100%;
        z-index: -1;

        img {
            width: 100%;
        }
    }

    &__content {
        padding-bottom: 95px;

        @media(min-width:576px) {
            padding-bottom: 191px;

        }

        @media(min-width:992px) {
            padding-top: 200px;
        }
        @media(min-width:1200px) {
            padding-top: 232px;
        }

        .title {
            margin-bottom: 20px;
            @media (min-width:992px) {
                margin-bottom: 30px;
            }
        }

        p {
            margin-bottom: 42px;

            @media (min-width:1400px) {
                padding-right: 10%;
            }
        }
    }

    &__form {
        max-width: 526px;
        .form-control {
            height: 66px;
            font-weight: 400;
        }
        .btn {
            @media (min-width:576px) {
                margin: 8px;
            }
        }
    }

    &__image-group {
        position: relative;
        z-index: 1;
        // width: 80%;
        margin-bottom: -10px;

        @media(min-width:992px) {
            margin-bottom: 0;
            margin-top: 10%;
            width: 85%;
        }

        @media(min-width:1200px) {
            // width: 80%;
            width: 85%;

        }

        @media(min-width:1400px) {
            width: 85%;
            // margin-left: -6%;

        }
    }

    &__half-image {
        position: absolute;
        left: -18%;
        top: -1%;
        width: 49%;
        z-index: -1
    }

    &__shape-1 {
        position: absolute;
        width: 14%;
        left: -11%;
        top: 21%;
        z-index: -3;

        img,
        svg {
            width: 100%;
        }
    }

    &__shape-2 {
        position: absolute;
        right: -1.5%;
        top: 15%;
        z-index: -1;

        img,
        svg {
            width: 100%;

        }
    }
}

.hero-form-01 {
    position: relative;
    max-width: calc(100% - 205px);

    .form-control {
        border-radius: 5px;
        min-height: 66px;
        width: 100%;
        background-color: #fff;
        font-size: 16px;
        color: color.$stratos;
        border-color: transparent;

        &::placeholder {
            opacity: 0.6;
        }

        &:focus {
            border-color: color.$carnation;
        }
    }

    .btn {
        margin-top: 10px;
        min-width: 180px;
        height: 50px;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        border-radius: 5px;

        @media (min-width:576px) {
            position: absolute;
            top: 0;
            right: 0;
            margin: 8px;
        }
    }
}