.content-section-24 {
    padding: 60px 0;
    position: relative;

    @media (min-width:768px) {
        padding: 80px 0;
    }

    @media (min-width:992px) {
        padding: 130px 0;
    }

    &__shape-1 {
        position: absolute;
        left: 0%;
        width: 5%;
        top: 9%;
    }

    &__shape-2 {
        position: absolute;
        right: 0%;
        width: 3%;
        bottom: 19%;
    }

    &__tab {
        background: white;
        filter: drop-shadow(0px 4px 40px rgba(43, 89, 255, 0.08));
        border-radius: 10px;
        &--title {
            padding: 25px 30px;
            padding-bottom: 28px;
            background: linear-gradient(90.28deg, #001C80 0.26%, #153ABF 30.33%, #2B59FF 99.79%);
            border-radius: 10px 10px 0px 0px;
            font-weight: bold;
            font-size: 26px;
            line-height: 1.4;
            color: white !important;

            @media (min-width:992px) {
                font-size: 30px;
            }
        }

        .nav {
            padding: 40px 30px;
            .nav-link {
                text-align: start;
                padding: 30px 0;
                border-bottom: 1px solid rgb(55, 124, 253, 0.1);
                font-size: 18px;
                line-height: 1.3;
                color: #000B33;

                @media (min-width:992px) {
                    font-size: 20px;
                }

                &.active {
                    background: none;
                    color: #FD4C5C;
                }

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    border: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    &__tab-content {
        &--img {
            margin-bottom: 30px;

            img {
                border-radius: 10px;
            }
        }

        .text {
            font-size: 18px;
            line-height: 1.3;
            @media (min-width:992px) {
                font-size: 20px;
            }
        }

        &--widget {
            margin-top: 40px;

            .title {
                font-weight: bold;
                font-size: 24px;
                line-height: 1.4;
                color: #010C16 !important;
                padding-bottom: 16px;
                @media (min-width:576px) {
                    font-size: 30px;
                }
            }

            .widget {
                display: flex;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }

                &--icon {
                    margin-right: 20px;
                    min-width: 40px;
                    max-width: 40px;
                    min-height: 40px;
                    max-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 500px;
                    @media (min-width:768px) {
                        min-width: 50px;
                        max-width: 50px;
                        min-height: 50px;
                        max-height: 50px; 
                    }
                    &.icon-bg-1 {
                        background: rgb(55, 124, 253, 0.1);
                    }

                    &.icon-bg-2 {
                        background: rgb(253, 76, 92, 0.1);
                    }

                    &.icon-bg-3 {
                        background: rgb(0, 187, 152, 0.1);
                    }
                }

                &--text {
                    p {
                        font-size: 14px;
                        @media (min-width:992px) {
                            font-size: 16px;
                        }
                        span {
                            font-weight: 600;
                            font-size: 18px;
                            color: #377CFD;
                            line-height: 1.3;
                            @media (min-width:992px) {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}