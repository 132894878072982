.about-section-05{
    position: relative;
    padding: 60px 0;
    background: rgb(43, 89, 255, 0.02);
    @media (min-width:768px) {
        padding: 80px 0;
    }
    @media (min-width:992px) {
        padding: 100px 0;
    }
    @media (min-width:1200px) {
        padding: 130px 0;
    }
    &__image-group{
        @media (min-width:992px) {
            margin-left: -70%;
        }
        @media (min-width:1200px) {
            margin-left: -106%;
            margin-right: -23%;
        }
    }
    &__content{
        .btn{
            margin-top: 30px;
            min-width: 150px;
            @media (min-width:768px) {
                margin-top: 40px;
            }
        }
    }
    &__shape{
        position: absolute;
        top: 0;
        right: 0;
        width: 12.9%;    
    }
}