.hero-section-09{
    background: rgb(0, 187, 152,0.05);
    padding-top: 107px;
    padding-bottom: 60px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @media (min-width:768px) {
        padding-top: 180px;
        padding-bottom: 80px;    
    }
    @media (min-width:992px) {
        padding-top: 160px;
        padding-bottom: 100px;
    }
    @media (min-width:1200px) {
        padding-top: 204px;
        padding-bottom: 144px;
    }
    &__bg-shpae{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__content{
        @media (min-width:992px) {
            margin-bottom: 40px;
        }
    }
    &__image-group{
        position: relative;
        @media (min-width:992px) {
            margin-right: -55%;
            margin-left: 63px;
        }
        &--shape-1{
            position: absolute;
            left: -10%;
            width: 21.5%;
            bottom: 11.5%;
            z-index: -1;      
        }
        &--shape-2{
            position: absolute;
            top: -13.9%;
            right: 9%;
            width: 18.5%;
            z-index: -1;
        }
    }
    &__content{
        .text{
            padding-top: 15px;
            font-weight: 600;
        }
        &--form{
            margin-top: 30px;
            @media (min-width:992px) {
                margin-top: 40px; 
            }
            .form-control {
                height: 66px;
                font-weight: 400;
            }
            .btn {
                @media (min-width:576px) {
                    margin: 8px;
                }
            }
        }
    }
    &__shape{
        position: absolute;
        left: 0;
        top: 19%;
        width: 5.5%;
    }
}