.hero-section-07 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    &__circle-1{
        position: absolute;
        top: 17%;
        left: 3%;
        border-radius: 500px;
        width: 20px;
        height: 20px;
        background: #FFC83E;
    }
    &__circle-2{
        position: absolute;
        top: 19%;
        right: 13%;
        border: 3px solid #00BB98;
        border-radius: 500px;
        width: 20px;
        height: 20px;
    }
    &__circle-3{
        position: absolute;
        top: 60%;
        right: 2%;
        border-radius: 500px;
        width: 20px;
        height: 20px;
        background: #FD4C5C;
    }
    &__circle-4{
        position: absolute;
        top: 80%;
        left: 44%;    
        border-radius: 500px;
        width: 20px;
        height: 20px;
        border: 3px solid #FFC83E;
    }
    &__circle-5{
        position: absolute;
        bottom: 17%;
        left: 2%;
        border-radius: 500px;
        width: 10px;
        height: 10px;
        background: #FD4C5C;
    }
    &__bottom-shape {
        position: absolute;
        bottom:-1px;
        left: 0;
        width: 100%;
        img {
            width: 100%;
        }
    }

    &__content {
        margin-top: 120px;
        @media (min-width:992px) {
            margin-top: -110px;
        }
        .text {
            margin-top: 15px;
        }

        &--btn-group {
            margin-top: 40px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .btn {
                min-width: 200px;
                height: 60px;
                border-radius: 10px;
                margin-bottom: 17px;
                margin-right: 55px;
                @media (min-width:768px) {
                    min-width: 220px;
                    height: 60px;
                    margin-bottom: 0;
                    margin-right: 17px;
                }
                @media (min-width:1200px) {
                    min-width: 280px;
                    height: 70px;
                }
            }

            .btn-2 {
                display: flex;
                align-items: center;

                .icon {
                    min-width: 60px;
                    min-height: 60px;
                    max-width: 60px;
                    max-height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    border-radius: 500px;
                    margin-right: 16px;
                }

                span {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 26px;
                    color: white;
                }
            }
        }
    }

    &__image-group {
        position: relative;
        z-index: -1;
        @media (min-width:992px) {
            margin-top: 95px;

        }
        img{
            max-width: 100%;
        }
        &--shape{
            position: absolute;
            top: 18%;
            right: 5%;
            width: 49%;
            z-index: -1;
        }
    }
    &__shape-1{
        position: absolute;
        bottom: 2%;
        left: 12.5%;
        width: 6.9%;
    }
}